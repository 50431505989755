import React from 'react';
import { generateStoryElementData } from '../../static-data';
import { Window } from '../../utils/interfaces';

interface ICssProps {
  textColor?: string;
  darkTextColor?: string;
  hyperlinkColor?: string;
  iconType?: string;
  darkBlockQuoteColor?: string;
  darkBackgroundShade?: string;
  blockQuoteColor?: string;
  backgroundShade?: string;
  darkBorderColor?: string;
  borderColor?: string;
  darkHeaderBgColor?: string;
  headerBgColor?: string;
  iconColor?: string;
  darkIconColor?: string;
}

interface IStoryElementProps {
  componentName: string;
  subtype: string;
  enableDarkModePreview: boolean;
  template?: string;
  css?: ICssProps;
  opts?: object;
}

declare var window: Window;

export const StoryElement = (props: IStoryElementProps) => {
  if (window.QT_STORY_COMPONENTS) {
    const { componentName, subtype, enableDarkModePreview, css = {}, ...elementConfig } = props;
    const StoryElementComponent = window.QT_STORY_COMPONENTS[componentName];
    const getUpdatedElementConfig = () => {
      switch (componentName) {
        case 'Text':
          return {
            ...elementConfig,
            css: {
              hyperlinkColor: css.hyperlinkColor || '#2f81cd',
              textColor: enableDarkModePreview ? css.darkTextColor || '#fff' : css.textColor || '#000'
            }
          };
        case 'BlockQuote':
          return {
            ...elementConfig,
            css: {
              iconType: css.iconType || 'edgeIcon',
              blockQuoteColor: (enableDarkModePreview ? css.darkBlockQuoteColor : css.blockQuoteColor) || '',
              backgroundShade: (enableDarkModePreview ? css.darkBackgroundShade : css.backgroundShade) || ''
            }
          };
        case 'Quote':
          return {
            ...elementConfig,
            css: {
              borderColor: (enableDarkModePreview ? css.darkBorderColor : css.borderColor) || '#2f81cd'
            }
          };
        case 'Blurb':
          return {
            ...elementConfig,
            css: {
              borderColor: (enableDarkModePreview ? css.darkBorderColor : css.borderColor) || '#3cab92'
            }
          };
        case 'Summary':
          return {
            ...elementConfig,
            css: {
              headerBgColor: (enableDarkModePreview ? css.darkHeaderBgColor : css.headerBgColor) || ''
            }
          };
        case 'AlsoRead':
          return {
            ...elementConfig,
            css: {
              textColor: (enableDarkModePreview ? css.darkTextColor : css.textColor) || ''
            }
          };
        case 'QuestionAnswer':
          switch (subtype) {
            case 'q-and-a':
              return {
                ...elementConfig,
                css: {
                  iconColor: (enableDarkModePreview ? css.darkIconColor : css.iconColor) || ''
                },
                opts: {
                  type: 'q-and-a'
                }
              };
            case 'question':
              return {
                ...elementConfig,
                css: {
                  iconColor: (enableDarkModePreview ? css.darkIconColor : css.iconColor) || ''
                },
                opts: {
                  type: 'question'
                }
              };
            case 'answer':
              return {
                ...elementConfig,
                css: {
                  iconColor: (enableDarkModePreview ? css.darkIconColor : css.iconColor) || ''
                },
                opts: {
                  type: 'answer'
                }
              };
          }
        default:
          return { ...elementConfig, css };
      }
    };
    const elementsConfig = getUpdatedElementConfig();
    return <StoryElementComponent element={generateStoryElementData(subtype)} {...elementsConfig} />;
  }
  return null;
};
