import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { get, camelCase } from 'lodash';
import { TextArea } from '@quintype/em/components/text-area';
import styles from './styles.module.css';
import {
  updateBackupConfig,
  updateConfigVersion,
  updateHaveUnsavedChanges,
  updateHistoryActionStatus,
  updatePBConfig
} from '../../actions/builder-action';
import { updateCustomCodeGeneralSettings } from '../../actions/custom-code-action';
import { checkValidEncodedCustomCode, encodeCustomCode, saveConfig } from '../../utils/utils';
import { Button } from '@quintype/em/components/button';
import { IParams } from '../../utils/interfaces';
import { Close } from '../../atoms/close-svg';
import { ConfirmationPopup } from '../confirmation-popup/index';
import { SaveMessage } from '../save-message';

const supportedPages = [{ pageName: 'ampGenericStoryPage', displayName: 'AMP Story Page' }];
const getPageDisplayName = (_pageName: string) => supportedPages.find(page => page.pageName === _pageName)?.displayName;

const AmpCustomCode = () => {
  const [currentTab, setCurrentTab] = useState('ampGenericStoryPage');
  const [showSaveMsg, setShowSaveMsg] = useState(false);
  const [showConfirmationPopup, toggleConfirmationPopup] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { publisherId = '', domain } = useParams<IParams>();
  const { insertToHead = '', insertToBody = '' } = useSelector((state: any) =>
    get(state, ['builder', 'config', 'general', 'tags', currentTab], {})
  );
  const { config, haveUnsavedChanges, backupConfig, editControl } = useSelector(state => get(state, ['builder']));
  const hasEditAccess = get(editControl, ['access'], false);

  const updateSettings = (key: string, value: string, currentValue: string) => {
    dispatch(
      updateCustomCodeGeneralSettings({
        [currentTab]: {
          ...{ insertToHead, insertToBody },
          ...{ [key]: encodeCustomCode(value) }
        }
      })
    );

    dispatch(updateHaveUnsavedChanges(true));
  };

  const navigateToHome = () => {
    history.push(`/accounts/${publisherId}/${domain}`);
  };

  const onActionButtonClick = async (navigate: boolean = true) => {
    const updatedConfig = { ...config, version: config.version + 1 };
    const { activeVersion = 0, actionButtonStatus } = await saveConfig(publisherId, updatedConfig, domain);
    batch(() => {
      dispatch(updateConfigVersion(activeVersion));
      dispatch(updateHistoryActionStatus(actionButtonStatus));
      dispatch(updateBackupConfig({ config }));
      dispatch(updateHaveUnsavedChanges(false));
    });
    if (navigate) {
      navigateToHome();
    }
  };

  const onCancel = () => toggleConfirmationPopup(false);

  const onDiscard = () => {
    dispatch(updatePBConfig(backupConfig));
    toggleConfirmationPopup(false);
    dispatch(updateHaveUnsavedChanges(false));
    navigateToHome();
  };

  const onClose = () => {
    if (haveUnsavedChanges) {
      toggleConfirmationPopup(true);
    } else {
      navigateToHome();
    }
  };

  const insertCode = (label: string, tag: string, value: string, key: string) => (
    <TextArea
      label={label}
      placeholder={`Paste any valid AMP HTML code here. The code will be inserted to the ${tag}`}
      value={checkValidEncodedCustomCode(value)}
      onChange={(data: string) => updateSettings(key, data, currentTab)}
      disabled={!hasEditAccess}
    />
  );

  const saveHandler = () => {
    onActionButtonClick(false);
    setShowSaveMsg(true);
    setTimeout(() => {
      setShowSaveMsg(false);
    }, 1500);
  };

  return (
    <div className={styles.tagsGeneralSettingsWrapper}>
      <div>
        <h4>Insert code</h4>
        {supportedPages
          .map(page => page.displayName)
          .map((page, i) => (
            <div onClick={() => setCurrentTab(supportedPages[i].pageName)} key={page}>
              {page}
            </div>
          ))}
      </div>
      <div>
        <div className={styles.saveWrapper}>
          <h3>Insert code in {getPageDisplayName(currentTab)}</h3>
          {hasEditAccess && (
            <Button type="primary" onClick={saveHandler}>
              Save
            </Button>
          )}
        </div>
        {insertCode('Insert to head', '<head>', insertToHead, 'insertToHead')}
        {insertCode('Insert to body', '<body>', insertToBody, 'insertToBody')}
      </div>
      <div className={styles.closeIcon} onClick={onClose}>
        <Close />
      </div>
      {showConfirmationPopup && (
        <ConfirmationPopup onSave={onActionButtonClick} onCancel={onCancel} onDiscard={onDiscard} />
      )}
      {showSaveMsg && <SaveMessage />}
    </div>
  );
};

export default AmpCustomCode;
