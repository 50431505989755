import { FieldLabel } from '@quintype/em/components/field-label';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateRowTitleStyle } from '../../actions/builder-action';
import { ThemeSetter } from '../theme-setter';
import rowTitleData from './row-title-data';
import styles from './row-title.module.css';

interface IProps {
  description?: string;
}

export const RowTitleStyle: React.FunctionComponent<IProps> = ({ description }) => {
  const { enableDarkModePreview = false } = useSelector(state => get(state, ['builder', 'config'], {}));
  const { underLineColor, darkUnderLineColor } = useSelector(state =>
    get(state, ['builder', 'config', 'general', 'rows', 'titleStyle'], '')
  );
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const updateStyleName = (name: string, index: number) => {
    setSelectedIndex(index);
    dispatch(updateRowTitleStyle({ type: name }));
  };

  return (
    <>
      <FieldLabel label="Choose design" classname={styles.label} />
      {rowTitleData.map((item: { name: string; svg: string }, index: number) => (
        <div
          key={index}
          className={selectedIndex === index ? styles.selectedImgWrapper : styles.imageWrapper}
          onClick={() => updateStyleName(item.name, index)}
        >
          <img src={item.svg} className={styles.image} alt="Row title style" />
        </div>
      ))}
      {description && <p className={styles.description}>{description}</p>}
      <ThemeSetter
        label="Choose color"
        updateTheStore={(key: string, color: string) => dispatch(updateRowTitleStyle({ [key]: color }))}
        lightColorExtract={underLineColor}
        lightUpdateStoreKey="underLineColor"
        darkColorExtract={darkUnderLineColor}
        darkUpdateStoreKey="darkUnderLineColor"
        lightDefaultColor="#3cab92"
        darkDefaultColor="#3cab92"
        enableDarkModePreview={enableDarkModePreview}
      />
    </>
  );
};
