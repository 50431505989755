import { Checkbox } from '@quintype/em/components/checkbox';
import Select from '@quintype/em/components/select';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { headerSettings, updateHeaderSettings } from '../../../actions/header-action';
import { defaultDarkTheme, defaultTheme } from '../../../constant';
import { ThemeSetter } from '../../../molecules/theme-setter';
import { IDarkMode, IDateTime, IHeaderConfig, IPBState } from '../../../utils/interfaces';
import { ColorPicker } from '../../../molecules/color-picker';

import { updateNextConfig } from '../../../actions/builder-action';

import styles from './header-settings.module.css';

export const PrimaryColorPicker = () => {
  const dispatch = useDispatch();
  const updateHeaderColors = (key: string, value: string) => dispatch(updateHeaderSettings({ [key]: value }));

  const { enableDarkModePreview = false, header } = useSelector((state: IPBState) => get(state, ['builder', 'config']));
  const {
    primaryBackgroundColor = '',
    primaryTextColor = '',
    darkPrimaryBackgroundColor = '',
    darkPrimaryTextColor = ''
  }: IHeaderConfig = header;

  return (
    <>
      <ThemeSetter
        label="Primary header background color"
        updateTheStore={updateHeaderColors}
        lightColorExtract={primaryBackgroundColor}
        lightUpdateStoreKey="primaryBackgroundColor"
        darkColorExtract={darkPrimaryBackgroundColor}
        darkUpdateStoreKey="darkPrimaryBackgroundColor"
        lightDefaultColor="#3cab92"
        darkDefaultColor="#292929"
        enableDarkModePreview={enableDarkModePreview}
      />
      <ThemeSetter
        label="Primary header text color"
        updateTheStore={updateHeaderColors}
        lightColorExtract={primaryTextColor}
        lightUpdateStoreKey="primaryTextColor"
        darkColorExtract={darkPrimaryTextColor}
        darkUpdateStoreKey="darkPrimaryTextColor"
        lightDefaultColor={defaultTheme}
        darkDefaultColor={defaultTheme}
        enableDarkModePreview={enableDarkModePreview}
      />
    </>
  );
};

export const SecondaryColorPicker = () => {
  const dispatch = useDispatch();
  const updateHeaderColors = (key: string, value: string) => dispatch(updateHeaderSettings({ [key]: value }));
  const { enableDarkModePreview = false, header } = useSelector((state: IPBState) => get(state, ['builder', 'config']));
  const {
    secondaryBackgroundColor = '',
    secondaryTextColor = '',
    darkSecondaryBackgroundColor = '',
    darkSecondaryTextColor = ''
  }: IHeaderConfig = header;

  return (
    <>
      <ThemeSetter
        label="Secondary header background color"
        updateTheStore={updateHeaderColors}
        lightColorExtract={secondaryBackgroundColor}
        lightUpdateStoreKey="secondaryBackgroundColor"
        darkColorExtract={darkSecondaryBackgroundColor}
        darkUpdateStoreKey="darkSecondaryBackgroundColor"
        lightDefaultColor={defaultTheme}
        darkDefaultColor="#303030"
        enableDarkModePreview={enableDarkModePreview}
      />
      <ThemeSetter
        label="Secondary header text color"
        updateTheStore={updateHeaderColors}
        lightColorExtract={secondaryTextColor}
        lightUpdateStoreKey="secondaryTextColor"
        darkColorExtract={darkSecondaryTextColor}
        darkUpdateStoreKey="darkSecondaryTextColor"
        lightDefaultColor="#333333"
        darkDefaultColor={defaultTheme}
        enableDarkModePreview={enableDarkModePreview}
      />
    </>
  );
};

interface IStore {
  builder: {
    config: {
      general: {
        nextConfig: {
          header: {
            backgroundColor: string;
          };
        };
      };
    };
  };
}

export const AheadNxtColorPicker = () => {
  const dispatch = useDispatch();
  const updateHeaderColors = (key: string, value: string) => dispatch(updateNextConfig({ [key]: value }));
  const backgroundColor = useSelector((state: IStore) =>
    get(state, ['builder', 'config', 'general', 'nextConfig', 'header', 'backgroundColor'], '#3cab92')
  );

  return (
    <>
      <ColorPicker
        label="Background Color"
        colorExtract={backgroundColor}
        updateStoreKey="backgroundColor"
        updateTheStore={updateHeaderColors}
      />
    </>
  );
};

export const HamburgerAndMegaMenuSettings = () => {
  const dispatch = useDispatch();

  const updateHeaderColors = (key: string, value: string) => dispatch(updateHeaderSettings({ [key]: value }));
  const { enableDarkModePreview = false, header } = useSelector((state: IPBState) => get(state, ['builder', 'config']));
  const {
    hamburgerAndMegamenuBackgroundColor = '',
    hamburgerAndMegamenuDarkBackgroundColor = '',
    hamburgerAndMegamenuTextColor = '',
    hamburgerAndMegamenuDarkTextColor = '',
    enableHamburgerLogo = true
  } = header || {};

  const hamburgerLogo = (keyName: string, keyValue: boolean) => dispatch(updateHeaderSettings({ [keyName]: keyValue }));

  return (
    <>
      <ThemeSetter
        label="Background Color"
        updateTheStore={updateHeaderColors}
        lightColorExtract={hamburgerAndMegamenuBackgroundColor}
        lightUpdateStoreKey="hamburgerAndMegamenuBackgroundColor"
        darkColorExtract={hamburgerAndMegamenuDarkBackgroundColor}
        darkUpdateStoreKey="hamburgerAndMegamenuDarkBackgroundColor"
        lightDefaultColor="#fff"
        darkDefaultColor={defaultDarkTheme}
        enableDarkModePreview={enableDarkModePreview}
      />
      <ThemeSetter
        label="Text color"
        updateTheStore={updateHeaderColors}
        lightColorExtract={hamburgerAndMegamenuTextColor}
        lightUpdateStoreKey="hamburgerAndMegamenuTextColor"
        darkColorExtract={hamburgerAndMegamenuDarkTextColor}
        darkUpdateStoreKey="hamburgerAndMegamenuDarkTextColor"
        lightDefaultColor="#000"
        darkDefaultColor={defaultTheme}
        enableDarkModePreview={enableDarkModePreview}
      />
      <Checkbox
        label="Enable Hamburger Logo"
        id="enableHamburgerLogo"
        checked={enableHamburgerLogo}
        onChange={(value: any) => hamburgerLogo('enableHamburgerLogo', value)}
      />
    </>
  );
};

export const DateTimeSettings = () => {
  const dispatch = useDispatch();
  const { enableDate = false, enableTime = false } = useSelector((state: IDateTime) =>
    get(state, ['builder', 'config', 'header', 'dateTime'], {})
  );

  const enableDateTime = (keyName: string, keyValue: boolean) => dispatch(headerSettings({ [keyName]: keyValue }));

  const dateFormatters = [
    { label: 'January 28, 2021 (M D, Y)', value: 'mdy' },
    { label: '2021-01-28 (Y-M-D)', value: 'y-m-d' },
    { label: '01/28/2021 (M/D/Y)', value: 'm/d/y' },
    { label: '28/01/2021 (D/M/Y)', value: 'd/m/y' }
  ];

  const selectedFormatter = (keyName: string, value: string) => {
    dispatch(headerSettings({ [keyName]: value }));
  };

  return (
    <>
      <div data-test-id="header-enable-date" className={styles.checkboxWrapper}>
        <Checkbox
          label="Enable Date "
          id="enableDate"
          checked={enableDate}
          onChange={(value: any) => enableDateTime('enableDate', value)}
        />
      </div>
      {enableDate && (
        <Select
          label="Date Format"
          options={dateFormatters}
          onChange={(options: any) => selectedFormatter('dateFormat', options.value)}
          defaultValue={dateFormatters[0]}
        />
      )}
      <div data-test-id="header-enable-time" className={styles.checkboxWrapper}>
        <Checkbox
          label="Enable Time"
          id="enableTime"
          checked={enableTime}
          onChange={(value: any) => enableDateTime('enableTime', value)}
        />
      </div>
    </>
  );
};

export const DarkModeSettings = () => {
  const dispatch = useDispatch();

  const { showDarkModeToggle = false } =
    useSelector((state: IDarkMode) => get(state, ['builder', 'config', 'header'])) || {};

  const darkMode = (keyName: string, keyValue: boolean) => dispatch(updateHeaderSettings({ [keyName]: keyValue }));

  return (
    <>
      <Checkbox
        label="Display Dark Mode Toggle on Header"
        id="showDarkModeToggle"
        checked={showDarkModeToggle}
        onChange={(value: any) => darkMode('showDarkModeToggle', value)}
      />
    </>
  );
};
