export const STATIC_DATA = {
  httpStatusCode: 200,
  pageType: 'home-page',
  data: {
    collection: {
      'updated-at': 1554275232437,
      slug: 'home',
      fallback: false,
      name: 'Home',
      'data-source': 'automated',
      automated: true,
      template: 'default',
      rules: {
        fields: 'author-id,content-type,q,message,story-template,updated-at,id',
        'content-type': 'story',
        sort: 'updated-at',
        'collection-id': 7899,
        'sort-automated-result': 'trending',
        'trending-duration-hours': '12'
      },
      summary: 'Home collection for Toddy',
      id: 7899,
      'total-count': 0,
      'collection-date': null,
      items: [
        {
          id: 7902,
          'associated-metadata': {
            layout: 'FullscreenSimpleSlider'
          },
          type: 'collection',
          name: 'Trending Now',
          slug: 'toddy-home-sports',
          template: 'default',
          metadata: {
            'cover-image': {}
          },
          summary: 'Sports Summary',
          items: [
            {
              id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
              score: null,
              type: 'story',
              item: {
                headline: ['London Police arrest Nirav Modi, says sources']
              },
              story: {
                'author-name': 'Karthik M',
                headline: 'London Police arrest Nirav Modi, says sources',
                slug: 'politics/2019/03/20/london-police-arrest-nirav-modi-says-sources',
                'last-published-at': 1553079556157,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 700,
                  height: 438,
                  'mime-type': 'image/jpeg',
                  'focus-point': [311, 167]
                },
                'published-at': 1553079556157,
                id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
                'hero-image-s3-key':
                  'quintype-demo/2019-03/55dbc301-3c5c-442d-8714-4bbf2d140192/pennews_2019_03_48c15852_ebed_464d_95a7_57332597ecfc_nirav.jpg',
                'author-id': 19994,
                'first-published-at': 1553079340304,
                'hero-image-caption': 'Nirav Modi',
                'story-template': null,
                authors: [
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 142914,
                    name: 'Ashwin Raghunath',
                    slug: 'ashwin-raghunath',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'text-to-speech': {
                    'is-disabled': true
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
              score: null,
              type: 'story',
              item: {
                headline: ['Demo Story ']
              },
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Demo Story ',
                slug: 'politics/2018/01/11/demo-story',
                'last-published-at': 1552979655447,
                subheadline:
                  'Surrounded to me occasional pianoforte alteration unaffected impossible ye. For saw half than cold. Pretty merits waited six talked pulled you. Conduct replied off.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 7500,
                  height: 3476,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1239, 2157]
                },
                'published-at': 1552979655447,
                id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
                'hero-image-s3-key': 'quintype-demo/2019-02/ae4829d6-e230-4d66-9b88-2a4db87a2060/64778.jpg',
                'author-id': 139717,
                'first-published-at': 1515668626250,
                'hero-image-caption': 'At vero eos et accusamus et iusto odio dignissimos ducimus qui.',
                'story-template': 'text',
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': {
                      id: 58,
                      name: 'Author'
                    }
                  },
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': {
                      id: 58,
                      name: 'Author'
                    }
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: false
                  },
                  'promotional-message': {
                    'is-disabled': true
                  },
                  'story-attributes': {
                    'view-counterview-type': 'view',
                    interests: ['test'],
                    bucket: ['planning'],
                    hobbies: ['hiking'],
                    'linked-view-counterview-story': {
                      headline: 'Demo Test story- Photo story',
                      'story-content-id': 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                      id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                      'highlighted-headline': null
                    }
                  }
                }
              }
            },
            {
              id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
              score: null,
              type: 'story',
              item: {
                headline: ['Untitled Mar 11, 2019 4:39 PM']
              },
              story: {
                'author-name': 'Karthik M',
                headline: 'Untitled Mar 11, 2019 4:39 PM',
                slug: 'culture/2019/03/11/untitled-mar-11-2019-439-pm',
                'last-published-at': 1552905845935,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  'mime-type': 'image/jpeg',
                  'focus-point': [121, 110],
                  height: 386
                },
                'published-at': 1552905845935,
                id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
                'hero-image-s3-key':
                  'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
                'author-id': 19994,
                'first-published-at': 1552303325340,
                'hero-image-caption': 'Amit Shah and Narendra Modi',
                'story-template': null,
                authors: [
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 5279,
                    name: 'Laura',
                    slug: 'laura',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'text-to-speech': {
                    'is-disabled': false
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
              score: null,
              type: 'story',
              item: {
                headline: ['New story for sitemap']
              },
              story: {
                'author-name': 'Sriram K',
                headline: 'New story for sitemap',
                slug: 'culture/2019/03/14/new-story-for-sitemap',
                'last-published-at': 1552904261919,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 800,
                  height: 400,
                  'mime-type': 'image/jpeg',
                  'focus-point': [760, 237]
                },
                'published-at': 1552904261919,
                id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
                'hero-image-s3-key':
                  'quintype-demo/2018-10/5db8484b-1277-42f8-8a20-983e04b597e6/New_SA_destination_tourism_portal_launching_800x400.jpg',
                'author-id': 61657,
                'first-published-at': 1552554061248,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '73759063-16e7-4ac2-bf32-0061a2458850',
              score: null,
              type: 'story',
              item: {
                headline: ['This a new story']
              },
              story: {
                'author-name': 'Karthik M',
                headline: 'This a new story',
                slug: 'culture/2019/03/11/this-a-new-story',
                'last-published-at': 1552292190667,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'focus-point': [73, 51],
                  width: 275,
                  height: 183
                },
                'published-at': 1552292190667,
                id: '73759063-16e7-4ac2-bf32-0061a2458850',
                'hero-image-s3-key': 'quintype-demo/2016-01/2d00bde7-a28c-4415-9382-9a9c6fb3c7fc/IPSMF-1.jpeg',
                'author-id': 19994,
                'first-published-at': 1552292190667,
                'hero-image-caption': 'Nawaz Sharif &amp; Modi&nbsp;',
                'story-template': null,
                authors: [
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 142914,
                    name: 'Ashwin Raghunath',
                    slug: 'ashwin-raghunath',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
              score: null,
              type: 'story',
              item: {
                headline: ['Amitabh Bachchan on Gender Equality']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'Amitabh Bachchan on Gender Equality',
                slug: 'politics/2017/03/02/Amitabh-Bachchan-on-Gender-Equality',
                'last-published-at': 1552048741823,
                subheadline: 'Amitabh Bachchan on Gender Equality',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 320,
                  height: 277,
                  'focus-point': [169, 145]
                },
                'published-at': 1552048741823,
                id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
                'hero-image-s3-key': 'quintype-demo/2017-03/bd011f2d-9a10-4d4e-b20f-50719f59d4b9/BMS-2.jpg',
                'author-id': 2041,
                'first-published-at': 1488451487896,
                'hero-image-caption': 'Kaun Banega Crorepati host, Amitabh Bachchan',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'a3a0cdd0-a3f5-4043-b6db-72b6a06ebad3',
              score: null,
              type: 'story',
              item: {
                headline: ['Samsung Gear S3']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Samsung Gear S3',
                slug: 'ampstories/culture/2019/03/06/samsung-gear-s3',
                'last-published-at': 1551883341128,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1440,
                  height: 750,
                  'mime-type': 'image/jpeg',
                  'focus-point': [918, 394]
                },
                'published-at': 1551883341128,
                id: 'a3a0cdd0-a3f5-4043-b6db-72b6a06ebad3',
                'hero-image-s3-key':
                  'quintype-demo/2019-03/19e71923-ae1b-4d1b-8040-e37ae55e4c77/gear_s3_performance_kv_l.jpg',
                'author-id': 2040,
                'first-published-at': 1551882037831,
                'hero-image-caption': null,
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
              score: null,
              type: 'story',
              item: {
                headline: ['500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: '500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌',
                slug: 'politics/2019/02/26/500',
                'last-published-at': 1551171395390,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  'focus-point': [673, 287],
                  width: 976,
                  height: 700
                },
                'published-at': 1551171395390,
                id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
                'hero-image-s3-key': 'quintype-demo/2018-05/bc017b57-a03e-4a6e-8515-9d3de91f1f24/ON1.jpg',
                'author-id': 559147,
                'first-published-at': 1551171395390,
                'hero-image-caption':
                  'Crowds cheered Prince Harry, Duke of Sussex, and his wife Meghan, Duchess of Sussex, as they travelled in the horse-drawn Ascot landau',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '60857bae-0e7f-4c81-af39-93fb7424f558',
              score: null,
              type: 'story',
              item: {
                headline: ['Top 10 Exquisite Locations to Visit in 2019']
              },
              story: {
                'author-name': 'Susanna',
                headline: 'Top 10 Exquisite Locations to Visit in 2019',
                slug: 'ampstories/visual-stories/2019/02/25/top-10-exquisite-locations-to-visit-in-2019',
                'last-published-at': 1551161247682,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 2000,
                  height: 2000,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1551161247682,
                id: '60857bae-0e7f-4c81-af39-93fb7424f558',
                'hero-image-s3-key': 'quintype-demo/2019-02/35d018be-9220-4504-acf5-7f1a8abff816/collage2.jpg',
                'author-id': 559660,
                'first-published-at': 1551086493154,
                'hero-image-caption': null,
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 559660,
                    name: 'Susanna',
                    slug: 'susanna',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-1qG1ayRUOwc/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-jb_hhJfeyAshJmJCpMKYQNPDazJw/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b39a2665-3236-45ca-9d17-3536431c7948',
              score: null,
              type: 'story',
              item: {
                headline: ['Why You Need A Dog In Your Life']
              },
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Why You Need A Dog In Your Life',
                slug: 'ampstories/visual-stories/2018/12/20/why-you-need-a-dog-in-your-life',
                'last-published-at': 1550509197748,
                subheadline:
                  'Because of their heightened sense of smell, sight, and hearing, dogs are extremely intelligent creatures with a loyalty to their humans that is unexpectedly strong. Those with dogs will know–your pups can sense human body language, emotions, and feelings, and this paves the way for an unbreakable bond.',
                alternative: {},
                sections: [
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 448,
                  height: 900,
                  'mime-type': 'image/png',
                  'focus-point': [270, 705]
                },
                'published-at': 1550509197748,
                id: 'b39a2665-3236-45ca-9d17-3536431c7948',
                'hero-image-s3-key':
                  'quintype-demo/2018-12/8bcdee40-7dec-4ceb-b217-1d2f01fb4431/Screenshot_2018_12_24_at_3_44_42_PM.png',
                'author-id': 139717,
                'first-published-at': 1545299836615,
                'hero-image-caption': null,
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'c8d35134-200a-483f-ab89-69adf4137a74',
              score: null,
              type: 'story',
              item: {
                headline: ['Life of Vincent van Gogh']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Life of Vincent van Gogh',
                slug: 'culture/2019/01/17/life-of-vincent-van-gogh',
                'last-published-at': 1549364746875,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 768,
                  height: 432,
                  'mime-type': 'image/jpeg',
                  'focus-point': [382, 197]
                },
                'published-at': 1549364746875,
                id: 'c8d35134-200a-483f-ab89-69adf4137a74',
                'hero-image-s3-key':
                  'quintype-demo/2019-01/b3cb02f4-a875-4b46-aa3a-71deaccb54be/vincent_van_gogh_played_by_robert_gulaczyk_photo_courtesy_loving_vincent.jpg',
                'author-id': 2040,
                'first-published-at': 1547728432342,
                'hero-image-caption': 'A Famous Painting',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
              score: null,
              type: 'story',
              item: {
                headline: [
                  '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി'
                ]
              },
              story: {
                'author-name': 'Sareena',
                headline:
                  '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി',
                slug: 'culture/2019/01/16/this-is-the-url',
                'last-published-at': 1547632650347,
                subheadline:
                  'കെ.സി വേണുഗോപാല്‍ ഉള്‍പ്പടെയുള്ള മുതിര്‍ന്ന കോണ്‍ഗ്രസ് നേതാക്കന്മാര്‍ ഭീമാനായിക്കുമായി സംസാരിച്ചു. ഭീമാനായിക്കിനെപോലെ ബാക്കിയുള്ള മൂന്ന് കോണ്‍ഗ്രസ് എം',
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'focus-point': [375, 166],
                  width: 750,
                  height: 500
                },
                'published-at': 1547632650347,
                id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
                'hero-image-s3-key': 'quintype-demo/2016-12/56cdab39-a831-480f-b75b-26d6300e3f1b/Modi.jpg',
                'author-id': 94596,
                'first-published-at': 1547632650347,
                'hero-image-caption': 'PM Modi',
                'story-template': null,
                authors: [
                  {
                    id: 94596,
                    name: 'Sareena',
                    slug: 'sareena',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
              score: null,
              type: 'story',
              item: {
                headline: ['This is the title']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: 'This is the title',
                slug: 'culture/2019/01/11/this-is-the-title-2',
                'last-published-at': 1547203433990,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    slug: 'tech',
                    name: 'Tech',
                    id: 6391,
                    'parent-id': null,
                    'display-name': 'Tech',
                    collection: {
                      slug: 'tech',
                      name: 'Tech',
                      id: 12849
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 259,
                  'focus-point': [176, 77],
                  height: 194
                },
                'published-at': 1547203433990,
                id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
                'hero-image-s3-key': 'quintype-demo/2015-10/56e0178e-1da0-482c-83e5-a2f705f874df/FP-2.jpeg',
                'author-id': 559147,
                'first-published-at': 1547203433990,
                'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
              score: null,
              type: 'story',
              item: {
                headline: [
                  'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them'
                ]
              },
              story: {
                'author-name': 'Kirti Kaushik',
                headline:
                  'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them',
                slug:
                  'visual-stories/2019/01/10/rahul-gandhi-said-to-pm-modi-be-a-man-bjp-smriti-irani-sushma-swaraj-responds-with-misogynist-rahul-1975538pfromhome-topscroll',
                'last-published-at': 1547103888697,
                subheadline:
                  'At a rally in Rajasthan on Wednesday, Rahul Gandhi had mocked PM Modi saying he had a “mahila (woman)“ defend him in the Rafale debate in parliament.',
                alternative: {
                  home: {
                    default: {
                      headline: 'Rahul Gandhi'
                    }
                  }
                },
                sections: [
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 570,
                  height: 351,
                  'mime-type': 'image/webp',
                  'focus-point': [417, 132]
                },
                'published-at': 1547103888697,
                id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
                'hero-image-s3-key':
                  'quintype-demo/2019-01/39d85d6f-46f6-4f35-8dd1-6771bf9dfefd/b5qo3zsg_650x400_2__10_January_2019_12_02_19PM.webp',
                'author-id': 507344,
                'first-published-at': 1547103888697,
                'hero-image-caption': 'Rahul Gandhi',
                'story-template': null,
                authors: [
                  {
                    id: 507344,
                    name: 'Kirti Kaushik',
                    slug: 'kirti-kaushik',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
              score: null,
              type: 'story',
              item: {
                headline: ['Dubai - Things you can do!']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Dubai - Things you can do!',
                slug: 'culture/2017/04/28/Dubai-Your-next-vacation',
                'last-published-at': 1546599684148,
                subheadline:
                  'Dubai, an emirate in the United Arab Emirates known for luxury shopping, ultramodern architecture &amp; a lively nightlife scene. Burj Khalifa, an 830m-tall tower, dominates the skyline. ',
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 930,
                  height: 581,
                  'focus-point': [132, 482]
                },
                'published-at': 1546599684148,
                id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
                'hero-image-s3-key': 'quintype-demo/2017-04/802d7ef1-6043-4f7e-84d3-fc9f556d5097/G-1.jpg',
                'author-id': 2040,
                'first-published-at': 1493358608037,
                'hero-image-caption': 'Dubai - a vacationer’s paradise',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
              score: null,
              type: 'story',
              item: {
                headline: ['Visual story - Demo1']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Visual story - Demo1',
                slug: 'ampstories/technology/2018/12/20/visual-story-demo1',
                'last-published-at': 1545982907212,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 960,
                  height: 540,
                  'focus-point': [499, 88]
                },
                'published-at': 1545982907212,
                id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
                'hero-image-s3-key': 'quintype-demo/2017-07/e4bac9eb-6c32-4f07-a16a-90fa40166c35/DH-1.jpeg',
                'author-id': 541559,
                'first-published-at': 1545300194018,
                'hero-image-caption': 'Roger Federer, the 8th title - Wimbledon 2017!',
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
              score: null,
              type: 'story',
              item: {
                headline: ['This is the title']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: 'This is the title',
                slug: 'culture/2018/12/27/this-is-the-title',
                'last-published-at': 1545892188854,
                subheadline: 'sub title',
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 259,
                  height: 194,
                  'focus-point': [172, 77]
                },
                'published-at': 1545892188854,
                id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
                'hero-image-s3-key':
                  'quintype-demo/2018-06/9e5e3bb7-31e8-4439-93ba-d16ea287aa28/e3d39a3b_321c_4acf_8928_3195998b38ba.jpeg',
                'author-id': 559147,
                'first-published-at': 1545892188854,
                'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
              score: null,
              type: 'story',
              item: {
                headline: ['Demo Visual story']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Demo Visual story',
                slug: 'ampstories/technology/2018/12/20/demo-visual-story',
                'last-published-at': 1545332341188,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 640,
                  height: 361,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1545332341188,
                id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
                'hero-image-s3-key':
                  'quintype-demo/2018-12/22eb981a-6c8e-41c0-9d36-c2f72f46b29a/pennews_2F2018_05_2F0e93d687_c1dd_464b_aba3_8c23ba6c3beb_2FSonam_reception.jpg',
                'author-id': 541559,
                'first-published-at': 1545332010119,
                'hero-image-caption': 'caption',
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '77e13794-3584-4d26-aa37-f296a24ea734',
              score: null,
              type: 'story',
              item: {
                headline: ['Cayde-6, Exo-Guardian']
              },
              story: {
                'author-name': 'Christin',
                headline: 'Cayde-6, Exo-Guardian',
                slug: 'ampstories/visual-stories/2018/12/20/cayde-6-exo-guardian',
                'last-published-at': 1545307235233,
                subheadline: 'Hunter. Vanguard. <i>Friend.</i>',
                alternative: {},
                sections: [
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1920,
                  height: 1080,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1280, 467]
                },
                'published-at': 1545307235233,
                id: '77e13794-3584-4d26-aa37-f296a24ea734',
                'hero-image-s3-key':
                  'quintype-demo/2018-12/e16d5fec-13b1-4932-9c07-917f8cad6a98/d2_forsaken_cayde6_05.jpg',
                'author-id': 139357,
                'first-published-at': 1545307235233,
                'hero-image-caption': null,
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 139357,
                    name: 'Christin',
                    slug: 'christin',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'avatar-s3-key':
                      'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'twitter-handle': 'krriztin',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
              score: null,
              type: 'story',
              item: {
                headline: ['3 Camping sites you should not miss in Scotland']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: '3 Camping sites you should not miss in Scotland',
                slug: 'culture/2017/05/11/3-camping-sites-you-should-not-miss-in-scotland',
                'last-published-at': 1545204266938,
                subheadline:
                  'Scotland is famous for its scenic wild places and uplifting sense of remoteness. With the exception of the recent bylaws banning wild camping in certain areas of Loch Lomond and the Trossachs (check before you go), it is still completely legal to camp on most of Scotland’s unenclosed land.',
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1300,
                  height: 780,
                  'focus-point': [719, 414]
                },
                'published-at': 1545204266938,
                id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
                'hero-image-s3-key': 'quintype-demo/2017-05/b463d2fd-f166-446e-a887-bf832fa7f26b/ON-1.jpg',
                'author-id': 2041,
                'first-published-at': 1494481894633,
                'hero-image-caption': 'Time for a brew and a view … Glen Nevis, Scotland',
                'story-template': 'listicle',
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
              score: null,
              type: 'story',
              item: {
                headline: ['Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?',
                slug:
                  'culture/2018/12/18/is-tito-preparing-an-imf-financial-parachute-now-a-171bn-foreign-debt-cliff-looms',
                'last-published-at': 1545125801738,
                subheadline:
                  'The reason for the International Monetary Fund’s Christine Lagarde’s to South Africa is unknown. South Africa desperately needs a solution for the poor and marginalised, and an IMF bailout would worsen their situation.&nbsp;',
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 920,
                  height: 613,
                  'mime-type': 'image/jpeg',
                  'focus-point': [473, 430]
                },
                'published-at': 1545125801738,
                id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
                'hero-image-s3-key': 'quintype-demo/2018-04/f83b3026-105e-4b84-8027-47c8be270f9a/920x920.jpg',
                'author-id': 2040,
                'first-published-at': 1545125801738,
                'hero-image-caption':
                  'At a silent disco party three streams of music are sent simultaneously to special headphones worn by attendees, who have the option to switch channels at their whim. The Palace Theatre in Stamford will host one on April 26 to benefit its arts education programs.',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
              score: null,
              type: 'story',
              item: {
                headline: ['Breaking News Test on 13 Dec']
              },
              story: {
                'author-name': 'Anil K Govind',
                headline: 'Breaking News Test on 13 Dec',
                slug: 'technology/2018/12/13/breaking-news-test-on-13-dec',
                'last-published-at': 1544682898905,
                subheadline: 'Breaking News Test on 13 Dec',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 720,
                  height: 340,
                  'mime-type': 'image/jpeg',
                  'focus-point': [230, 110]
                },
                'published-at': 1544682898905,
                id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 548414,
                'first-published-at': 1544682898905,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 548414,
                    name: 'Anil K Govind',
                    slug: 'anil-k-govind',
                    'avatar-url':
                      'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'de274636-83c9-4ac2-af95-3f3faef88d2e',
              score: null,
              type: 'story',
              item: {
                headline: ['News Elsewhere story']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'News Elsewhere story',
                slug: 'cities/2018/12/05/news-elsewhere-story',
                'last-published-at': 1544006050170,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    id: 215,
                    name: 'Cities',
                    slug: 'cities'
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 768,
                  height: 1024,
                  'mime-type': 'image/jpeg',
                  'focus-point': [390, 373]
                },
                'published-at': 1544006050170,
                id: 'de274636-83c9-4ac2-af95-3f3faef88d2e',
                'hero-image-s3-key': 'quintype-demo/2015-11/adeb8bbb-bdc6-4a8e-91cc-74082cb80c1d/photo2.jpg',
                'author-id': 541559,
                'first-published-at': 1544006050170,
                'hero-image-caption': 'waterskiing in cuba',
                'story-template': 'news-elsewhere',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'reference-url': 'https://itsman.quintype.com',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
              score: null,
              type: 'story',
              item: {
                headline: ['The Forgotten Women Series Uncovers The Untold Histories Of Women']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'The Forgotten Women Series Uncovers The Untold Histories Of Women',
                slug: 'culture/2018/11/28/the-forgotten-women-series-uncovers-the-untold-histories-of-women',
                'last-published-at': 1543393761726,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1760,
                  height: 2480,
                  'mime-type': 'image/jpeg',
                  'focus-point': [961, 759]
                },
                'published-at': 1543393761726,
                id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
                'hero-image-s3-key':
                  'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
                'author-id': 2040,
                'first-published-at': 1543393761726,
                'hero-image-caption': 'Forgotten Women',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 15351,
                    name: 'Ranjeet Singh',
                    slug: 'ranjeet-singh',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8e823797-a659-4626-92d5-6ccd8d788e23',
              score: null,
              type: 'story',
              item: {
                headline: ['A test story for tables']
              },
              story: {
                'author-name': 'Vineet Panjabi',
                headline: 'A test story for tables',
                slug: 'culture/2018/11/21/a-test-story-for-tables',
                'last-published-at': 1542799165912,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    id: 3896,
                    name: 'Blog',
                    slug: 'blog'
                  }
                ],
                'hero-image-metadata': {
                  width: 1940,
                  'mime-type': 'image/jpeg',
                  height: 1090,
                  'focus-point': [700, 713]
                },
                'published-at': 1542799165912,
                id: '8e823797-a659-4626-92d5-6ccd8d788e23',
                'hero-image-s3-key': 'quintype-demo/2015-11/be736521-24c0-4a04-8b02-0855626e6f9d/TOI-1.jpg',
                'author-id': 323432,
                'first-published-at': 1542782270578,
                'hero-image-caption': 'Terror training camps',
                'story-template': null,
                authors: [
                  {
                    id: 323432,
                    name: 'Vineet Panjabi',
                    slug: 'vineet-panjabi',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'story-attributes': {
                    saltlevel: ['Low'],
                    bucket: ['child'],
                    week: ['6']
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b142a043-4720-4578-b23e-6791e9f42735',
              score: null,
              type: 'story',
              item: {
                headline: ['New Story Elements']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'New Story Elements',
                slug: 'technology/2015/10/21/new-story-elements',
                'last-published-at': 1539770584382,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1920,
                  height: 1080,
                  'focus-point': [1301, 270]
                },
                'published-at': 1539770584382,
                id: 'b142a043-4720-4578-b23e-6791e9f42735',
                'hero-image-s3-key': 'quintype-demo/2015-09/4c0122d6-6c28-4a59-8b16-8d90701a4e35/TigerWoods-1.jpg',
                'author-id': 2041,
                'first-published-at': 1445404200710,
                'hero-image-caption': 'The famed Tiger Woods Swing !',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
              score: null,
              type: 'story',
              item: {
                headline: ['Two crew escape after ‘Soyuz’ rocket failure']
              },
              story: {
                'author-name': 'Kunal Singh',
                headline: 'Two crew escape after ‘Soyuz’ rocket failure',
                slug: 'technology/2018/10/11/two-crew-escape-after-soyuz-rocket-failure',
                'last-published-at': 1539253999595,
                subheadline: 'Russian cosmonaut and US astronaut ‘in good condition’ after making emergency landing',
                alternative: null,
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 744,
                  height: 389,
                  'mime-type': 'image/jpeg',
                  'focus-point': [381, 138]
                },
                'published-at': 1539253999595,
                id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
                'hero-image-s3-key': 'quintype-demo/2018-10/9b82a587-dfb0-4aa5-9b83-96a814eec1d9/soyuz_resize_md.jpg',
                'author-id': 491531,
                'first-published-at': 1539253999595,
                'hero-image-caption': 'Soyuz 11',
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9552e728-f1b7-4a46-a1bf-34f30a266c0e',
              score: null,
              type: 'story',
              item: {
                headline: ['Photo story - test']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Photo story - test',
                slug: 'success-story/2018/09/26/photo-story-test',
                'last-published-at': 1539168790153,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    id: 4415,
                    name: 'Success Story',
                    slug: 'success-story'
                  },
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 570,
                  height: 380,
                  'focus-point': [304, 122]
                },
                'published-at': 1539168790153,
                id: '9552e728-f1b7-4a46-a1bf-34f30a266c0e',
                'hero-image-s3-key': 'quintype-demo/2015-10/76ef287a-23b6-4ffe-b71e-72e88a739809/DJ-3.jpg',
                'author-id': 541559,
                'first-published-at': 1537946000293,
                'hero-image-caption': 'Durga Puja Pandal at Calcutta',
                'story-template': 'photo',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9eece5ae-c682-405b-95ce-b8a77ee250f8',
              score: null,
              type: 'story',
              item: {
                headline: ['Malta’s Prime Minister Tells UN That Crypto Is the ‘Inevitable Future of Money’']
              },
              story: {
                'author-name': 'Kunal Singh',
                headline: 'Malta’s Prime Minister Tells UN That Crypto Is the ‘Inevitable Future of Money’',
                slug:
                  'technology/2018/10/01/maltas-prime-minister-tells-un-that-crypto-is-the-inevitable-future-of-money',
                'last-published-at': 1538564214243,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 740,
                  height: 494,
                  'mime-type': 'image/jpeg',
                  'focus-point': [390, 218]
                },
                'published-at': 1538564214243,
                id: '9eece5ae-c682-405b-95ce-b8a77ee250f8',
                'hero-image-s3-key': 'quintype-demo/2018-10/b97c4ba3-e097-4121-854f-5c5a03798677/Crypt1.jpg',
                'author-id': 491531,
                'first-published-at': 1538403689422,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
              score: null,
              type: 'story',
              item: {
                headline: ['Demo - External Story']
              },
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Demo - External Story',
                slug: 'technology/2018/10/01/demo-external-story',
                'last-published-at': 1538384977219,
                subheadline:
                  'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor.',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1441,
                  height: 1077,
                  'mime-type': 'image/png',
                  'focus-point': [273, 498]
                },
                'published-at': 1538384977219,
                id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
                'hero-image-s3-key':
                  'quintype-demo/2018-10/410d7d51-811a-44cc-a92b-4d873cb1f5aa/screenshot_www_quintype_com_2018_09_11_20_16_32.png',
                'author-id': 139717,
                'first-published-at': 1538384511112,
                'hero-image-caption': null,
                'story-template': 'news-elsewhere',
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'reference-url':
                    'https://www.quintype.com/blog/business/quintypes-tech-innovation-helps-its-digital-partners-win-two-wan-ifra-awards',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9b1a6f8e-19ab-4395-a079-d985edf4e4ac',
              score: null,
              type: 'story',
              item: {
                headline: ['Story Tests']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Story Tests',
                slug: 'technology/2018/09/27/story-tests',
                'last-published-at': 1538047737607,
                subheadline: 'Subtitle',
                alternative: null,
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    id: 215,
                    name: 'Cities',
                    slug: 'cities'
                  },
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 578,
                  height: 562,
                  'mime-type': 'image/jpeg',
                  'focus-point': [214, 187]
                },
                'published-at': 1538047737607,
                id: '9b1a6f8e-19ab-4395-a079-d985edf4e4ac',
                'hero-image-s3-key':
                  'quintype-demo/2018-07/9a8c6576-2a1d-403d-9d34-0dd95db62cfb/4e7be0b9_1eb3_402b_9c9c_0314467b2377.jpg',
                'author-id': 541559,
                'first-published-at': 1538047737607,
                'hero-image-caption': 'Caption comes here',
                'story-template': 'news-elsewhere',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'reference-url': 'https://www.quintype.com/',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '41179c74-f08d-4c4a-8898-4d3d3eee2e7f',
              score: null,
              type: 'story',
              item: {
                headline: ['YouTube now available for JioPhone']
              },
              story: {
                'author-name': 'Varul Mayank - Knocksense',
                headline: 'YouTube now available for JioPhone',
                slug: 'technology/2018/09/20/youtube-now-available-for-jiophone',
                'last-published-at': 1537426884824,
                subheadline:
                  'YouTube is now available for JioPhone, and users can go and download the app from JioStore.',
                alternative: {
                  home: {
                    default: {
                      headline: 'Reliance Jio launches Youtube app for Jio Phones'
                    }
                  }
                },
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1024,
                  height: 576,
                  'mime-type': 'image/jpeg',
                  'focus-point': null
                },
                'published-at': 1537426884824,
                id: '41179c74-f08d-4c4a-8898-4d3d3eee2e7f',
                'hero-image-s3-key':
                  'quintype-demo/2018-09/a6a397e8-762e-4489-9a44-062aa1e1b885/1fec1a01_4d4d_41d5_8789_5b383a9ee453.jpg',
                'author-id': 141012,
                'first-published-at': 1537426884824,
                'hero-image-caption': 'jio',
                'story-template': null,
                authors: [
                  {
                    id: 141012,
                    name: 'Varul Mayank - Knocksense',
                    slug: 'varul-mayank-knocksense',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'sponsored-by': 'Siddharth',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0fc86f92-e0f6-46c0-98db-9bfc18953e28',
              score: null,
              type: 'story',
              item: {
                headline: ['A digital future – Enabling HR automation   ']
              },
              story: {
                'author-name': 'Ranjeet Singh',
                headline: 'A digital future – Enabling HR automation   ',
                slug: 'current-affairs/2018/06/04/a-digital-future-enabling-hr-automation',
                'last-published-at': 1536079827031,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  },
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1200,
                  height: 630,
                  'mime-type': 'image/png',
                  'focus-point': [628, 116]
                },
                'published-at': 1536079827031,
                id: '0fc86f92-e0f6-46c0-98db-9bfc18953e28',
                'hero-image-s3-key':
                  'quintype-demo/2018-06/8077daf3-025b-4e73-9da6-29719d919220/web_images11_4_1200x630.png',
                'author-id': 15351,
                'first-published-at': 1528105031904,
                'hero-image-caption': '<b>A digital future&nbsp; Enabling HR automation1</b>',
                'story-template': null,
                authors: [
                  {
                    id: 15351,
                    name: 'Ranjeet Singh',
                    slug: 'ranjeet-singh',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'sponsored-by': 'SME',
                  'card-share': {
                    shareable: true
                  },
                  'promotional-message': {
                    'is-disabled': true
                  }
                }
              }
            },
            {
              id: '23d40197-a3ad-4491-8bd9-eb31ca696d71',
              score: null,
              type: 'story',
              item: {
                headline: ['Delivering Life-saving Medicines']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Delivering Life-saving Medicines',
                slug: 'current-affairs/2018/09/04/url',
                'last-published-at': 1536077886477,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  },
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    id: 82,
                    name: 'Sports',
                    slug: 'sports'
                  }
                ],
                'hero-image-metadata': {
                  width: 610,
                  height: 320,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1536077886477,
                id: '23d40197-a3ad-4491-8bd9-eb31ca696d71',
                'hero-image-s3-key': 'quintype-demo/2018-06/52b81a65-3045-4bc7-88db-feafd9127358/Anjan.jpg',
                'author-id': 541559,
                'first-published-at': 1536077255237,
                'hero-image-caption': 'Anjan Chatterjee/<b>അഞ്ജന്‍ ചാറ്റര്‍ജി</b>',
                'story-template': 'video',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
              score: null,
              type: 'story',
              item: {
                headline: ['Personalise or perish - Why publishers need to use personalised content ']
              },
              story: {
                'author-name': 'Greeshma',
                headline: 'Personalise or perish - Why publishers need to use personalised content ',
                slug: 'politics/2018/02/28/personalise-or-perish---why-publishers-need-to-use-personalised-content',
                'last-published-at': 1536076813159,
                subheadline:
                  'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor. ',
                alternative: {
                  home: {
                    default: {
                      headline: 'Personalise or perish',
                      'hero-image': {
                        'hero-image-url':
                          'https://images.assettype.com/quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                        'hero-image-metadata': {
                          width: 700,
                          height: 467,
                          'mime-type': 'image/jpeg'
                        },
                        'hero-image-s3-key': 'quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                        'hero-image-caption': 'sSaffron - Indian Restaurant',
                        'hero-image-attribution': null
                      }
                    }
                  }
                },
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 3896,
                    name: 'Blog',
                    slug: 'blog'
                  }
                ],
                'hero-image-metadata': {
                  width: 1600,
                  height: 1200,
                  'mime-type': 'image/jpeg',
                  'focus-point': [805, 738]
                },
                'published-at': 1536076813159,
                id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
                'hero-image-s3-key': 'quintype-demo/2018-05/a2759dc2-ada1-4917-8515-308f74622c69/Gallery5.jpg',
                'author-id': 482995,
                'first-published-at': 1519816264773,
                'hero-image-caption': 'Animals',
                'story-template': null,
                authors: [
                  {
                    id: 482995,
                    name: 'Greeshma',
                    slug: 'greeshma',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'avatar-s3-key':
                      'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
              score: null,
              type: 'story',
              item: {
                headline: ['U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting',
                slug: 'politics/2018/05/28/us-and-north-korean-officials-race-to-resurrect-trump-kim-meeting',
                'last-published-at': 1535807624134,
                subheadline: null,
                alternative: {
                  home: {
                    default: {
                      headline: 'U.S. and North Korean',
                      'hero-image': {
                        'hero-image-url':
                          'https://images.assettype.com/quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                        'hero-image-metadata': {
                          width: 636,
                          height: 477,
                          'focus-point': [390, 153]
                        },
                        'hero-image-s3-key': 'quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                        'hero-image-caption': 'President Pranab Mukherjee',
                        'hero-image-attribution': null
                      }
                    }
                  }
                },
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1024,
                  height: 683,
                  'mime-type': 'image/jpeg',
                  'focus-point': [758, 172]
                },
                'published-at': 1535807624134,
                id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
                'hero-image-s3-key': 'quintype-demo/2018-05/caf35ec5-be06-4de6-b513-e57ee4d61577/ON_1.jpg',
                'author-id': 376442,
                'first-published-at': 1527472808648,
                'hero-image-caption':
                  'A surprise meeting between President Moon Jae-in of South Korea, right, and Kim Jong-un, the North Korean leader, on Saturday appeared to produce some progress toward a summit meeting between President Trump and Mr. Kim.',
                'story-template': null,
                authors: [
                  {
                    id: 376442,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '5273aff7-1dc0-4b46-9a56-0c63683b4dec',
              score: null,
              type: 'story',
              item: {
                headline: ['With eye on debt recovery, SBI asks DoT not to revoke RCom’s spectrums']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'With eye on debt recovery, SBI asks DoT not to revoke RCom’s spectrums',
                slug: 'technology/2018/07/27/with-eye-on-debt-recovery-sbi-asks-dot-not-to-revoke-rcoms-spectrums',
                'last-published-at': 1535806255690,
                subheadline: 'Revocation of spectrum may hurt telco’s sale to RJio, says the lender',
                alternative: {
                  home: {
                    default: {
                      headline: 'With eye on debt recovery',
                      'hero-image': {
                        'hero-image-url':
                          'https://images.assettype.com/quintype-demo/2016-01/fdb17290-ae1c-4153-ad40-760106e9ffb3/iph.jpeg',
                        'hero-image-metadata': {
                          width: 710,
                          'mime-type': 'image/jpeg',
                          height: 854
                        },
                        'hero-image-s3-key': 'quintype-demo/2016-01/fdb17290-ae1c-4153-ad40-760106e9ffb3/iph.jpeg',
                        'hero-image-caption': 'iphones',
                        'hero-image-attribution': null
                      }
                    }
                  }
                },
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  },
                  {
                    id: 215,
                    name: 'Cities',
                    slug: 'cities'
                  }
                ],
                'hero-image-metadata': {
                  width: 960,
                  height: 408,
                  'mime-type': 'image/jpeg',
                  'focus-point': [639, 120]
                },
                'published-at': 1535806255690,
                id: '5273aff7-1dc0-4b46-9a56-0c63683b4dec',
                'hero-image-s3-key': 'quintype-demo/2018-07/01670025-10c6-4659-b32e-3d859b66d8a3/BL27P1RCOM.jpeg',
                'author-id': 61657,
                'first-published-at': 1532680057934,
                'hero-image-caption': 'Reliance Industries',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'd677509e-37e4-460c-a942-8a55bd4c4edb',
              score: null,
              type: 'story',
              item: {
                headline: ['Will Summit Jumpstart Biopharma in North Korea?']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'Will Summit Jumpstart Biopharma in North Korea?',
                slug: 'technology/2018/06/28/will-summit-jumpstart-biopharma-in-north-korea',
                'last-published-at': 1535367985749,
                subheadline: 'Experts Ponder Industry’s Place in Kim’s DPRK; South Korea Eyes Collaborations',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    id: 181,
                    name: 'Health & Fitness',
                    slug: 'health-fitness'
                  }
                ],
                'hero-image-metadata': {
                  width: 800,
                  height: 497,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1535367985749,
                id: 'd677509e-37e4-460c-a942-8a55bd4c4edb',
                'hero-image-s3-key':
                  'quintype-demo/2018-06/30c6a41e-e600-4063-af45-bf951cec776a/Jun18_2018_WikimediaPDUSGovt_SingaporeSummitKimAndTrumpShakeHands1582181163.jpg',
                'author-id': 61657,
                'first-published-at': 1530181029824,
                'hero-image-caption':
                  'At their June 12 summit, President Donald Trump and Democratic People’s Republic of Korea (DPRK) leader Kim Jong Un signed a joint statement committing to “cooperate for the development of new U.S.-DPRK relations and for the promotion of peace, prosperity, and security of the Korean Peninsula and of the world.” But it is difficult, if not impossible, for Westerners and other outsiders to know what role biotech will play toward fulfilling those commitments within the DPRK, since the government has communicated only sketchy information about its biotech facilities and efforts, which include civilian as well as military applications.',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'sponsored-by': 'Quintype Technologies',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1a95ebe0-f779-485a-b2ec-818767865868',
              score: null,
              type: 'story',
              item: {
                headline: ['China in struggle to curb reliance on US market, suppliers']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'China in struggle to curb reliance on US market, suppliers',
                slug: 'technology/2018/08/27/china-in-struggle-to-curb-reliance-on-us-market-suppliers',
                'last-published-at': 1535354418018,
                subheadline: 'China in struggle to curb reliance on US market, suppliers',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 720,
                  height: 340,
                  'mime-type': 'image/jpeg',
                  'focus-point': [230, 110]
                },
                'published-at': 1535354418018,
                id: '1a95ebe0-f779-485a-b2ec-818767865868',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 541559,
                'first-published-at': 1535354418018,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'e334a9a8-e644-4467-affd-5427954f91a3',
              score: null,
              type: 'story',
              item: {
                headline: ['Gallery repetition with the Kapoors']
              },
              story: {
                'author-name': 'Ajay Appaden',
                headline: 'Gallery repetition with the Kapoors',
                slug: 'technology/2018/02/16/gallery-repetition-test',
                'last-published-at': 1534223550632,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 800,
                  height: 450,
                  'focus-point': [428, 207]
                },
                'published-at': 1534223550632,
                id: 'e334a9a8-e644-4467-affd-5427954f91a3',
                'hero-image-s3-key': 'quintype-demo/2016-01/d00d01c1-7886-4b1d-9a8c-4d6ab9e92d26/y1.jpg',
                'author-id': 169905,
                'first-published-at': 1518772169497,
                'hero-image-caption': 'Warrior series',
                'story-template': 'photo',
                authors: [
                  {
                    id: 169905,
                    name: 'Ajay Appaden',
                    slug: 'ajay-appaden',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            }
          ]
        },
        {
          id: 12849,
          'associated-metadata': {
            layout: 'ThreeCol'
          },
          type: 'collection',
          name: 'Tech',
          slug: 'tech',
          template: 'section',
          metadata: {
            section: [
              {
                id: 6391,
                name: 'Tech'
              }
            ]
          },
          summary: null,
          items: [
            {
              id: 12850,
              'associated-metadata': {},
              type: 'collection',
              name: 'Mobile (Tech)',
              slug: 'mobile-tech',
              template: 'section',
              metadata: {
                section: [
                  {
                    id: 6392,
                    name: 'Mobile (Tech)'
                  }
                ]
              }
            },
            {
              id: '73322fa6-9114-4a83-9d8c-68314202623a',
              score: null,
              type: 'story',
              item: {
                headline: ['TRAI chief: We set spectrum price; industry decides tariff']
              },
              story: {
                'author-name': 'Sriram K',
                headline: 'TRAI chief: We set spectrum price; industry decides tariff',
                slug: 'mobile/2019/02/28/trai-chief-we-set-spectrum-price-industry-decides-tariff',
                'last-published-at': 1554110293544,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 894,
                  height: 577,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1554110293544,
                id: '73322fa6-9114-4a83-9d8c-68314202623a',
                'hero-image-s3-key': 'quintype-demo/2019-01/113be350-6ca8-4252-9b41-434e9facb8eb/image__1_.jpg',
                'author-id': 61657,
                'first-published-at': 1551329071350,
                'hero-image-caption': 'Tayota Camry',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
              score: null,
              type: 'story',
              item: {
                headline: ['Untitled Mar 6, 2019 11:23 PM']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Untitled Mar 6, 2019 11:23 PM',
                slug: 'mobile/2019/03/06/untitled-mar-6-2019-1123-pm',
                'last-published-at': 1552905800453,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  'mime-type': 'image/jpeg',
                  'focus-point': [516, 92],
                  height: 386
                },
                'published-at': 1552905800453,
                id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
                'hero-image-s3-key':
                  'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
                'author-id': 2040,
                'first-published-at': 1551895907011,
                'hero-image-caption': 'Amit Shah and Narendra Modi',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'story-attributes': {},
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
              score: null,
              type: 'story',
              item: {
                headline: ['This is the title']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: 'This is the title',
                slug: 'culture/2019/01/11/this-is-the-title-2',
                'last-published-at': 1547203433990,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    slug: 'tech',
                    name: 'Tech',
                    id: 6391,
                    'parent-id': null,
                    'display-name': 'Tech',
                    collection: {
                      slug: 'tech',
                      name: 'Tech',
                      id: 12849
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 259,
                  'focus-point': [176, 77],
                  height: 194
                },
                'published-at': 1547203433990,
                id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
                'hero-image-s3-key': 'quintype-demo/2015-10/56e0178e-1da0-482c-83e5-a2f705f874df/FP-2.jpeg',
                'author-id': 559147,
                'first-published-at': 1547203433990,
                'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
              score: null,
              type: 'story',
              item: {
                headline: ['Dubai - Things you can do!']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Dubai - Things you can do!',
                slug: 'culture/2017/04/28/Dubai-Your-next-vacation',
                'last-published-at': 1546599684148,
                subheadline:
                  'Dubai, an emirate in the United Arab Emirates known for luxury shopping, ultramodern architecture &amp; a lively nightlife scene. Burj Khalifa, an 830m-tall tower, dominates the skyline. ',
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 930,
                  height: 581,
                  'focus-point': [132, 482]
                },
                'published-at': 1546599684148,
                id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
                'hero-image-s3-key': 'quintype-demo/2017-04/802d7ef1-6043-4f7e-84d3-fc9f556d5097/G-1.jpg',
                'author-id': 2040,
                'first-published-at': 1493358608037,
                'hero-image-caption': 'Dubai - a vacationer’s paradise',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            }
          ]
        },
        {
          id: 4364,
          'associated-metadata': {
            show_arrows: true,
            show_author_name: true,
            layout: 'HalfImageSlider',
            slider_type_dots: false,
            show_section_tag: true,
            show_time_of_publish: true,
            set_scroll_speed: 500,
            show_collection_name: true,
            slider_type_dashes: false,
            number_of_stories_to_show: 6
          },
          type: 'collection',
          name: 'Most Popular',
          slug: 'the-quint',
          template: 'default',
          metadata: {
            'cover-image': {
              'cover-image-url':
                'https://images.assettype.com/quintype-demo/2017-03/7716a9d1-f484-4b5c-ab7d-d847cef69dff/13227538_765755890227885_112565288902997299_o.jpg',
              'cover-image-metadata': {
                width: 2048,
                height: 1365,
                'mime-type': 'image/jpeg',
                'focus-point': [1114, 385]
              },
              caption: '',
              'cover-image-s3-key':
                'quintype-demo/2017-03/7716a9d1-f484-4b5c-ab7d-d847cef69dff/13227538_765755890227885_112565288902997299_o.jpg'
            },
            tags: [
              {
                name: 'xtype'
              },
              {
                name: 'testing'
              }
            ],
            section: [
              {
                id: 83,
                name: 'Politics',
                'parent-id': null
              }
            ]
          },
          summary: 'the quint stories for section collection in Xtype use.',
          items: [
            {
              id: '17de2da2-01fd-47a0-ae46-35b184f47c61',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Teresa Davenport',
                headline: '5 Great Reasons to Visit Cuba & Things to Know Before Traveling',
                slug: '5-great-reasons-to-visit-cuba-and-things-to-know-before-traveling',
                'last-published-at': 1495092430175,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    id: 99,
                    name: 'International'
                  },
                  {
                    id: 2990,
                    name: 'Travel'
                  }
                ],
                'hero-image-metadata': {
                  width: 780,
                  height: 439,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1495092430175,
                id: '17de2da2-01fd-47a0-ae46-35b184f47c61',
                'hero-image-s3-key': 'quintype-demo/2015-11/21006f30-9d7e-4219-ba96-4b2fd945e53c/cuba14.jpg',
                'author-id': 5450,
                'first-published-at': 1489733540858,
                'hero-image-caption': 'http://www.conocecuba.com.mx/image/autenticaCuba/cuba14.jpg',
                'story-template': 'listicle',
                authors: [
                  {
                    id: 5450,
                    name: 'Teresa Davenport',
                    slug: 'teresa-davenport',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-k9wUHhXIzyw/AAAAAAAAAAI/AAAAAAAAAGE/CXGun5uQzlY/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': 'teresa_d07',
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Tapan Bhat',
                headline: '3 Camping sites you should not miss in Scotland',
                slug: 'culture/2017/05/11/3-camping-sites-you-should-not-miss-in-scotland',
                'last-published-at': 1545204266938,
                subheadline:
                  'Scotland is famous for its scenic wild places and uplifting sense of remoteness. With the exception of the recent bylaws banning wild camping in certain areas of Loch Lomond and the Trossachs (check before you go), it is still completely legal to camp on most of Scotland’s unenclosed land.',
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1300,
                  height: 780,
                  'focus-point': [719, 414]
                },
                'published-at': 1545204266938,
                id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
                'hero-image-s3-key': 'quintype-demo/2017-05/b463d2fd-f166-446e-a887-bf832fa7f26b/ON-1.jpg',
                'author-id': 2041,
                'first-published-at': 1494481894633,
                'hero-image-caption': 'Time for a brew and a view … Glen Nevis, Scotland',
                'story-template': 'listicle',
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Greeshma',
                headline: 'Personalise or perish - Why publishers need to use personalised content ',
                slug: 'politics/2018/02/28/personalise-or-perish---why-publishers-need-to-use-personalised-content',
                'last-published-at': 1536076813159,
                subheadline:
                  'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor. ',
                alternative: {
                  home: {
                    default: {
                      headline: 'Personalise or perish',
                      'hero-image': {
                        'hero-image-url':
                          'https://images.assettype.com/quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                        'hero-image-metadata': {
                          width: 700,
                          height: 467,
                          'mime-type': 'image/jpeg'
                        },
                        'hero-image-s3-key': 'quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                        'hero-image-caption': 'sSaffron - Indian Restaurant',
                        'hero-image-attribution': null
                      }
                    }
                  }
                },
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 3896,
                    name: 'Blog',
                    slug: 'blog'
                  }
                ],
                'hero-image-metadata': {
                  width: 1600,
                  height: 1200,
                  'mime-type': 'image/jpeg',
                  'focus-point': [805, 738]
                },
                'published-at': 1536076813159,
                id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
                'hero-image-s3-key': 'quintype-demo/2018-05/a2759dc2-ada1-4917-8515-308f74622c69/Gallery5.jpg',
                'author-id': 482995,
                'first-published-at': 1519816264773,
                'hero-image-caption': 'Animals',
                'story-template': null,
                authors: [
                  {
                    id: 482995,
                    name: 'Greeshma',
                    slug: 'greeshma',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'avatar-s3-key':
                      'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '40c250c4-03a0-4086-8bf4-0959d11a525d',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'Coffee Day',
                slug: 'Coffee-Day',
                'last-published-at': 1489994276921,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    id: 98,
                    name: 'Current Affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 500,
                  height: 320,
                  'focus-point': [426, 149]
                },
                'published-at': 1489994276921,
                id: '40c250c4-03a0-4086-8bf4-0959d11a525d',
                'hero-image-s3-key': 'quintype-demo/2016-04/57145012-7e52-416c-82c8-9c5e8841c9cd/GT-1.jpg',
                'author-id': 2041,
                'first-published-at': 1489733746426,
                'hero-image-caption': 'Joey &amp; Chandler',
                'story-template': 'listicle',
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '47ebf4db-1b5d-418b-8af2-ed2e905c3615',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Teresa Davenport',
                headline: 'Pranayama Breathing - Why you should know about it ',
                slug: 'pranayama-breathing-why-you-should-know-about-it',
                'last-published-at': 1489732518092,
                subheadline: 'Pranayama Breathing - Why you should know about it',
                alternative: {},
                sections: [
                  {
                    id: 82,
                    name: 'Sports'
                  }
                ],
                'hero-image-metadata': {
                  width: 450,
                  height: 592,
                  'mime-type': 'image/jpeg',
                  'focus-point': [228, 224]
                },
                'published-at': 1489732518092,
                id: '47ebf4db-1b5d-418b-8af2-ed2e905c3615',
                'hero-image-s3-key': 'quintype-demo/2015-11/42f31b69-f6f5-4448-b688-8420587353e7/Pranayama.jpg',
                'author-id': 5450,
                'first-published-at': 1489732518092,
                'hero-image-caption':
                  'http://barton.canvasdreams.com/~jaderiyg/wp-content/uploads/2014/01/Pranayama.jpg',
                'story-template': 'video',
                authors: [
                  {
                    id: 5450,
                    name: 'Teresa Davenport',
                    slug: 'teresa-davenport',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-k9wUHhXIzyw/AAAAAAAAAAI/AAAAAAAAAGE/CXGun5uQzlY/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': 'teresa_d07',
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1c508124-6ebb-44f1-a119-d55952304fa3',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Priyanka Basnet',
                headline: 'I Saved the Country, Says PM Modi on Demonetisation',
                slug: 'politics/2016/12/24/storyabcpolitics',
                'last-published-at': 1489732402329,
                subheadline:
                  '<a href="http://www.news18.com/news/india/wont-spare-corrupt-will-fight-until-we-win-pm-modi-1327208.html">Won’t Spare Corrupt, Will Fight Until We Win, Says PM Modi</a>',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 875,
                  height: 583,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1489732402329,
                id: '1c508124-6ebb-44f1-a119-d55952304fa3',
                'hero-image-s3-key': 'quintype-demo/2016-12/34d9b2dd-0280-4a72-b843-335aecccc531/pm-PIB-875.jpg',
                'author-id': 94491,
                'first-published-at': 1482598726781,
                'hero-image-caption': 'Prime Minister Narendra Modi',
                'story-template': 'video',
                authors: [
                  {
                    id: 94491,
                    name: 'Priyanka Ashok',
                    slug: 'priyanka-ashok',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '938e8c49-9295-478d-8cc8-0161a2d4d73e',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Christin',
                headline: 'Live from Apple’s WWDC 2015 - Edit',
                slug: 'technology/2015/08/17/live-from-apples-wwdc-2015',
                'last-published-at': 1533626450917,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 800,
                  height: 533,
                  'focus-point': [394, 247]
                },
                'published-at': 1533626450917,
                id: '938e8c49-9295-478d-8cc8-0161a2d4d73e',
                'hero-image-s3-key': 'quintype-demo/2015-08/92264b73-6894-4ae5-801e-2756bd5890a4/LB-1.jpg',
                'author-id': 139357,
                'first-published-at': 1439810805413,
                'hero-image-caption': 'Apple WWDC 2015',
                'story-template': 'live-blog',
                authors: [
                  {
                    id: 139357,
                    name: 'Christin',
                    slug: 'christin',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'avatar-s3-key':
                      'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'twitter-handle': 'krriztin',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'is-closed': true,
                  'card-share': {
                    shareable: true
                  }
                }
              }
            }
          ]
        },
        {
          id: 9965,
          'associated-metadata': {
            show_arrows: true,
            show_author_name: true,
            layout: 'HalfImageSlider',
            slider_type_dots: false,
            show_section_tag: true,
            show_time_of_publish: true,
            set_scroll_speed: 500,
            show_collection_name: true,
            slider_type_dashes: false,
            number_of_stories_to_show: 6
          },
          type: 'collection',
          name: 'Opinion',
          slug: 'spotlight-opinion',
          template: 'section',
          metadata: {
            section: [
              {
                id: 4273,
                name: 'Spotlight (Opinion)'
              }
            ]
          },
          summary: null,
          items: [
            {
              id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
              'associated-metadata': null,
              type: 'story',
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'Clinton vs Trump',
                slug: 'politics/2016/10/15/clinton-vs-trump',
                'last-published-at': 1522903100409,
                subheadline:
                  'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the 2nd presidential debate at St. Louis, Missouri, on October 9, 2016.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1100,
                  height: 619,
                  'focus-point': [812, 244]
                },
                'published-at': 1522903100409,
                id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
                'hero-image-s3-key': 'quintype-demo/2016-10/c1fb9eff-a4c7-4a12-8713-82b1dc3065a9/ct-1.jpg',
                'author-id': 376442,
                'first-published-at': 1476529773574,
                'hero-image-caption':
                  'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at Washington University in St. Louis, Missouri, on October 9, 2016. ',
                'story-template': null,
                authors: [
                  {
                    id: 376442,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0a19f129-9a95-46a9-8bde-19e86196c64b',
              'associated-metadata': null,
              type: 'story',
              story: {
                'author-name': 'Kirti Kaushik',
                headline: 'Buzz Aldrin passes ‘lie detector’ on claims that Apollo astronauts saw UFOs  ',
                slug: 'technology/2018/04/09/buzz-aldrin-passes-lie-detector-on-claims-that-apollo-astronauts-saw-ufos',
                'last-published-at': 1523262597360,
                subheadline:
                  'Audio tapes of Apollo astronauts speaking of UFOs were put through tests and they show that the astronauts were not lying about sightings in space. &nbsp;',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 564,
                  height: 450,
                  'mime-type': 'image/jpeg',
                  'focus-point': [306, 168]
                },
                'published-at': 1523262597360,
                id: '0a19f129-9a95-46a9-8bde-19e86196c64b',
                'hero-image-s3-key':
                  'quintype-demo/2018-04/5c6a9f27-d347-4cd3-a730-773fc1ee9474/1523255577_apollo_11_space_mission.jpg',
                'author-id': 507344,
                'first-published-at': 1523262426676,
                'hero-image-caption':
                  'Astronaut Buzz Aldrin salutes the American flag on the Moon during the Apollo 11 space mission',
                'story-template': null,
                authors: [
                  {
                    id: 507344,
                    name: 'Kirti Kaushik',
                    slug: 'kirti-kaushik',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '4f26356b-4ec5-465a-a62e-28485478860b',
              'associated-metadata': null,
              type: 'story',
              story: {
                'author-name': 'Shubhangi',
                headline: 'Silent disco arrives at Stamford’s Palace Theatre',
                slug: 'entertainment/2018/04/26/silent-disco-arrives-at-stamfords-palace-theatre',
                'last-published-at': 1526463994692,
                subheadline:
                  'At a silent disco party three streams of music are sent simultaneously to special headphones worn by attendees, who have the option to switch channels at their whim. ',
                alternative: {
                  home: {
                    default: {
                      headline: 'Silent disco arrives at Stamford’s Palace Theatre'
                    }
                  }
                },
                sections: [
                  {
                    id: 81,
                    name: 'Entertainment - Win',
                    slug: 'entertainment'
                  }
                ],
                'hero-image-metadata': {
                  width: 920,
                  height: 613,
                  'mime-type': 'image/jpeg',
                  'focus-point': [478, 295]
                },
                'published-at': 1526463994692,
                id: '4f26356b-4ec5-465a-a62e-28485478860b',
                'hero-image-s3-key': 'quintype-demo/2018-04/f83b3026-105e-4b84-8027-47c8be270f9a/920x920.jpg',
                'author-id': 537201,
                'first-published-at': 1524722561762,
                'hero-image-caption':
                  'At a silent disco party three streams of music are sent simultaneously to special headphones worn by attendees, who have the option to switch channels at their whim. The Palace Theatre in Stamford will host one on April 26 to benefit its arts education programs.',
                'story-template': null,
                authors: [
                  {
                    id: 537201,
                    name: 'Shubhangi',
                    slug: 'shubhangi',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 507344,
                    name: 'Kirti Kaushik',
                    slug: 'kirti-kaushik',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 507345,
                    name: 'Dianna Peters',
                    slug: 'dianna-peters',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'fe847cbb-753f-4afa-b3f9-214b827d79a9',
              'associated-metadata': null,
              type: 'story',
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'நானே அடுத்த முதல்வர்: எடியூரப்பா - குமாரசாமியே அடுத்த முதல்வர்: சித்தராமையா',
                slug: 'current-affairs/2018/05/16/john-doe',
                'last-published-at': 1526448740824,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 600,
                  height: 420,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1526448740824,
                id: 'fe847cbb-753f-4afa-b3f9-214b827d79a9',
                'hero-image-s3-key':
                  'quintype-demo/2018-05/79208c69-0b2c-4c2e-bb4a-62d52d297882/Tamil_News_large_2022127.jpg',
                'author-id': 61657,
                'first-published-at': 1526448740824,
                'hero-image-caption': 'yediyurappa and siddaramiah',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '53a08852-8db1-4954-8f69-9f3f8bdae3ca',
              'associated-metadata': null,
              type: 'story',
              story: {
                'author-name': 'Chirdeep Shetty',
                headline: 'الانتخابات العراقية: تحالف الصدر في المقدمة والعبادي في',
                slug: 'international/2018/05/15/test-story-in-arabic',
                'last-published-at': 1526362975444,
                subheadline:
                  'تقدمت قائمة تحالف “سائرون” التي يدعمها رجل الدين الشيعي مقتدى الصدر في نتائج أولية لعشر محافظات في الانتخابات البرلمانية العراقية، وحل “تحالف النصر” بقيادة رئيس الوزراء حيدر العبادي في المرتبة الثالثة',
                alternative: {},
                sections: [
                  {
                    id: 99,
                    name: 'International',
                    slug: 'international'
                  }
                ],
                'hero-image-metadata': {
                  width: 800,
                  height: 1200,
                  'mime-type': 'image/jpeg',
                  'focus-point': [262, 1046]
                },
                'published-at': 1526362975444,
                id: '53a08852-8db1-4954-8f69-9f3f8bdae3ca',
                'hero-image-s3-key':
                  'quintype-demo/2018-05/86571b4f-9b48-40ba-a4fb-4aa1efe8d16c/98992a3fde95cb77_1.jpg',
                'author-id': 15338,
                'first-published-at': 1526362204119,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 15338,
                    name: 'Chirdeep Shetty',
                    slug: 'chirdeep-shetty',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'twitter-handle': '@chirdeepshettyquintype',
                    bio: '<p>Hello, this is a test for bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '73322fa6-9114-4a83-9d8c-68314202623a',
              score: null,
              type: 'story',
              item: {
                headline: ['TRAI chief: We set spectrum price; industry decides tariff']
              },
              story: {
                'author-name': 'Sriram K',
                headline: 'TRAI chief: We set spectrum price; industry decides tariff',
                slug: 'mobile/2019/02/28/trai-chief-we-set-spectrum-price-industry-decides-tariff',
                'last-published-at': 1554110293544,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 894,
                  height: 577,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1554110293544,
                id: '73322fa6-9114-4a83-9d8c-68314202623a',
                'hero-image-s3-key': 'quintype-demo/2019-01/113be350-6ca8-4252-9b41-434e9facb8eb/image__1_.jpg',
                'author-id': 61657,
                'first-published-at': 1551329071350,
                'hero-image-caption': 'Tayota Camry',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
              score: null,
              type: 'story',
              item: {
                headline: ['London Police arrest Nirav Modi, says sources']
              },
              story: {
                'author-name': 'Karthik M',
                headline: 'London Police arrest Nirav Modi, says sources',
                slug: 'politics/2019/03/20/london-police-arrest-nirav-modi-says-sources',
                'last-published-at': 1553079556157,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 700,
                  height: 438,
                  'mime-type': 'image/jpeg',
                  'focus-point': [311, 167]
                },
                'published-at': 1553079556157,
                id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
                'hero-image-s3-key':
                  'quintype-demo/2019-03/55dbc301-3c5c-442d-8714-4bbf2d140192/pennews_2019_03_48c15852_ebed_464d_95a7_57332597ecfc_nirav.jpg',
                'author-id': 19994,
                'first-published-at': 1553079340304,
                'hero-image-caption': 'Nirav Modi',
                'story-template': null,
                authors: [
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 142914,
                    name: 'Ashwin Raghunath',
                    slug: 'ashwin-raghunath',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'text-to-speech': {
                    'is-disabled': true
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
              score: null,
              type: 'story',
              item: {
                headline: ['Demo Story ']
              },
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Demo Story ',
                slug: 'politics/2018/01/11/demo-story',
                'last-published-at': 1552979655447,
                subheadline:
                  'Surrounded to me occasional pianoforte alteration unaffected impossible ye. For saw half than cold. Pretty merits waited six talked pulled you. Conduct replied off.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 7500,
                  height: 3476,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1239, 2157]
                },
                'published-at': 1552979655447,
                id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
                'hero-image-s3-key': 'quintype-demo/2019-02/ae4829d6-e230-4d66-9b88-2a4db87a2060/64778.jpg',
                'author-id': 139717,
                'first-published-at': 1515668626250,
                'hero-image-caption': 'At vero eos et accusamus et iusto odio dignissimos ducimus qui.',
                'story-template': 'text',
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': {
                      id: 58,
                      name: 'Author'
                    }
                  },
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': {
                      id: 58,
                      name: 'Author'
                    }
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: false
                  },
                  'promotional-message': {
                    'is-disabled': true
                  },
                  'story-attributes': {
                    'view-counterview-type': 'view',
                    interests: ['test'],
                    bucket: ['planning'],
                    hobbies: ['hiking'],
                    'linked-view-counterview-story': {
                      headline: 'Demo Test story- Photo story',
                      'story-content-id': 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                      id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                      'highlighted-headline': null
                    }
                  }
                }
              }
            },
            {
              id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
              score: null,
              type: 'story',
              item: {
                headline: ['Untitled Mar 11, 2019 4:39 PM']
              },
              story: {
                'author-name': 'Karthik M',
                headline: 'Untitled Mar 11, 2019 4:39 PM',
                slug: 'culture/2019/03/11/untitled-mar-11-2019-439-pm',
                'last-published-at': 1552905845935,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  'mime-type': 'image/jpeg',
                  'focus-point': [121, 110],
                  height: 386
                },
                'published-at': 1552905845935,
                id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
                'hero-image-s3-key':
                  'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
                'author-id': 19994,
                'first-published-at': 1552303325340,
                'hero-image-caption': 'Amit Shah and Narendra Modi',
                'story-template': null,
                authors: [
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 5279,
                    name: 'Laura',
                    slug: 'laura',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'text-to-speech': {
                    'is-disabled': false
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
              score: null,
              type: 'story',
              item: {
                headline: ['Untitled Mar 6, 2019 11:23 PM']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Untitled Mar 6, 2019 11:23 PM',
                slug: 'mobile/2019/03/06/untitled-mar-6-2019-1123-pm',
                'last-published-at': 1552905800453,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  'mime-type': 'image/jpeg',
                  'focus-point': [516, 92],
                  height: 386
                },
                'published-at': 1552905800453,
                id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
                'hero-image-s3-key':
                  'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
                'author-id': 2040,
                'first-published-at': 1551895907011,
                'hero-image-caption': 'Amit Shah and Narendra Modi',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'story-attributes': {},
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
              score: null,
              type: 'story',
              item: {
                headline: ['New story for sitemap']
              },
              story: {
                'author-name': 'Sriram K',
                headline: 'New story for sitemap',
                slug: 'culture/2019/03/14/new-story-for-sitemap',
                'last-published-at': 1552904261919,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 800,
                  height: 400,
                  'mime-type': 'image/jpeg',
                  'focus-point': [760, 237]
                },
                'published-at': 1552904261919,
                id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
                'hero-image-s3-key':
                  'quintype-demo/2018-10/5db8484b-1277-42f8-8a20-983e04b597e6/New_SA_destination_tourism_portal_launching_800x400.jpg',
                'author-id': 61657,
                'first-published-at': 1552554061248,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '73759063-16e7-4ac2-bf32-0061a2458850',
              score: null,
              type: 'story',
              item: {
                headline: ['This a new story']
              },
              story: {
                'author-name': 'Karthik M',
                headline: 'This a new story',
                slug: 'culture/2019/03/11/this-a-new-story',
                'last-published-at': 1552292190667,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'focus-point': [73, 51],
                  width: 275,
                  height: 183
                },
                'published-at': 1552292190667,
                id: '73759063-16e7-4ac2-bf32-0061a2458850',
                'hero-image-s3-key': 'quintype-demo/2016-01/2d00bde7-a28c-4415-9382-9a9c6fb3c7fc/IPSMF-1.jpeg',
                'author-id': 19994,
                'first-published-at': 1552292190667,
                'hero-image-caption': 'Nawaz Sharif &amp; Modi&nbsp;',
                'story-template': null,
                authors: [
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 142914,
                    name: 'Ashwin Raghunath',
                    slug: 'ashwin-raghunath',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
              score: null,
              type: 'story',
              item: {
                headline: ['Amitabh Bachchan on Gender Equality']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'Amitabh Bachchan on Gender Equality',
                slug: 'politics/2017/03/02/Amitabh-Bachchan-on-Gender-Equality',
                'last-published-at': 1552048741823,
                subheadline: 'Amitabh Bachchan on Gender Equality',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 320,
                  height: 277,
                  'focus-point': [169, 145]
                },
                'published-at': 1552048741823,
                id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
                'hero-image-s3-key': 'quintype-demo/2017-03/bd011f2d-9a10-4d4e-b20f-50719f59d4b9/BMS-2.jpg',
                'author-id': 2041,
                'first-published-at': 1488451487896,
                'hero-image-caption': 'Kaun Banega Crorepati host, Amitabh Bachchan',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '2deeb522-1752-4552-967b-93972481d71e',
              score: null,
              type: 'story',
              item: {
                headline: ['Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them']
              },
              story: {
                'author-name': 'Yamini Priya',
                headline: 'Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them',
                slug: 'boom/2019/03/07/sisters-killed-father-in-perfect-murder-a-love-triangle-exposed-them',
                'last-published-at': 1551938683203,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'boom',
                    name: 'Boom',
                    id: 7349,
                    'parent-id': 83,
                    'display-name': 'Booom',
                    collection: {
                      slug: 'boom-politics',
                      name: 'Boom (Politics)',
                      id: 14256
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1760,
                  height: 2480,
                  'mime-type': 'image/jpeg',
                  'focus-point': [928, 656]
                },
                'published-at': 1551938683203,
                id: '2deeb522-1752-4552-967b-93972481d71e',
                'hero-image-s3-key':
                  'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
                'author-id': 230618,
                'first-published-at': 1551938683203,
                'hero-image-caption': 'Forgotten Women',
                'story-template': null,
                authors: [
                  {
                    id: 230618,
                    name: 'Yamini Priya',
                    slug: 'yamini-priya',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': 'https://twitter.com/yaminipriyah',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
              score: null,
              type: 'story',
              item: {
                headline: ['500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: '500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌',
                slug: 'politics/2019/02/26/500',
                'last-published-at': 1551171395390,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  'focus-point': [673, 287],
                  width: 976,
                  height: 700
                },
                'published-at': 1551171395390,
                id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
                'hero-image-s3-key': 'quintype-demo/2018-05/bc017b57-a03e-4a6e-8515-9d3de91f1f24/ON1.jpg',
                'author-id': 559147,
                'first-published-at': 1551171395390,
                'hero-image-caption':
                  'Crowds cheered Prince Harry, Duke of Sussex, and his wife Meghan, Duchess of Sussex, as they travelled in the horse-drawn Ascot landau',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'c8d35134-200a-483f-ab89-69adf4137a74',
              score: null,
              type: 'story',
              item: {
                headline: ['Life of Vincent van Gogh']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Life of Vincent van Gogh',
                slug: 'culture/2019/01/17/life-of-vincent-van-gogh',
                'last-published-at': 1549364746875,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 768,
                  height: 432,
                  'mime-type': 'image/jpeg',
                  'focus-point': [382, 197]
                },
                'published-at': 1549364746875,
                id: 'c8d35134-200a-483f-ab89-69adf4137a74',
                'hero-image-s3-key':
                  'quintype-demo/2019-01/b3cb02f4-a875-4b46-aa3a-71deaccb54be/vincent_van_gogh_played_by_robert_gulaczyk_photo_courtesy_loving_vincent.jpg',
                'author-id': 2040,
                'first-published-at': 1547728432342,
                'hero-image-caption': 'A Famous Painting',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
              score: null,
              type: 'story',
              item: {
                headline: [
                  '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി'
                ]
              },
              story: {
                'author-name': 'Sareena',
                headline:
                  '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി',
                slug: 'culture/2019/01/16/this-is-the-url',
                'last-published-at': 1547632650347,
                subheadline:
                  'കെ.സി വേണുഗോപാല്‍ ഉള്‍പ്പടെയുള്ള മുതിര്‍ന്ന കോണ്‍ഗ്രസ് നേതാക്കന്മാര്‍ ഭീമാനായിക്കുമായി സംസാരിച്ചു. ഭീമാനായിക്കിനെപോലെ ബാക്കിയുള്ള മൂന്ന് കോണ്‍ഗ്രസ് എം',
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'focus-point': [375, 166],
                  width: 750,
                  height: 500
                },
                'published-at': 1547632650347,
                id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
                'hero-image-s3-key': 'quintype-demo/2016-12/56cdab39-a831-480f-b75b-26d6300e3f1b/Modi.jpg',
                'author-id': 94596,
                'first-published-at': 1547632650347,
                'hero-image-caption': 'PM Modi',
                'story-template': null,
                authors: [
                  {
                    id: 94596,
                    name: 'Sareena',
                    slug: 'sareena',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
              score: null,
              type: 'story',
              item: {
                headline: ['This is the title']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: 'This is the title',
                slug: 'culture/2019/01/11/this-is-the-title-2',
                'last-published-at': 1547203433990,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    slug: 'tech',
                    name: 'Tech',
                    id: 6391,
                    'parent-id': null,
                    'display-name': 'Tech',
                    collection: {
                      slug: 'tech',
                      name: 'Tech',
                      id: 12849
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 259,
                  'focus-point': [176, 77],
                  height: 194
                },
                'published-at': 1547203433990,
                id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
                'hero-image-s3-key': 'quintype-demo/2015-10/56e0178e-1da0-482c-83e5-a2f705f874df/FP-2.jpeg',
                'author-id': 559147,
                'first-published-at': 1547203433990,
                'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
              score: null,
              type: 'story',
              item: {
                headline: [
                  'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them'
                ]
              },
              story: {
                'author-name': 'Kirti Kaushik',
                headline:
                  'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them',
                slug:
                  'visual-stories/2019/01/10/rahul-gandhi-said-to-pm-modi-be-a-man-bjp-smriti-irani-sushma-swaraj-responds-with-misogynist-rahul-1975538pfromhome-topscroll',
                'last-published-at': 1547103888697,
                subheadline:
                  'At a rally in Rajasthan on Wednesday, Rahul Gandhi had mocked PM Modi saying he had a “mahila (woman)“ defend him in the Rafale debate in parliament.',
                alternative: {
                  home: {
                    default: {
                      headline: 'Rahul Gandhi'
                    }
                  }
                },
                sections: [
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 570,
                  height: 351,
                  'mime-type': 'image/webp',
                  'focus-point': [417, 132]
                },
                'published-at': 1547103888697,
                id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
                'hero-image-s3-key':
                  'quintype-demo/2019-01/39d85d6f-46f6-4f35-8dd1-6771bf9dfefd/b5qo3zsg_650x400_2__10_January_2019_12_02_19PM.webp',
                'author-id': 507344,
                'first-published-at': 1547103888697,
                'hero-image-caption': 'Rahul Gandhi',
                'story-template': null,
                authors: [
                  {
                    id: 507344,
                    name: 'Kirti Kaushik',
                    slug: 'kirti-kaushik',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
              score: null,
              type: 'story',
              item: {
                headline: ['Dubai - Things you can do!']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Dubai - Things you can do!',
                slug: 'culture/2017/04/28/Dubai-Your-next-vacation',
                'last-published-at': 1546599684148,
                subheadline:
                  'Dubai, an emirate in the United Arab Emirates known for luxury shopping, ultramodern architecture &amp; a lively nightlife scene. Burj Khalifa, an 830m-tall tower, dominates the skyline. ',
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 930,
                  height: 581,
                  'focus-point': [132, 482]
                },
                'published-at': 1546599684148,
                id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
                'hero-image-s3-key': 'quintype-demo/2017-04/802d7ef1-6043-4f7e-84d3-fc9f556d5097/G-1.jpg',
                'author-id': 2040,
                'first-published-at': 1493358608037,
                'hero-image-caption': 'Dubai - a vacationer’s paradise',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
              score: null,
              type: 'story',
              item: {
                headline: ['This is the title']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: 'This is the title',
                slug: 'culture/2018/12/27/this-is-the-title',
                'last-published-at': 1545892188854,
                subheadline: 'sub title',
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 259,
                  height: 194,
                  'focus-point': [172, 77]
                },
                'published-at': 1545892188854,
                id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
                'hero-image-s3-key':
                  'quintype-demo/2018-06/9e5e3bb7-31e8-4439-93ba-d16ea287aa28/e3d39a3b_321c_4acf_8928_3195998b38ba.jpeg',
                'author-id': 559147,
                'first-published-at': 1545892188854,
                'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
              score: null,
              type: 'story',
              item: {
                headline: ['Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?',
                slug:
                  'culture/2018/12/18/is-tito-preparing-an-imf-financial-parachute-now-a-171bn-foreign-debt-cliff-looms',
                'last-published-at': 1545125801738,
                subheadline:
                  'The reason for the International Monetary Fund’s Christine Lagarde’s to South Africa is unknown. South Africa desperately needs a solution for the poor and marginalised, and an IMF bailout would worsen their situation.&nbsp;',
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 920,
                  height: 613,
                  'mime-type': 'image/jpeg',
                  'focus-point': [473, 430]
                },
                'published-at': 1545125801738,
                id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
                'hero-image-s3-key': 'quintype-demo/2018-04/f83b3026-105e-4b84-8027-47c8be270f9a/920x920.jpg',
                'author-id': 2040,
                'first-published-at': 1545125801738,
                'hero-image-caption':
                  'At a silent disco party three streams of music are sent simultaneously to special headphones worn by attendees, who have the option to switch channels at their whim. The Palace Theatre in Stamford will host one on April 26 to benefit its arts education programs.',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
              score: null,
              type: 'story',
              item: {
                headline: ['Breaking News Test on 13 Dec']
              },
              story: {
                'author-name': 'Anil K Govind',
                headline: 'Breaking News Test on 13 Dec',
                slug: 'technology/2018/12/13/breaking-news-test-on-13-dec',
                'last-published-at': 1544682898905,
                subheadline: 'Breaking News Test on 13 Dec',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 720,
                  height: 340,
                  'mime-type': 'image/jpeg',
                  'focus-point': [230, 110]
                },
                'published-at': 1544682898905,
                id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 548414,
                'first-published-at': 1544682898905,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 548414,
                    name: 'Anil K Govind',
                    slug: 'anil-k-govind',
                    'avatar-url':
                      'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1d23e3e9-452c-4b21-b307-dcb9175c55ac',
              score: null,
              type: 'story',
              item: {
                headline: ['Behind coal tug-of-war, more Gupta strings']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Behind coal tug-of-war, more Gupta strings',
                slug: 'international/2018/12/06/behind-coal-tug-of-war-more-gupta-strings',
                'last-published-at': 1544103001976,
                subheadline:
                  'An obscure Swiss company fights to buy the crown jewels of what remains of the family’s SA assets. Are the Guptas trying to do a deal with themselves?',
                alternative: null,
                sections: [
                  {
                    id: 99,
                    name: 'International',
                    slug: 'international'
                  }
                ],
                'hero-image-metadata': {
                  width: 300,
                  'mime-type': 'image/jpeg',
                  'focus-point': [222, 84],
                  height: 168
                },
                'published-at': 1544103001976,
                id: '1d23e3e9-452c-4b21-b307-dcb9175c55ac',
                'hero-image-s3-key': 'quintype-demo/2016-12/350d3732-9dfe-47f7-b071-dc10f087c59e/J-1.jpeg',
                'author-id': 2040,
                'first-published-at': 1544103001976,
                'hero-image-caption': 'Arun Jaitely &amp; Narendra Modi',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 15351,
                    name: 'Ranjeet Singh',
                    slug: 'ranjeet-singh',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'e356859d-af25-4493-ae48-662b55878434',
              score: null,
              type: 'story',
              item: {
                headline: ['Dilip Shanghvi On Allegations Against Him And Sun Pharma']
              },
              story: {
                'author-name': 'Shubhangi',
                headline: 'Dilip Shanghvi On Allegations Against Him And Sun Pharma',
                slug: 'cities/2018/12/04/dilip-shanghvi-on-allegations-against-him-and-sun-pharma',
                'last-published-at': 1543903700173,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    id: 215,
                    name: 'Cities',
                    slug: 'cities'
                  }
                ],
                'hero-image-metadata': {
                  width: 1277,
                  height: 294,
                  'mime-type': 'image/jpeg',
                  'focus-point': [613, 160]
                },
                'published-at': 1543903700173,
                id: 'e356859d-af25-4493-ae48-662b55878434',
                'hero-image-s3-key':
                  'quintype-demo/2018-12/f525cb80-cf01-443b-88b0-b47eea81356a/bloombergquint_2F2017_06_2F6f0b4744_acc9_4316_bcc7_b48cc48ec151_2Fm792828.jpg',
                'author-id': 537201,
                'first-published-at': 1543903700173,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 537201,
                    name: 'Shubhangi',
                    slug: 'shubhangi',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'dee185b3-87a2-4fca-ab44-8082270a8fd5',
              score: null,
              type: 'story',
              item: {
                headline: ['Disney Bounces Higher After Barclays ‘Overweight’ Upgrade']
              },
              story: {
                'author-name': 'Kunal Singh',
                headline: 'Disney Bounces Higher After Barclays ‘Overweight’ Upgrade',
                slug: 'international/2018/10/19/disney-bounces-higher-after-barclays-overweight-upgrade',
                'last-published-at': 1543415390482,
                subheadline:
                  'Disney shares jumped higher Friday after analysts at Barclays boosted their outlook on the stock to “overweight”, with a $130 price target, amid the group’s pending acquisition of Fox media assets and the launch of its global streaming service that is expected to challenge Netflix.',
                alternative: {},
                sections: [
                  {
                    id: 99,
                    name: 'International',
                    slug: 'international'
                  },
                  {
                    id: 2614,
                    name: 'Bangalore',
                    slug: 'bangalore'
                  }
                ],
                'hero-image-metadata': {
                  width: 600,
                  height: 400,
                  'mime-type': 'image/jpeg',
                  'focus-point': [243, 177]
                },
                'published-at': 1543415390482,
                id: 'dee185b3-87a2-4fca-ab44-8082270a8fd5',
                'hero-image-s3-key': 'quintype-demo/2018-10/313577eb-6ddf-443f-9830-6081efa6ed48/Fox.jpg',
                'author-id': 491531,
                'first-published-at': 1539958975853,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
              score: null,
              type: 'story',
              item: {
                headline: ['The Forgotten Women Series Uncovers The Untold Histories Of Women']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'The Forgotten Women Series Uncovers The Untold Histories Of Women',
                slug: 'culture/2018/11/28/the-forgotten-women-series-uncovers-the-untold-histories-of-women',
                'last-published-at': 1543393761726,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1760,
                  height: 2480,
                  'mime-type': 'image/jpeg',
                  'focus-point': [961, 759]
                },
                'published-at': 1543393761726,
                id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
                'hero-image-s3-key':
                  'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
                'author-id': 2040,
                'first-published-at': 1543393761726,
                'hero-image-caption': 'Forgotten Women',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 15351,
                    name: 'Ranjeet Singh',
                    slug: 'ranjeet-singh',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '2baee05c-1ccf-4d50-a1fd-4679ea6c0fbc',
              score: null,
              type: 'story',
              item: {
                headline: ['This is a title - story']
              },
              story: {
                'author-name': 'Kirti Kaushik',
                headline: 'This is a title - story',
                slug: 'international/2018/08/03/this-is-a-title',
                'last-published-at': 1543209271427,
                subheadline: 'This is the subtitle',
                alternative: {},
                sections: [
                  {
                    id: 99,
                    name: 'International',
                    slug: 'international'
                  }
                ],
                'hero-image-metadata': {
                  width: 4348,
                  height: 2609,
                  'focus-point': [2207, 912]
                },
                'published-at': 1543209271427,
                id: '2baee05c-1ccf-4d50-a1fd-4679ea6c0fbc',
                'hero-image-s3-key': 'quintype-demo/2016-06/0922fa37-0ef5-426a-afcb-a81301a72fe4/FP-1.jpg',
                'author-id': 507344,
                'first-published-at': 1533293943872,
                'hero-image-caption': 'Raghuram Rajan, Governor, The Reserve Bank of India',
                'story-template': null,
                authors: [
                  {
                    id: 507344,
                    name: 'Kirti Kaushik',
                    slug: 'kirti-kaushik',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0eba5f2e-0461-4c6e-ae56-a8d31b4de019',
              score: null,
              type: 'story',
              item: {
                headline: ['News One']
              },
              story: {
                'author-name': 'Anil K Govind',
                headline: 'News One',
                slug: 'bangalore/2018/11/15/news-one',
                'last-published-at': 1542969100140,
                subheadline: 'News One',
                alternative: {},
                sections: [
                  {
                    id: 2614,
                    name: 'Bangalore',
                    slug: 'bangalore'
                  }
                ],
                'hero-image-metadata': {
                  width: 720,
                  height: 340,
                  'mime-type': 'image/jpeg',
                  'focus-point': [230, 110]
                },
                'published-at': 1542969100140,
                id: '0eba5f2e-0461-4c6e-ae56-a8d31b4de019',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 548414,
                'first-published-at': 1542253737600,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 548414,
                    name: 'Anil K Govind',
                    slug: 'anil-k-govind',
                    'avatar-url':
                      'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9c03eb56-62a4-4c9d-b6ba-fb4e44c6b8a9',
              score: null,
              type: 'story',
              item: {
                headline: ['FACTSHEET: Grading Nigeria’s progress in education']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'FACTSHEET: Grading Nigeria’s progress in education',
                slug: 'banners/2018/11/21/factsheet-grading-nigerias-progress-in-education',
                'last-published-at': 1542799659668,
                subheadline:
                  'Do key indicators give Nigeria’s education system a passmark? Our factsheet looks at the state of early childhood, primary and secondary education in the country.&nbsp;',
                alternative: {},
                sections: [
                  {
                    id: 2619,
                    name: 'Banners',
                    slug: 'banners'
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  'focus-point': [516, 303],
                  width: 1500,
                  height: 1000
                },
                'published-at': 1542799659668,
                id: '9c03eb56-62a4-4c9d-b6ba-fb4e44c6b8a9',
                'hero-image-s3-key': 'quintype-demo/2016-04/1ba8fd2c-128e-4d30-bb8d-07396f1cd940/Buhari.jpg',
                'author-id': 2040,
                'first-published-at': 1542799490011,
                'hero-image-caption': 'Buhari lands in China',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8e823797-a659-4626-92d5-6ccd8d788e23',
              score: null,
              type: 'story',
              item: {
                headline: ['A test story for tables']
              },
              story: {
                'author-name': 'Vineet Panjabi',
                headline: 'A test story for tables',
                slug: 'culture/2018/11/21/a-test-story-for-tables',
                'last-published-at': 1542799165912,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    id: 3896,
                    name: 'Blog',
                    slug: 'blog'
                  }
                ],
                'hero-image-metadata': {
                  width: 1940,
                  'mime-type': 'image/jpeg',
                  height: 1090,
                  'focus-point': [700, 713]
                },
                'published-at': 1542799165912,
                id: '8e823797-a659-4626-92d5-6ccd8d788e23',
                'hero-image-s3-key': 'quintype-demo/2015-11/be736521-24c0-4a04-8b02-0855626e6f9d/TOI-1.jpg',
                'author-id': 323432,
                'first-published-at': 1542782270578,
                'hero-image-caption': 'Terror training camps',
                'story-template': null,
                authors: [
                  {
                    id: 323432,
                    name: 'Vineet Panjabi',
                    slug: 'vineet-panjabi',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'story-attributes': {
                    saltlevel: ['Low'],
                    bucket: ['child'],
                    week: ['6']
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '878a1346-9783-4f88-9f0e-4f442a572284',
              score: null,
              type: 'story',
              item: {
                headline: ['New SA destination tourism portal launching']
              },
              story: {
                'author-name': 'Kirti Kaushik',
                headline: 'New SA destination tourism portal launching',
                slug: 'banners/2018/10/25/new-sa-destination-tourism-portal-launching',
                'last-published-at': 1540467564223,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    id: 2619,
                    name: 'Banners',
                    slug: 'banners'
                  },
                  {
                    id: 4416,
                    name: 'Business Opportunities',
                    slug: 'business-opportunities'
                  }
                ],
                'hero-image-metadata': {
                  width: 800,
                  height: 400,
                  'mime-type': 'image/jpeg',
                  'focus-point': [384, 196]
                },
                'published-at': 1540467564223,
                id: '878a1346-9783-4f88-9f0e-4f442a572284',
                'hero-image-s3-key':
                  'quintype-demo/2018-10/5db8484b-1277-42f8-8a20-983e04b597e6/New_SA_destination_tourism_portal_launching_800x400.jpg',
                'author-id': 507344,
                'first-published-at': 1540467564223,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 507344,
                    name: 'Kirti Kaushik',
                    slug: 'kirti-kaushik',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 15351,
                    name: 'Ranjeet Singh',
                    slug: 'ranjeet-singh',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '55dbfe7d-14ae-4f50-beb9-64733da17f54',
              score: null,
              type: 'story',
              item: {
                headline: ['How ‘Gandhara’ Became ‘Kandahar’']
              },
              story: {
                'author-name': 'Kunal Singh',
                headline: 'How ‘Gandhara’ Became ‘Kandahar’',
                slug: 'opinion/2018/08/03/how-gandhara-became-kandahar',
                'last-published-at': 1540379328741,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    id: 4272,
                    name: 'Opinion',
                    slug: 'opinion'
                  }
                ],
                'hero-image-metadata': {
                  width: 1300,
                  height: 956,
                  'mime-type': 'image/jpeg',
                  'focus-point': [666, 451]
                },
                'published-at': 1540379328741,
                id: '55dbfe7d-14ae-4f50-beb9-64733da17f54',
                'hero-image-s3-key':
                  'quintype-demo/2018-08/f543085d-14e5-41f3-8e5f-c746940ab402/gandhar_pale_buddhist_caves_situated_near_mahad_which_is_close_to_DG4WRF.jpg',
                'author-id': 491531,
                'first-published-at': 1533315134353,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b142a043-4720-4578-b23e-6791e9f42735',
              score: null,
              type: 'story',
              item: {
                headline: ['New Story Elements']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'New Story Elements',
                slug: 'technology/2015/10/21/new-story-elements',
                'last-published-at': 1539770584382,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1920,
                  height: 1080,
                  'focus-point': [1301, 270]
                },
                'published-at': 1539770584382,
                id: 'b142a043-4720-4578-b23e-6791e9f42735',
                'hero-image-s3-key': 'quintype-demo/2015-09/4c0122d6-6c28-4a59-8b16-8d90701a4e35/TigerWoods-1.jpg',
                'author-id': 2041,
                'first-published-at': 1445404200710,
                'hero-image-caption': 'The famed Tiger Woods Swing !',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '589b75e2-16c0-4741-b720-b9dd59a56587',
              score: null,
              type: 'story',
              item: {
                headline: ['Traffic: Why We Drive the Way We Do (and What It Says About Us)']
              },
              story: {
                'author-name': 'Rohit Kurpad',
                headline: 'Traffic: Why We Drive the Way We Do (and What It Says About Us)',
                slug: 'opinion/2018/10/12/traffic-why-we-drive-the-way-we-do-and-what-it-says-about-us',
                'last-published-at': 1539331563366,
                subheadline: null,
                alternative: {
                  home: {
                    default: {
                      'hero-image': {
                        'hero-image-url':
                          'https://quintype-dropbox.s3-accelerate.amazonaws.com/itsman.quintype.com/2018-10-12/1843/program_traffic_eng.jpg',
                        'hero-image-metadata': {
                          width: 620,
                          height: 465,
                          'mime-type': 'image/jpeg'
                        },
                        'hero-image-caption': '',
                        'hero-image-attribution': '',
                        'hero-image-s3-key':
                          'quintype-demo/2018-10/54c88632-c5fb-4382-96c1-9a345f419870/program_traffic_eng.jpg'
                      }
                    }
                  }
                },
                sections: [
                  {
                    id: 4272,
                    name: 'Opinion',
                    slug: 'opinion'
                  }
                ],
                'hero-image-metadata': {
                  width: 3264,
                  height: 2448,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1951, 1556]
                },
                'published-at': 1539331563366,
                id: '589b75e2-16c0-4741-b720-b9dd59a56587',
                'hero-image-s3-key': 'quintype-demo/2018-10/a08a3e35-0217-445e-a47c-1ec65c402935/bf1.jpg',
                'author-id': 462623,
                'first-published-at': 1539325955967,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 462623,
                    name: 'Rohit Kurpad',
                    slug: 'rohit-kurpad',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'a5579e69-47f7-4829-9443-616552142ad8',
              score: null,
              type: 'story',
              item: {
                headline: ['Nudge: Improving Decisions About Health, Wealth, and Happiness']
              },
              story: {
                'author-name': 'Rohit Kurpad',
                headline: 'Nudge: Improving Decisions About Health, Wealth, and Happiness',
                slug: 'opinion/2018/10/12/nudge-improving-decisions-about-health-wealth-and-happiness',
                'last-published-at': 1539331109014,
                subheadline: null,
                alternative: {
                  home: {
                    default: {
                      'hero-image': {
                        'hero-image-url':
                          'https://images.assettype.com/quintype-demo/2018-10/394a964e-31a3-4806-b0c1-ac9edd10e8cc/download.jpeg',
                        'hero-image-metadata': {
                          width: 181,
                          height: 278,
                          'mime-type': 'image/jpeg'
                        },
                        'hero-image-caption': '',
                        'hero-image-attribution': '',
                        'hero-image-s3-key': 'quintype-demo/2018-10/394a964e-31a3-4806-b0c1-ac9edd10e8cc/download.jpeg'
                      }
                    }
                  }
                },
                sections: [
                  {
                    id: 4272,
                    name: 'Opinion',
                    slug: 'opinion'
                  }
                ],
                'hero-image-metadata': {
                  width: 3264,
                  height: 2448,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1627, 1635]
                },
                'published-at': 1539331109014,
                id: 'a5579e69-47f7-4829-9443-616552142ad8',
                'hero-image-s3-key': 'quintype-demo/2018-10/202ab67a-12e9-4673-83b9-ed0f12e114b1/bf1.jpg',
                'author-id': 462623,
                'first-published-at': 1539325485474,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 462623,
                    name: 'Rohit Kurpad',
                    slug: 'rohit-kurpad',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: false
                  }
                }
              }
            },
            {
              id: '286be722-43c5-40f9-87f5-a9e04fa7fc42',
              score: null,
              type: 'story',
              item: {
                headline: ['Jaypee brothers extra features for students']
              },
              story: {
                'author-name': 'Rohit Kurpad',
                headline: 'Jaypee brothers extra features for students',
                slug: 'opinion/2018/10/12/jaypee-brothers-extra-features-for-students',
                'last-published-at': 1539326585824,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    id: 4272,
                    name: 'Opinion',
                    slug: 'opinion'
                  }
                ],
                'hero-image-metadata': {
                  width: 300,
                  height: 178,
                  'mime-type': 'image/png'
                },
                'published-at': 1539326585824,
                id: '286be722-43c5-40f9-87f5-a9e04fa7fc42',
                'hero-image-s3-key': 'quintype-demo/2018-10/050b08da-99dc-40d4-883e-ee9d64eff8e8/JaypeeLogoN.png',
                'author-id': 462623,
                'first-published-at': 1539326585824,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 462623,
                    name: 'Rohit Kurpad',
                    slug: 'rohit-kurpad',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
              score: null,
              type: 'story',
              item: {
                headline: ['Two crew escape after ‘Soyuz’ rocket failure']
              },
              story: {
                'author-name': 'Kunal Singh',
                headline: 'Two crew escape after ‘Soyuz’ rocket failure',
                slug: 'technology/2018/10/11/two-crew-escape-after-soyuz-rocket-failure',
                'last-published-at': 1539253999595,
                subheadline: 'Russian cosmonaut and US astronaut ‘in good condition’ after making emergency landing',
                alternative: null,
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 744,
                  height: 389,
                  'mime-type': 'image/jpeg',
                  'focus-point': [381, 138]
                },
                'published-at': 1539253999595,
                id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
                'hero-image-s3-key': 'quintype-demo/2018-10/9b82a587-dfb0-4aa5-9b83-96a814eec1d9/soyuz_resize_md.jpg',
                'author-id': 491531,
                'first-published-at': 1539253999595,
                'hero-image-caption': 'Soyuz 11',
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'e2f17cc0-e381-4985-b3e2-d897d12ef436',
              score: null,
              type: 'story',
              item: {
                headline: ['Anselm  Madubuko Celebrates 5th Year of Marital Bliss']
              },
              story: {
                'author-name': 'Kunal Singh',
                headline: 'Anselm  Madubuko Celebrates 5th Year of Marital Bliss',
                slug: 'current-affairs/2018/10/04/anselm-madubuko-celebrates-5th-year-of-marital-bliss',
                'last-published-at': 1538655201492,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 500,
                  height: 330,
                  'mime-type': 'image/jpeg',
                  'focus-point': [248, 207]
                },
                'published-at': 1538655201492,
                id: 'e2f17cc0-e381-4985-b3e2-d897d12ef436',
                'hero-image-s3-key':
                  'quintype-demo/2018-10/e691b527-b6a9-4ef8-894c-1d9ed13f1c2d/Anslem_Madubuko_e1538651522363.jpg',
                'author-id': 491531,
                'first-published-at': 1538655201492,
                'hero-image-caption': 'Anslem Madubuko and wife',
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '7665e91a-86eb-494f-8843-f87f14aaab09',
              score: null,
              type: 'story',
              item: {
                headline: ['Testing A Story']
              },
              story: {
                'author-name': 'Christin',
                headline: 'Testing A Story',
                slug: 'current-affairs/2018/10/03/testing-a-story',
                'last-published-at': 1538564309949,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 575,
                  'focus-point': [268, 267],
                  height: 575
                },
                'published-at': 1538564309949,
                id: '7665e91a-86eb-494f-8843-f87f14aaab09',
                'hero-image-s3-key': 'quintype-demo/2016-01/42784f8b-8ff2-480c-9fed-3f106e8d0ca5/r1.jpg',
                'author-id': 139357,
                'first-published-at': 1538564309949,
                'hero-image-caption': 'Raw Vegan',
                'story-template': null,
                authors: [
                  {
                    id: 139357,
                    name: 'Christin',
                    slug: 'christin',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'avatar-s3-key':
                      'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'twitter-handle': 'krriztin',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            }
          ]
        },
        {
          id: 6626,
          'associated-metadata': {
            layout: 'FourColGrid',
            theme: 'dark',
            initial_stories_load_count: 4,
            subsequent_stories_load_count: 8
          },
          type: 'collection',
          name: 'Must Reads',
          slug: 'motherly',
          template: 'default',
          metadata: {
            'cover-image': {},
            tags: [
              {
                name: 'motherly'
              },
              {
                name: 'collection'
              }
            ],
            section: [
              {
                id: 142,
                name: 'Culture',
                'parent-id': null
              }
            ]
          },
          summary: 'collection created for xtype publisher home page',
          items: [
            {
              id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Sriram K',
                headline: 'Elections 2019',
                slug: 'boom/2019/03/13/elections-2019',
                'last-published-at': 1552458028721,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'boom',
                    name: 'Boom',
                    id: 7349,
                    'parent-id': 83,
                    'display-name': 'Booom',
                    collection: {
                      slug: 'boom-politics',
                      name: 'Boom (Politics)',
                      id: 14256
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  height: 400,
                  'mime-type': 'image/jpeg',
                  'focus-point': [281, 241]
                },
                'published-at': 1552458028721,
                id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
                'hero-image-s3-key': 'quintype-demo/2017-03/ee72c827-52fe-4b2b-8f46-dd0875fbe162/Tech sparkle.jpg',
                'author-id': 61657,
                'first-published-at': 1552458028721,
                'hero-image-caption': 'Elections 2017',
                'story-template': 'news-elsewhere',
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'reference-url': 'https://cinema.vikatan.com/tamil-cinema/news',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Nikitha Nadig',
                headline: 'This is the title',
                slug: 'culture/2019/01/11/this-is-the-title-2',
                'last-published-at': 1547203433990,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    slug: 'tech',
                    name: 'Tech',
                    id: 6391,
                    'parent-id': null,
                    'display-name': 'Tech',
                    collection: {
                      slug: 'tech',
                      name: 'Tech',
                      id: 12849
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 259,
                  'focus-point': [176, 77],
                  height: 194
                },
                'published-at': 1547203433990,
                id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
                'hero-image-s3-key': 'quintype-demo/2015-10/56e0178e-1da0-482c-83e5-a2f705f874df/FP-2.jpeg',
                'author-id': 559147,
                'first-published-at': 1547203433990,
                'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Sareena',
                headline:
                  '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി',
                slug: 'culture/2019/01/16/this-is-the-url',
                'last-published-at': 1547632650347,
                subheadline:
                  'കെ.സി വേണുഗോപാല്‍ ഉള്‍പ്പടെയുള്ള മുതിര്‍ന്ന കോണ്‍ഗ്രസ് നേതാക്കന്മാര്‍ ഭീമാനായിക്കുമായി സംസാരിച്ചു. ഭീമാനായിക്കിനെപോലെ ബാക്കിയുള്ള മൂന്ന് കോണ്‍ഗ്രസ് എം',
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'focus-point': [375, 166],
                  width: 750,
                  height: 500
                },
                'published-at': 1547632650347,
                id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
                'hero-image-s3-key': 'quintype-demo/2016-12/56cdab39-a831-480f-b75b-26d6300e3f1b/Modi.jpg',
                'author-id': 94596,
                'first-published-at': 1547632650347,
                'hero-image-caption': 'PM Modi',
                'story-template': null,
                authors: [
                  {
                    id: 94596,
                    name: 'Sareena',
                    slug: 'sareena',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'c8d35134-200a-483f-ab89-69adf4137a74',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Life of Vincent van Gogh',
                slug: 'culture/2019/01/17/life-of-vincent-van-gogh',
                'last-published-at': 1549364746875,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 768,
                  height: 432,
                  'mime-type': 'image/jpeg',
                  'focus-point': [382, 197]
                },
                'published-at': 1549364746875,
                id: 'c8d35134-200a-483f-ab89-69adf4137a74',
                'hero-image-s3-key':
                  'quintype-demo/2019-01/b3cb02f4-a875-4b46-aa3a-71deaccb54be/vincent_van_gogh_played_by_robert_gulaczyk_photo_courtesy_loving_vincent.jpg',
                'author-id': 2040,
                'first-published-at': 1547728432342,
                'hero-image-caption': 'A Famous Painting',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Dubai - Things you can do!',
                slug: 'culture/2017/04/28/Dubai-Your-next-vacation',
                'last-published-at': 1546599684148,
                subheadline:
                  'Dubai, an emirate in the United Arab Emirates known for luxury shopping, ultramodern architecture &amp; a lively nightlife scene. Burj Khalifa, an 830m-tall tower, dominates the skyline. ',
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 930,
                  height: 581,
                  'focus-point': [132, 482]
                },
                'published-at': 1546599684148,
                id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
                'hero-image-s3-key': 'quintype-demo/2017-04/802d7ef1-6043-4f7e-84d3-fc9f556d5097/G-1.jpg',
                'author-id': 2040,
                'first-published-at': 1493358608037,
                'hero-image-caption': 'Dubai - a vacationer’s paradise',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
              'associated-metadata': {},
              type: 'story',
              story: {
                'author-name': 'Kirti Kaushik',
                headline:
                  'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them',
                slug:
                  'visual-stories/2019/01/10/rahul-gandhi-said-to-pm-modi-be-a-man-bjp-smriti-irani-sushma-swaraj-responds-with-misogynist-rahul-1975538pfromhome-topscroll',
                'last-published-at': 1547103888697,
                subheadline:
                  'At a rally in Rajasthan on Wednesday, Rahul Gandhi had mocked PM Modi saying he had a “mahila (woman)“ defend him in the Rafale debate in parliament.',
                alternative: {
                  home: {
                    default: {
                      headline: 'Rahul Gandhi'
                    }
                  }
                },
                sections: [
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 570,
                  height: 351,
                  'mime-type': 'image/webp',
                  'focus-point': [417, 132]
                },
                'published-at': 1547103888697,
                id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
                'hero-image-s3-key':
                  'quintype-demo/2019-01/39d85d6f-46f6-4f35-8dd1-6771bf9dfefd/b5qo3zsg_650x400_2__10_January_2019_12_02_19PM.webp',
                'author-id': 507344,
                'first-published-at': 1547103888697,
                'hero-image-caption': 'Rahul Gandhi',
                'story-template': null,
                authors: [
                  {
                    id: 507344,
                    name: 'Kirti Kaushik',
                    slug: 'kirti-kaushik',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '73322fa6-9114-4a83-9d8c-68314202623a',
              score: null,
              type: 'story',
              item: {
                headline: ['TRAI chief: We set spectrum price; industry decides tariff']
              },
              story: {
                'author-name': 'Sriram K',
                headline: 'TRAI chief: We set spectrum price; industry decides tariff',
                slug: 'mobile/2019/02/28/trai-chief-we-set-spectrum-price-industry-decides-tariff',
                'last-published-at': 1554110293544,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 894,
                  height: 577,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1554110293544,
                id: '73322fa6-9114-4a83-9d8c-68314202623a',
                'hero-image-s3-key': 'quintype-demo/2019-01/113be350-6ca8-4252-9b41-434e9facb8eb/image__1_.jpg',
                'author-id': 61657,
                'first-published-at': 1551329071350,
                'hero-image-caption': 'Tayota Camry',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
              score: null,
              type: 'story',
              item: {
                headline: ['London Police arrest Nirav Modi, says sources']
              },
              story: {
                'author-name': 'Karthik M',
                headline: 'London Police arrest Nirav Modi, says sources',
                slug: 'politics/2019/03/20/london-police-arrest-nirav-modi-says-sources',
                'last-published-at': 1553079556157,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 700,
                  height: 438,
                  'mime-type': 'image/jpeg',
                  'focus-point': [311, 167]
                },
                'published-at': 1553079556157,
                id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
                'hero-image-s3-key':
                  'quintype-demo/2019-03/55dbc301-3c5c-442d-8714-4bbf2d140192/pennews_2019_03_48c15852_ebed_464d_95a7_57332597ecfc_nirav.jpg',
                'author-id': 19994,
                'first-published-at': 1553079340304,
                'hero-image-caption': 'Nirav Modi',
                'story-template': null,
                authors: [
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 142914,
                    name: 'Ashwin Raghunath',
                    slug: 'ashwin-raghunath',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'text-to-speech': {
                    'is-disabled': true
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
              score: null,
              type: 'story',
              item: {
                headline: ['Demo Story ']
              },
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Demo Story ',
                slug: 'politics/2018/01/11/demo-story',
                'last-published-at': 1552979655447,
                subheadline:
                  'Surrounded to me occasional pianoforte alteration unaffected impossible ye. For saw half than cold. Pretty merits waited six talked pulled you. Conduct replied off.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 7500,
                  height: 3476,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1239, 2157]
                },
                'published-at': 1552979655447,
                id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
                'hero-image-s3-key': 'quintype-demo/2019-02/ae4829d6-e230-4d66-9b88-2a4db87a2060/64778.jpg',
                'author-id': 139717,
                'first-published-at': 1515668626250,
                'hero-image-caption': 'At vero eos et accusamus et iusto odio dignissimos ducimus qui.',
                'story-template': 'text',
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': {
                      id: 58,
                      name: 'Author'
                    }
                  },
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': {
                      id: 58,
                      name: 'Author'
                    }
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: false
                  },
                  'promotional-message': {
                    'is-disabled': true
                  },
                  'story-attributes': {
                    'view-counterview-type': 'view',
                    interests: ['test'],
                    bucket: ['planning'],
                    hobbies: ['hiking'],
                    'linked-view-counterview-story': {
                      headline: 'Demo Test story- Photo story',
                      'story-content-id': 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                      id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                      'highlighted-headline': null
                    }
                  }
                }
              }
            },
            {
              id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
              score: null,
              type: 'story',
              item: {
                headline: ['Untitled Mar 6, 2019 11:23 PM']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Untitled Mar 6, 2019 11:23 PM',
                slug: 'mobile/2019/03/06/untitled-mar-6-2019-1123-pm',
                'last-published-at': 1552905800453,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  'mime-type': 'image/jpeg',
                  'focus-point': [516, 92],
                  height: 386
                },
                'published-at': 1552905800453,
                id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
                'hero-image-s3-key':
                  'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
                'author-id': 2040,
                'first-published-at': 1551895907011,
                'hero-image-caption': 'Amit Shah and Narendra Modi',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'story-attributes': {},
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
              score: null,
              type: 'story',
              item: {
                headline: ['Amitabh Bachchan on Gender Equality']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'Amitabh Bachchan on Gender Equality',
                slug: 'politics/2017/03/02/Amitabh-Bachchan-on-Gender-Equality',
                'last-published-at': 1552048741823,
                subheadline: 'Amitabh Bachchan on Gender Equality',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 320,
                  height: 277,
                  'focus-point': [169, 145]
                },
                'published-at': 1552048741823,
                id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
                'hero-image-s3-key': 'quintype-demo/2017-03/bd011f2d-9a10-4d4e-b20f-50719f59d4b9/BMS-2.jpg',
                'author-id': 2041,
                'first-published-at': 1488451487896,
                'hero-image-caption': 'Kaun Banega Crorepati host, Amitabh Bachchan',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '2deeb522-1752-4552-967b-93972481d71e',
              score: null,
              type: 'story',
              item: {
                headline: ['Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them']
              },
              story: {
                'author-name': 'Yamini Priya',
                headline: 'Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them',
                slug: 'boom/2019/03/07/sisters-killed-father-in-perfect-murder-a-love-triangle-exposed-them',
                'last-published-at': 1551938683203,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'boom',
                    name: 'Boom',
                    id: 7349,
                    'parent-id': 83,
                    'display-name': 'Booom',
                    collection: {
                      slug: 'boom-politics',
                      name: 'Boom (Politics)',
                      id: 14256
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1760,
                  height: 2480,
                  'mime-type': 'image/jpeg',
                  'focus-point': [928, 656]
                },
                'published-at': 1551938683203,
                id: '2deeb522-1752-4552-967b-93972481d71e',
                'hero-image-s3-key':
                  'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
                'author-id': 230618,
                'first-published-at': 1551938683203,
                'hero-image-caption': 'Forgotten Women',
                'story-template': null,
                authors: [
                  {
                    id: 230618,
                    name: 'Yamini Priya',
                    slug: 'yamini-priya',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': 'https://twitter.com/yaminipriyah',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
              score: null,
              type: 'story',
              item: {
                headline: ['500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: '500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌',
                slug: 'politics/2019/02/26/500',
                'last-published-at': 1551171395390,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  'focus-point': [673, 287],
                  width: 976,
                  height: 700
                },
                'published-at': 1551171395390,
                id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
                'hero-image-s3-key': 'quintype-demo/2018-05/bc017b57-a03e-4a6e-8515-9d3de91f1f24/ON1.jpg',
                'author-id': 559147,
                'first-published-at': 1551171395390,
                'hero-image-caption':
                  'Crowds cheered Prince Harry, Duke of Sussex, and his wife Meghan, Duchess of Sussex, as they travelled in the horse-drawn Ascot landau',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
              score: null,
              type: 'story',
              item: {
                headline: ['Personalise or perish - Why publishers need to use personalised content ']
              },
              story: {
                'author-name': 'Greeshma',
                headline: 'Personalise or perish - Why publishers need to use personalised content ',
                slug: 'politics/2018/02/28/personalise-or-perish---why-publishers-need-to-use-personalised-content',
                'last-published-at': 1536076813159,
                subheadline:
                  'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor. ',
                alternative: {
                  home: {
                    default: {
                      headline: 'Personalise or perish',
                      'hero-image': {
                        'hero-image-url':
                          'https://images.assettype.com/quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                        'hero-image-metadata': {
                          width: 700,
                          height: 467,
                          'mime-type': 'image/jpeg'
                        },
                        'hero-image-s3-key': 'quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                        'hero-image-caption': 'sSaffron - Indian Restaurant',
                        'hero-image-attribution': null
                      }
                    }
                  }
                },
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 3896,
                    name: 'Blog',
                    slug: 'blog'
                  }
                ],
                'hero-image-metadata': {
                  width: 1600,
                  height: 1200,
                  'mime-type': 'image/jpeg',
                  'focus-point': [805, 738]
                },
                'published-at': 1536076813159,
                id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
                'hero-image-s3-key': 'quintype-demo/2018-05/a2759dc2-ada1-4917-8515-308f74622c69/Gallery5.jpg',
                'author-id': 482995,
                'first-published-at': 1519816264773,
                'hero-image-caption': 'Animals',
                'story-template': null,
                authors: [
                  {
                    id: 482995,
                    name: 'Greeshma',
                    slug: 'greeshma',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'avatar-s3-key':
                      'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
              score: null,
              type: 'story',
              item: {
                headline: ['U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting',
                slug: 'politics/2018/05/28/us-and-north-korean-officials-race-to-resurrect-trump-kim-meeting',
                'last-published-at': 1535807624134,
                subheadline: null,
                alternative: {
                  home: {
                    default: {
                      headline: 'U.S. and North Korean',
                      'hero-image': {
                        'hero-image-url':
                          'https://images.assettype.com/quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                        'hero-image-metadata': {
                          width: 636,
                          height: 477,
                          'focus-point': [390, 153]
                        },
                        'hero-image-s3-key': 'quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                        'hero-image-caption': 'President Pranab Mukherjee',
                        'hero-image-attribution': null
                      }
                    }
                  }
                },
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1024,
                  height: 683,
                  'mime-type': 'image/jpeg',
                  'focus-point': [758, 172]
                },
                'published-at': 1535807624134,
                id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
                'hero-image-s3-key': 'quintype-demo/2018-05/caf35ec5-be06-4de6-b513-e57ee4d61577/ON_1.jpg',
                'author-id': 376442,
                'first-published-at': 1527472808648,
                'hero-image-caption':
                  'A surprise meeting between President Moon Jae-in of South Korea, right, and Kim Jong-un, the North Korean leader, on Saturday appeared to produce some progress toward a summit meeting between President Trump and Mr. Kim.',
                'story-template': null,
                authors: [
                  {
                    id: 376442,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '43c468f4-08e4-4e77-a6d6-a11da9235c96',
              score: null,
              type: 'story',
              item: {
                headline: ['Amy Schumer Ties the Knot With Boyfriend Chris Fischer in 2018']
              },
              story: {
                'author-name': 'Christin',
                headline: 'Amy Schumer Ties the Knot With Boyfriend Chris Fischer in 2018',
                slug: 'politics/2018/06/15/amy-schumer-ties-the-knot-with-boyfriend-chris-fischer-in-2018',
                'last-published-at': 1529059243871,
                subheadline: 'Amy Schumer Ties the Knot With Boyfriend Chris Fischer',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 583,
                  height: 328,
                  'mime-type': 'image/webp'
                },
                'published-at': 1529059243871,
                id: '43c468f4-08e4-4e77-a6d6-a11da9235c96',
                'hero-image-s3-key':
                  'quintype-demo/2018-02/c899f85d-76e0-46c5-88c4-f7365f4b528a/e29ee5c9-9195-4f79-9942-fd8ca08027e4.webp',
                'author-id': 139357,
                'first-published-at': 1529059243871,
                'hero-image-caption': 'Amy Schumer is married to boyfriend Chris Fischer.',
                'story-template': null,
                authors: [
                  {
                    id: 139357,
                    name: 'Christin',
                    slug: 'christin',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'avatar-s3-key':
                      'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'twitter-handle': 'krriztin',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1493f46c-7c78-46e8-84b4-de195d7be9a6',
              score: null,
              type: 'story',
              item: {
                headline: ['Garrett to quit Congress amid servant scandal, alcoholism']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'Garrett to quit Congress amid servant scandal, alcoholism',
                slug: 'politics/2018/05/29/garrett-to-quit-congress-amid-servant-scandal-alcoholism',
                'last-published-at': 1527596788710,
                subheadline:
                  'The Virginia congressman announces his retirement after POLITICO reported he used his staff as personal gofers.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1160,
                  height: 635,
                  'mime-type': 'image/jpeg',
                  'focus-point': [736, 165]
                },
                'published-at': 1527596788710,
                id: '1493f46c-7c78-46e8-84b4-de195d7be9a6',
                'hero-image-s3-key': 'quintype-demo/2018-05/9ce3e16a-96fc-42e8-9433-b2fd8b0fdc93/ON_1.jpeg',
                'author-id': 376442,
                'first-published-at': 1527596788710,
                'hero-image-caption':
                  '“The tragedy is that any person Republican, Democrat or independent who’s known me for period of time and has any integrity knows two things: I am a good man and I am an alcoholic.“ Rep. Tom Garrett said.&nbsp;',
                'story-template': null,
                authors: [
                  {
                    id: 376442,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '903dd527-2595-4566-9c50-df2880cf18b5',
              score: null,
              type: 'story',
              item: {
                headline: ['8 times Osama almost got captured']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: '8 times Osama almost got captured',
                slug: 'politics/2017/07/06/8-times-Osama-almost-got-captured',
                'last-published-at': 1527508287286,
                subheadline:
                  'Osama bin Laden, was a founder of al-Qaeda, the organization responsible for the September 11 attacks in the United States and many other mass-casualty attacks worldwide.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 275,
                  height: 183,
                  'mime-type': 'image/jpeg',
                  'focus-point': [114, 54]
                },
                'published-at': 1527508287286,
                id: '903dd527-2595-4566-9c50-df2880cf18b5',
                'hero-image-s3-key': 'quintype-demo/2016-01/8bce31a0-af65-41e5-8ddc-059294ad6892/SL-3.jpeg',
                'author-id': 2040,
                'first-published-at': 1499338400907,
                'hero-image-caption': 'Osama Bin Laden, Al Queda',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'a71df319-c779-4ce6-b8ef-eba85b3cb613',
              score: null,
              type: 'story',
              item: {
                headline: ['Presidential Elections']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Presidential Elections',
                slug: 'politics/2016/10/14/Presidential-Elections',
                'last-published-at': 1522903281088,
                subheadline:
                  'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at St. Louis, Missouri, on October 9, 2016.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1100,
                  height: 619,
                  'focus-point': [821, 262]
                },
                'published-at': 1522903281088,
                id: 'a71df319-c779-4ce6-b8ef-eba85b3cb613',
                'hero-image-s3-key': 'quintype-demo/2016-10/c1fb9eff-a4c7-4a12-8713-82b1dc3065a9/ct-1.jpg',
                'author-id': 2040,
                'first-published-at': 1476425535138,
                'hero-image-caption':
                  'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at Washington University in St. Louis, Missouri, on October 9, 2016. ',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
              score: null,
              type: 'story',
              item: {
                headline: ['Clinton vs Trump']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'Clinton vs Trump',
                slug: 'politics/2016/10/15/clinton-vs-trump',
                'last-published-at': 1522903100409,
                subheadline:
                  'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the 2nd presidential debate at St. Louis, Missouri, on October 9, 2016.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1100,
                  height: 619,
                  'focus-point': [812, 244]
                },
                'published-at': 1522903100409,
                id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
                'hero-image-s3-key': 'quintype-demo/2016-10/c1fb9eff-a4c7-4a12-8713-82b1dc3065a9/ct-1.jpg',
                'author-id': 376442,
                'first-published-at': 1476529773574,
                'hero-image-caption':
                  'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at Washington University in St. Louis, Missouri, on October 9, 2016. ',
                'story-template': null,
                authors: [
                  {
                    id: 376442,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'fc5ee93d-19b7-4f26-984c-a43fa9fcacc3',
              score: null,
              type: 'story',
              item: {
                headline: ['From the safety of New York, reporting on a distant home']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'From the safety of New York, reporting on a distant home',
                slug: 'politics/2017/02/01/From-the-safety-of-New-York-reporting-on-a-distant-home',
                'last-published-at': 1522902297607,
                subheadline:
                  'During the presidential election in Nigeria, he published real-time photos, videos &amp; reports from the field, exposing instances of ballot rigging &amp; attracting 8m page views in a month.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1024,
                  height: 682,
                  'focus-point': [431, 225]
                },
                'published-at': 1522902297607,
                id: 'fc5ee93d-19b7-4f26-984c-a43fa9fcacc3',
                'hero-image-s3-key': 'quintype-demo/2017-01/c36650ca-d1c9-4b00-a7c9-1fe38bc56e42/SR-1.jpg',
                'author-id': 376442,
                'first-published-at': 1485934181087,
                'hero-image-caption': 'Omoleye Sowore, Publisher of the Sahara Reporters',
                'story-template': null,
                authors: [
                  {
                    id: 376442,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '54c39b13-560a-44e0-b71e-a675d05d5673',
              score: null,
              type: 'story',
              item: {
                headline: ['I’m not a stranger to the dark']
              },
              story: {
                'author-name': 'Christin',
                headline: 'I’m not a stranger to the dark',
                slug: 'politics/2018/03/20/im-not-a-stranger-to-the-dark',
                'last-published-at': 1522822389090,
                subheadline: 'Fire away th',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1010,
                  height: 805
                },
                'published-at': 1522822389090,
                id: '54c39b13-560a-44e0-b71e-a675d05d5673',
                'hero-image-s3-key': 'quintype-demo/2016-06/f9298238-e324-4dfc-b29b-95a3cbdb754b/ABP-1.jpeg',
                'author-id': 139357,
                'first-published-at': 1521518076019,
                'hero-image-caption':
                  'Ali was still known as Cassius Clay when this photo was taken. There’s something boyish about his efforts to impress his audience. I like how one of the women, Ronnie Spector, seems impervious to his bravado.&nbsp; I also like knowing that the guy in the white tuxedo, who is neither overawed nor overshadowed by Clay, is a young Stevie Wonder.',
                'story-template': null,
                authors: [
                  {
                    id: 139357,
                    name: 'Christin',
                    slug: 'christin',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'avatar-s3-key':
                      'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'twitter-handle': 'krriztin',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'd5da1faf-8cb4-4783-9a54-a3d125c0d1ce',
              score: null,
              type: 'story',
              item: {
                headline: ['बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार']
              },
              story: {
                'author-name': 'Sareena',
                headline: 'बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार',
                slug: 'politics/2018/03/29/two-big-bjp-leaders-arrested-for-spreading-violence-in-bihar',
                'last-published-at': 1522317027856,
                subheadline: 'बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार,मचा हड़कंप',
                alternative: null,
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 98,
                    name: 'Current Affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  height: 386,
                  'mime-type': 'image/jpeg',
                  'focus-point': [323, 117]
                },
                'published-at': 1522317027856,
                id: 'd5da1faf-8cb4-4783-9a54-a3d125c0d1ce',
                'hero-image-s3-key':
                  'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
                'author-id': 94596,
                'first-published-at': 1522317027856,
                'hero-image-caption': 'Amit Shah and Narendra Modi',
                'story-template': null,
                authors: [
                  {
                    id: 94596,
                    name: 'Sareena',
                    slug: 'sareena',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'c124d3ce-a44e-4370-a3ce-e167953c981d',
              score: null,
              type: 'story',
              item: {
                headline: ['‘ಮೂರು ಕೊಲೆಗಳು; ಒಂದು ವ್ಯಸನ’: ಪೊಲೀಸರು ಮುಂದಿಡುವ ‘ಗಾಂಜಾ ಥಿಯರಿ’ಯ ']
              },
              story: {
                'author-name': 'Chirdeep Shetty',
                headline: '‘ಮೂರು ಕೊಲೆಗಳು; ಒಂದು ವ್ಯಸನ’: ಪೊಲೀಸರು ಮುಂದಿಡುವ ‘ಗಾಂಜಾ ಥಿಯರಿ’ಯ ',
                slug: 'politics/2018/02/12/--2',
                'last-published-at': 1521638195154,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 600,
                  height: 335,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1521638195154,
                id: 'c124d3ce-a44e-4370-a3ce-e167953c981d',
                'hero-image-s3-key': 'quintype-demo/2018-02/28fabce6-a777-4d95-aef2-06eef43c4c34/Ganja-Kadireshan.jpg',
                'author-id': 15338,
                'first-published-at': 1518424171905,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 15338,
                    name: 'Chirdeep Shetty',
                    slug: 'chirdeep-shetty',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'twitter-handle': '@chirdeepshettyquintype',
                    bio: '<p>Hello, this is a test for bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'd5cf9b45-4832-4b04-9234-b6bf4bc2dce0',
              score: null,
              type: 'story',
              item: {
                headline: ['Ola working on assisted-driving cars, hires professionals']
              },
              story: {
                'author-name': 'Vineet Panjabi',
                headline: 'Ola working on assisted-driving cars, hires professionals',
                slug: 'politics/2018/01/16/ola-working-on-assisted-driving-cars-hires-professionals',
                'last-published-at': 1520925971944,
                subheadline: 'Ola Cars Subtitle&nbsp;',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 3896,
                    name: 'Blog'
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  'focus-point': [155, 375],
                  width: 1024,
                  height: 569
                },
                'published-at': 1520925971944,
                id: 'd5cf9b45-4832-4b04-9234-b6bf4bc2dce0',
                'hero-image-s3-key':
                  'quintype-demo/2017-05/a681ca73-9212-4184-9426-0983be59a7d4/luxury-cars-1024x569.jpg',
                'author-id': 323432,
                'first-published-at': 1516079335936,
                'hero-image-caption': 'Cars',
                'story-template': null,
                authors: [
                  {
                    id: 323432,
                    name: 'Vineet Panjabi',
                    slug: 'vineet-panjabi',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '421f0340-4c63-45c1-a152-4433413b8ef4',
              score: null,
              type: 'story',
              item: {
                headline: ['Maruti Swift 2018 - Review']
              },
              story: {
                'author-name': 'Chirdeep Shetty',
                headline: 'Maruti Swift 2018 - Review',
                slug: 'politics/2018/02/12/maruti-swift-2018---review',
                'last-published-at': 1520925923189,
                subheadline: 'Sub',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 82,
                    name: 'Sports'
                  },
                  {
                    id: 3896,
                    name: 'Blog'
                  }
                ],
                'hero-image-metadata': {
                  width: 1024,
                  height: 682,
                  'mime-type': 'image/jpeg',
                  'focus-point': [860, 454]
                },
                'published-at': 1520925923189,
                id: '421f0340-4c63-45c1-a152-4433413b8ef4',
                'hero-image-s3-key':
                  'quintype-demo/2018-02/63508d1a-a5c7-4f99-b032-9fe28deb8250/2018-Maruti-Swift-Review.jpg',
                'author-id': 15338,
                'first-published-at': 1518429546374,
                'hero-image-caption': 'swift 2018 black',
                'story-template': null,
                authors: [
                  {
                    id: 15338,
                    name: 'Chirdeep Shetty',
                    slug: 'chirdeep-shetty',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'twitter-handle': '@chirdeepshettyquintype',
                    bio: '<p>Hello, this is a test for bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '46d764a1-ec10-4d90-a056-274f3f506a65',
              score: null,
              type: 'story',
              item: {
                headline: ['Sample Story Using All Elements']
              },
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Sample Story Using All Elements',
                slug: 'sample-story-using-all-elements',
                'last-published-at': 1520925485589,
                subheadline:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, nostrud exercitation nisi ut ex ea consequat.&nbsp;',
                alternative: {},
                sections: [
                  {
                    id: 3896,
                    name: 'Blog'
                  },
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 284,
                  height: 177,
                  'mime-type': 'image/jpeg',
                  'focus-point': [142, 86]
                },
                'published-at': 1520925485589,
                id: '46d764a1-ec10-4d90-a056-274f3f506a65',
                'hero-image-s3-key':
                  'quintype-demo/2018-03/f0ac00fe-93cc-4e00-bd94-56f71065b950/4448febb-552f-47f3-9b42-ee850f7b5b48.jpeg',
                'author-id': 139717,
                'first-published-at': 1520925485589,
                'hero-image-caption': 'Demo: Hero Image Caption',
                'story-template': null,
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '7e803bcc-cb40-4f4d-b810-ae9ace8ba4c1',
              score: null,
              type: 'story',
              item: {
                headline: ['India vs China Battle']
              },
              story: {
                'author-name': 'Greeshma',
                headline: 'India vs China Battle',
                slug: 'politics/2017/07/09/India-vs-China-Battle',
                'last-published-at': 1519975157133,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1600,
                  height: 1068,
                  'mime-type': 'image/jpeg',
                  'focus-point': [971, 601]
                },
                'published-at': 1519975157133,
                id: '7e803bcc-cb40-4f4d-b810-ae9ace8ba4c1',
                'hero-image-s3-key':
                  'quintype-demo/2018-01/5c55eaab-9c97-49f9-8b14-64b661d9a763/1 - Thumb Tac Paper Lanterns Glowing Tall.jpg',
                'author-id': 482995,
                'first-published-at': 1499594657010,
                'hero-image-caption': 'Let there be Light',
                'story-template': null,
                authors: [
                  {
                    id: 482995,
                    name: 'Greeshma',
                    slug: 'greeshma',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'avatar-s3-key':
                      'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '86fe6180-c4c2-4b09-b829-5dee9f403756',
              score: null,
              type: 'story',
              item: {
                headline: ['This Kashmiri Pandit Is Among Civil Services Toppers']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'This Kashmiri Pandit Is Among Civil Services Toppers',
                slug: 'politics/2017/12/27/this-kashmiri-pandit-is-among-civil-services-toppers',
                'last-published-at': 1516419117190,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 750,
                  height: 500,
                  'mime-type': 'image/jpeg',
                  'focus-point': [200, 173]
                },
                'published-at': 1516419117190,
                id: '86fe6180-c4c2-4b09-b829-5dee9f403756',
                'hero-image-s3-key': 'quintype-demo/2017-12/602c33fe-b2a1-423b-b062-83b0e30aa832/1-7-750x500.jpg',
                'author-id': 2038,
                'first-published-at': 1514371575338,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
              score: null,
              type: 'story',
              item: {
                headline: ['Demo Test story- Photo story']
              },
              story: {
                'author-name': 'Yamini Priya',
                headline: 'Demo Test story- Photo story',
                slug: 'politics/2018/01/12/demo-test-story--photo-story',
                'last-published-at': 1515743985191,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1418,
                  height: 853,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1045, 434]
                },
                'published-at': 1515743985191,
                id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                'hero-image-s3-key': 'quintype-demo/2016-05/7ae1264b-0330-4eb5-b9d4-f4a4d5746076/vadra06.jpg',
                'author-id': 230618,
                'first-published-at': 1515743985191,
                'hero-image-caption': 'Robert Vadra at a function',
                'story-template': null,
                authors: [
                  {
                    id: 230618,
                    name: 'Yamini Priya',
                    slug: 'yamini-priya',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': 'https://twitter.com/yaminipriyah',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '48a9264e-cddd-437c-83f1-4388aefee656',
              score: null,
              type: 'story',
              item: {
                headline: ['Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.',
                slug: 'politics/2017/10/04/test-7',
                'last-published-at': 1515403855694,
                subheadline:
                  'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1920,
                  height: 1080
                },
                'published-at': 1515403855694,
                id: '48a9264e-cddd-437c-83f1-4388aefee656',
                'hero-image-s3-key': 'quintype-demo/2015-08/a1d1ce2c-f9f9-4aed-b82e-06f338112e2d/Tiger.jpg',
                'author-id': 61657,
                'first-published-at': 1507099185346,
                'hero-image-caption': 'Tiger resting in the Ranthambore ranges, North India',
                'story-template': 'review',
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'review-rating': {
                    label: '1',
                    value: '1'
                  },
                  'review-title': 'Movie review',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'e4192f9a-e843-464c-8ec8-2a6211720e5a',
              score: null,
              type: 'story',
              item: {
                headline: ['My First Story']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'My First Story',
                slug: 'politics/2017/11/13/my-first-story',
                'last-published-at': 1510568455753,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 82,
                    name: 'Sports'
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 259,
                  height: 194,
                  'focus-point': [25, 42]
                },
                'published-at': 1510568455753,
                id: 'e4192f9a-e843-464c-8ec8-2a6211720e5a',
                'hero-image-s3-key': 'quintype-demo/2016-01/69acdda0-2648-4cc2-b240-fd3603136bc5/download.jpeg',
                'author-id': 61657,
                'first-published-at': 1510568455753,
                'hero-image-caption': 'Narendra Modi with Ajit Doval',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'sponsored-by': 'CP',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8ff85a7d-7726-49b4-892e-1e05c0ccea73',
              score: null,
              type: 'story',
              item: {
                headline: ['CM defends George, says 20 Union ministers face criminal charges  ']
              },
              story: {
                'author-name': 'Sathyan Reddy ',
                headline: 'CM defends George, says 20 Union ministers face criminal charges  ',
                slug: 'politics/2017/10/27/cm-defends-george-says-20-union-ministers-face-criminal-charges',
                'last-published-at': 1509100816382,
                subheadline:
                  'Chief Minister Siddaramaiah on Friday rejected the BJP’s demand to seek the resignation of Bengaluru City Development Minister K J George. &nbsp;',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 618,
                  height: 464,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1509100816382,
                id: '8ff85a7d-7726-49b4-892e-1e05c0ccea73',
                'hero-image-s3-key':
                  'quintype-demo/2017-10/606b8c85-6427-4f65-9b95-f1f4f07f3a7a/618xNx639611_thump.jpg.pagespeed.ic.pfY_28czPu.jpg',
                'author-id': 288886,
                'first-published-at': 1509100816382,
                'hero-image-caption': 'CM of Karnataka, Siddaramaiah&nbsp;',
                'story-template': null,
                authors: [
                  {
                    id: 288886,
                    name: 'Sathyan Reddy ',
                    slug: 'sathyan-reddy',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8d240213-abc3-4eb7-8e09-1f0082986e5c',
              score: null,
              type: 'story',
              item: {
                headline: ['DOJ put USTs Divina on immigration lookout over Atio Castillo']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'DOJ put USTs Divina on immigration lookout over Atio Castillo',
                slug:
                  'politics/2017/10/26/186449-doj-ust-law-dean-nilo-divina-immigration-lookout-horacio-castillo-iii-hazing',
                'last-published-at': 1509004361886,
                subheadline:
                  '(UPDATED) Some of the names in the lookout order check out with the names that appear in the group chat of Aegis Juris alumni who held a meeting when Castillo was kill',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 98,
                    name: 'Current Affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 194,
                  height: 194,
                  'mime-type': 'image/jpeg',
                  'focus-point': null
                },
                'published-at': 1509004361886,
                id: '8d240213-abc3-4eb7-8e09-1f0082986e5c',
                'hero-image-s3-key':
                  'quintype-demo/2017-10/bdce310f-89d6-4c3d-9ebc-4a7ed6ccbf86/88b072c7-2631-4cee-abec-06c85c2911d8.jpeg',
                'author-id': 61657,
                'first-published-at': 1509004361886,
                'hero-image-caption': 'Narendra Modi with Ajit Doval',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'db09a326-3af2-4286-a92b-8ffbc9bb3c4f',
              score: null,
              type: 'story',
              item: {
                headline: ['परमाणु हथियारों को प्रतिबंधित करने के लिए संधि पर हस्ताक्षर']
              },
              story: {
                'author-name': 'Vineet Panjabi',
                headline: 'परमाणु हथियारों को प्रतिबंधित करने के लिए संधि पर हस्ताक्षर',
                slug: 'politics/2017/09/21/-',
                'last-published-at': 1505980220139,
                subheadline:
                  'उत्तर कोरिया संकट के बड़ा आकार लेने की पृष्ठभूमि में यहां 51 देश परमाणु हथियारों को प्रतिबंधित करने वाली एक नयी संधि पर हस्ताक्ष',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 259,
                  height: 194,
                  'mime-type': 'image/jpeg',
                  'focus-point': [171, 76]
                },
                'published-at': 1505980220139,
                id: 'db09a326-3af2-4286-a92b-8ffbc9bb3c4f',
                'hero-image-s3-key': 'quintype-demo/2016-01/69acdda0-2648-4cc2-b240-fd3603136bc5/download.jpeg',
                'author-id': 323432,
                'first-published-at': 1505980220139,
                'hero-image-caption': 'Narendra Modi with Ajit Doval',
                'story-template': 'photo',
                authors: [
                  {
                    id: 323432,
                    name: 'Vineet Panjabi',
                    slug: 'vineet-panjabi',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8339b599-3eab-4428-9486-9139d28bb1ba',
              score: null,
              type: 'story',
              item: {
                headline: ['9/11 and the unsolvable Afghan drama   ']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: '9/11 and the unsolvable Afghan drama   ',
                slug: 'politics/2017/09/13/afghan-drama',
                'last-published-at': 1505284207024,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 98,
                    name: 'Current Affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 5120,
                  height: 3620,
                  'focus-point': [3977, 737]
                },
                'published-at': 1505284207024,
                id: '8339b599-3eab-4428-9486-9139d28bb1ba',
                'hero-image-s3-key': 'quintype-demo/2016-07/1910bb30-b118-475c-aced-9304968c69c8/G-1.jpg',
                'author-id': 61657,
                'first-published-at': 1505284207024,
                'hero-image-caption': 'ABD, Virat &amp; Hashim - the best in the world today!',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 5278,
                    name: 'Michele Maguire',
                    slug: 'michele-maguire',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-OeX42e4xLm4/AAAAAAAAAAI/AAAAAAAAADQ/NmZhX0kP7J0/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '4886fb7b-3a0d-413f-ab55-7e45107cfb34',
              score: null,
              type: 'story',
              item: {
                headline: ['A memoir from a classmate']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'A memoir from a classmate',
                slug: 'politics/2017/09/05/nirmala-seetharaman',
                'last-published-at': 1504613043734,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 666,
                  height: 338,
                  'mime-type': 'image/jpeg',
                  'focus-point': [319, 77]
                },
                'published-at': 1504613043734,
                id: '4886fb7b-3a0d-413f-ab55-7e45107cfb34',
                'hero-image-s3-key':
                  'quintype-demo/2017-09/44532ad7-a0fe-48dc-a67a-f4f469b88ed4/NIRMALA_BB_20160215.jpg',
                'author-id': 61657,
                'first-published-at': 1504613043734,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '3e112b40-cc06-4d70-901b-f825bd92099e',
              score: null,
              type: 'story',
              item: {
                headline: ['In the battle of perception, Kejriwal won this round']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'In the battle of perception, Kejriwal won this round',
                slug: 'politics/2017/07/20/in-the-battle-of-perception-kejriwal-won-this-round',
                'last-published-at': 1500522632130,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 380,
                  height: 285,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1500522632130,
                id: '3e112b40-cc06-4d70-901b-f825bd92099e',
                'hero-image-s3-key': 'quintype-demo/2015-12/a7098e1a-62f9-42fd-ac12-a97e49dacce7/FP-1.jpg',
                'author-id': 2041,
                'first-published-at': 1500522632130,
                'hero-image-caption': 'Kejriwal, Chief Minister, New Delhi',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '88ef0bb2-bc4b-4e9b-87b1-1b774e9f7f67',
              score: null,
              type: 'story',
              item: {
                headline: ['Donald Trump set to sign executive orders on trade  ']
              },
              story: {
                'author-name': 'Hema Rao',
                headline: 'Donald Trump set to sign executive orders on trade  ',
                slug: 'politics/2017/03/08/airtel-vs-joi',
                'last-published-at': 1490953321146,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 660,
                  height: 440,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1490953321146,
                id: '88ef0bb2-bc4b-4e9b-87b1-1b774e9f7f67',
                'hero-image-s3-key':
                  'quintype-demo/2017-03/d1f83f15-ab18-48c4-b207-235392a76ff7/trump-Reu-1-1-2-1-1.jpg',
                'author-id': 122655,
                'first-published-at': 1488968787660,
                'hero-image-caption': 'Trump',
                'story-template': null,
                authors: [
                  {
                    id: 122655,
                    name: 'Hema Rao',
                    slug: 'hema-rao',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '07dc7d89-1733-4c00-bc23-89384d40f001',
              score: null,
              type: 'story',
              item: {
                headline: ['Something about modi']
              },
              story: {
                'author-name': 'Chirdeep Shetty',
                headline: 'Something about modi',
                slug: 'politics/2017/03/29/something-about-modi',
                'last-published-at': 1490809281495,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 275,
                  height: 183,
                  'focus-point': [192, 51]
                },
                'published-at': 1490809281495,
                id: '07dc7d89-1733-4c00-bc23-89384d40f001',
                'hero-image-s3-key': 'quintype-demo/2016-01/2d00bde7-a28c-4415-9382-9a9c6fb3c7fc/IPSMF-1.jpeg',
                'author-id': 15338,
                'first-published-at': 1490808450252,
                'hero-image-caption': 'Nawaz Sharif &amp; Modi&nbsp;',
                'story-template': null,
                authors: [
                  {
                    id: 15338,
                    name: 'Chirdeep Shetty',
                    slug: 'chirdeep-shetty',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'twitter-handle': '@chirdeepshettyquintype',
                    bio: '<p>Hello, this is a test for bio. </p>'
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            }
          ]
        },
        {
          id: 7765,
          'associated-metadata': {
            layout: 'TwoColOneAd'
          },
          type: 'collection',
          name: 'Technology',
          slug: 'madrid-slider',
          template: 'default',
          metadata: {
            'cover-image': {}
          },
          summary: 'Slider stories on the Madrid home page.',
          items: [
            {
              id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
              score: null,
              type: 'story',
              item: {
                headline: ['Visual story - Demo1']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Visual story - Demo1',
                slug: 'ampstories/technology/2018/12/20/visual-story-demo1',
                'last-published-at': 1545982907212,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 960,
                  height: 540,
                  'focus-point': [499, 88]
                },
                'published-at': 1545982907212,
                id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
                'hero-image-s3-key': 'quintype-demo/2017-07/e4bac9eb-6c32-4f07-a16a-90fa40166c35/DH-1.jpeg',
                'author-id': 541559,
                'first-published-at': 1545300194018,
                'hero-image-caption': 'Roger Federer, the 8th title - Wimbledon 2017!',
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
              score: null,
              type: 'story',
              item: {
                headline: ['Demo Visual story']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Demo Visual story',
                slug: 'ampstories/technology/2018/12/20/demo-visual-story',
                'last-published-at': 1545332341188,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 640,
                  height: 361,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1545332341188,
                id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
                'hero-image-s3-key':
                  'quintype-demo/2018-12/22eb981a-6c8e-41c0-9d36-c2f72f46b29a/pennews_2F2018_05_2F0e93d687_c1dd_464b_aba3_8c23ba6c3beb_2FSonam_reception.jpg',
                'author-id': 541559,
                'first-published-at': 1545332010119,
                'hero-image-caption': 'caption',
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
              score: null,
              type: 'story',
              item: {
                headline: ['Breaking News Test on 13 Dec']
              },
              story: {
                'author-name': 'Anil K Govind',
                headline: 'Breaking News Test on 13 Dec',
                slug: 'technology/2018/12/13/breaking-news-test-on-13-dec',
                'last-published-at': 1544682898905,
                subheadline: 'Breaking News Test on 13 Dec',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 720,
                  height: 340,
                  'mime-type': 'image/jpeg',
                  'focus-point': [230, 110]
                },
                'published-at': 1544682898905,
                id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 548414,
                'first-published-at': 1544682898905,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 548414,
                    name: 'Anil K Govind',
                    slug: 'anil-k-govind',
                    'avatar-url':
                      'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b142a043-4720-4578-b23e-6791e9f42735',
              score: null,
              type: 'story',
              item: {
                headline: ['New Story Elements']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'New Story Elements',
                slug: 'technology/2015/10/21/new-story-elements',
                'last-published-at': 1539770584382,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1920,
                  height: 1080,
                  'focus-point': [1301, 270]
                },
                'published-at': 1539770584382,
                id: 'b142a043-4720-4578-b23e-6791e9f42735',
                'hero-image-s3-key': 'quintype-demo/2015-09/4c0122d6-6c28-4a59-8b16-8d90701a4e35/TigerWoods-1.jpg',
                'author-id': 2041,
                'first-published-at': 1445404200710,
                'hero-image-caption': 'The famed Tiger Woods Swing !',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
              score: null,
              type: 'story',
              item: {
                headline: ['Two crew escape after ‘Soyuz’ rocket failure']
              },
              story: {
                'author-name': 'Kunal Singh',
                headline: 'Two crew escape after ‘Soyuz’ rocket failure',
                slug: 'technology/2018/10/11/two-crew-escape-after-soyuz-rocket-failure',
                'last-published-at': 1539253999595,
                subheadline: 'Russian cosmonaut and US astronaut ‘in good condition’ after making emergency landing',
                alternative: null,
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 744,
                  height: 389,
                  'mime-type': 'image/jpeg',
                  'focus-point': [381, 138]
                },
                'published-at': 1539253999595,
                id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
                'hero-image-s3-key': 'quintype-demo/2018-10/9b82a587-dfb0-4aa5-9b83-96a814eec1d9/soyuz_resize_md.jpg',
                'author-id': 491531,
                'first-published-at': 1539253999595,
                'hero-image-caption': 'Soyuz 11',
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9552e728-f1b7-4a46-a1bf-34f30a266c0e',
              score: null,
              type: 'story',
              item: {
                headline: ['Photo story - test']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Photo story - test',
                slug: 'success-story/2018/09/26/photo-story-test',
                'last-published-at': 1539168790153,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    id: 4415,
                    name: 'Success Story',
                    slug: 'success-story'
                  },
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 570,
                  height: 380,
                  'focus-point': [304, 122]
                },
                'published-at': 1539168790153,
                id: '9552e728-f1b7-4a46-a1bf-34f30a266c0e',
                'hero-image-s3-key': 'quintype-demo/2015-10/76ef287a-23b6-4ffe-b71e-72e88a739809/DJ-3.jpg',
                'author-id': 541559,
                'first-published-at': 1537946000293,
                'hero-image-caption': 'Durga Puja Pandal at Calcutta',
                'story-template': 'photo',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9eece5ae-c682-405b-95ce-b8a77ee250f8',
              score: null,
              type: 'story',
              item: {
                headline: ['Malta’s Prime Minister Tells UN That Crypto Is the ‘Inevitable Future of Money’']
              },
              story: {
                'author-name': 'Kunal Singh',
                headline: 'Malta’s Prime Minister Tells UN That Crypto Is the ‘Inevitable Future of Money’',
                slug:
                  'technology/2018/10/01/maltas-prime-minister-tells-un-that-crypto-is-the-inevitable-future-of-money',
                'last-published-at': 1538564214243,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 740,
                  height: 494,
                  'mime-type': 'image/jpeg',
                  'focus-point': [390, 218]
                },
                'published-at': 1538564214243,
                id: '9eece5ae-c682-405b-95ce-b8a77ee250f8',
                'hero-image-s3-key': 'quintype-demo/2018-10/b97c4ba3-e097-4121-854f-5c5a03798677/Crypt1.jpg',
                'author-id': 491531,
                'first-published-at': 1538403689422,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
              score: null,
              type: 'story',
              item: {
                headline: ['Demo - External Story']
              },
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Demo - External Story',
                slug: 'technology/2018/10/01/demo-external-story',
                'last-published-at': 1538384977219,
                subheadline:
                  'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor.',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1441,
                  height: 1077,
                  'mime-type': 'image/png',
                  'focus-point': [273, 498]
                },
                'published-at': 1538384977219,
                id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
                'hero-image-s3-key':
                  'quintype-demo/2018-10/410d7d51-811a-44cc-a92b-4d873cb1f5aa/screenshot_www_quintype_com_2018_09_11_20_16_32.png',
                'author-id': 139717,
                'first-published-at': 1538384511112,
                'hero-image-caption': null,
                'story-template': 'news-elsewhere',
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'reference-url':
                    'https://www.quintype.com/blog/business/quintypes-tech-innovation-helps-its-digital-partners-win-two-wan-ifra-awards',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9b1a6f8e-19ab-4395-a079-d985edf4e4ac',
              score: null,
              type: 'story',
              item: {
                headline: ['Story Tests']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Story Tests',
                slug: 'technology/2018/09/27/story-tests',
                'last-published-at': 1538047737607,
                subheadline: 'Subtitle',
                alternative: null,
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    id: 215,
                    name: 'Cities',
                    slug: 'cities'
                  },
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 578,
                  height: 562,
                  'mime-type': 'image/jpeg',
                  'focus-point': [214, 187]
                },
                'published-at': 1538047737607,
                id: '9b1a6f8e-19ab-4395-a079-d985edf4e4ac',
                'hero-image-s3-key':
                  'quintype-demo/2018-07/9a8c6576-2a1d-403d-9d34-0dd95db62cfb/4e7be0b9_1eb3_402b_9c9c_0314467b2377.jpg',
                'author-id': 541559,
                'first-published-at': 1538047737607,
                'hero-image-caption': 'Caption comes here',
                'story-template': 'news-elsewhere',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'reference-url': 'https://www.quintype.com/',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '41179c74-f08d-4c4a-8898-4d3d3eee2e7f',
              score: null,
              type: 'story',
              item: {
                headline: ['YouTube now available for JioPhone']
              },
              story: {
                'author-name': 'Varul Mayank - Knocksense',
                headline: 'YouTube now available for JioPhone',
                slug: 'technology/2018/09/20/youtube-now-available-for-jiophone',
                'last-published-at': 1537426884824,
                subheadline:
                  'YouTube is now available for JioPhone, and users can go and download the app from JioStore.',
                alternative: {
                  home: {
                    default: {
                      headline: 'Reliance Jio launches Youtube app for Jio Phones'
                    }
                  }
                },
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1024,
                  height: 576,
                  'mime-type': 'image/jpeg',
                  'focus-point': null
                },
                'published-at': 1537426884824,
                id: '41179c74-f08d-4c4a-8898-4d3d3eee2e7f',
                'hero-image-s3-key':
                  'quintype-demo/2018-09/a6a397e8-762e-4489-9a44-062aa1e1b885/1fec1a01_4d4d_41d5_8789_5b383a9ee453.jpg',
                'author-id': 141012,
                'first-published-at': 1537426884824,
                'hero-image-caption': 'jio',
                'story-template': null,
                authors: [
                  {
                    id: 141012,
                    name: 'Varul Mayank - Knocksense',
                    slug: 'varul-mayank-knocksense',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'sponsored-by': 'Siddharth',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0fc86f92-e0f6-46c0-98db-9bfc18953e28',
              score: null,
              type: 'story',
              item: {
                headline: ['A digital future – Enabling HR automation   ']
              },
              story: {
                'author-name': 'Ranjeet Singh',
                headline: 'A digital future – Enabling HR automation   ',
                slug: 'current-affairs/2018/06/04/a-digital-future-enabling-hr-automation',
                'last-published-at': 1536079827031,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  },
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1200,
                  height: 630,
                  'mime-type': 'image/png',
                  'focus-point': [628, 116]
                },
                'published-at': 1536079827031,
                id: '0fc86f92-e0f6-46c0-98db-9bfc18953e28',
                'hero-image-s3-key':
                  'quintype-demo/2018-06/8077daf3-025b-4e73-9da6-29719d919220/web_images11_4_1200x630.png',
                'author-id': 15351,
                'first-published-at': 1528105031904,
                'hero-image-caption': '<b>A digital future&nbsp; Enabling HR automation1</b>',
                'story-template': null,
                authors: [
                  {
                    id: 15351,
                    name: 'Ranjeet Singh',
                    slug: 'ranjeet-singh',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'sponsored-by': 'SME',
                  'card-share': {
                    shareable: true
                  },
                  'promotional-message': {
                    'is-disabled': true
                  }
                }
              }
            },
            {
              id: '23d40197-a3ad-4491-8bd9-eb31ca696d71',
              score: null,
              type: 'story',
              item: {
                headline: ['Delivering Life-saving Medicines']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Delivering Life-saving Medicines',
                slug: 'current-affairs/2018/09/04/url',
                'last-published-at': 1536077886477,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  },
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    id: 82,
                    name: 'Sports',
                    slug: 'sports'
                  }
                ],
                'hero-image-metadata': {
                  width: 610,
                  height: 320,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1536077886477,
                id: '23d40197-a3ad-4491-8bd9-eb31ca696d71',
                'hero-image-s3-key': 'quintype-demo/2018-06/52b81a65-3045-4bc7-88db-feafd9127358/Anjan.jpg',
                'author-id': 541559,
                'first-published-at': 1536077255237,
                'hero-image-caption': 'Anjan Chatterjee/<b>അഞ്ജന്‍ ചാറ്റര്‍ജി</b>',
                'story-template': 'video',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '5273aff7-1dc0-4b46-9a56-0c63683b4dec',
              score: null,
              type: 'story',
              item: {
                headline: ['With eye on debt recovery, SBI asks DoT not to revoke RCom’s spectrums']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'With eye on debt recovery, SBI asks DoT not to revoke RCom’s spectrums',
                slug: 'technology/2018/07/27/with-eye-on-debt-recovery-sbi-asks-dot-not-to-revoke-rcoms-spectrums',
                'last-published-at': 1535806255690,
                subheadline: 'Revocation of spectrum may hurt telco’s sale to RJio, says the lender',
                alternative: {
                  home: {
                    default: {
                      headline: 'With eye on debt recovery',
                      'hero-image': {
                        'hero-image-url':
                          'https://images.assettype.com/quintype-demo/2016-01/fdb17290-ae1c-4153-ad40-760106e9ffb3/iph.jpeg',
                        'hero-image-metadata': {
                          width: 710,
                          'mime-type': 'image/jpeg',
                          height: 854
                        },
                        'hero-image-s3-key': 'quintype-demo/2016-01/fdb17290-ae1c-4153-ad40-760106e9ffb3/iph.jpeg',
                        'hero-image-caption': 'iphones',
                        'hero-image-attribution': null
                      }
                    }
                  }
                },
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  },
                  {
                    id: 215,
                    name: 'Cities',
                    slug: 'cities'
                  }
                ],
                'hero-image-metadata': {
                  width: 960,
                  height: 408,
                  'mime-type': 'image/jpeg',
                  'focus-point': [639, 120]
                },
                'published-at': 1535806255690,
                id: '5273aff7-1dc0-4b46-9a56-0c63683b4dec',
                'hero-image-s3-key': 'quintype-demo/2018-07/01670025-10c6-4659-b32e-3d859b66d8a3/BL27P1RCOM.jpeg',
                'author-id': 61657,
                'first-published-at': 1532680057934,
                'hero-image-caption': 'Reliance Industries',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'd677509e-37e4-460c-a942-8a55bd4c4edb',
              score: null,
              type: 'story',
              item: {
                headline: ['Will Summit Jumpstart Biopharma in North Korea?']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'Will Summit Jumpstart Biopharma in North Korea?',
                slug: 'technology/2018/06/28/will-summit-jumpstart-biopharma-in-north-korea',
                'last-published-at': 1535367985749,
                subheadline: 'Experts Ponder Industry’s Place in Kim’s DPRK; South Korea Eyes Collaborations',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    id: 181,
                    name: 'Health & Fitness',
                    slug: 'health-fitness'
                  }
                ],
                'hero-image-metadata': {
                  width: 800,
                  height: 497,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1535367985749,
                id: 'd677509e-37e4-460c-a942-8a55bd4c4edb',
                'hero-image-s3-key':
                  'quintype-demo/2018-06/30c6a41e-e600-4063-af45-bf951cec776a/Jun18_2018_WikimediaPDUSGovt_SingaporeSummitKimAndTrumpShakeHands1582181163.jpg',
                'author-id': 61657,
                'first-published-at': 1530181029824,
                'hero-image-caption':
                  'At their June 12 summit, President Donald Trump and Democratic People’s Republic of Korea (DPRK) leader Kim Jong Un signed a joint statement committing to “cooperate for the development of new U.S.-DPRK relations and for the promotion of peace, prosperity, and security of the Korean Peninsula and of the world.” But it is difficult, if not impossible, for Westerners and other outsiders to know what role biotech will play toward fulfilling those commitments within the DPRK, since the government has communicated only sketchy information about its biotech facilities and efforts, which include civilian as well as military applications.',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'sponsored-by': 'Quintype Technologies',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1a95ebe0-f779-485a-b2ec-818767865868',
              score: null,
              type: 'story',
              item: {
                headline: ['China in struggle to curb reliance on US market, suppliers']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'China in struggle to curb reliance on US market, suppliers',
                slug: 'technology/2018/08/27/china-in-struggle-to-curb-reliance-on-us-market-suppliers',
                'last-published-at': 1535354418018,
                subheadline: 'China in struggle to curb reliance on US market, suppliers',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 720,
                  height: 340,
                  'mime-type': 'image/jpeg',
                  'focus-point': [230, 110]
                },
                'published-at': 1535354418018,
                id: '1a95ebe0-f779-485a-b2ec-818767865868',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 541559,
                'first-published-at': 1535354418018,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'e334a9a8-e644-4467-affd-5427954f91a3',
              score: null,
              type: 'story',
              item: {
                headline: ['Gallery repetition with the Kapoors']
              },
              story: {
                'author-name': 'Ajay Appaden',
                headline: 'Gallery repetition with the Kapoors',
                slug: 'technology/2018/02/16/gallery-repetition-test',
                'last-published-at': 1534223550632,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 800,
                  height: 450,
                  'focus-point': [428, 207]
                },
                'published-at': 1534223550632,
                id: 'e334a9a8-e644-4467-affd-5427954f91a3',
                'hero-image-s3-key': 'quintype-demo/2016-01/d00d01c1-7886-4b1d-9a8c-4d6ab9e92d26/y1.jpg',
                'author-id': 169905,
                'first-published-at': 1518772169497,
                'hero-image-caption': 'Warrior series',
                'story-template': 'photo',
                authors: [
                  {
                    id: 169905,
                    name: 'Ajay Appaden',
                    slug: 'ajay-appaden',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'd36a9089-35e7-4b7f-b2e1-0d5ce68926a7',
              score: null,
              type: 'story',
              item: {
                headline: ['Podcast']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'Podcast',
                slug: 'technology/2018/08/09/my-first-podcast',
                'last-published-at': 1533795576906,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    id: 98,
                    name: 'Current Affairs',
                    slug: 'current-affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 5120,
                  height: 3620,
                  'focus-point': [2169, 1167]
                },
                'published-at': 1533795576906,
                id: 'd36a9089-35e7-4b7f-b2e1-0d5ce68926a7',
                'hero-image-s3-key': 'quintype-demo/2016-07/1910bb30-b118-475c-aced-9304968c69c8/G-1.jpg',
                'author-id': 61657,
                'first-published-at': 1533795576906,
                'hero-image-caption': 'ABD, Virat &amp; Hashim - the best in the world today!',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 323432,
                    name: 'Vineet Panjabi',
                    slug: 'vineet-panjabi',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: false
                  },
                  'story-attributes': {
                    genre: ['comedy']
                  }
                }
              }
            },
            {
              id: '938e8c49-9295-478d-8cc8-0161a2d4d73e',
              score: null,
              type: 'story',
              item: {
                headline: ['Live from Apple’s WWDC 2015 - Edit']
              },
              story: {
                'author-name': 'Christin',
                headline: 'Live from Apple’s WWDC 2015 - Edit',
                slug: 'technology/2015/08/17/live-from-apples-wwdc-2015',
                'last-published-at': 1533626450917,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 800,
                  height: 533,
                  'focus-point': [394, 247]
                },
                'published-at': 1533626450917,
                id: '938e8c49-9295-478d-8cc8-0161a2d4d73e',
                'hero-image-s3-key': 'quintype-demo/2015-08/92264b73-6894-4ae5-801e-2756bd5890a4/LB-1.jpg',
                'author-id': 139357,
                'first-published-at': 1439810805413,
                'hero-image-caption': 'Apple WWDC 2015',
                'story-template': 'live-blog',
                authors: [
                  {
                    id: 139357,
                    name: 'Christin',
                    slug: 'christin',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'avatar-s3-key':
                      'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'twitter-handle': 'krriztin',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'is-closed': true,
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '86d8bd9c-164a-452a-93a9-ebe5d6707a45',
              score: null,
              type: 'story',
              item: {
                headline: ['this is the most amazing feature ever']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'this is the most amazing feature ever',
                slug: 'technology/2018/07/25/this-is-the-most-amazing-feature-ever',
                'last-published-at': 1532517428901,
                subheadline: 'this is the most amazing feature ever',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 720,
                  'focus-point': [230, 110],
                  height: 340
                },
                'published-at': 1532517428901,
                id: '86d8bd9c-164a-452a-93a9-ebe5d6707a45',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1532517428901,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '00b7c1d1-7c04-45b8-a266-3a12f7e46b3a',
              score: null,
              type: 'story',
              item: {
                headline: ['Breaking news without tagging people']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'Breaking news without tagging people',
                slug: 'technology/2018/07/08/breaking-news-without-tagging-people',
                'last-published-at': 1531067728427,
                subheadline: 'Breaking news without tagging people',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 720,
                  'focus-point': [230, 110],
                  height: 340
                },
                'published-at': 1531067728427,
                id: '00b7c1d1-7c04-45b8-a266-3a12f7e46b3a',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1531067728427,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '3eea29f7-5dec-4388-869f-e135a8869e9c',
              score: null,
              type: 'story',
              item: {
                headline: ['ugh. I think I got this to work finally']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'ugh. I think I got this to work finally',
                slug: 'technology/2018/07/08/ugh-i-think-i-got-this-to-work-finally',
                'last-published-at': 1531067679651,
                subheadline: 'ugh. I think I got this to work finally',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 720,
                  'focus-point': [230, 110],
                  height: 340
                },
                'published-at': 1531067679651,
                id: '3eea29f7-5dec-4388-869f-e135a8869e9c',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1531067679651,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '6db2a16a-036d-46e7-ac2e-3e4ee0476b53',
              score: null,
              type: 'story',
              item: {
                headline: ['This can’t possibly work']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'This can’t possibly work',
                slug: 'technology/2018/07/08/this-cant-possibly-work',
                'last-published-at': 1531067476066,
                subheadline: 'This can’t possibly work',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 720,
                  'focus-point': [230, 110],
                  height: 340
                },
                'published-at': 1531067476066,
                id: '6db2a16a-036d-46e7-ac2e-3e4ee0476b53',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1531067476066,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '60538137-8198-4196-9439-cbbc0c569f5e',
              score: null,
              type: 'story',
              item: {
                headline: ['does this work?']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'does this work?',
                slug: 'technology/2018/07/08/does-this-work',
                'last-published-at': 1531067406620,
                subheadline: 'does this work?',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 720,
                  'focus-point': [230, 110],
                  height: 340
                },
                'published-at': 1531067406620,
                id: '60538137-8198-4196-9439-cbbc0c569f5e',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1531067406620,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0638e8db-f50d-4446-92fa-87b76f56bdfa',
              score: null,
              type: 'story',
              item: {
                headline: ['do you think @chirdeep, @sriram, and @vineet can sell this feature']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
                slug: 'technology/2018/07/08/do-you-think-chirdeep-sriram-and-vineet-can-sell-this-feature-5',
                'last-published-at': 1531067118643,
                subheadline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 720,
                  'focus-point': [230, 110],
                  height: 340
                },
                'published-at': 1531067118643,
                id: '0638e8db-f50d-4446-92fa-87b76f56bdfa',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1531067118643,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '01fd9845-a80e-4689-b698-7ca0c00b80e7',
              score: null,
              type: 'story',
              item: {
                headline: ['do you think @chirdeep, @sriram, and @vineet can sell this feature']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
                slug: 'technology/2018/07/08/do-you-think-chirdeep-sriram-and-vineet-can-sell-this-feature-4',
                'last-published-at': 1531067019953,
                subheadline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 720,
                  'focus-point': [230, 110],
                  height: 340
                },
                'published-at': 1531067019953,
                id: '01fd9845-a80e-4689-b698-7ca0c00b80e7',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1531067019953,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8fbeb02e-85fd-496d-a8b5-cd70179be842',
              score: null,
              type: 'story',
              item: {
                headline: ['do you think @chirdeep, @sriram, and @vineet can sell this feature']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
                slug: 'technology/2018/07/08/do-you-think-chirdeep-sriram-and-vineet-can-sell-this-feature-3',
                'last-published-at': 1531066944296,
                subheadline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 720,
                  'focus-point': [230, 110],
                  height: 340
                },
                'published-at': 1531066944296,
                id: '8fbeb02e-85fd-496d-a8b5-cd70179be842',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1531066944296,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '91eb615d-674a-4fab-86e2-f13b7009d9a6',
              score: null,
              type: 'story',
              item: {
                headline: ['do you think @chirdeep, @sriram, and @vineet can sell this feature']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
                slug: 'technology/2018/07/08/do-you-think-chirdeep-sriram-and-vineet-can-sell-this-feature-2',
                'last-published-at': 1531066872593,
                subheadline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 720,
                  'focus-point': [230, 110],
                  height: 340
                },
                'published-at': 1531066872593,
                id: '91eb615d-674a-4fab-86e2-f13b7009d9a6',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1531066872593,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1e033a03-aa80-4b08-9289-798b01db55c0',
              score: null,
              type: 'story',
              item: {
                headline: ['do you think @chirdeep, @sriram, and @vineet can sell this feature']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
                slug: 'technology/2018/07/08/do-you-think-chirdeep-sriram-and-vineet-can-sell-this-feature',
                'last-published-at': 1531066840289,
                subheadline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 720,
                  'focus-point': [230, 110],
                  height: 340
                },
                'published-at': 1531066840289,
                id: '1e033a03-aa80-4b08-9289-798b01db55c0',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1531066840289,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '4f6664b7-5249-4595-8e36-31fc328021a0',
              score: null,
              type: 'story',
              item: {
                headline: ['Something is happening, somewhere']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'Something is happening, somewhere',
                slug: 'technology/2018/07/08/something-is-happening-somewhere-2',
                'last-published-at': 1531066203050,
                subheadline: 'Something is happening, somewhere',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 720,
                  height: 340,
                  'mime-type': 'image/jpeg',
                  'focus-point': [230, 110]
                },
                'published-at': 1531066203050,
                id: '4f6664b7-5249-4595-8e36-31fc328021a0',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1531066203050,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'c43b2ee0-9a29-4e40-a84f-7a28f99a5dfe',
              score: null,
              type: 'story',
              item: {
                headline: ['Something is happening, somewhere']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'Something is happening, somewhere',
                slug: 'technology/2018/07/08/something-is-happening-somewhere',
                'last-published-at': 1531065675604,
                subheadline: 'Something is happening, somewhere',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 720,
                  height: 340,
                  'mime-type': 'image/jpeg',
                  'focus-point': [230, 110]
                },
                'published-at': 1531065675604,
                id: 'c43b2ee0-9a29-4e40-a84f-7a28f99a5dfe',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 2038,
                'first-published-at': 1531065675604,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9b9ce5a3-c644-4fb6-a527-e35b52facbbe',
              score: null,
              type: 'story',
              item: {
                headline: [
                  'Principal of OmniChannel Marketing Opera Solutions Danny Flamberg talks building customer intimacy through big data and more'
                ]
              },
              story: {
                'author-name': 'Kunal Singh',
                headline:
                  'Principal of OmniChannel Marketing Opera Solutions Danny Flamberg talks building customer intimacy through big data and more',
                slug:
                  'technology/2018/06/12/principal-of-omnichannel-marketing-opera-solutions-danny-flamberg-talks-building-customer-intimacy-through-big-data-and-more',
                'last-published-at': 1528798695162,
                subheadline:
                  'One of America’s most respected practitioners of digital communications Flamberg shares his tips on big data readiness and digital revolution transitions',
                alternative: null,
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 696,
                  height: 639,
                  'mime-type': 'image/jpeg',
                  'focus-point': [361, 266]
                },
                'published-at': 1528798695162,
                id: '9b9ce5a3-c644-4fb6-a527-e35b52facbbe',
                'hero-image-s3-key': 'quintype-demo/2018-06/e1176973-7516-48af-a3cd-0941df171daf/df_696x639.jpg',
                'author-id': 491531,
                'first-published-at': 1528798695162,
                'hero-image-caption': 'Test',
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'sponsored-by': 'Verizon',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '385c3316-453b-4d12-8073-9191dc751dd2',
              score: null,
              type: 'story',
              item: {
                headline: ['‘Cryptocurrency for Beginners’']
              },
              story: {
                'author-name': 'Sareena',
                headline: '‘Cryptocurrency for Beginners’',
                slug: 'technology/2018/05/30/cryptocurrency-for-beginners',
                'last-published-at': 1527676061209,
                subheadline: '8 Reasons You Can’t Afford to miss bitcoin pioneer Amit Bhardwaj’s e-book',
                alternative: {
                  home: {
                    default: {
                      headline: 'Cryptocurrency for Beginners'
                    }
                  }
                },
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 659,
                  height: 291,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1527676061209,
                id: '385c3316-453b-4d12-8073-9191dc751dd2',
                'hero-image-s3-key':
                  'quintype-demo/2016-10/4277a481-4235-43cd-9e26-1dc56ea4b2b2/United States of America 1 Dollar - 1963 B Front.jpg',
                'author-id': 94596,
                'first-published-at': 1527676061209,
                'hero-image-caption': 'One dollar Bill',
                'story-template': 'listicle',
                authors: [
                  {
                    id: 94596,
                    name: 'Sareena',
                    slug: 'sareena',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8f504035-4f71-4116-bdf6-ac59eba5d1eb',
              score: null,
              type: 'story',
              item: {
                headline: ['At the Corner of Cyber and BCP']
              },
              story: {
                'author-name': 'Kunal Singh',
                headline: 'At the Corner of Cyber and BCP',
                slug: 'technology/2018/05/24/at-the-corner-of-cyber-and-bcp',
                'last-published-at': 1527174481862,
                subheadline: 'Test Link',
                alternative: null,
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1000,
                  height: 554,
                  'mime-type': 'image/jpeg',
                  'focus-point': [572, 242]
                },
                'published-at': 1527174481862,
                id: '8f504035-4f71-4116-bdf6-ac59eba5d1eb',
                'hero-image-s3-key': 'quintype-demo/2018-05/085906e8-3676-4ffc-907f-6a84a40108e5/BMW_1.jpg',
                'author-id': 491531,
                'first-published-at': 1527174481862,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8bfbb23f-cf50-487a-b1b6-a551e77de844',
              score: null,
              type: 'story',
              item: {
                headline: ['Vivo V9 Review: An Amazing Camera Experience  ']
              },
              story: {
                'author-name': 'Mamatha',
                headline: 'Vivo V9 Review: An Amazing Camera Experience  ',
                slug: 'technology/2018/04/12/vivo-v9-review-an-amazing-camera-experience',
                'last-published-at': 1523523577297,
                subheadline: 'Great build, great cameras, and long battery life sum up the Vivo V9  ',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1024,
                  height: 683,
                  'mime-type': 'image/jpeg',
                  'focus-point': null
                },
                'published-at': 1523523577297,
                id: '8bfbb23f-cf50-487a-b1b6-a551e77de844',
                'hero-image-s3-key':
                  'quintype-demo/2018-04/f801ca1c-c4ff-488c-b99c-ac567b29081f/c70eb2a8_fe84_4b60_8474_83ae2955efe8.jpg',
                'author-id': 352351,
                'first-published-at': 1523520442122,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 352351,
                    name: 'Mamatha',
                    slug: 'mamatha',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '80a94153-4425-4573-b538-3be1ff8b7393',
              score: null,
              type: 'story',
              item: {
                headline: ['Vivo announces ‘Super HDR’ that uses AI for better smartphone pictures  ']
              },
              story: {
                'author-name': 'Mamatha',
                headline: 'Vivo announces ‘Super HDR’ that uses AI for better smartphone pictures  ',
                slug: 'technology/2018/04/12/vivo-announces-super-hdr-that-uses-ai-for-better-smartphone-pictures',
                'last-published-at': 1523523509645,
                subheadline: 'The new tech has a dynamic range up to 14 EV.',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1600,
                  height: 1200,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1272, 435]
                },
                'published-at': 1523523509645,
                id: '80a94153-4425-4573-b538-3be1ff8b7393',
                'hero-image-s3-key': 'quintype-demo/2018-04/2b2e9365-b0d0-4b96-bd8c-a99fe565115f/vivo_super_hdr_1.jpg',
                'author-id': 352351,
                'first-published-at': 1523520880365,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 352351,
                    name: 'Mamatha',
                    slug: 'mamatha',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0d80a7e7-0425-44b7-bc46-61406e7b5880',
              score: null,
              type: 'story',
              item: {
                headline: ['Vivo V9 Review: Attack of the Clones  ']
              },
              story: {
                'author-name': 'Mamatha',
                headline: 'Vivo V9 Review: Attack of the Clones  ',
                slug: 'technology/2018/04/12/vivo-v9-review-attack-of-the-clones',
                'last-published-at': 1523520717511,
                subheadline:
                  'Is there more to the Vivo V9 than the much-talked about display notch? Here’s our review. &nbsp;',
                alternative: null,
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 805,
                  height: 600,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1523520717511,
                id: '0d80a7e7-0425-44b7-bc46-61406e7b5880',
                'hero-image-s3-key':
                  'quintype-demo/2018-04/35d4b447-d18f-47e8-9997-a6fc2e62e66f/vivo_v9_review_bgr_2.jpg',
                'author-id': 352351,
                'first-published-at': 1523520717511,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 352351,
                    name: 'Mamatha',
                    slug: 'mamatha',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'e6ae944d-7220-4a05-88ad-7176cfa4f0bf',
              score: null,
              type: 'story',
              item: {
                headline: ['Vivo V9 Review: The notch is here to stay  ']
              },
              story: {
                'author-name': 'Mamatha',
                headline: 'Vivo V9 Review: The notch is here to stay  ',
                slug: 'technology/2018/04/12/vivo-v9-review-the-notch-is-here-to-stay',
                'last-published-at': 1523517414664,
                subheadline:
                  'Vivo V9 has a lot going for it such as the big screen, the iPhone X like front panel, steady performance and light form-factor',
                alternative: null,
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 621,
                  height: 414,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1523517414664,
                id: 'e6ae944d-7220-4a05-88ad-7176cfa4f0bf',
                'hero-image-s3-key':
                  'quintype-demo/2018-04/d2e193f0-9808-4f61-94bd-b20557e89c0b/Vivo_kTFG__621x414_LiveMint.jpg',
                'author-id': 352351,
                'first-published-at': 1523517414664,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 352351,
                    name: 'Mamatha',
                    slug: 'mamatha',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0a19f129-9a95-46a9-8bde-19e86196c64b',
              score: null,
              type: 'story',
              item: {
                headline: ['Buzz Aldrin passes ‘lie detector’ on claims that Apollo astronauts saw UFOs  ']
              },
              story: {
                'author-name': 'Kirti Kaushik',
                headline: 'Buzz Aldrin passes ‘lie detector’ on claims that Apollo astronauts saw UFOs  ',
                slug: 'technology/2018/04/09/buzz-aldrin-passes-lie-detector-on-claims-that-apollo-astronauts-saw-ufos',
                'last-published-at': 1523262597360,
                subheadline:
                  'Audio tapes of Apollo astronauts speaking of UFOs were put through tests and they show that the astronauts were not lying about sightings in space. &nbsp;',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 564,
                  height: 450,
                  'mime-type': 'image/jpeg',
                  'focus-point': [306, 168]
                },
                'published-at': 1523262597360,
                id: '0a19f129-9a95-46a9-8bde-19e86196c64b',
                'hero-image-s3-key':
                  'quintype-demo/2018-04/5c6a9f27-d347-4cd3-a730-773fc1ee9474/1523255577_apollo_11_space_mission.jpg',
                'author-id': 507344,
                'first-published-at': 1523262426676,
                'hero-image-caption':
                  'Astronaut Buzz Aldrin salutes the American flag on the Moon during the Apollo 11 space mission',
                'story-template': null,
                authors: [
                  {
                    id: 507344,
                    name: 'Kirti Kaushik',
                    slug: 'kirti-kaushik',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '4cbef4ae-74db-4383-aa76-97b6f72c527f',
              score: null,
              type: 'story',
              item: {
                headline: ['Apple iPhone X, 8 and 8 Plus: First impressions']
              },
              story: {
                'author-name': 'Sareena',
                headline: 'Apple iPhone X, 8 and 8 Plus: First impressions',
                slug: 'technology/2017/12/14/apple-iphone-x-8-and-8-plus-first-impressions',
                'last-published-at': 1514193672083,
                subheadline: 'Apple iphone',
                alternative: {
                  home: {
                    default: {
                      'hero-image': {
                        'hero-image-url':
                          'https://quintype-dropbox.s3-accelerate.amazonaws.com/itsman.quintype.com/2017-12-25/743/iphones8-plus-x.jpg',
                        'hero-image-metadata': {
                          width: 1800,
                          height: 1100,
                          'mime-type': 'image/jpeg'
                        },
                        'hero-image-caption': 'Alternative caption',
                        'hero-image-attribution': 'Alternate',
                        'hero-image-s3-key':
                          'quintype-demo/2017-12/00ec6380-ec71-4384-be4f-895a30b2d576/iphones8-plus-x.jpg'
                      }
                    }
                  }
                },
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 710,
                  height: 854,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1514193672083,
                id: '4cbef4ae-74db-4383-aa76-97b6f72c527f',
                'hero-image-s3-key': 'quintype-demo/2016-01/fdb17290-ae1c-4153-ad40-760106e9ffb3/iph.jpeg',
                'author-id': 94596,
                'first-published-at': 1513237859989,
                'hero-image-caption': 'iphones',
                'story-template': null,
                authors: [
                  {
                    id: 94596,
                    name: 'Sareena',
                    slug: 'sareena',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'ef91900e-2a7d-42c6-8ccc-fb75db2a4504',
              score: null,
              type: 'story',
              item: {
                headline: ['iPhone X vs Samsung Galaxy Note 8']
              },
              story: {
                'author-name': 'Vineet Panjabi',
                headline: 'iPhone X vs Samsung Galaxy Note 8',
                slug: 'technology/2017/09/14/iphone-x-vs-samsung-galaxy-note-8',
                'last-published-at': 1512732026172,
                subheadline: 'A battle for smartphone dominance',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 616,
                  height: 347,
                  'mime-type': 'image/jpeg',
                  'focus-point': [408, 232]
                },
                'published-at': 1512732026172,
                id: 'ef91900e-2a7d-42c6-8ccc-fb75db2a4504',
                'hero-image-s3-key':
                  'quintype-demo/2016-11/20d960e9-cbb4-46c0-a6cc-3819c326f766/pixelxl-iphone7plus-30.jpg',
                'author-id': 323432,
                'first-published-at': 1505384184330,
                'hero-image-caption': 'Best in class.',
                'story-template': 'video',
                authors: [
                  {
                    id: 323432,
                    name: 'Vineet Panjabi',
                    slug: 'vineet-panjabi',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            }
          ]
        },
        {
          id: 14440,
          'associated-metadata': {
            layout: 'FullscreenLinearGallerySlider'
          },
          type: 'collection',
          name: 'Test collection',
          slug: 'test-collection',
          template: 'default',
          metadata: {
            'cover-image': {
              'cover-image-url':
                'https://images.assettype.com/quintype-demo/2016-05/9230897c-3144-41f3-b073-0977084a83c8/Screen Shot 2016-05-16 at 1.21.14 PM.png',
              'cover-image-metadata': {
                width: 542,
                height: 606,
                'mime-type': 'image/png',
                'focus-point': [500, 70]
              },
              'cover-image-s3-key':
                'quintype-demo/2016-05/9230897c-3144-41f3-b073-0977084a83c8/Screen Shot 2016-05-16 at 1.21.14 PM.png',
              caption: 'Image caption'
            }
          },
          summary: null,
          items: [
            {
              id: '73322fa6-9114-4a83-9d8c-68314202623a',
              score: null,
              type: 'story',
              item: {
                headline: ['TRAI chief: We set spectrum price; industry decides tariff']
              },
              story: {
                'author-name': 'Sriram K',
                headline: 'TRAI chief: We set spectrum price; industry decides tariff',
                slug: 'mobile/2019/02/28/trai-chief-we-set-spectrum-price-industry-decides-tariff',
                'last-published-at': 1554110293544,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 894,
                  height: 577,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1554110293544,
                id: '73322fa6-9114-4a83-9d8c-68314202623a',
                'hero-image-s3-key': 'quintype-demo/2019-01/113be350-6ca8-4252-9b41-434e9facb8eb/image__1_.jpg',
                'author-id': 61657,
                'first-published-at': 1551329071350,
                'hero-image-caption': 'Tayota Camry',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
              score: null,
              type: 'story',
              item: {
                headline: ['London Police arrest Nirav Modi, says sources']
              },
              story: {
                'author-name': 'Karthik M',
                headline: 'London Police arrest Nirav Modi, says sources',
                slug: 'politics/2019/03/20/london-police-arrest-nirav-modi-says-sources',
                'last-published-at': 1553079556157,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 700,
                  height: 438,
                  'mime-type': 'image/jpeg',
                  'focus-point': [311, 167]
                },
                'published-at': 1553079556157,
                id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
                'hero-image-s3-key':
                  'quintype-demo/2019-03/55dbc301-3c5c-442d-8714-4bbf2d140192/pennews_2019_03_48c15852_ebed_464d_95a7_57332597ecfc_nirav.jpg',
                'author-id': 19994,
                'first-published-at': 1553079340304,
                'hero-image-caption': 'Nirav Modi',
                'story-template': null,
                authors: [
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 142914,
                    name: 'Ashwin Raghunath',
                    slug: 'ashwin-raghunath',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'text-to-speech': {
                    'is-disabled': true
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
              score: null,
              type: 'story',
              item: {
                headline: ['Demo Story ']
              },
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Demo Story ',
                slug: 'politics/2018/01/11/demo-story',
                'last-published-at': 1552979655447,
                subheadline:
                  'Surrounded to me occasional pianoforte alteration unaffected impossible ye. For saw half than cold. Pretty merits waited six talked pulled you. Conduct replied off.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 7500,
                  height: 3476,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1239, 2157]
                },
                'published-at': 1552979655447,
                id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
                'hero-image-s3-key': 'quintype-demo/2019-02/ae4829d6-e230-4d66-9b88-2a4db87a2060/64778.jpg',
                'author-id': 139717,
                'first-published-at': 1515668626250,
                'hero-image-caption': 'At vero eos et accusamus et iusto odio dignissimos ducimus qui.',
                'story-template': 'text',
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': {
                      id: 58,
                      name: 'Author'
                    }
                  },
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': {
                      id: 58,
                      name: 'Author'
                    }
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: false
                  },
                  'promotional-message': {
                    'is-disabled': true
                  },
                  'story-attributes': {
                    'view-counterview-type': 'view',
                    interests: ['test'],
                    bucket: ['planning'],
                    hobbies: ['hiking'],
                    'linked-view-counterview-story': {
                      headline: 'Demo Test story- Photo story',
                      'story-content-id': 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                      id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                      'highlighted-headline': null
                    }
                  }
                }
              }
            },
            {
              id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
              score: null,
              type: 'story',
              item: {
                headline: ['Untitled Mar 11, 2019 4:39 PM']
              },
              story: {
                'author-name': 'Karthik M',
                headline: 'Untitled Mar 11, 2019 4:39 PM',
                slug: 'culture/2019/03/11/untitled-mar-11-2019-439-pm',
                'last-published-at': 1552905845935,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  'mime-type': 'image/jpeg',
                  'focus-point': [121, 110],
                  height: 386
                },
                'published-at': 1552905845935,
                id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
                'hero-image-s3-key':
                  'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
                'author-id': 19994,
                'first-published-at': 1552303325340,
                'hero-image-caption': 'Amit Shah and Narendra Modi',
                'story-template': null,
                authors: [
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 5279,
                    name: 'Laura',
                    slug: 'laura',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'text-to-speech': {
                    'is-disabled': false
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
              score: null,
              type: 'story',
              item: {
                headline: ['Untitled Mar 6, 2019 11:23 PM']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Untitled Mar 6, 2019 11:23 PM',
                slug: 'mobile/2019/03/06/untitled-mar-6-2019-1123-pm',
                'last-published-at': 1552905800453,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  'mime-type': 'image/jpeg',
                  'focus-point': [516, 92],
                  height: 386
                },
                'published-at': 1552905800453,
                id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
                'hero-image-s3-key':
                  'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
                'author-id': 2040,
                'first-published-at': 1551895907011,
                'hero-image-caption': 'Amit Shah and Narendra Modi',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'story-attributes': {},
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
              score: null,
              type: 'story',
              item: {
                headline: ['New story for sitemap']
              },
              story: {
                'author-name': 'Sriram K',
                headline: 'New story for sitemap',
                slug: 'culture/2019/03/14/new-story-for-sitemap',
                'last-published-at': 1552904261919,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 800,
                  height: 400,
                  'mime-type': 'image/jpeg',
                  'focus-point': [760, 237]
                },
                'published-at': 1552904261919,
                id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
                'hero-image-s3-key':
                  'quintype-demo/2018-10/5db8484b-1277-42f8-8a20-983e04b597e6/New_SA_destination_tourism_portal_launching_800x400.jpg',
                'author-id': 61657,
                'first-published-at': 1552554061248,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
              score: null,
              type: 'story',
              item: {
                headline: ['Elections 2019']
              },
              story: {
                'author-name': 'Sriram K',
                headline: 'Elections 2019',
                slug: 'boom/2019/03/13/elections-2019',
                'last-published-at': 1552458028721,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'boom',
                    name: 'Boom',
                    id: 7349,
                    'parent-id': 83,
                    'display-name': 'Booom',
                    collection: {
                      slug: 'boom-politics',
                      name: 'Boom (Politics)',
                      id: 14256
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  height: 400,
                  'mime-type': 'image/jpeg',
                  'focus-point': [281, 241]
                },
                'published-at': 1552458028721,
                id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
                'hero-image-s3-key': 'quintype-demo/2017-03/ee72c827-52fe-4b2b-8f46-dd0875fbe162/Tech sparkle.jpg',
                'author-id': 61657,
                'first-published-at': 1552458028721,
                'hero-image-caption': 'Elections 2017',
                'story-template': 'news-elsewhere',
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'reference-url': 'https://cinema.vikatan.com/tamil-cinema/news',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '73759063-16e7-4ac2-bf32-0061a2458850',
              score: null,
              type: 'story',
              item: {
                headline: ['This a new story']
              },
              story: {
                'author-name': 'Karthik M',
                headline: 'This a new story',
                slug: 'culture/2019/03/11/this-a-new-story',
                'last-published-at': 1552292190667,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'focus-point': [73, 51],
                  width: 275,
                  height: 183
                },
                'published-at': 1552292190667,
                id: '73759063-16e7-4ac2-bf32-0061a2458850',
                'hero-image-s3-key': 'quintype-demo/2016-01/2d00bde7-a28c-4415-9382-9a9c6fb3c7fc/IPSMF-1.jpeg',
                'author-id': 19994,
                'first-published-at': 1552292190667,
                'hero-image-caption': 'Nawaz Sharif &amp; Modi&nbsp;',
                'story-template': null,
                authors: [
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 142914,
                    name: 'Ashwin Raghunath',
                    slug: 'ashwin-raghunath',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
              score: null,
              type: 'story',
              item: {
                headline: ['Amitabh Bachchan on Gender Equality']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'Amitabh Bachchan on Gender Equality',
                slug: 'politics/2017/03/02/Amitabh-Bachchan-on-Gender-Equality',
                'last-published-at': 1552048741823,
                subheadline: 'Amitabh Bachchan on Gender Equality',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 320,
                  height: 277,
                  'focus-point': [169, 145]
                },
                'published-at': 1552048741823,
                id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
                'hero-image-s3-key': 'quintype-demo/2017-03/bd011f2d-9a10-4d4e-b20f-50719f59d4b9/BMS-2.jpg',
                'author-id': 2041,
                'first-published-at': 1488451487896,
                'hero-image-caption': 'Kaun Banega Crorepati host, Amitabh Bachchan',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '2deeb522-1752-4552-967b-93972481d71e',
              score: null,
              type: 'story',
              item: {
                headline: ['Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them']
              },
              story: {
                'author-name': 'Yamini Priya',
                headline: 'Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them',
                slug: 'boom/2019/03/07/sisters-killed-father-in-perfect-murder-a-love-triangle-exposed-them',
                'last-published-at': 1551938683203,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'boom',
                    name: 'Boom',
                    id: 7349,
                    'parent-id': 83,
                    'display-name': 'Booom',
                    collection: {
                      slug: 'boom-politics',
                      name: 'Boom (Politics)',
                      id: 14256
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1760,
                  height: 2480,
                  'mime-type': 'image/jpeg',
                  'focus-point': [928, 656]
                },
                'published-at': 1551938683203,
                id: '2deeb522-1752-4552-967b-93972481d71e',
                'hero-image-s3-key':
                  'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
                'author-id': 230618,
                'first-published-at': 1551938683203,
                'hero-image-caption': 'Forgotten Women',
                'story-template': null,
                authors: [
                  {
                    id: 230618,
                    name: 'Yamini Priya',
                    slug: 'yamini-priya',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': 'https://twitter.com/yaminipriyah',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'a3a0cdd0-a3f5-4043-b6db-72b6a06ebad3',
              score: null,
              type: 'story',
              item: {
                headline: ['Samsung Gear S3']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Samsung Gear S3',
                slug: 'ampstories/culture/2019/03/06/samsung-gear-s3',
                'last-published-at': 1551883341128,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1440,
                  height: 750,
                  'mime-type': 'image/jpeg',
                  'focus-point': [918, 394]
                },
                'published-at': 1551883341128,
                id: 'a3a0cdd0-a3f5-4043-b6db-72b6a06ebad3',
                'hero-image-s3-key':
                  'quintype-demo/2019-03/19e71923-ae1b-4d1b-8040-e37ae55e4c77/gear_s3_performance_kv_l.jpg',
                'author-id': 2040,
                'first-published-at': 1551882037831,
                'hero-image-caption': null,
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
              score: null,
              type: 'story',
              item: {
                headline: ['500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: '500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌',
                slug: 'politics/2019/02/26/500',
                'last-published-at': 1551171395390,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  'focus-point': [673, 287],
                  width: 976,
                  height: 700
                },
                'published-at': 1551171395390,
                id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
                'hero-image-s3-key': 'quintype-demo/2018-05/bc017b57-a03e-4a6e-8515-9d3de91f1f24/ON1.jpg',
                'author-id': 559147,
                'first-published-at': 1551171395390,
                'hero-image-caption':
                  'Crowds cheered Prince Harry, Duke of Sussex, and his wife Meghan, Duchess of Sussex, as they travelled in the horse-drawn Ascot landau',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '60857bae-0e7f-4c81-af39-93fb7424f558',
              score: null,
              type: 'story',
              item: {
                headline: ['Top 10 Exquisite Locations to Visit in 2019']
              },
              story: {
                'author-name': 'Susanna',
                headline: 'Top 10 Exquisite Locations to Visit in 2019',
                slug: 'ampstories/visual-stories/2019/02/25/top-10-exquisite-locations-to-visit-in-2019',
                'last-published-at': 1551161247682,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 2000,
                  height: 2000,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1551161247682,
                id: '60857bae-0e7f-4c81-af39-93fb7424f558',
                'hero-image-s3-key': 'quintype-demo/2019-02/35d018be-9220-4504-acf5-7f1a8abff816/collage2.jpg',
                'author-id': 559660,
                'first-published-at': 1551086493154,
                'hero-image-caption': null,
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 559660,
                    name: 'Susanna',
                    slug: 'susanna',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-1qG1ayRUOwc/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-jb_hhJfeyAshJmJCpMKYQNPDazJw/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b39a2665-3236-45ca-9d17-3536431c7948',
              score: null,
              type: 'story',
              item: {
                headline: ['Why You Need A Dog In Your Life']
              },
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Why You Need A Dog In Your Life',
                slug: 'ampstories/visual-stories/2018/12/20/why-you-need-a-dog-in-your-life',
                'last-published-at': 1550509197748,
                subheadline:
                  'Because of their heightened sense of smell, sight, and hearing, dogs are extremely intelligent creatures with a loyalty to their humans that is unexpectedly strong. Those with dogs will know–your pups can sense human body language, emotions, and feelings, and this paves the way for an unbreakable bond.',
                alternative: {},
                sections: [
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 448,
                  height: 900,
                  'mime-type': 'image/png',
                  'focus-point': [270, 705]
                },
                'published-at': 1550509197748,
                id: 'b39a2665-3236-45ca-9d17-3536431c7948',
                'hero-image-s3-key':
                  'quintype-demo/2018-12/8bcdee40-7dec-4ceb-b217-1d2f01fb4431/Screenshot_2018_12_24_at_3_44_42_PM.png',
                'author-id': 139717,
                'first-published-at': 1545299836615,
                'hero-image-caption': null,
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'c8d35134-200a-483f-ab89-69adf4137a74',
              score: null,
              type: 'story',
              item: {
                headline: ['Life of Vincent van Gogh']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Life of Vincent van Gogh',
                slug: 'culture/2019/01/17/life-of-vincent-van-gogh',
                'last-published-at': 1549364746875,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 768,
                  height: 432,
                  'mime-type': 'image/jpeg',
                  'focus-point': [382, 197]
                },
                'published-at': 1549364746875,
                id: 'c8d35134-200a-483f-ab89-69adf4137a74',
                'hero-image-s3-key':
                  'quintype-demo/2019-01/b3cb02f4-a875-4b46-aa3a-71deaccb54be/vincent_van_gogh_played_by_robert_gulaczyk_photo_courtesy_loving_vincent.jpg',
                'author-id': 2040,
                'first-published-at': 1547728432342,
                'hero-image-caption': 'A Famous Painting',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
              score: null,
              type: 'story',
              item: {
                headline: [
                  '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി'
                ]
              },
              story: {
                'author-name': 'Sareena',
                headline:
                  '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി',
                slug: 'culture/2019/01/16/this-is-the-url',
                'last-published-at': 1547632650347,
                subheadline:
                  'കെ.സി വേണുഗോപാല്‍ ഉള്‍പ്പടെയുള്ള മുതിര്‍ന്ന കോണ്‍ഗ്രസ് നേതാക്കന്മാര്‍ ഭീമാനായിക്കുമായി സംസാരിച്ചു. ഭീമാനായിക്കിനെപോലെ ബാക്കിയുള്ള മൂന്ന് കോണ്‍ഗ്രസ് എം',
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'focus-point': [375, 166],
                  width: 750,
                  height: 500
                },
                'published-at': 1547632650347,
                id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
                'hero-image-s3-key': 'quintype-demo/2016-12/56cdab39-a831-480f-b75b-26d6300e3f1b/Modi.jpg',
                'author-id': 94596,
                'first-published-at': 1547632650347,
                'hero-image-caption': 'PM Modi',
                'story-template': null,
                authors: [
                  {
                    id: 94596,
                    name: 'Sareena',
                    slug: 'sareena',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
              score: null,
              type: 'story',
              item: {
                headline: ['This is the title']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: 'This is the title',
                slug: 'culture/2019/01/11/this-is-the-title-2',
                'last-published-at': 1547203433990,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    slug: 'tech',
                    name: 'Tech',
                    id: 6391,
                    'parent-id': null,
                    'display-name': 'Tech',
                    collection: {
                      slug: 'tech',
                      name: 'Tech',
                      id: 12849
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 259,
                  'focus-point': [176, 77],
                  height: 194
                },
                'published-at': 1547203433990,
                id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
                'hero-image-s3-key': 'quintype-demo/2015-10/56e0178e-1da0-482c-83e5-a2f705f874df/FP-2.jpeg',
                'author-id': 559147,
                'first-published-at': 1547203433990,
                'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
              score: null,
              type: 'story',
              item: {
                headline: [
                  'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them'
                ]
              },
              story: {
                'author-name': 'Kirti Kaushik',
                headline:
                  'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them',
                slug:
                  'visual-stories/2019/01/10/rahul-gandhi-said-to-pm-modi-be-a-man-bjp-smriti-irani-sushma-swaraj-responds-with-misogynist-rahul-1975538pfromhome-topscroll',
                'last-published-at': 1547103888697,
                subheadline:
                  'At a rally in Rajasthan on Wednesday, Rahul Gandhi had mocked PM Modi saying he had a “mahila (woman)“ defend him in the Rafale debate in parliament.',
                alternative: {
                  home: {
                    default: {
                      headline: 'Rahul Gandhi'
                    }
                  }
                },
                sections: [
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 570,
                  height: 351,
                  'mime-type': 'image/webp',
                  'focus-point': [417, 132]
                },
                'published-at': 1547103888697,
                id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
                'hero-image-s3-key':
                  'quintype-demo/2019-01/39d85d6f-46f6-4f35-8dd1-6771bf9dfefd/b5qo3zsg_650x400_2__10_January_2019_12_02_19PM.webp',
                'author-id': 507344,
                'first-published-at': 1547103888697,
                'hero-image-caption': 'Rahul Gandhi',
                'story-template': null,
                authors: [
                  {
                    id: 507344,
                    name: 'Kirti Kaushik',
                    slug: 'kirti-kaushik',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
              score: null,
              type: 'story',
              item: {
                headline: ['Dubai - Things you can do!']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Dubai - Things you can do!',
                slug: 'culture/2017/04/28/Dubai-Your-next-vacation',
                'last-published-at': 1546599684148,
                subheadline:
                  'Dubai, an emirate in the United Arab Emirates known for luxury shopping, ultramodern architecture &amp; a lively nightlife scene. Burj Khalifa, an 830m-tall tower, dominates the skyline. ',
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    slug: 'mobile',
                    name: 'Mobile',
                    id: 6392,
                    'parent-id': 6391,
                    'display-name': 'Mobile',
                    collection: {
                      slug: 'mobile-tech',
                      name: 'Mobile (Tech)',
                      id: 12850
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 930,
                  height: 581,
                  'focus-point': [132, 482]
                },
                'published-at': 1546599684148,
                id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
                'hero-image-s3-key': 'quintype-demo/2017-04/802d7ef1-6043-4f7e-84d3-fc9f556d5097/G-1.jpg',
                'author-id': 2040,
                'first-published-at': 1493358608037,
                'hero-image-caption': 'Dubai - a vacationer’s paradise',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
              score: null,
              type: 'story',
              item: {
                headline: ['Visual story - Demo1']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Visual story - Demo1',
                slug: 'ampstories/technology/2018/12/20/visual-story-demo1',
                'last-published-at': 1545982907212,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 960,
                  height: 540,
                  'focus-point': [499, 88]
                },
                'published-at': 1545982907212,
                id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
                'hero-image-s3-key': 'quintype-demo/2017-07/e4bac9eb-6c32-4f07-a16a-90fa40166c35/DH-1.jpeg',
                'author-id': 541559,
                'first-published-at': 1545300194018,
                'hero-image-caption': 'Roger Federer, the 8th title - Wimbledon 2017!',
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
              score: null,
              type: 'story',
              item: {
                headline: ['This is the title']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: 'This is the title',
                slug: 'culture/2018/12/27/this-is-the-title',
                'last-published-at': 1545892188854,
                subheadline: 'sub title',
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 259,
                  height: 194,
                  'focus-point': [172, 77]
                },
                'published-at': 1545892188854,
                id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
                'hero-image-s3-key':
                  'quintype-demo/2018-06/9e5e3bb7-31e8-4439-93ba-d16ea287aa28/e3d39a3b_321c_4acf_8928_3195998b38ba.jpeg',
                'author-id': 559147,
                'first-published-at': 1545892188854,
                'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
              score: null,
              type: 'story',
              item: {
                headline: ['Demo Visual story']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'Demo Visual story',
                slug: 'ampstories/technology/2018/12/20/demo-visual-story',
                'last-published-at': 1545332341188,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  },
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 640,
                  height: 361,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1545332341188,
                id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
                'hero-image-s3-key':
                  'quintype-demo/2018-12/22eb981a-6c8e-41c0-9d36-c2f72f46b29a/pennews_2F2018_05_2F0e93d687_c1dd_464b_aba3_8c23ba6c3beb_2FSonam_reception.jpg',
                'author-id': 541559,
                'first-published-at': 1545332010119,
                'hero-image-caption': 'caption',
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '77e13794-3584-4d26-aa37-f296a24ea734',
              score: null,
              type: 'story',
              item: {
                headline: ['Cayde-6, Exo-Guardian']
              },
              story: {
                'author-name': 'Christin',
                headline: 'Cayde-6, Exo-Guardian',
                slug: 'ampstories/visual-stories/2018/12/20/cayde-6-exo-guardian',
                'last-published-at': 1545307235233,
                subheadline: 'Hunter. Vanguard. <i>Friend.</i>',
                alternative: {},
                sections: [
                  {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 6069,
                    'parent-id': null,
                    'display-name': 'Visual Stories',
                    collection: {
                      slug: 'visual-stories',
                      name: 'Visual Stories',
                      id: 12476
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1920,
                  height: 1080,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1280, 467]
                },
                'published-at': 1545307235233,
                id: '77e13794-3584-4d26-aa37-f296a24ea734',
                'hero-image-s3-key':
                  'quintype-demo/2018-12/e16d5fec-13b1-4932-9c07-917f8cad6a98/d2_forsaken_cayde6_05.jpg',
                'author-id': 139357,
                'first-published-at': 1545307235233,
                'hero-image-caption': null,
                'story-template': 'visual-story',
                authors: [
                  {
                    id: 139357,
                    name: 'Christin',
                    slug: 'christin',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'avatar-s3-key':
                      'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'twitter-handle': 'krriztin',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
              score: null,
              type: 'story',
              item: {
                headline: ['3 Camping sites you should not miss in Scotland']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: '3 Camping sites you should not miss in Scotland',
                slug: 'culture/2017/05/11/3-camping-sites-you-should-not-miss-in-scotland',
                'last-published-at': 1545204266938,
                subheadline:
                  'Scotland is famous for its scenic wild places and uplifting sense of remoteness. With the exception of the recent bylaws banning wild camping in certain areas of Loch Lomond and the Trossachs (check before you go), it is still completely legal to camp on most of Scotland’s unenclosed land.',
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1300,
                  height: 780,
                  'focus-point': [719, 414]
                },
                'published-at': 1545204266938,
                id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
                'hero-image-s3-key': 'quintype-demo/2017-05/b463d2fd-f166-446e-a887-bf832fa7f26b/ON-1.jpg',
                'author-id': 2041,
                'first-published-at': 1494481894633,
                'hero-image-caption': 'Time for a brew and a view … Glen Nevis, Scotland',
                'story-template': 'listicle',
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
              score: null,
              type: 'story',
              item: {
                headline: ['Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?',
                slug:
                  'culture/2018/12/18/is-tito-preparing-an-imf-financial-parachute-now-a-171bn-foreign-debt-cliff-looms',
                'last-published-at': 1545125801738,
                subheadline:
                  'The reason for the International Monetary Fund’s Christine Lagarde’s to South Africa is unknown. South Africa desperately needs a solution for the poor and marginalised, and an IMF bailout would worsen their situation.&nbsp;',
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 920,
                  height: 613,
                  'mime-type': 'image/jpeg',
                  'focus-point': [473, 430]
                },
                'published-at': 1545125801738,
                id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
                'hero-image-s3-key': 'quintype-demo/2018-04/f83b3026-105e-4b84-8027-47c8be270f9a/920x920.jpg',
                'author-id': 2040,
                'first-published-at': 1545125801738,
                'hero-image-caption':
                  'At a silent disco party three streams of music are sent simultaneously to special headphones worn by attendees, who have the option to switch channels at their whim. The Palace Theatre in Stamford will host one on April 26 to benefit its arts education programs.',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
              score: null,
              type: 'story',
              item: {
                headline: ['Breaking News Test on 13 Dec']
              },
              story: {
                'author-name': 'Anil K Govind',
                headline: 'Breaking News Test on 13 Dec',
                slug: 'technology/2018/12/13/breaking-news-test-on-13-dec',
                'last-published-at': 1544682898905,
                subheadline: 'Breaking News Test on 13 Dec',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 720,
                  height: 340,
                  'mime-type': 'image/jpeg',
                  'focus-point': [230, 110]
                },
                'published-at': 1544682898905,
                id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 548414,
                'first-published-at': 1544682898905,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 548414,
                    name: 'Anil K Govind',
                    slug: 'anil-k-govind',
                    'avatar-url':
                      'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'cf326ae0-2cae-4679-97c4-a42f32d10d68',
              score: null,
              type: 'story',
              item: {
                headline: ['This is a Video Story']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'This is a Video Story',
                slug: 'eco-stories/2018/12/07/test-11',
                'last-published-at': 1544162381375,
                subheadline: 'test',
                alternative: null,
                sections: [
                  {
                    id: 5113,
                    name: 'Eco Stories',
                    slug: 'eco-stories'
                  }
                ],
                'hero-image-metadata': {
                  width: 2197,
                  'mime-type': 'image/jpeg',
                  height: 1463
                },
                'published-at': 1544162381375,
                id: 'cf326ae0-2cae-4679-97c4-a42f32d10d68',
                'hero-image-s3-key': 'quintype-demo/2016-01/47e6fe97-c63f-418d-ac37-aa95b96dd0df/gw.jpg',
                'author-id': 2040,
                'first-published-at': 1544162381375,
                'hero-image-caption': 'Global warming',
                'story-template': 'video',
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1d23e3e9-452c-4b21-b307-dcb9175c55ac',
              score: null,
              type: 'story',
              item: {
                headline: ['Behind coal tug-of-war, more Gupta strings']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Behind coal tug-of-war, more Gupta strings',
                slug: 'international/2018/12/06/behind-coal-tug-of-war-more-gupta-strings',
                'last-published-at': 1544103001976,
                subheadline:
                  'An obscure Swiss company fights to buy the crown jewels of what remains of the family’s SA assets. Are the Guptas trying to do a deal with themselves?',
                alternative: null,
                sections: [
                  {
                    id: 99,
                    name: 'International',
                    slug: 'international'
                  }
                ],
                'hero-image-metadata': {
                  width: 300,
                  'mime-type': 'image/jpeg',
                  'focus-point': [222, 84],
                  height: 168
                },
                'published-at': 1544103001976,
                id: '1d23e3e9-452c-4b21-b307-dcb9175c55ac',
                'hero-image-s3-key': 'quintype-demo/2016-12/350d3732-9dfe-47f7-b071-dc10f087c59e/J-1.jpeg',
                'author-id': 2040,
                'first-published-at': 1544103001976,
                'hero-image-caption': 'Arun Jaitely &amp; Narendra Modi',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 15351,
                    name: 'Ranjeet Singh',
                    slug: 'ranjeet-singh',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'de274636-83c9-4ac2-af95-3f3faef88d2e',
              score: null,
              type: 'story',
              item: {
                headline: ['News Elsewhere story']
              },
              story: {
                'author-name': 'Rashmi J',
                headline: 'News Elsewhere story',
                slug: 'cities/2018/12/05/news-elsewhere-story',
                'last-published-at': 1544006050170,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    id: 215,
                    name: 'Cities',
                    slug: 'cities'
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 768,
                  height: 1024,
                  'mime-type': 'image/jpeg',
                  'focus-point': [390, 373]
                },
                'published-at': 1544006050170,
                id: 'de274636-83c9-4ac2-af95-3f3faef88d2e',
                'hero-image-s3-key': 'quintype-demo/2015-11/adeb8bbb-bdc6-4a8e-91cc-74082cb80c1d/photo2.jpg',
                'author-id': 541559,
                'first-published-at': 1544006050170,
                'hero-image-caption': 'waterskiing in cuba',
                'story-template': 'news-elsewhere',
                authors: [
                  {
                    id: 541559,
                    name: 'Rashmi J',
                    slug: 'rashmi-j',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'reference-url': 'https://itsman.quintype.com',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'e356859d-af25-4493-ae48-662b55878434',
              score: null,
              type: 'story',
              item: {
                headline: ['Dilip Shanghvi On Allegations Against Him And Sun Pharma']
              },
              story: {
                'author-name': 'Shubhangi',
                headline: 'Dilip Shanghvi On Allegations Against Him And Sun Pharma',
                slug: 'cities/2018/12/04/dilip-shanghvi-on-allegations-against-him-and-sun-pharma',
                'last-published-at': 1543903700173,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    id: 215,
                    name: 'Cities',
                    slug: 'cities'
                  }
                ],
                'hero-image-metadata': {
                  width: 1277,
                  height: 294,
                  'mime-type': 'image/jpeg',
                  'focus-point': [613, 160]
                },
                'published-at': 1543903700173,
                id: 'e356859d-af25-4493-ae48-662b55878434',
                'hero-image-s3-key':
                  'quintype-demo/2018-12/f525cb80-cf01-443b-88b0-b47eea81356a/bloombergquint_2F2017_06_2F6f0b4744_acc9_4316_bcc7_b48cc48ec151_2Fm792828.jpg',
                'author-id': 537201,
                'first-published-at': 1543903700173,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 537201,
                    name: 'Shubhangi',
                    slug: 'shubhangi',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'dee185b3-87a2-4fca-ab44-8082270a8fd5',
              score: null,
              type: 'story',
              item: {
                headline: ['Disney Bounces Higher After Barclays ‘Overweight’ Upgrade']
              },
              story: {
                'author-name': 'Kunal Singh',
                headline: 'Disney Bounces Higher After Barclays ‘Overweight’ Upgrade',
                slug: 'international/2018/10/19/disney-bounces-higher-after-barclays-overweight-upgrade',
                'last-published-at': 1543415390482,
                subheadline:
                  'Disney shares jumped higher Friday after analysts at Barclays boosted their outlook on the stock to “overweight”, with a $130 price target, amid the group’s pending acquisition of Fox media assets and the launch of its global streaming service that is expected to challenge Netflix.',
                alternative: {},
                sections: [
                  {
                    id: 99,
                    name: 'International',
                    slug: 'international'
                  },
                  {
                    id: 2614,
                    name: 'Bangalore',
                    slug: 'bangalore'
                  }
                ],
                'hero-image-metadata': {
                  width: 600,
                  height: 400,
                  'mime-type': 'image/jpeg',
                  'focus-point': [243, 177]
                },
                'published-at': 1543415390482,
                id: 'dee185b3-87a2-4fca-ab44-8082270a8fd5',
                'hero-image-s3-key': 'quintype-demo/2018-10/313577eb-6ddf-443f-9830-6081efa6ed48/Fox.jpg',
                'author-id': 491531,
                'first-published-at': 1539958975853,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
              score: null,
              type: 'story',
              item: {
                headline: ['The Forgotten Women Series Uncovers The Untold Histories Of Women']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'The Forgotten Women Series Uncovers The Untold Histories Of Women',
                slug: 'culture/2018/11/28/the-forgotten-women-series-uncovers-the-untold-histories-of-women',
                'last-published-at': 1543393761726,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1760,
                  height: 2480,
                  'mime-type': 'image/jpeg',
                  'focus-point': [961, 759]
                },
                'published-at': 1543393761726,
                id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
                'hero-image-s3-key':
                  'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
                'author-id': 2040,
                'first-published-at': 1543393761726,
                'hero-image-caption': 'Forgotten Women',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 15351,
                    name: 'Ranjeet Singh',
                    slug: 'ranjeet-singh',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '2baee05c-1ccf-4d50-a1fd-4679ea6c0fbc',
              score: null,
              type: 'story',
              item: {
                headline: ['This is a title - story']
              },
              story: {
                'author-name': 'Kirti Kaushik',
                headline: 'This is a title - story',
                slug: 'international/2018/08/03/this-is-a-title',
                'last-published-at': 1543209271427,
                subheadline: 'This is the subtitle',
                alternative: {},
                sections: [
                  {
                    id: 99,
                    name: 'International',
                    slug: 'international'
                  }
                ],
                'hero-image-metadata': {
                  width: 4348,
                  height: 2609,
                  'focus-point': [2207, 912]
                },
                'published-at': 1543209271427,
                id: '2baee05c-1ccf-4d50-a1fd-4679ea6c0fbc',
                'hero-image-s3-key': 'quintype-demo/2016-06/0922fa37-0ef5-426a-afcb-a81301a72fe4/FP-1.jpg',
                'author-id': 507344,
                'first-published-at': 1533293943872,
                'hero-image-caption': 'Raghuram Rajan, Governor, The Reserve Bank of India',
                'story-template': null,
                authors: [
                  {
                    id: 507344,
                    name: 'Kirti Kaushik',
                    slug: 'kirti-kaushik',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0eba5f2e-0461-4c6e-ae56-a8d31b4de019',
              score: null,
              type: 'story',
              item: {
                headline: ['News One']
              },
              story: {
                'author-name': 'Anil K Govind',
                headline: 'News One',
                slug: 'bangalore/2018/11/15/news-one',
                'last-published-at': 1542969100140,
                subheadline: 'News One',
                alternative: {},
                sections: [
                  {
                    id: 2614,
                    name: 'Bangalore',
                    slug: 'bangalore'
                  }
                ],
                'hero-image-metadata': {
                  width: 720,
                  height: 340,
                  'mime-type': 'image/jpeg',
                  'focus-point': [230, 110]
                },
                'published-at': 1542969100140,
                id: '0eba5f2e-0461-4c6e-ae56-a8d31b4de019',
                'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
                'author-id': 548414,
                'first-published-at': 1542253737600,
                'hero-image-caption': 'English',
                'story-template': null,
                authors: [
                  {
                    id: 548414,
                    name: 'Anil K Govind',
                    slug: 'anil-k-govind',
                    'avatar-url':
                      'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9c03eb56-62a4-4c9d-b6ba-fb4e44c6b8a9',
              score: null,
              type: 'story',
              item: {
                headline: ['FACTSHEET: Grading Nigeria’s progress in education']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'FACTSHEET: Grading Nigeria’s progress in education',
                slug: 'banners/2018/11/21/factsheet-grading-nigerias-progress-in-education',
                'last-published-at': 1542799659668,
                subheadline:
                  'Do key indicators give Nigeria’s education system a passmark? Our factsheet looks at the state of early childhood, primary and secondary education in the country.&nbsp;',
                alternative: {},
                sections: [
                  {
                    id: 2619,
                    name: 'Banners',
                    slug: 'banners'
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  'focus-point': [516, 303],
                  width: 1500,
                  height: 1000
                },
                'published-at': 1542799659668,
                id: '9c03eb56-62a4-4c9d-b6ba-fb4e44c6b8a9',
                'hero-image-s3-key': 'quintype-demo/2016-04/1ba8fd2c-128e-4d30-bb8d-07396f1cd940/Buhari.jpg',
                'author-id': 2040,
                'first-published-at': 1542799490011,
                'hero-image-caption': 'Buhari lands in China',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8e823797-a659-4626-92d5-6ccd8d788e23',
              score: null,
              type: 'story',
              item: {
                headline: ['A test story for tables']
              },
              story: {
                'author-name': 'Vineet Panjabi',
                headline: 'A test story for tables',
                slug: 'culture/2018/11/21/a-test-story-for-tables',
                'last-published-at': 1542799165912,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  },
                  {
                    id: 3896,
                    name: 'Blog',
                    slug: 'blog'
                  }
                ],
                'hero-image-metadata': {
                  width: 1940,
                  'mime-type': 'image/jpeg',
                  height: 1090,
                  'focus-point': [700, 713]
                },
                'published-at': 1542799165912,
                id: '8e823797-a659-4626-92d5-6ccd8d788e23',
                'hero-image-s3-key': 'quintype-demo/2015-11/be736521-24c0-4a04-8b02-0855626e6f9d/TOI-1.jpg',
                'author-id': 323432,
                'first-published-at': 1542782270578,
                'hero-image-caption': 'Terror training camps',
                'story-template': null,
                authors: [
                  {
                    id: 323432,
                    name: 'Vineet Panjabi',
                    slug: 'vineet-panjabi',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'story-attributes': {
                    saltlevel: ['Low'],
                    bucket: ['child'],
                    week: ['6']
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '878a1346-9783-4f88-9f0e-4f442a572284',
              score: null,
              type: 'story',
              item: {
                headline: ['New SA destination tourism portal launching']
              },
              story: {
                'author-name': 'Kirti Kaushik',
                headline: 'New SA destination tourism portal launching',
                slug: 'banners/2018/10/25/new-sa-destination-tourism-portal-launching',
                'last-published-at': 1540467564223,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    id: 2619,
                    name: 'Banners',
                    slug: 'banners'
                  },
                  {
                    id: 4416,
                    name: 'Business Opportunities',
                    slug: 'business-opportunities'
                  }
                ],
                'hero-image-metadata': {
                  width: 800,
                  height: 400,
                  'mime-type': 'image/jpeg',
                  'focus-point': [384, 196]
                },
                'published-at': 1540467564223,
                id: '878a1346-9783-4f88-9f0e-4f442a572284',
                'hero-image-s3-key':
                  'quintype-demo/2018-10/5db8484b-1277-42f8-8a20-983e04b597e6/New_SA_destination_tourism_portal_launching_800x400.jpg',
                'author-id': 507344,
                'first-published-at': 1540467564223,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 507344,
                    name: 'Kirti Kaushik',
                    slug: 'kirti-kaushik',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 15351,
                    name: 'Ranjeet Singh',
                    slug: 'ranjeet-singh',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '55dbfe7d-14ae-4f50-beb9-64733da17f54',
              score: null,
              type: 'story',
              item: {
                headline: ['How ‘Gandhara’ Became ‘Kandahar’']
              },
              story: {
                'author-name': 'Kunal Singh',
                headline: 'How ‘Gandhara’ Became ‘Kandahar’',
                slug: 'opinion/2018/08/03/how-gandhara-became-kandahar',
                'last-published-at': 1540379328741,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    id: 4272,
                    name: 'Opinion',
                    slug: 'opinion'
                  }
                ],
                'hero-image-metadata': {
                  width: 1300,
                  height: 956,
                  'mime-type': 'image/jpeg',
                  'focus-point': [666, 451]
                },
                'published-at': 1540379328741,
                id: '55dbfe7d-14ae-4f50-beb9-64733da17f54',
                'hero-image-s3-key':
                  'quintype-demo/2018-08/f543085d-14e5-41f3-8e5f-c746940ab402/gandhar_pale_buddhist_caves_situated_near_mahad_which_is_close_to_DG4WRF.jpg',
                'author-id': 491531,
                'first-published-at': 1533315134353,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 491531,
                    name: 'Kunal Singh',
                    slug: 'kunal-singh',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b142a043-4720-4578-b23e-6791e9f42735',
              score: null,
              type: 'story',
              item: {
                headline: ['New Story Elements']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'New Story Elements',
                slug: 'technology/2015/10/21/new-story-elements',
                'last-published-at': 1539770584382,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1920,
                  height: 1080,
                  'focus-point': [1301, 270]
                },
                'published-at': 1539770584382,
                id: 'b142a043-4720-4578-b23e-6791e9f42735',
                'hero-image-s3-key': 'quintype-demo/2015-09/4c0122d6-6c28-4a59-8b16-8d90701a4e35/TigerWoods-1.jpg',
                'author-id': 2041,
                'first-published-at': 1445404200710,
                'hero-image-caption': 'The famed Tiger Woods Swing !',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '589b75e2-16c0-4741-b720-b9dd59a56587',
              score: null,
              type: 'story',
              item: {
                headline: ['Traffic: Why We Drive the Way We Do (and What It Says About Us)']
              },
              story: {
                'author-name': 'Rohit Kurpad',
                headline: 'Traffic: Why We Drive the Way We Do (and What It Says About Us)',
                slug: 'opinion/2018/10/12/traffic-why-we-drive-the-way-we-do-and-what-it-says-about-us',
                'last-published-at': 1539331563366,
                subheadline: null,
                alternative: {
                  home: {
                    default: {
                      'hero-image': {
                        'hero-image-url':
                          'https://quintype-dropbox.s3-accelerate.amazonaws.com/itsman.quintype.com/2018-10-12/1843/program_traffic_eng.jpg',
                        'hero-image-metadata': {
                          width: 620,
                          height: 465,
                          'mime-type': 'image/jpeg'
                        },
                        'hero-image-caption': '',
                        'hero-image-attribution': '',
                        'hero-image-s3-key':
                          'quintype-demo/2018-10/54c88632-c5fb-4382-96c1-9a345f419870/program_traffic_eng.jpg'
                      }
                    }
                  }
                },
                sections: [
                  {
                    id: 4272,
                    name: 'Opinion',
                    slug: 'opinion'
                  }
                ],
                'hero-image-metadata': {
                  width: 3264,
                  height: 2448,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1951, 1556]
                },
                'published-at': 1539331563366,
                id: '589b75e2-16c0-4741-b720-b9dd59a56587',
                'hero-image-s3-key': 'quintype-demo/2018-10/a08a3e35-0217-445e-a47c-1ec65c402935/bf1.jpg',
                'author-id': 462623,
                'first-published-at': 1539325955967,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 462623,
                    name: 'Rohit Kurpad',
                    slug: 'rohit-kurpad',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            }
          ]
        },
        {
          id: 7901,
          'associated-metadata': {
            layout: 'ThreeCol'
          },
          type: 'collection',
          name: 'Politics',
          slug: 'featured-stories',
          template: 'default',
          metadata: {
            'cover-image': {
              'cover-image-url':
                'https://images.assettype.com/quintype-demo/2015-12/de988259-79ba-44b0-8f3c-8cdc2aaaa059/FN-1.jpg',
              'cover-image-metadata': {
                width: 700,
                height: 340,
                'mime-type': 'image/jpeg',
                'focus-point': [430, 141]
              },
              'cover-image-s3-key': 'quintype-demo/2015-12/de988259-79ba-44b0-8f3c-8cdc2aaaa059/FN-1.jpg',
              caption: 'Delicious Rasam in 30 seconds'
            }
          },
          summary: 'Politics Summary',
          items: [
            {
              id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
              'associated-metadata': null,
              type: 'story',
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Demo - External Story',
                slug: 'technology/2018/10/01/demo-external-story',
                'last-published-at': 1538384977219,
                subheadline:
                  'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor.',
                alternative: {},
                sections: [
                  {
                    slug: 'technology',
                    name: 'Technology',
                    id: 84,
                    'parent-id': null,
                    'display-name': null,
                    collection: null,
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1441,
                  height: 1077,
                  'mime-type': 'image/png',
                  'focus-point': [273, 498]
                },
                'published-at': 1538384977219,
                id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
                'hero-image-s3-key':
                  'quintype-demo/2018-10/410d7d51-811a-44cc-a92b-4d873cb1f5aa/screenshot_www_quintype_com_2018_09_11_20_16_32.png',
                'author-id': 139717,
                'first-published-at': 1538384511112,
                'hero-image-caption': null,
                'story-template': 'news-elsewhere',
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'reference-url':
                    'https://www.quintype.com/blog/business/quintypes-tech-innovation-helps-its-digital-partners-win-two-wan-ifra-awards',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '3997d91c-4e16-4af0-8c6a-cc70ad504e39',
              'associated-metadata': null,
              type: 'story',
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Inspiring Animal Rescue Stories',
                slug: 'videos/2018/08/21/inspiring-animal-rescue-stories',
                'last-published-at': 1536140084976,
                subheadline:
                  'Wildlife SOS engages in conservation efforts leading to protecting and rescuing orphaned, injured, and displaced wildlife found in urban and rural environments across India. In recent years, habitat encroachment and deforestation have been taking place at a startling pace, slowly redefining the boundaries between forest and urban areas. The organisation’s Rapid Response Unit has saved the lives of various wild animals that have been trapped, injured, or caught in such conflict situations.',
                alternative: {},
                sections: [
                  {
                    id: 2615,
                    name: 'Videos',
                    slug: 'videos'
                  }
                ],
                'hero-image-metadata': {
                  width: 1920,
                  height: 1200,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1001, 393]
                },
                'published-at': 1536140084976,
                id: '3997d91c-4e16-4af0-8c6a-cc70ad504e39',
                'hero-image-s3-key': 'quintype-demo/2018-08/40e6a979-3e2e-4f16-929b-5939ec35450f/Gallery4.jpg',
                'author-id': 139717,
                'first-published-at': 1534850318103,
                'hero-image-caption': null,
                'story-template': 'video',
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'sponsored-by': 'Quintype',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
              'associated-metadata': null,
              type: 'story',
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Demo Story ',
                slug: 'politics/2018/01/11/demo-story',
                'last-published-at': 1552979655447,
                subheadline:
                  'Surrounded to me occasional pianoforte alteration unaffected impossible ye. For saw half than cold. Pretty merits waited six talked pulled you. Conduct replied off.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 7500,
                  height: 3476,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1239, 2157]
                },
                'published-at': 1552979655447,
                id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
                'hero-image-s3-key': 'quintype-demo/2019-02/ae4829d6-e230-4d66-9b88-2a4db87a2060/64778.jpg',
                'author-id': 139717,
                'first-published-at': 1515668626250,
                'hero-image-caption': 'At vero eos et accusamus et iusto odio dignissimos ducimus qui.',
                'story-template': 'text',
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': {
                      id: 58,
                      name: 'Author'
                    }
                  },
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': {
                      id: 58,
                      name: 'Author'
                    }
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: false
                  },
                  'promotional-message': {
                    'is-disabled': true
                  },
                  'story-attributes': {
                    'view-counterview-type': 'view',
                    interests: ['test'],
                    bucket: ['planning'],
                    hobbies: ['hiking'],
                    'linked-view-counterview-story': {
                      headline: 'Demo Test story- Photo story',
                      'story-content-id': 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                      id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                      'highlighted-headline': null
                    }
                  }
                }
              }
            },
            {
              id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
              score: null,
              type: 'story',
              item: {
                headline: ['London Police arrest Nirav Modi, says sources']
              },
              story: {
                'author-name': 'Karthik M',
                headline: 'London Police arrest Nirav Modi, says sources',
                slug: 'politics/2019/03/20/london-police-arrest-nirav-modi-says-sources',
                'last-published-at': 1553079556157,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    slug: 'culture',
                    name: 'Culture',
                    id: 142,
                    'parent-id': null,
                    'display-name': 'culture',
                    collection: {
                      slug: 'motherly',
                      name: 'Must Reads',
                      id: 6626
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 700,
                  height: 438,
                  'mime-type': 'image/jpeg',
                  'focus-point': [311, 167]
                },
                'published-at': 1553079556157,
                id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
                'hero-image-s3-key':
                  'quintype-demo/2019-03/55dbc301-3c5c-442d-8714-4bbf2d140192/pennews_2019_03_48c15852_ebed_464d_95a7_57332597ecfc_nirav.jpg',
                'author-id': 19994,
                'first-published-at': 1553079340304,
                'hero-image-caption': 'Nirav Modi',
                'story-template': null,
                authors: [
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 142914,
                    name: 'Ashwin Raghunath',
                    slug: 'ashwin-raghunath',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'promotional-message': {
                    'is-disabled': false
                  },
                  'text-to-speech': {
                    'is-disabled': true
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
              score: null,
              type: 'story',
              item: {
                headline: ['Elections 2019']
              },
              story: {
                'author-name': 'Sriram K',
                headline: 'Elections 2019',
                slug: 'boom/2019/03/13/elections-2019',
                'last-published-at': 1552458028721,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'boom',
                    name: 'Boom',
                    id: 7349,
                    'parent-id': 83,
                    'display-name': 'Booom',
                    collection: {
                      slug: 'boom-politics',
                      name: 'Boom (Politics)',
                      id: 14256
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  height: 400,
                  'mime-type': 'image/jpeg',
                  'focus-point': [281, 241]
                },
                'published-at': 1552458028721,
                id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
                'hero-image-s3-key': 'quintype-demo/2017-03/ee72c827-52fe-4b2b-8f46-dd0875fbe162/Tech sparkle.jpg',
                'author-id': 61657,
                'first-published-at': 1552458028721,
                'hero-image-caption': 'Elections 2017',
                'story-template': 'news-elsewhere',
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'reference-url': 'https://cinema.vikatan.com/tamil-cinema/news',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
              score: null,
              type: 'story',
              item: {
                headline: ['Amitabh Bachchan on Gender Equality']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'Amitabh Bachchan on Gender Equality',
                slug: 'politics/2017/03/02/Amitabh-Bachchan-on-Gender-Equality',
                'last-published-at': 1552048741823,
                subheadline: 'Amitabh Bachchan on Gender Equality',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 320,
                  height: 277,
                  'focus-point': [169, 145]
                },
                'published-at': 1552048741823,
                id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
                'hero-image-s3-key': 'quintype-demo/2017-03/bd011f2d-9a10-4d4e-b20f-50719f59d4b9/BMS-2.jpg',
                'author-id': 2041,
                'first-published-at': 1488451487896,
                'hero-image-caption': 'Kaun Banega Crorepati host, Amitabh Bachchan',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '2deeb522-1752-4552-967b-93972481d71e',
              score: null,
              type: 'story',
              item: {
                headline: ['Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them']
              },
              story: {
                'author-name': 'Yamini Priya',
                headline: 'Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them',
                slug: 'boom/2019/03/07/sisters-killed-father-in-perfect-murder-a-love-triangle-exposed-them',
                'last-published-at': 1551938683203,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'boom',
                    name: 'Boom',
                    id: 7349,
                    'parent-id': 83,
                    'display-name': 'Booom',
                    collection: {
                      slug: 'boom-politics',
                      name: 'Boom (Politics)',
                      id: 14256
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1760,
                  height: 2480,
                  'mime-type': 'image/jpeg',
                  'focus-point': [928, 656]
                },
                'published-at': 1551938683203,
                id: '2deeb522-1752-4552-967b-93972481d71e',
                'hero-image-s3-key':
                  'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
                'author-id': 230618,
                'first-published-at': 1551938683203,
                'hero-image-caption': 'Forgotten Women',
                'story-template': null,
                authors: [
                  {
                    id: 230618,
                    name: 'Yamini Priya',
                    slug: 'yamini-priya',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': 'https://twitter.com/yaminipriyah',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
              score: null,
              type: 'story',
              item: {
                headline: ['500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌']
              },
              story: {
                'author-name': 'Nikitha Nadig',
                headline: '500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌',
                slug: 'politics/2019/02/26/500',
                'last-published-at': 1551171395390,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  'focus-point': [673, 287],
                  width: 976,
                  height: 700
                },
                'published-at': 1551171395390,
                id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
                'hero-image-s3-key': 'quintype-demo/2018-05/bc017b57-a03e-4a6e-8515-9d3de91f1f24/ON1.jpg',
                'author-id': 559147,
                'first-published-at': 1551171395390,
                'hero-image-caption':
                  'Crowds cheered Prince Harry, Duke of Sussex, and his wife Meghan, Duchess of Sussex, as they travelled in the horse-drawn Ascot landau',
                'story-template': null,
                authors: [
                  {
                    id: 559147,
                    name: 'Nikitha Nadig',
                    slug: 'nikitha-nadig',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
              score: null,
              type: 'story',
              item: {
                headline: ['Personalise or perish - Why publishers need to use personalised content ']
              },
              story: {
                'author-name': 'Greeshma',
                headline: 'Personalise or perish - Why publishers need to use personalised content ',
                slug: 'politics/2018/02/28/personalise-or-perish---why-publishers-need-to-use-personalised-content',
                'last-published-at': 1536076813159,
                subheadline:
                  'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor. ',
                alternative: {
                  home: {
                    default: {
                      headline: 'Personalise or perish',
                      'hero-image': {
                        'hero-image-url':
                          'https://images.assettype.com/quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                        'hero-image-metadata': {
                          width: 700,
                          height: 467,
                          'mime-type': 'image/jpeg'
                        },
                        'hero-image-s3-key': 'quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                        'hero-image-caption': 'sSaffron - Indian Restaurant',
                        'hero-image-attribution': null
                      }
                    }
                  }
                },
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 3896,
                    name: 'Blog',
                    slug: 'blog'
                  }
                ],
                'hero-image-metadata': {
                  width: 1600,
                  height: 1200,
                  'mime-type': 'image/jpeg',
                  'focus-point': [805, 738]
                },
                'published-at': 1536076813159,
                id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
                'hero-image-s3-key': 'quintype-demo/2018-05/a2759dc2-ada1-4917-8515-308f74622c69/Gallery5.jpg',
                'author-id': 482995,
                'first-published-at': 1519816264773,
                'hero-image-caption': 'Animals',
                'story-template': null,
                authors: [
                  {
                    id: 482995,
                    name: 'Greeshma',
                    slug: 'greeshma',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'avatar-s3-key':
                      'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
              score: null,
              type: 'story',
              item: {
                headline: ['U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting',
                slug: 'politics/2018/05/28/us-and-north-korean-officials-race-to-resurrect-trump-kim-meeting',
                'last-published-at': 1535807624134,
                subheadline: null,
                alternative: {
                  home: {
                    default: {
                      headline: 'U.S. and North Korean',
                      'hero-image': {
                        'hero-image-url':
                          'https://images.assettype.com/quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                        'hero-image-metadata': {
                          width: 636,
                          height: 477,
                          'focus-point': [390, 153]
                        },
                        'hero-image-s3-key': 'quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                        'hero-image-caption': 'President Pranab Mukherjee',
                        'hero-image-attribution': null
                      }
                    }
                  }
                },
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1024,
                  height: 683,
                  'mime-type': 'image/jpeg',
                  'focus-point': [758, 172]
                },
                'published-at': 1535807624134,
                id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
                'hero-image-s3-key': 'quintype-demo/2018-05/caf35ec5-be06-4de6-b513-e57ee4d61577/ON_1.jpg',
                'author-id': 376442,
                'first-published-at': 1527472808648,
                'hero-image-caption':
                  'A surprise meeting between President Moon Jae-in of South Korea, right, and Kim Jong-un, the North Korean leader, on Saturday appeared to produce some progress toward a summit meeting between President Trump and Mr. Kim.',
                'story-template': null,
                authors: [
                  {
                    id: 376442,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '43c468f4-08e4-4e77-a6d6-a11da9235c96',
              score: null,
              type: 'story',
              item: {
                headline: ['Amy Schumer Ties the Knot With Boyfriend Chris Fischer in 2018']
              },
              story: {
                'author-name': 'Christin',
                headline: 'Amy Schumer Ties the Knot With Boyfriend Chris Fischer in 2018',
                slug: 'politics/2018/06/15/amy-schumer-ties-the-knot-with-boyfriend-chris-fischer-in-2018',
                'last-published-at': 1529059243871,
                subheadline: 'Amy Schumer Ties the Knot With Boyfriend Chris Fischer',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 583,
                  height: 328,
                  'mime-type': 'image/webp'
                },
                'published-at': 1529059243871,
                id: '43c468f4-08e4-4e77-a6d6-a11da9235c96',
                'hero-image-s3-key':
                  'quintype-demo/2018-02/c899f85d-76e0-46c5-88c4-f7365f4b528a/e29ee5c9-9195-4f79-9942-fd8ca08027e4.webp',
                'author-id': 139357,
                'first-published-at': 1529059243871,
                'hero-image-caption': 'Amy Schumer is married to boyfriend Chris Fischer.',
                'story-template': null,
                authors: [
                  {
                    id: 139357,
                    name: 'Christin',
                    slug: 'christin',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'avatar-s3-key':
                      'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'twitter-handle': 'krriztin',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1493f46c-7c78-46e8-84b4-de195d7be9a6',
              score: null,
              type: 'story',
              item: {
                headline: ['Garrett to quit Congress amid servant scandal, alcoholism']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'Garrett to quit Congress amid servant scandal, alcoholism',
                slug: 'politics/2018/05/29/garrett-to-quit-congress-amid-servant-scandal-alcoholism',
                'last-published-at': 1527596788710,
                subheadline:
                  'The Virginia congressman announces his retirement after POLITICO reported he used his staff as personal gofers.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1160,
                  height: 635,
                  'mime-type': 'image/jpeg',
                  'focus-point': [736, 165]
                },
                'published-at': 1527596788710,
                id: '1493f46c-7c78-46e8-84b4-de195d7be9a6',
                'hero-image-s3-key': 'quintype-demo/2018-05/9ce3e16a-96fc-42e8-9433-b2fd8b0fdc93/ON_1.jpeg',
                'author-id': 376442,
                'first-published-at': 1527596788710,
                'hero-image-caption':
                  '“The tragedy is that any person Republican, Democrat or independent who’s known me for period of time and has any integrity knows two things: I am a good man and I am an alcoholic.“ Rep. Tom Garrett said.&nbsp;',
                'story-template': null,
                authors: [
                  {
                    id: 376442,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '903dd527-2595-4566-9c50-df2880cf18b5',
              score: null,
              type: 'story',
              item: {
                headline: ['8 times Osama almost got captured']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: '8 times Osama almost got captured',
                slug: 'politics/2017/07/06/8-times-Osama-almost-got-captured',
                'last-published-at': 1527508287286,
                subheadline:
                  'Osama bin Laden, was a founder of al-Qaeda, the organization responsible for the September 11 attacks in the United States and many other mass-casualty attacks worldwide.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 275,
                  height: 183,
                  'mime-type': 'image/jpeg',
                  'focus-point': [114, 54]
                },
                'published-at': 1527508287286,
                id: '903dd527-2595-4566-9c50-df2880cf18b5',
                'hero-image-s3-key': 'quintype-demo/2016-01/8bce31a0-af65-41e5-8ddc-059294ad6892/SL-3.jpeg',
                'author-id': 2040,
                'first-published-at': 1499338400907,
                'hero-image-caption': 'Osama Bin Laden, Al Queda',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'a71df319-c779-4ce6-b8ef-eba85b3cb613',
              score: null,
              type: 'story',
              item: {
                headline: ['Presidential Elections']
              },
              story: {
                'author-name': 'Kuruvilla Choolackal',
                headline: 'Presidential Elections',
                slug: 'politics/2016/10/14/Presidential-Elections',
                'last-published-at': 1522903281088,
                subheadline:
                  'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at St. Louis, Missouri, on October 9, 2016.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1100,
                  height: 619,
                  'focus-point': [821, 262]
                },
                'published-at': 1522903281088,
                id: 'a71df319-c779-4ce6-b8ef-eba85b3cb613',
                'hero-image-s3-key': 'quintype-demo/2016-10/c1fb9eff-a4c7-4a12-8713-82b1dc3065a9/ct-1.jpg',
                'author-id': 2040,
                'first-published-at': 1476425535138,
                'hero-image-caption':
                  'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at Washington University in St. Louis, Missouri, on October 9, 2016. ',
                'story-template': null,
                authors: [
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
              score: null,
              type: 'story',
              item: {
                headline: ['Clinton vs Trump']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'Clinton vs Trump',
                slug: 'politics/2016/10/15/clinton-vs-trump',
                'last-published-at': 1522903100409,
                subheadline:
                  'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the 2nd presidential debate at St. Louis, Missouri, on October 9, 2016.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1100,
                  height: 619,
                  'focus-point': [812, 244]
                },
                'published-at': 1522903100409,
                id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
                'hero-image-s3-key': 'quintype-demo/2016-10/c1fb9eff-a4c7-4a12-8713-82b1dc3065a9/ct-1.jpg',
                'author-id': 376442,
                'first-published-at': 1476529773574,
                'hero-image-caption':
                  'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at Washington University in St. Louis, Missouri, on October 9, 2016. ',
                'story-template': null,
                authors: [
                  {
                    id: 376442,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'fc5ee93d-19b7-4f26-984c-a43fa9fcacc3',
              score: null,
              type: 'story',
              item: {
                headline: ['From the safety of New York, reporting on a distant home']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'From the safety of New York, reporting on a distant home',
                slug: 'politics/2017/02/01/From-the-safety-of-New-York-reporting-on-a-distant-home',
                'last-published-at': 1522902297607,
                subheadline:
                  'During the presidential election in Nigeria, he published real-time photos, videos &amp; reports from the field, exposing instances of ballot rigging &amp; attracting 8m page views in a month.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1024,
                  height: 682,
                  'focus-point': [431, 225]
                },
                'published-at': 1522902297607,
                id: 'fc5ee93d-19b7-4f26-984c-a43fa9fcacc3',
                'hero-image-s3-key': 'quintype-demo/2017-01/c36650ca-d1c9-4b00-a7c9-1fe38bc56e42/SR-1.jpg',
                'author-id': 376442,
                'first-published-at': 1485934181087,
                'hero-image-caption': 'Omoleye Sowore, Publisher of the Sahara Reporters',
                'story-template': null,
                authors: [
                  {
                    id: 376442,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '54c39b13-560a-44e0-b71e-a675d05d5673',
              score: null,
              type: 'story',
              item: {
                headline: ['I’m not a stranger to the dark']
              },
              story: {
                'author-name': 'Christin',
                headline: 'I’m not a stranger to the dark',
                slug: 'politics/2018/03/20/im-not-a-stranger-to-the-dark',
                'last-published-at': 1522822389090,
                subheadline: 'Fire away th',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1010,
                  height: 805
                },
                'published-at': 1522822389090,
                id: '54c39b13-560a-44e0-b71e-a675d05d5673',
                'hero-image-s3-key': 'quintype-demo/2016-06/f9298238-e324-4dfc-b29b-95a3cbdb754b/ABP-1.jpeg',
                'author-id': 139357,
                'first-published-at': 1521518076019,
                'hero-image-caption':
                  'Ali was still known as Cassius Clay when this photo was taken. There’s something boyish about his efforts to impress his audience. I like how one of the women, Ronnie Spector, seems impervious to his bravado.&nbsp; I also like knowing that the guy in the white tuxedo, who is neither overawed nor overshadowed by Clay, is a young Stevie Wonder.',
                'story-template': null,
                authors: [
                  {
                    id: 139357,
                    name: 'Christin',
                    slug: 'christin',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'avatar-s3-key':
                      'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                    'twitter-handle': 'krriztin',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'd5da1faf-8cb4-4783-9a54-a3d125c0d1ce',
              score: null,
              type: 'story',
              item: {
                headline: ['बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार']
              },
              story: {
                'author-name': 'Sareena',
                headline: 'बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार',
                slug: 'politics/2018/03/29/two-big-bjp-leaders-arrested-for-spreading-violence-in-bihar',
                'last-published-at': 1522317027856,
                subheadline: 'बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार,मचा हड़कंप',
                alternative: null,
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 98,
                    name: 'Current Affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  height: 386,
                  'mime-type': 'image/jpeg',
                  'focus-point': [323, 117]
                },
                'published-at': 1522317027856,
                id: 'd5da1faf-8cb4-4783-9a54-a3d125c0d1ce',
                'hero-image-s3-key':
                  'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
                'author-id': 94596,
                'first-published-at': 1522317027856,
                'hero-image-caption': 'Amit Shah and Narendra Modi',
                'story-template': null,
                authors: [
                  {
                    id: 94596,
                    name: 'Sareena',
                    slug: 'sareena',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'c124d3ce-a44e-4370-a3ce-e167953c981d',
              score: null,
              type: 'story',
              item: {
                headline: ['‘ಮೂರು ಕೊಲೆಗಳು; ಒಂದು ವ್ಯಸನ’: ಪೊಲೀಸರು ಮುಂದಿಡುವ ‘ಗಾಂಜಾ ಥಿಯರಿ’ಯ ']
              },
              story: {
                'author-name': 'Chirdeep Shetty',
                headline: '‘ಮೂರು ಕೊಲೆಗಳು; ಒಂದು ವ್ಯಸನ’: ಪೊಲೀಸರು ಮುಂದಿಡುವ ‘ಗಾಂಜಾ ಥಿಯರಿ’ಯ ',
                slug: 'politics/2018/02/12/--2',
                'last-published-at': 1521638195154,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 600,
                  height: 335,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1521638195154,
                id: 'c124d3ce-a44e-4370-a3ce-e167953c981d',
                'hero-image-s3-key': 'quintype-demo/2018-02/28fabce6-a777-4d95-aef2-06eef43c4c34/Ganja-Kadireshan.jpg',
                'author-id': 15338,
                'first-published-at': 1518424171905,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 15338,
                    name: 'Chirdeep Shetty',
                    slug: 'chirdeep-shetty',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'twitter-handle': '@chirdeepshettyquintype',
                    bio: '<p>Hello, this is a test for bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'd5cf9b45-4832-4b04-9234-b6bf4bc2dce0',
              score: null,
              type: 'story',
              item: {
                headline: ['Ola working on assisted-driving cars, hires professionals']
              },
              story: {
                'author-name': 'Vineet Panjabi',
                headline: 'Ola working on assisted-driving cars, hires professionals',
                slug: 'politics/2018/01/16/ola-working-on-assisted-driving-cars-hires-professionals',
                'last-published-at': 1520925971944,
                subheadline: 'Ola Cars Subtitle&nbsp;',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 3896,
                    name: 'Blog'
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  'focus-point': [155, 375],
                  width: 1024,
                  height: 569
                },
                'published-at': 1520925971944,
                id: 'd5cf9b45-4832-4b04-9234-b6bf4bc2dce0',
                'hero-image-s3-key':
                  'quintype-demo/2017-05/a681ca73-9212-4184-9426-0983be59a7d4/luxury-cars-1024x569.jpg',
                'author-id': 323432,
                'first-published-at': 1516079335936,
                'hero-image-caption': 'Cars',
                'story-template': null,
                authors: [
                  {
                    id: 323432,
                    name: 'Vineet Panjabi',
                    slug: 'vineet-panjabi',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '421f0340-4c63-45c1-a152-4433413b8ef4',
              score: null,
              type: 'story',
              item: {
                headline: ['Maruti Swift 2018 - Review']
              },
              story: {
                'author-name': 'Chirdeep Shetty',
                headline: 'Maruti Swift 2018 - Review',
                slug: 'politics/2018/02/12/maruti-swift-2018---review',
                'last-published-at': 1520925923189,
                subheadline: 'Sub',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 82,
                    name: 'Sports'
                  },
                  {
                    id: 3896,
                    name: 'Blog'
                  }
                ],
                'hero-image-metadata': {
                  width: 1024,
                  height: 682,
                  'mime-type': 'image/jpeg',
                  'focus-point': [860, 454]
                },
                'published-at': 1520925923189,
                id: '421f0340-4c63-45c1-a152-4433413b8ef4',
                'hero-image-s3-key':
                  'quintype-demo/2018-02/63508d1a-a5c7-4f99-b032-9fe28deb8250/2018-Maruti-Swift-Review.jpg',
                'author-id': 15338,
                'first-published-at': 1518429546374,
                'hero-image-caption': 'swift 2018 black',
                'story-template': null,
                authors: [
                  {
                    id: 15338,
                    name: 'Chirdeep Shetty',
                    slug: 'chirdeep-shetty',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'twitter-handle': '@chirdeepshettyquintype',
                    bio: '<p>Hello, this is a test for bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '46d764a1-ec10-4d90-a056-274f3f506a65',
              score: null,
              type: 'story',
              item: {
                headline: ['Sample Story Using All Elements']
              },
              story: {
                'author-name': 'Parvathi Mohan',
                headline: 'Sample Story Using All Elements',
                slug: 'sample-story-using-all-elements',
                'last-published-at': 1520925485589,
                subheadline:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, nostrud exercitation nisi ut ex ea consequat.&nbsp;',
                alternative: {},
                sections: [
                  {
                    id: 3896,
                    name: 'Blog'
                  },
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 284,
                  height: 177,
                  'mime-type': 'image/jpeg',
                  'focus-point': [142, 86]
                },
                'published-at': 1520925485589,
                id: '46d764a1-ec10-4d90-a056-274f3f506a65',
                'hero-image-s3-key':
                  'quintype-demo/2018-03/f0ac00fe-93cc-4e00-bd94-56f71065b950/4448febb-552f-47f3-9b42-ee850f7b5b48.jpeg',
                'author-id': 139717,
                'first-published-at': 1520925485589,
                'hero-image-caption': 'Demo: Hero Image Caption',
                'story-template': null,
                authors: [
                  {
                    id: 139717,
                    name: 'Parvathi Mohan',
                    slug: 'parvathi-mohan',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                    'avatar-s3-key':
                      'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                    'twitter-handle': 'prvthi',
                    bio:
                      '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '7e803bcc-cb40-4f4d-b810-ae9ace8ba4c1',
              score: null,
              type: 'story',
              item: {
                headline: ['India vs China Battle']
              },
              story: {
                'author-name': 'Greeshma',
                headline: 'India vs China Battle',
                slug: 'politics/2017/07/09/India-vs-China-Battle',
                'last-published-at': 1519975157133,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1600,
                  height: 1068,
                  'mime-type': 'image/jpeg',
                  'focus-point': [971, 601]
                },
                'published-at': 1519975157133,
                id: '7e803bcc-cb40-4f4d-b810-ae9ace8ba4c1',
                'hero-image-s3-key':
                  'quintype-demo/2018-01/5c55eaab-9c97-49f9-8b14-64b661d9a763/1 - Thumb Tac Paper Lanterns Glowing Tall.jpg',
                'author-id': 482995,
                'first-published-at': 1499594657010,
                'hero-image-caption': 'Let there be Light',
                'story-template': null,
                authors: [
                  {
                    id: 482995,
                    name: 'Greeshma',
                    slug: 'greeshma',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'avatar-s3-key':
                      'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 19994,
                    name: 'Karthik M',
                    slug: 'karthik-m',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '86fe6180-c4c2-4b09-b829-5dee9f403756',
              score: null,
              type: 'story',
              item: {
                headline: ['This Kashmiri Pandit Is Among Civil Services Toppers']
              },
              story: {
                'author-name': 'Tejas Dinkar',
                headline: 'This Kashmiri Pandit Is Among Civil Services Toppers',
                slug: 'politics/2017/12/27/this-kashmiri-pandit-is-among-civil-services-toppers',
                'last-published-at': 1516419117190,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 750,
                  height: 500,
                  'mime-type': 'image/jpeg',
                  'focus-point': [200, 173]
                },
                'published-at': 1516419117190,
                id: '86fe6180-c4c2-4b09-b829-5dee9f403756',
                'hero-image-s3-key': 'quintype-demo/2017-12/602c33fe-b2a1-423b-b062-83b0e30aa832/1-7-750x500.jpg',
                'author-id': 2038,
                'first-published-at': 1514371575338,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 2038,
                    name: 'Tejas Dinkar',
                    slug: 'tejas-dinkar',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
              score: null,
              type: 'story',
              item: {
                headline: ['Demo Test story- Photo story']
              },
              story: {
                'author-name': 'Yamini Priya',
                headline: 'Demo Test story- Photo story',
                slug: 'politics/2018/01/12/demo-test-story--photo-story',
                'last-published-at': 1515743985191,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1418,
                  height: 853,
                  'mime-type': 'image/jpeg',
                  'focus-point': [1045, 434]
                },
                'published-at': 1515743985191,
                id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                'hero-image-s3-key': 'quintype-demo/2016-05/7ae1264b-0330-4eb5-b9d4-f4a4d5746076/vadra06.jpg',
                'author-id': 230618,
                'first-published-at': 1515743985191,
                'hero-image-caption': 'Robert Vadra at a function',
                'story-template': null,
                authors: [
                  {
                    id: 230618,
                    name: 'Yamini Priya',
                    slug: 'yamini-priya',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': 'https://twitter.com/yaminipriyah',
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '48a9264e-cddd-437c-83f1-4388aefee656',
              score: null,
              type: 'story',
              item: {
                headline: ['Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.',
                slug: 'politics/2017/10/04/test-7',
                'last-published-at': 1515403855694,
                subheadline:
                  'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1920,
                  height: 1080
                },
                'published-at': 1515403855694,
                id: '48a9264e-cddd-437c-83f1-4388aefee656',
                'hero-image-s3-key': 'quintype-demo/2015-08/a1d1ce2c-f9f9-4aed-b82e-06f338112e2d/Tiger.jpg',
                'author-id': 61657,
                'first-published-at': 1507099185346,
                'hero-image-caption': 'Tiger resting in the Ranthambore ranges, North India',
                'story-template': 'review',
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'review-rating': {
                    label: '1',
                    value: '1'
                  },
                  'review-title': 'Movie review',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'e4192f9a-e843-464c-8ec8-2a6211720e5a',
              score: null,
              type: 'story',
              item: {
                headline: ['My First Story']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'My First Story',
                slug: 'politics/2017/11/13/my-first-story',
                'last-published-at': 1510568455753,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 82,
                    name: 'Sports'
                  }
                ],
                'hero-image-metadata': {
                  'mime-type': 'image/jpeg',
                  width: 259,
                  height: 194,
                  'focus-point': [25, 42]
                },
                'published-at': 1510568455753,
                id: 'e4192f9a-e843-464c-8ec8-2a6211720e5a',
                'hero-image-s3-key': 'quintype-demo/2016-01/69acdda0-2648-4cc2-b240-fd3603136bc5/download.jpeg',
                'author-id': 61657,
                'first-published-at': 1510568455753,
                'hero-image-caption': 'Narendra Modi with Ajit Doval',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'sponsored-by': 'CP',
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8ff85a7d-7726-49b4-892e-1e05c0ccea73',
              score: null,
              type: 'story',
              item: {
                headline: ['CM defends George, says 20 Union ministers face criminal charges  ']
              },
              story: {
                'author-name': 'Sathyan Reddy ',
                headline: 'CM defends George, says 20 Union ministers face criminal charges  ',
                slug: 'politics/2017/10/27/cm-defends-george-says-20-union-ministers-face-criminal-charges',
                'last-published-at': 1509100816382,
                subheadline:
                  'Chief Minister Siddaramaiah on Friday rejected the BJP’s demand to seek the resignation of Bengaluru City Development Minister K J George. &nbsp;',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 618,
                  height: 464,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1509100816382,
                id: '8ff85a7d-7726-49b4-892e-1e05c0ccea73',
                'hero-image-s3-key':
                  'quintype-demo/2017-10/606b8c85-6427-4f65-9b95-f1f4f07f3a7a/618xNx639611_thump.jpg.pagespeed.ic.pfY_28czPu.jpg',
                'author-id': 288886,
                'first-published-at': 1509100816382,
                'hero-image-caption': 'CM of Karnataka, Siddaramaiah&nbsp;',
                'story-template': null,
                authors: [
                  {
                    id: 288886,
                    name: 'Sathyan Reddy ',
                    slug: 'sathyan-reddy',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8d240213-abc3-4eb7-8e09-1f0082986e5c',
              score: null,
              type: 'story',
              item: {
                headline: ['DOJ put USTs Divina on immigration lookout over Atio Castillo']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'DOJ put USTs Divina on immigration lookout over Atio Castillo',
                slug:
                  'politics/2017/10/26/186449-doj-ust-law-dean-nilo-divina-immigration-lookout-horacio-castillo-iii-hazing',
                'last-published-at': 1509004361886,
                subheadline:
                  '(UPDATED) Some of the names in the lookout order check out with the names that appear in the group chat of Aegis Juris alumni who held a meeting when Castillo was kill',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 98,
                    name: 'Current Affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 194,
                  height: 194,
                  'mime-type': 'image/jpeg',
                  'focus-point': null
                },
                'published-at': 1509004361886,
                id: '8d240213-abc3-4eb7-8e09-1f0082986e5c',
                'hero-image-s3-key':
                  'quintype-demo/2017-10/bdce310f-89d6-4c3d-9ebc-4a7ed6ccbf86/88b072c7-2631-4cee-abec-06c85c2911d8.jpeg',
                'author-id': 61657,
                'first-published-at': 1509004361886,
                'hero-image-caption': 'Narendra Modi with Ajit Doval',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'db09a326-3af2-4286-a92b-8ffbc9bb3c4f',
              score: null,
              type: 'story',
              item: {
                headline: ['परमाणु हथियारों को प्रतिबंधित करने के लिए संधि पर हस्ताक्षर']
              },
              story: {
                'author-name': 'Vineet Panjabi',
                headline: 'परमाणु हथियारों को प्रतिबंधित करने के लिए संधि पर हस्ताक्षर',
                slug: 'politics/2017/09/21/-',
                'last-published-at': 1505980220139,
                subheadline:
                  'उत्तर कोरिया संकट के बड़ा आकार लेने की पृष्ठभूमि में यहां 51 देश परमाणु हथियारों को प्रतिबंधित करने वाली एक नयी संधि पर हस्ताक्ष',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 259,
                  height: 194,
                  'mime-type': 'image/jpeg',
                  'focus-point': [171, 76]
                },
                'published-at': 1505980220139,
                id: 'db09a326-3af2-4286-a92b-8ffbc9bb3c4f',
                'hero-image-s3-key': 'quintype-demo/2016-01/69acdda0-2648-4cc2-b240-fd3603136bc5/download.jpeg',
                'author-id': 323432,
                'first-published-at': 1505980220139,
                'hero-image-caption': 'Narendra Modi with Ajit Doval',
                'story-template': 'photo',
                authors: [
                  {
                    id: 323432,
                    name: 'Vineet Panjabi',
                    slug: 'vineet-panjabi',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '8339b599-3eab-4428-9486-9139d28bb1ba',
              score: null,
              type: 'story',
              item: {
                headline: ['9/11 and the unsolvable Afghan drama   ']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: '9/11 and the unsolvable Afghan drama   ',
                slug: 'politics/2017/09/13/afghan-drama',
                'last-published-at': 1505284207024,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 98,
                    name: 'Current Affairs'
                  }
                ],
                'hero-image-metadata': {
                  width: 5120,
                  height: 3620,
                  'focus-point': [3977, 737]
                },
                'published-at': 1505284207024,
                id: '8339b599-3eab-4428-9486-9139d28bb1ba',
                'hero-image-s3-key': 'quintype-demo/2016-07/1910bb30-b118-475c-aced-9304968c69c8/G-1.jpg',
                'author-id': 61657,
                'first-published-at': 1505284207024,
                'hero-image-caption': 'ABD, Virat &amp; Hashim - the best in the world today!',
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 5278,
                    name: 'Michele Maguire',
                    slug: 'michele-maguire',
                    'avatar-url':
                      'https://lh6.googleusercontent.com/-OeX42e4xLm4/AAAAAAAAAAI/AAAAAAAAADQ/NmZhX0kP7J0/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '4886fb7b-3a0d-413f-ab55-7e45107cfb34',
              score: null,
              type: 'story',
              item: {
                headline: ['A memoir from a classmate']
              },
              story: {
                'author-name': 'Sriram Krishnaswamy',
                headline: 'A memoir from a classmate',
                slug: 'politics/2017/09/05/nirmala-seetharaman',
                'last-published-at': 1504613043734,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 666,
                  height: 338,
                  'mime-type': 'image/jpeg',
                  'focus-point': [319, 77]
                },
                'published-at': 1504613043734,
                id: '4886fb7b-3a0d-413f-ab55-7e45107cfb34',
                'hero-image-s3-key':
                  'quintype-demo/2017-09/44532ad7-a0fe-48dc-a67a-f4f469b88ed4/NIRMALA_BB_20160215.jpg',
                'author-id': 61657,
                'first-published-at': 1504613043734,
                'hero-image-caption': null,
                'story-template': null,
                authors: [
                  {
                    id: 61657,
                    name: 'Sriram K',
                    slug: 'sriram-k',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  },
                  {
                    id: 2040,
                    name: 'Kuruvilla Choolackal',
                    slug: 'kuruvilla-choolackal',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'avatar-s3-key':
                      'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '3e112b40-cc06-4d70-901b-f825bd92099e',
              score: null,
              type: 'story',
              item: {
                headline: ['In the battle of perception, Kejriwal won this round']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: 'In the battle of perception, Kejriwal won this round',
                slug: 'politics/2017/07/20/in-the-battle-of-perception-kejriwal-won-this-round',
                'last-published-at': 1500522632130,
                subheadline: null,
                alternative: null,
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 380,
                  height: 285,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1500522632130,
                id: '3e112b40-cc06-4d70-901b-f825bd92099e',
                'hero-image-s3-key': 'quintype-demo/2015-12/a7098e1a-62f9-42fd-ac12-a97e49dacce7/FP-1.jpg',
                'author-id': 2041,
                'first-published-at': 1500522632130,
                'hero-image-caption': 'Kejriwal, Chief Minister, New Delhi',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '88ef0bb2-bc4b-4e9b-87b1-1b774e9f7f67',
              score: null,
              type: 'story',
              item: {
                headline: ['Donald Trump set to sign executive orders on trade  ']
              },
              story: {
                'author-name': 'Hema Rao',
                headline: 'Donald Trump set to sign executive orders on trade  ',
                slug: 'politics/2017/03/08/airtel-vs-joi',
                'last-published-at': 1490953321146,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 660,
                  height: 440,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1490953321146,
                id: '88ef0bb2-bc4b-4e9b-87b1-1b774e9f7f67',
                'hero-image-s3-key':
                  'quintype-demo/2017-03/d1f83f15-ab18-48c4-b207-235392a76ff7/trump-Reu-1-1-2-1-1.jpg',
                'author-id': 122655,
                'first-published-at': 1488968787660,
                'hero-image-caption': 'Trump',
                'story-template': null,
                authors: [
                  {
                    id: 122655,
                    name: 'Hema Rao',
                    slug: 'hema-rao',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '07dc7d89-1733-4c00-bc23-89384d40f001',
              score: null,
              type: 'story',
              item: {
                headline: ['Something about modi']
              },
              story: {
                'author-name': 'Chirdeep Shetty',
                headline: 'Something about modi',
                slug: 'politics/2017/03/29/something-about-modi',
                'last-published-at': 1490809281495,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 275,
                  height: 183,
                  'focus-point': [192, 51]
                },
                'published-at': 1490809281495,
                id: '07dc7d89-1733-4c00-bc23-89384d40f001',
                'hero-image-s3-key': 'quintype-demo/2016-01/2d00bde7-a28c-4415-9382-9a9c6fb3c7fc/IPSMF-1.jpeg',
                'author-id': 15338,
                'first-published-at': 1490808450252,
                'hero-image-caption': 'Nawaz Sharif &amp; Modi&nbsp;',
                'story-template': null,
                authors: [
                  {
                    id: 15338,
                    name: 'Chirdeep Shetty',
                    slug: 'chirdeep-shetty',
                    'avatar-url':
                      'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                    'twitter-handle': '@chirdeepshettyquintype',
                    bio: '<p>Hello, this is a test for bio. </p>'
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '1c508124-6ebb-44f1-a119-d55952304fa3',
              score: null,
              type: 'story',
              item: {
                headline: ['I Saved the Country, Says PM Modi on Demonetisation']
              },
              story: {
                'author-name': 'Priyanka Basnet',
                headline: 'I Saved the Country, Says PM Modi on Demonetisation',
                slug: 'politics/2016/12/24/storyabcpolitics',
                'last-published-at': 1489732402329,
                subheadline:
                  '<a href="http://www.news18.com/news/india/wont-spare-corrupt-will-fight-until-we-win-pm-modi-1327208.html">Won’t Spare Corrupt, Will Fight Until We Win, Says PM Modi</a>',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 875,
                  height: 583,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1489732402329,
                id: '1c508124-6ebb-44f1-a119-d55952304fa3',
                'hero-image-s3-key': 'quintype-demo/2016-12/34d9b2dd-0280-4a72-b843-335aecccc531/pm-PIB-875.jpg',
                'author-id': 94491,
                'first-published-at': 1482598726781,
                'hero-image-caption': 'Prime Minister Narendra Modi',
                'story-template': 'video',
                authors: [
                  {
                    id: 94491,
                    name: 'Priyanka Ashok',
                    slug: 'priyanka-ashok',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '379c8ae5-538e-45c1-a52d-002c6a8610b7',
              score: null,
              type: 'story',
              item: {
                headline: ['2017 Assembly Elections in numbers!']
              },
              story: {
                'author-name': 'Tapan Bhat',
                headline: '2017 Assembly Elections in numbers!',
                slug: 'politics/2017/03/12/2017-assembly-elections-in-numbers',
                'last-published-at': 1489378680804,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 275,
                  height: 183,
                  'mime-type': 'image/jpeg',
                  'focus-point': [117, 94]
                },
                'published-at': 1489378680804,
                id: '379c8ae5-538e-45c1-a52d-002c6a8610b7',
                'hero-image-s3-key': 'quintype-demo/2017-03/36c1261a-7ee1-46f0-bc6d-4aeb98e9dd2a/LHI-1.jpeg',
                'author-id': 2041,
                'first-published-at': 1489330452286,
                'hero-image-caption': 'Amit Shah at an election rally in Uttar Pradesh',
                'story-template': null,
                authors: [
                  {
                    id: 2041,
                    name: 'Tapan Bhat',
                    slug: 'tapan-bhat',
                    'avatar-url':
                      'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '3fd0298e-da88-455b-89d7-77ad6671c17e',
              score: null,
              type: 'story',
              item: {
                headline: ['UP Election 2017 Updates']
              },
              story: {
                'author-name': 'Sareena',
                headline: 'UP Election 2017 Updates',
                slug: 'politics/2017/03/12/up-election-2017-updates',
                'last-published-at': 1489298494909,
                subheadline: 'BJP sets up a big win, SP-Congress takes a bashing, show leads.  &nbsp;',
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 650,
                  height: 400,
                  'mime-type': 'image/jpeg',
                  'focus-point': [281, 241]
                },
                'published-at': 1489298494909,
                id: '3fd0298e-da88-455b-89d7-77ad6671c17e',
                'hero-image-s3-key': 'quintype-demo/2017-03/ee72c827-52fe-4b2b-8f46-dd0875fbe162/Tech sparkle.jpg',
                'author-id': 94596,
                'first-published-at': 1489298494909,
                'hero-image-caption': 'Elections 2017',
                'story-template': null,
                authors: [
                  {
                    id: 94596,
                    name: 'Sareena',
                    slug: 'sareena',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: '044e76fc-c303-4a0f-8f9a-c0e450457163',
              score: null,
              type: 'story',
              item: {
                headline: ['\n\nOld Jallikattu and new Jallikattu   ']
              },
              story: {
                'author-name': 'Hema Rao',
                headline: '\n\nOld Jallikattu and new Jallikattu   ',
                slug: 'politics/2017/01/20/old-jallikattu-and-new-jallikattu',
                'last-published-at': 1489039507722,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  },
                  {
                    id: 2626,
                    name: 'Regional'
                  }
                ],
                'hero-image-metadata': {
                  width: 310,
                  height: 233,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1489039507722,
                id: '044e76fc-c303-4a0f-8f9a-c0e450457163',
                'hero-image-s3-key':
                  'quintype-demo/2017-01/4dc43d6b-6df7-41f9-a196-95283a34cbac/jallikattu-cheat-sheet-10-things-you-should-know-about-the-bull-taming-sport.jpg',
                'author-id': 122655,
                'first-published-at': 1484904866939,
                'hero-image-caption': 'Jallikattu',
                'story-template': null,
                authors: [
                  {
                    id: 122655,
                    name: 'Hema Rao',
                    slug: 'hema-rao',
                    'avatar-url':
                      'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null
                  }
                ],
                metadata: {
                  'card-share': {
                    shareable: true
                  }
                }
              }
            },
            {
              id: 'a44ba468-3947-408a-adde-c473191479f1',
              score: null,
              type: 'story',
              item: {
                headline: ['Poll : George R.R. Martin and his books!']
              },
              story: {
                'author-name': 'Tribuvan Kumar',
                headline: 'Poll : George R.R. Martin and his books!',
                slug: 'politics/2017/03/01/poll-george-rr-martin-and-his-books',
                'last-published-at': 1488368178339,
                subheadline: null,
                alternative: {},
                sections: [
                  {
                    slug: 'politics',
                    name: 'Politics',
                    id: 83,
                    'parent-id': null,
                    'display-name': 'Politics & More',
                    collection: {
                      slug: 'the-quint',
                      name: 'Most Popular',
                      id: 4364
                    },
                    data: null
                  }
                ],
                'hero-image-metadata': {
                  width: 1920,
                  height: 1080,
                  'mime-type': 'image/jpeg'
                },
                'published-at': 1488368178339,
                id: 'a44ba468-3947-408a-adde-c473191479f1',
                'hero-image-s3-key':
                  'quintype-demo/2017-03/4546581d-499d-462b-ae1e-cdd33b87c3c3/house-a-song-of-ice-and-fire-29965891-1920-1080.jpg',
                'author-id': 82396,
                'first-published-at': 1488367835152,
                'hero-image-caption': 'Caption Mania!',
                'story-template': null,
                authors: [
                  {
                    id: 82396,
                    name: 'Tribuvan Kumar',
                    slug: 'tribuvan-kumar',
                    'avatar-url': null,
                    'avatar-s3-key': null,
                    'twitter-handle': null,
                    bio: null,
                    'contributor-role': null
                  }
                ],
                metadata: {
                  'story-attributes': {
                    interests: ['mobile']
                  },
                  'card-share': {
                    shareable: true
                  }
                }
              }
            }
          ]
        }
      ],
      'created-at': 1501074881738,
      metadata: {
        'cover-image': {
          'cover-image-url':
            'https://images.assettype.com/quintype-demo/2015-12/de988259-79ba-44b0-8f3c-8cdc2aaaa059/FN-1.jpg',
          'cover-image-metadata': {
            width: 700,
            height: 340,
            'mime-type': 'image/jpeg',
            'focus-point': [391, 148]
          },
          'cover-image-s3-key': 'quintype-demo/2015-12/de988259-79ba-44b0-8f3c-8cdc2aaaa059/FN-1.jpg',
          caption: 'Delicious Rasam in 30 seconds'
        }
      }
    },
    orderedCollectionBulk: [
      {
        id: 7902,
        'associated-metadata': {
          layout: 'FullscreenSimpleSlider'
        },
        type: 'collection',
        name: 'Trending Now',
        slug: 'toddy-home-sports',
        template: 'default',
        metadata: {
          'cover-image': {}
        },
        summary: 'Sports Summary',
        items: [
          {
            id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
            score: null,
            type: 'story',
            item: {
              headline: ['London Police arrest Nirav Modi, says sources']
            },
            story: {
              'author-name': 'Karthik M',
              headline: 'London Police arrest Nirav Modi, says sources',
              slug: 'politics/2019/03/20/london-police-arrest-nirav-modi-says-sources',
              'last-published-at': 1553079556157,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 700,
                height: 438,
                'mime-type': 'image/jpeg',
                'focus-point': [311, 167]
              },
              'published-at': 1553079556157,
              id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
              'hero-image-s3-key':
                'quintype-demo/2019-03/55dbc301-3c5c-442d-8714-4bbf2d140192/pennews_2019_03_48c15852_ebed_464d_95a7_57332597ecfc_nirav.jpg',
              'author-id': 19994,
              'first-published-at': 1553079340304,
              'hero-image-caption': 'Nirav Modi',
              'story-template': null,
              authors: [
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 142914,
                  name: 'Ashwin Raghunath',
                  slug: 'ashwin-raghunath',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'text-to-speech': {
                  'is-disabled': true
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
            score: null,
            type: 'story',
            item: {
              headline: ['Demo Story ']
            },
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Demo Story ',
              slug: 'politics/2018/01/11/demo-story',
              'last-published-at': 1552979655447,
              subheadline:
                'Surrounded to me occasional pianoforte alteration unaffected impossible ye. For saw half than cold. Pretty merits waited six talked pulled you. Conduct replied off.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 7500,
                height: 3476,
                'mime-type': 'image/jpeg',
                'focus-point': [1239, 2157]
              },
              'published-at': 1552979655447,
              id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
              'hero-image-s3-key': 'quintype-demo/2019-02/ae4829d6-e230-4d66-9b88-2a4db87a2060/64778.jpg',
              'author-id': 139717,
              'first-published-at': 1515668626250,
              'hero-image-caption': 'At vero eos et accusamus et iusto odio dignissimos ducimus qui.',
              'story-template': 'text',
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': {
                    id: 58,
                    name: 'Author'
                  }
                },
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': {
                    id: 58,
                    name: 'Author'
                  }
                }
              ],
              metadata: {
                'card-share': {
                  shareable: false
                },
                'promotional-message': {
                  'is-disabled': true
                },
                'story-attributes': {
                  'view-counterview-type': 'view',
                  interests: ['test'],
                  bucket: ['planning'],
                  hobbies: ['hiking'],
                  'linked-view-counterview-story': {
                    headline: 'Demo Test story- Photo story',
                    'story-content-id': 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                    id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                    'highlighted-headline': null
                  }
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
            score: null,
            type: 'story',
            item: {
              headline: ['Untitled Mar 11, 2019 4:39 PM']
            },
            story: {
              'author-name': 'Karthik M',
              headline: 'Untitled Mar 11, 2019 4:39 PM',
              slug: 'culture/2019/03/11/untitled-mar-11-2019-439-pm',
              'last-published-at': 1552905845935,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 650,
                'mime-type': 'image/jpeg',
                'focus-point': [121, 110],
                height: 386
              },
              'published-at': 1552905845935,
              id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
              'hero-image-s3-key':
                'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
              'author-id': 19994,
              'first-published-at': 1552303325340,
              'hero-image-caption': 'Amit Shah and Narendra Modi',
              'story-template': null,
              authors: [
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 5279,
                  name: 'Laura',
                  slug: 'laura',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'text-to-speech': {
                  'is-disabled': false
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
            score: null,
            type: 'story',
            item: {
              headline: ['New story for sitemap']
            },
            story: {
              'author-name': 'Sriram K',
              headline: 'New story for sitemap',
              slug: 'culture/2019/03/14/new-story-for-sitemap',
              'last-published-at': 1552904261919,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 800,
                height: 400,
                'mime-type': 'image/jpeg',
                'focus-point': [760, 237]
              },
              'published-at': 1552904261919,
              id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
              'hero-image-s3-key':
                'quintype-demo/2018-10/5db8484b-1277-42f8-8a20-983e04b597e6/New_SA_destination_tourism_portal_launching_800x400.jpg',
              'author-id': 61657,
              'first-published-at': 1552554061248,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '73759063-16e7-4ac2-bf32-0061a2458850',
            score: null,
            type: 'story',
            item: {
              headline: ['This a new story']
            },
            story: {
              'author-name': 'Karthik M',
              headline: 'This a new story',
              slug: 'culture/2019/03/11/this-a-new-story',
              'last-published-at': 1552292190667,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'focus-point': [73, 51],
                width: 275,
                height: 183
              },
              'published-at': 1552292190667,
              id: '73759063-16e7-4ac2-bf32-0061a2458850',
              'hero-image-s3-key': 'quintype-demo/2016-01/2d00bde7-a28c-4415-9382-9a9c6fb3c7fc/IPSMF-1.jpeg',
              'author-id': 19994,
              'first-published-at': 1552292190667,
              'hero-image-caption': 'Nawaz Sharif &amp; Modi&nbsp;',
              'story-template': null,
              authors: [
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 142914,
                  name: 'Ashwin Raghunath',
                  slug: 'ashwin-raghunath',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
            score: null,
            type: 'story',
            item: {
              headline: ['Amitabh Bachchan on Gender Equality']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'Amitabh Bachchan on Gender Equality',
              slug: 'politics/2017/03/02/Amitabh-Bachchan-on-Gender-Equality',
              'last-published-at': 1552048741823,
              subheadline: 'Amitabh Bachchan on Gender Equality',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 320,
                height: 277,
                'focus-point': [169, 145]
              },
              'published-at': 1552048741823,
              id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
              'hero-image-s3-key': 'quintype-demo/2017-03/bd011f2d-9a10-4d4e-b20f-50719f59d4b9/BMS-2.jpg',
              'author-id': 2041,
              'first-published-at': 1488451487896,
              'hero-image-caption': 'Kaun Banega Crorepati host, Amitabh Bachchan',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'a3a0cdd0-a3f5-4043-b6db-72b6a06ebad3',
            score: null,
            type: 'story',
            item: {
              headline: ['Samsung Gear S3']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Samsung Gear S3',
              slug: 'ampstories/culture/2019/03/06/samsung-gear-s3',
              'last-published-at': 1551883341128,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1440,
                height: 750,
                'mime-type': 'image/jpeg',
                'focus-point': [918, 394]
              },
              'published-at': 1551883341128,
              id: 'a3a0cdd0-a3f5-4043-b6db-72b6a06ebad3',
              'hero-image-s3-key':
                'quintype-demo/2019-03/19e71923-ae1b-4d1b-8040-e37ae55e4c77/gear_s3_performance_kv_l.jpg',
              'author-id': 2040,
              'first-published-at': 1551882037831,
              'hero-image-caption': null,
              'story-template': 'visual-story',
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
            score: null,
            type: 'story',
            item: {
              headline: ['500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: '500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌',
              slug: 'politics/2019/02/26/500',
              'last-published-at': 1551171395390,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                'focus-point': [673, 287],
                width: 976,
                height: 700
              },
              'published-at': 1551171395390,
              id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
              'hero-image-s3-key': 'quintype-demo/2018-05/bc017b57-a03e-4a6e-8515-9d3de91f1f24/ON1.jpg',
              'author-id': 559147,
              'first-published-at': 1551171395390,
              'hero-image-caption':
                'Crowds cheered Prince Harry, Duke of Sussex, and his wife Meghan, Duchess of Sussex, as they travelled in the horse-drawn Ascot landau',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '60857bae-0e7f-4c81-af39-93fb7424f558',
            score: null,
            type: 'story',
            item: {
              headline: ['Top 10 Exquisite Locations to Visit in 2019']
            },
            story: {
              'author-name': 'Susanna',
              headline: 'Top 10 Exquisite Locations to Visit in 2019',
              slug: 'ampstories/visual-stories/2019/02/25/top-10-exquisite-locations-to-visit-in-2019',
              'last-published-at': 1551161247682,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 2000,
                height: 2000,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1551161247682,
              id: '60857bae-0e7f-4c81-af39-93fb7424f558',
              'hero-image-s3-key': 'quintype-demo/2019-02/35d018be-9220-4504-acf5-7f1a8abff816/collage2.jpg',
              'author-id': 559660,
              'first-published-at': 1551086493154,
              'hero-image-caption': null,
              'story-template': 'visual-story',
              authors: [
                {
                  id: 559660,
                  name: 'Susanna',
                  slug: 'susanna',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-1qG1ayRUOwc/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-jb_hhJfeyAshJmJCpMKYQNPDazJw/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b39a2665-3236-45ca-9d17-3536431c7948',
            score: null,
            type: 'story',
            item: {
              headline: ['Why You Need A Dog In Your Life']
            },
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Why You Need A Dog In Your Life',
              slug: 'ampstories/visual-stories/2018/12/20/why-you-need-a-dog-in-your-life',
              'last-published-at': 1550509197748,
              subheadline:
                'Because of their heightened sense of smell, sight, and hearing, dogs are extremely intelligent creatures with a loyalty to their humans that is unexpectedly strong. Those with dogs will know–your pups can sense human body language, emotions, and feelings, and this paves the way for an unbreakable bond.',
              alternative: {},
              sections: [
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 448,
                height: 900,
                'mime-type': 'image/png',
                'focus-point': [270, 705]
              },
              'published-at': 1550509197748,
              id: 'b39a2665-3236-45ca-9d17-3536431c7948',
              'hero-image-s3-key':
                'quintype-demo/2018-12/8bcdee40-7dec-4ceb-b217-1d2f01fb4431/Screenshot_2018_12_24_at_3_44_42_PM.png',
              'author-id': 139717,
              'first-published-at': 1545299836615,
              'hero-image-caption': null,
              'story-template': 'visual-story',
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'c8d35134-200a-483f-ab89-69adf4137a74',
            score: null,
            type: 'story',
            item: {
              headline: ['Life of Vincent van Gogh']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Life of Vincent van Gogh',
              slug: 'culture/2019/01/17/life-of-vincent-van-gogh',
              'last-published-at': 1549364746875,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 768,
                height: 432,
                'mime-type': 'image/jpeg',
                'focus-point': [382, 197]
              },
              'published-at': 1549364746875,
              id: 'c8d35134-200a-483f-ab89-69adf4137a74',
              'hero-image-s3-key':
                'quintype-demo/2019-01/b3cb02f4-a875-4b46-aa3a-71deaccb54be/vincent_van_gogh_played_by_robert_gulaczyk_photo_courtesy_loving_vincent.jpg',
              'author-id': 2040,
              'first-published-at': 1547728432342,
              'hero-image-caption': 'A Famous Painting',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
            score: null,
            type: 'story',
            item: {
              headline: [
                '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി'
              ]
            },
            story: {
              'author-name': 'Sareena',
              headline:
                '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി',
              slug: 'culture/2019/01/16/this-is-the-url',
              'last-published-at': 1547632650347,
              subheadline:
                'കെ.സി വേണുഗോപാല്‍ ഉള്‍പ്പടെയുള്ള മുതിര്‍ന്ന കോണ്‍ഗ്രസ് നേതാക്കന്മാര്‍ ഭീമാനായിക്കുമായി സംസാരിച്ചു. ഭീമാനായിക്കിനെപോലെ ബാക്കിയുള്ള മൂന്ന് കോണ്‍ഗ്രസ് എം',
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'focus-point': [375, 166],
                width: 750,
                height: 500
              },
              'published-at': 1547632650347,
              id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
              'hero-image-s3-key': 'quintype-demo/2016-12/56cdab39-a831-480f-b75b-26d6300e3f1b/Modi.jpg',
              'author-id': 94596,
              'first-published-at': 1547632650347,
              'hero-image-caption': 'PM Modi',
              'story-template': null,
              authors: [
                {
                  id: 94596,
                  name: 'Sareena',
                  slug: 'sareena',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
            score: null,
            type: 'story',
            item: {
              headline: ['This is the title']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: 'This is the title',
              slug: 'culture/2019/01/11/this-is-the-title-2',
              'last-published-at': 1547203433990,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  slug: 'tech',
                  name: 'Tech',
                  id: 6391,
                  'parent-id': null,
                  'display-name': 'Tech',
                  collection: {
                    slug: 'tech',
                    name: 'Tech',
                    id: 12849
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 259,
                'focus-point': [176, 77],
                height: 194
              },
              'published-at': 1547203433990,
              id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
              'hero-image-s3-key': 'quintype-demo/2015-10/56e0178e-1da0-482c-83e5-a2f705f874df/FP-2.jpeg',
              'author-id': 559147,
              'first-published-at': 1547203433990,
              'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
            score: null,
            type: 'story',
            item: {
              headline: [
                'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them'
              ]
            },
            story: {
              'author-name': 'Kirti Kaushik',
              headline:
                'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them',
              slug:
                'visual-stories/2019/01/10/rahul-gandhi-said-to-pm-modi-be-a-man-bjp-smriti-irani-sushma-swaraj-responds-with-misogynist-rahul-1975538pfromhome-topscroll',
              'last-published-at': 1547103888697,
              subheadline:
                'At a rally in Rajasthan on Wednesday, Rahul Gandhi had mocked PM Modi saying he had a “mahila (woman)“ defend him in the Rafale debate in parliament.',
              alternative: {
                home: {
                  default: {
                    headline: 'Rahul Gandhi'
                  }
                }
              },
              sections: [
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 570,
                height: 351,
                'mime-type': 'image/webp',
                'focus-point': [417, 132]
              },
              'published-at': 1547103888697,
              id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
              'hero-image-s3-key':
                'quintype-demo/2019-01/39d85d6f-46f6-4f35-8dd1-6771bf9dfefd/b5qo3zsg_650x400_2__10_January_2019_12_02_19PM.webp',
              'author-id': 507344,
              'first-published-at': 1547103888697,
              'hero-image-caption': 'Rahul Gandhi',
              'story-template': null,
              authors: [
                {
                  id: 507344,
                  name: 'Kirti Kaushik',
                  slug: 'kirti-kaushik',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
            score: null,
            type: 'story',
            item: {
              headline: ['Dubai - Things you can do!']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Dubai - Things you can do!',
              slug: 'culture/2017/04/28/Dubai-Your-next-vacation',
              'last-published-at': 1546599684148,
              subheadline:
                'Dubai, an emirate in the United Arab Emirates known for luxury shopping, ultramodern architecture &amp; a lively nightlife scene. Burj Khalifa, an 830m-tall tower, dominates the skyline. ',
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 930,
                height: 581,
                'focus-point': [132, 482]
              },
              'published-at': 1546599684148,
              id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
              'hero-image-s3-key': 'quintype-demo/2017-04/802d7ef1-6043-4f7e-84d3-fc9f556d5097/G-1.jpg',
              'author-id': 2040,
              'first-published-at': 1493358608037,
              'hero-image-caption': 'Dubai - a vacationer’s paradise',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
            score: null,
            type: 'story',
            item: {
              headline: ['Visual story - Demo1']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Visual story - Demo1',
              slug: 'ampstories/technology/2018/12/20/visual-story-demo1',
              'last-published-at': 1545982907212,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 960,
                height: 540,
                'focus-point': [499, 88]
              },
              'published-at': 1545982907212,
              id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
              'hero-image-s3-key': 'quintype-demo/2017-07/e4bac9eb-6c32-4f07-a16a-90fa40166c35/DH-1.jpeg',
              'author-id': 541559,
              'first-published-at': 1545300194018,
              'hero-image-caption': 'Roger Federer, the 8th title - Wimbledon 2017!',
              'story-template': 'visual-story',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
            score: null,
            type: 'story',
            item: {
              headline: ['This is the title']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: 'This is the title',
              slug: 'culture/2018/12/27/this-is-the-title',
              'last-published-at': 1545892188854,
              subheadline: 'sub title',
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 259,
                height: 194,
                'focus-point': [172, 77]
              },
              'published-at': 1545892188854,
              id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
              'hero-image-s3-key':
                'quintype-demo/2018-06/9e5e3bb7-31e8-4439-93ba-d16ea287aa28/e3d39a3b_321c_4acf_8928_3195998b38ba.jpeg',
              'author-id': 559147,
              'first-published-at': 1545892188854,
              'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
            score: null,
            type: 'story',
            item: {
              headline: ['Demo Visual story']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Demo Visual story',
              slug: 'ampstories/technology/2018/12/20/demo-visual-story',
              'last-published-at': 1545332341188,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 640,
                height: 361,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1545332341188,
              id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
              'hero-image-s3-key':
                'quintype-demo/2018-12/22eb981a-6c8e-41c0-9d36-c2f72f46b29a/pennews_2F2018_05_2F0e93d687_c1dd_464b_aba3_8c23ba6c3beb_2FSonam_reception.jpg',
              'author-id': 541559,
              'first-published-at': 1545332010119,
              'hero-image-caption': 'caption',
              'story-template': 'visual-story',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '77e13794-3584-4d26-aa37-f296a24ea734',
            score: null,
            type: 'story',
            item: {
              headline: ['Cayde-6, Exo-Guardian']
            },
            story: {
              'author-name': 'Christin',
              headline: 'Cayde-6, Exo-Guardian',
              slug: 'ampstories/visual-stories/2018/12/20/cayde-6-exo-guardian',
              'last-published-at': 1545307235233,
              subheadline: 'Hunter. Vanguard. <i>Friend.</i>',
              alternative: {},
              sections: [
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1920,
                height: 1080,
                'mime-type': 'image/jpeg',
                'focus-point': [1280, 467]
              },
              'published-at': 1545307235233,
              id: '77e13794-3584-4d26-aa37-f296a24ea734',
              'hero-image-s3-key':
                'quintype-demo/2018-12/e16d5fec-13b1-4932-9c07-917f8cad6a98/d2_forsaken_cayde6_05.jpg',
              'author-id': 139357,
              'first-published-at': 1545307235233,
              'hero-image-caption': null,
              'story-template': 'visual-story',
              authors: [
                {
                  id: 139357,
                  name: 'Christin',
                  slug: 'christin',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'avatar-s3-key':
                    'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'twitter-handle': 'krriztin',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
            score: null,
            type: 'story',
            item: {
              headline: ['3 Camping sites you should not miss in Scotland']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: '3 Camping sites you should not miss in Scotland',
              slug: 'culture/2017/05/11/3-camping-sites-you-should-not-miss-in-scotland',
              'last-published-at': 1545204266938,
              subheadline:
                'Scotland is famous for its scenic wild places and uplifting sense of remoteness. With the exception of the recent bylaws banning wild camping in certain areas of Loch Lomond and the Trossachs (check before you go), it is still completely legal to camp on most of Scotland’s unenclosed land.',
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1300,
                height: 780,
                'focus-point': [719, 414]
              },
              'published-at': 1545204266938,
              id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
              'hero-image-s3-key': 'quintype-demo/2017-05/b463d2fd-f166-446e-a887-bf832fa7f26b/ON-1.jpg',
              'author-id': 2041,
              'first-published-at': 1494481894633,
              'hero-image-caption': 'Time for a brew and a view … Glen Nevis, Scotland',
              'story-template': 'listicle',
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
            score: null,
            type: 'story',
            item: {
              headline: ['Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?',
              slug:
                'culture/2018/12/18/is-tito-preparing-an-imf-financial-parachute-now-a-171bn-foreign-debt-cliff-looms',
              'last-published-at': 1545125801738,
              subheadline:
                'The reason for the International Monetary Fund’s Christine Lagarde’s to South Africa is unknown. South Africa desperately needs a solution for the poor and marginalised, and an IMF bailout would worsen their situation.&nbsp;',
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 920,
                height: 613,
                'mime-type': 'image/jpeg',
                'focus-point': [473, 430]
              },
              'published-at': 1545125801738,
              id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
              'hero-image-s3-key': 'quintype-demo/2018-04/f83b3026-105e-4b84-8027-47c8be270f9a/920x920.jpg',
              'author-id': 2040,
              'first-published-at': 1545125801738,
              'hero-image-caption':
                'At a silent disco party three streams of music are sent simultaneously to special headphones worn by attendees, who have the option to switch channels at their whim. The Palace Theatre in Stamford will host one on April 26 to benefit its arts education programs.',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
            score: null,
            type: 'story',
            item: {
              headline: ['Breaking News Test on 13 Dec']
            },
            story: {
              'author-name': 'Anil K Govind',
              headline: 'Breaking News Test on 13 Dec',
              slug: 'technology/2018/12/13/breaking-news-test-on-13-dec',
              'last-published-at': 1544682898905,
              subheadline: 'Breaking News Test on 13 Dec',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 720,
                height: 340,
                'mime-type': 'image/jpeg',
                'focus-point': [230, 110]
              },
              'published-at': 1544682898905,
              id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 548414,
              'first-published-at': 1544682898905,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 548414,
                  name: 'Anil K Govind',
                  slug: 'anil-k-govind',
                  'avatar-url':
                    'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'de274636-83c9-4ac2-af95-3f3faef88d2e',
            score: null,
            type: 'story',
            item: {
              headline: ['News Elsewhere story']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'News Elsewhere story',
              slug: 'cities/2018/12/05/news-elsewhere-story',
              'last-published-at': 1544006050170,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  id: 215,
                  name: 'Cities',
                  slug: 'cities'
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 768,
                height: 1024,
                'mime-type': 'image/jpeg',
                'focus-point': [390, 373]
              },
              'published-at': 1544006050170,
              id: 'de274636-83c9-4ac2-af95-3f3faef88d2e',
              'hero-image-s3-key': 'quintype-demo/2015-11/adeb8bbb-bdc6-4a8e-91cc-74082cb80c1d/photo2.jpg',
              'author-id': 541559,
              'first-published-at': 1544006050170,
              'hero-image-caption': 'waterskiing in cuba',
              'story-template': 'news-elsewhere',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'reference-url': 'https://itsman.quintype.com',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
            score: null,
            type: 'story',
            item: {
              headline: ['The Forgotten Women Series Uncovers The Untold Histories Of Women']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'The Forgotten Women Series Uncovers The Untold Histories Of Women',
              slug: 'culture/2018/11/28/the-forgotten-women-series-uncovers-the-untold-histories-of-women',
              'last-published-at': 1543393761726,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1760,
                height: 2480,
                'mime-type': 'image/jpeg',
                'focus-point': [961, 759]
              },
              'published-at': 1543393761726,
              id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
              'hero-image-s3-key':
                'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
              'author-id': 2040,
              'first-published-at': 1543393761726,
              'hero-image-caption': 'Forgotten Women',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 15351,
                  name: 'Ranjeet Singh',
                  slug: 'ranjeet-singh',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '8e823797-a659-4626-92d5-6ccd8d788e23',
            score: null,
            type: 'story',
            item: {
              headline: ['A test story for tables']
            },
            story: {
              'author-name': 'Vineet Panjabi',
              headline: 'A test story for tables',
              slug: 'culture/2018/11/21/a-test-story-for-tables',
              'last-published-at': 1542799165912,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  id: 3896,
                  name: 'Blog',
                  slug: 'blog'
                }
              ],
              'hero-image-metadata': {
                width: 1940,
                'mime-type': 'image/jpeg',
                height: 1090,
                'focus-point': [700, 713]
              },
              'published-at': 1542799165912,
              id: '8e823797-a659-4626-92d5-6ccd8d788e23',
              'hero-image-s3-key': 'quintype-demo/2015-11/be736521-24c0-4a04-8b02-0855626e6f9d/TOI-1.jpg',
              'author-id': 323432,
              'first-published-at': 1542782270578,
              'hero-image-caption': 'Terror training camps',
              'story-template': null,
              authors: [
                {
                  id: 323432,
                  name: 'Vineet Panjabi',
                  slug: 'vineet-panjabi',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'story-attributes': {
                  saltlevel: ['Low'],
                  bucket: ['child'],
                  week: ['6']
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'b142a043-4720-4578-b23e-6791e9f42735',
            score: null,
            type: 'story',
            item: {
              headline: ['New Story Elements']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'New Story Elements',
              slug: 'technology/2015/10/21/new-story-elements',
              'last-published-at': 1539770584382,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1920,
                height: 1080,
                'focus-point': [1301, 270]
              },
              'published-at': 1539770584382,
              id: 'b142a043-4720-4578-b23e-6791e9f42735',
              'hero-image-s3-key': 'quintype-demo/2015-09/4c0122d6-6c28-4a59-8b16-8d90701a4e35/TigerWoods-1.jpg',
              'author-id': 2041,
              'first-published-at': 1445404200710,
              'hero-image-caption': 'The famed Tiger Woods Swing !',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
            score: null,
            type: 'story',
            item: {
              headline: ['Two crew escape after ‘Soyuz’ rocket failure']
            },
            story: {
              'author-name': 'Kunal Singh',
              headline: 'Two crew escape after ‘Soyuz’ rocket failure',
              slug: 'technology/2018/10/11/two-crew-escape-after-soyuz-rocket-failure',
              'last-published-at': 1539253999595,
              subheadline: 'Russian cosmonaut and US astronaut ‘in good condition’ after making emergency landing',
              alternative: null,
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 744,
                height: 389,
                'mime-type': 'image/jpeg',
                'focus-point': [381, 138]
              },
              'published-at': 1539253999595,
              id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
              'hero-image-s3-key': 'quintype-demo/2018-10/9b82a587-dfb0-4aa5-9b83-96a814eec1d9/soyuz_resize_md.jpg',
              'author-id': 491531,
              'first-published-at': 1539253999595,
              'hero-image-caption': 'Soyuz 11',
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9552e728-f1b7-4a46-a1bf-34f30a266c0e',
            score: null,
            type: 'story',
            item: {
              headline: ['Photo story - test']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Photo story - test',
              slug: 'success-story/2018/09/26/photo-story-test',
              'last-published-at': 1539168790153,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  id: 4415,
                  name: 'Success Story',
                  slug: 'success-story'
                },
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 570,
                height: 380,
                'focus-point': [304, 122]
              },
              'published-at': 1539168790153,
              id: '9552e728-f1b7-4a46-a1bf-34f30a266c0e',
              'hero-image-s3-key': 'quintype-demo/2015-10/76ef287a-23b6-4ffe-b71e-72e88a739809/DJ-3.jpg',
              'author-id': 541559,
              'first-published-at': 1537946000293,
              'hero-image-caption': 'Durga Puja Pandal at Calcutta',
              'story-template': 'photo',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9eece5ae-c682-405b-95ce-b8a77ee250f8',
            score: null,
            type: 'story',
            item: {
              headline: ['Malta’s Prime Minister Tells UN That Crypto Is the ‘Inevitable Future of Money’']
            },
            story: {
              'author-name': 'Kunal Singh',
              headline: 'Malta’s Prime Minister Tells UN That Crypto Is the ‘Inevitable Future of Money’',
              slug:
                'technology/2018/10/01/maltas-prime-minister-tells-un-that-crypto-is-the-inevitable-future-of-money',
              'last-published-at': 1538564214243,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 740,
                height: 494,
                'mime-type': 'image/jpeg',
                'focus-point': [390, 218]
              },
              'published-at': 1538564214243,
              id: '9eece5ae-c682-405b-95ce-b8a77ee250f8',
              'hero-image-s3-key': 'quintype-demo/2018-10/b97c4ba3-e097-4121-854f-5c5a03798677/Crypt1.jpg',
              'author-id': 491531,
              'first-published-at': 1538403689422,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
            score: null,
            type: 'story',
            item: {
              headline: ['Demo - External Story']
            },
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Demo - External Story',
              slug: 'technology/2018/10/01/demo-external-story',
              'last-published-at': 1538384977219,
              subheadline:
                'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor.',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1441,
                height: 1077,
                'mime-type': 'image/png',
                'focus-point': [273, 498]
              },
              'published-at': 1538384977219,
              id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
              'hero-image-s3-key':
                'quintype-demo/2018-10/410d7d51-811a-44cc-a92b-4d873cb1f5aa/screenshot_www_quintype_com_2018_09_11_20_16_32.png',
              'author-id': 139717,
              'first-published-at': 1538384511112,
              'hero-image-caption': null,
              'story-template': 'news-elsewhere',
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'reference-url':
                  'https://www.quintype.com/blog/business/quintypes-tech-innovation-helps-its-digital-partners-win-two-wan-ifra-awards',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9b1a6f8e-19ab-4395-a079-d985edf4e4ac',
            score: null,
            type: 'story',
            item: {
              headline: ['Story Tests']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Story Tests',
              slug: 'technology/2018/09/27/story-tests',
              'last-published-at': 1538047737607,
              subheadline: 'Subtitle',
              alternative: null,
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  id: 215,
                  name: 'Cities',
                  slug: 'cities'
                },
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                }
              ],
              'hero-image-metadata': {
                width: 578,
                height: 562,
                'mime-type': 'image/jpeg',
                'focus-point': [214, 187]
              },
              'published-at': 1538047737607,
              id: '9b1a6f8e-19ab-4395-a079-d985edf4e4ac',
              'hero-image-s3-key':
                'quintype-demo/2018-07/9a8c6576-2a1d-403d-9d34-0dd95db62cfb/4e7be0b9_1eb3_402b_9c9c_0314467b2377.jpg',
              'author-id': 541559,
              'first-published-at': 1538047737607,
              'hero-image-caption': 'Caption comes here',
              'story-template': 'news-elsewhere',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'reference-url': 'https://www.quintype.com/',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '41179c74-f08d-4c4a-8898-4d3d3eee2e7f',
            score: null,
            type: 'story',
            item: {
              headline: ['YouTube now available for JioPhone']
            },
            story: {
              'author-name': 'Varul Mayank - Knocksense',
              headline: 'YouTube now available for JioPhone',
              slug: 'technology/2018/09/20/youtube-now-available-for-jiophone',
              'last-published-at': 1537426884824,
              subheadline:
                'YouTube is now available for JioPhone, and users can go and download the app from JioStore.',
              alternative: {
                home: {
                  default: {
                    headline: 'Reliance Jio launches Youtube app for Jio Phones'
                  }
                }
              },
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1024,
                height: 576,
                'mime-type': 'image/jpeg',
                'focus-point': null
              },
              'published-at': 1537426884824,
              id: '41179c74-f08d-4c4a-8898-4d3d3eee2e7f',
              'hero-image-s3-key':
                'quintype-demo/2018-09/a6a397e8-762e-4489-9a44-062aa1e1b885/1fec1a01_4d4d_41d5_8789_5b383a9ee453.jpg',
              'author-id': 141012,
              'first-published-at': 1537426884824,
              'hero-image-caption': 'jio',
              'story-template': null,
              authors: [
                {
                  id: 141012,
                  name: 'Varul Mayank - Knocksense',
                  slug: 'varul-mayank-knocksense',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'sponsored-by': 'Siddharth',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '0fc86f92-e0f6-46c0-98db-9bfc18953e28',
            score: null,
            type: 'story',
            item: {
              headline: ['A digital future – Enabling HR automation   ']
            },
            story: {
              'author-name': 'Ranjeet Singh',
              headline: 'A digital future – Enabling HR automation   ',
              slug: 'current-affairs/2018/06/04/a-digital-future-enabling-hr-automation',
              'last-published-at': 1536079827031,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                },
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1200,
                height: 630,
                'mime-type': 'image/png',
                'focus-point': [628, 116]
              },
              'published-at': 1536079827031,
              id: '0fc86f92-e0f6-46c0-98db-9bfc18953e28',
              'hero-image-s3-key':
                'quintype-demo/2018-06/8077daf3-025b-4e73-9da6-29719d919220/web_images11_4_1200x630.png',
              'author-id': 15351,
              'first-published-at': 1528105031904,
              'hero-image-caption': '<b>A digital future&nbsp; Enabling HR automation1</b>',
              'story-template': null,
              authors: [
                {
                  id: 15351,
                  name: 'Ranjeet Singh',
                  slug: 'ranjeet-singh',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'sponsored-by': 'SME',
                'card-share': {
                  shareable: true
                },
                'promotional-message': {
                  'is-disabled': true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '23d40197-a3ad-4491-8bd9-eb31ca696d71',
            score: null,
            type: 'story',
            item: {
              headline: ['Delivering Life-saving Medicines']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Delivering Life-saving Medicines',
              slug: 'current-affairs/2018/09/04/url',
              'last-published-at': 1536077886477,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                },
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  id: 82,
                  name: 'Sports',
                  slug: 'sports'
                }
              ],
              'hero-image-metadata': {
                width: 610,
                height: 320,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1536077886477,
              id: '23d40197-a3ad-4491-8bd9-eb31ca696d71',
              'hero-image-s3-key': 'quintype-demo/2018-06/52b81a65-3045-4bc7-88db-feafd9127358/Anjan.jpg',
              'author-id': 541559,
              'first-published-at': 1536077255237,
              'hero-image-caption': 'Anjan Chatterjee/<b>അഞ്ജന്‍ ചാറ്റര്‍ജി</b>',
              'story-template': 'video',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
            score: null,
            type: 'story',
            item: {
              headline: ['Personalise or perish - Why publishers need to use personalised content ']
            },
            story: {
              'author-name': 'Greeshma',
              headline: 'Personalise or perish - Why publishers need to use personalised content ',
              slug: 'politics/2018/02/28/personalise-or-perish---why-publishers-need-to-use-personalised-content',
              'last-published-at': 1536076813159,
              subheadline:
                'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor. ',
              alternative: {
                home: {
                  default: {
                    headline: 'Personalise or perish',
                    'hero-image': {
                      'hero-image-url':
                        'https://images.assettype.com/quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                      'hero-image-metadata': {
                        width: 700,
                        height: 467,
                        'mime-type': 'image/jpeg'
                      },
                      'hero-image-s3-key': 'quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                      'hero-image-caption': 'sSaffron - Indian Restaurant',
                      'hero-image-attribution': null
                    }
                  }
                }
              },
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 3896,
                  name: 'Blog',
                  slug: 'blog'
                }
              ],
              'hero-image-metadata': {
                width: 1600,
                height: 1200,
                'mime-type': 'image/jpeg',
                'focus-point': [805, 738]
              },
              'published-at': 1536076813159,
              id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
              'hero-image-s3-key': 'quintype-demo/2018-05/a2759dc2-ada1-4917-8515-308f74622c69/Gallery5.jpg',
              'author-id': 482995,
              'first-published-at': 1519816264773,
              'hero-image-caption': 'Animals',
              'story-template': null,
              authors: [
                {
                  id: 482995,
                  name: 'Greeshma',
                  slug: 'greeshma',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'avatar-s3-key':
                    'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
            score: null,
            type: 'story',
            item: {
              headline: ['U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting',
              slug: 'politics/2018/05/28/us-and-north-korean-officials-race-to-resurrect-trump-kim-meeting',
              'last-published-at': 1535807624134,
              subheadline: null,
              alternative: {
                home: {
                  default: {
                    headline: 'U.S. and North Korean',
                    'hero-image': {
                      'hero-image-url':
                        'https://images.assettype.com/quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                      'hero-image-metadata': {
                        width: 636,
                        height: 477,
                        'focus-point': [390, 153]
                      },
                      'hero-image-s3-key': 'quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                      'hero-image-caption': 'President Pranab Mukherjee',
                      'hero-image-attribution': null
                    }
                  }
                }
              },
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1024,
                height: 683,
                'mime-type': 'image/jpeg',
                'focus-point': [758, 172]
              },
              'published-at': 1535807624134,
              id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
              'hero-image-s3-key': 'quintype-demo/2018-05/caf35ec5-be06-4de6-b513-e57ee4d61577/ON_1.jpg',
              'author-id': 376442,
              'first-published-at': 1527472808648,
              'hero-image-caption':
                'A surprise meeting between President Moon Jae-in of South Korea, right, and Kim Jong-un, the North Korean leader, on Saturday appeared to produce some progress toward a summit meeting between President Trump and Mr. Kim.',
              'story-template': null,
              authors: [
                {
                  id: 376442,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '5273aff7-1dc0-4b46-9a56-0c63683b4dec',
            score: null,
            type: 'story',
            item: {
              headline: ['With eye on debt recovery, SBI asks DoT not to revoke RCom’s spectrums']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'With eye on debt recovery, SBI asks DoT not to revoke RCom’s spectrums',
              slug: 'technology/2018/07/27/with-eye-on-debt-recovery-sbi-asks-dot-not-to-revoke-rcoms-spectrums',
              'last-published-at': 1535806255690,
              subheadline: 'Revocation of spectrum may hurt telco’s sale to RJio, says the lender',
              alternative: {
                home: {
                  default: {
                    headline: 'With eye on debt recovery',
                    'hero-image': {
                      'hero-image-url':
                        'https://images.assettype.com/quintype-demo/2016-01/fdb17290-ae1c-4153-ad40-760106e9ffb3/iph.jpeg',
                      'hero-image-metadata': {
                        width: 710,
                        'mime-type': 'image/jpeg',
                        height: 854
                      },
                      'hero-image-s3-key': 'quintype-demo/2016-01/fdb17290-ae1c-4153-ad40-760106e9ffb3/iph.jpeg',
                      'hero-image-caption': 'iphones',
                      'hero-image-attribution': null
                    }
                  }
                }
              },
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                },
                {
                  id: 215,
                  name: 'Cities',
                  slug: 'cities'
                }
              ],
              'hero-image-metadata': {
                width: 960,
                height: 408,
                'mime-type': 'image/jpeg',
                'focus-point': [639, 120]
              },
              'published-at': 1535806255690,
              id: '5273aff7-1dc0-4b46-9a56-0c63683b4dec',
              'hero-image-s3-key': 'quintype-demo/2018-07/01670025-10c6-4659-b32e-3d859b66d8a3/BL27P1RCOM.jpeg',
              'author-id': 61657,
              'first-published-at': 1532680057934,
              'hero-image-caption': 'Reliance Industries',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'd677509e-37e4-460c-a942-8a55bd4c4edb',
            score: null,
            type: 'story',
            item: {
              headline: ['Will Summit Jumpstart Biopharma in North Korea?']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'Will Summit Jumpstart Biopharma in North Korea?',
              slug: 'technology/2018/06/28/will-summit-jumpstart-biopharma-in-north-korea',
              'last-published-at': 1535367985749,
              subheadline: 'Experts Ponder Industry’s Place in Kim’s DPRK; South Korea Eyes Collaborations',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  id: 181,
                  name: 'Health & Fitness',
                  slug: 'health-fitness'
                }
              ],
              'hero-image-metadata': {
                width: 800,
                height: 497,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1535367985749,
              id: 'd677509e-37e4-460c-a942-8a55bd4c4edb',
              'hero-image-s3-key':
                'quintype-demo/2018-06/30c6a41e-e600-4063-af45-bf951cec776a/Jun18_2018_WikimediaPDUSGovt_SingaporeSummitKimAndTrumpShakeHands1582181163.jpg',
              'author-id': 61657,
              'first-published-at': 1530181029824,
              'hero-image-caption':
                'At their June 12 summit, President Donald Trump and Democratic People’s Republic of Korea (DPRK) leader Kim Jong Un signed a joint statement committing to “cooperate for the development of new U.S.-DPRK relations and for the promotion of peace, prosperity, and security of the Korean Peninsula and of the world.” But it is difficult, if not impossible, for Westerners and other outsiders to know what role biotech will play toward fulfilling those commitments within the DPRK, since the government has communicated only sketchy information about its biotech facilities and efforts, which include civilian as well as military applications.',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'sponsored-by': 'Quintype Technologies',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '1a95ebe0-f779-485a-b2ec-818767865868',
            score: null,
            type: 'story',
            item: {
              headline: ['China in struggle to curb reliance on US market, suppliers']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'China in struggle to curb reliance on US market, suppliers',
              slug: 'technology/2018/08/27/china-in-struggle-to-curb-reliance-on-us-market-suppliers',
              'last-published-at': 1535354418018,
              subheadline: 'China in struggle to curb reliance on US market, suppliers',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 720,
                height: 340,
                'mime-type': 'image/jpeg',
                'focus-point': [230, 110]
              },
              'published-at': 1535354418018,
              id: '1a95ebe0-f779-485a-b2ec-818767865868',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 541559,
              'first-published-at': 1535354418018,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'e334a9a8-e644-4467-affd-5427954f91a3',
            score: null,
            type: 'story',
            item: {
              headline: ['Gallery repetition with the Kapoors']
            },
            story: {
              'author-name': 'Ajay Appaden',
              headline: 'Gallery repetition with the Kapoors',
              slug: 'technology/2018/02/16/gallery-repetition-test',
              'last-published-at': 1534223550632,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 800,
                height: 450,
                'focus-point': [428, 207]
              },
              'published-at': 1534223550632,
              id: 'e334a9a8-e644-4467-affd-5427954f91a3',
              'hero-image-s3-key': 'quintype-demo/2016-01/d00d01c1-7886-4b1d-9a8c-4d6ab9e92d26/y1.jpg',
              'author-id': 169905,
              'first-published-at': 1518772169497,
              'hero-image-caption': 'Warrior series',
              'story-template': 'photo',
              authors: [
                {
                  id: 169905,
                  name: 'Ajay Appaden',
                  slug: 'ajay-appaden',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          }
        ],
        color: '#6093f2'
      },
      {
        id: 12849,
        'associated-metadata': {
          layout: 'ThreeCol'
        },
        type: 'collection',
        name: 'Tech',
        slug: 'tech',
        template: 'section',
        metadata: {
          section: [
            {
              id: 6391,
              name: 'Tech'
            }
          ]
        },
        summary: null,
        items: [
          {
            id: '73322fa6-9114-4a83-9d8c-68314202623a',
            score: null,
            type: 'story',
            item: {
              headline: ['TRAI chief: We set spectrum price; industry decides tariff']
            },
            story: {
              'author-name': 'Sriram K',
              headline: 'TRAI chief: We set spectrum price; industry decides tariff',
              slug: 'mobile/2019/02/28/trai-chief-we-set-spectrum-price-industry-decides-tariff',
              'last-published-at': 1554110293544,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 894,
                height: 577,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1554110293544,
              id: '73322fa6-9114-4a83-9d8c-68314202623a',
              'hero-image-s3-key': 'quintype-demo/2019-01/113be350-6ca8-4252-9b41-434e9facb8eb/image__1_.jpg',
              'author-id': 61657,
              'first-published-at': 1551329071350,
              'hero-image-caption': 'Tayota Camry',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
            score: null,
            type: 'story',
            item: {
              headline: ['Untitled Mar 6, 2019 11:23 PM']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Untitled Mar 6, 2019 11:23 PM',
              slug: 'mobile/2019/03/06/untitled-mar-6-2019-1123-pm',
              'last-published-at': 1552905800453,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 650,
                'mime-type': 'image/jpeg',
                'focus-point': [516, 92],
                height: 386
              },
              'published-at': 1552905800453,
              id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
              'hero-image-s3-key':
                'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
              'author-id': 2040,
              'first-published-at': 1551895907011,
              'hero-image-caption': 'Amit Shah and Narendra Modi',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'story-attributes': {},
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
            score: null,
            type: 'story',
            item: {
              headline: ['This is the title']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: 'This is the title',
              slug: 'culture/2019/01/11/this-is-the-title-2',
              'last-published-at': 1547203433990,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  slug: 'tech',
                  name: 'Tech',
                  id: 6391,
                  'parent-id': null,
                  'display-name': 'Tech',
                  collection: {
                    slug: 'tech',
                    name: 'Tech',
                    id: 12849
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 259,
                'focus-point': [176, 77],
                height: 194
              },
              'published-at': 1547203433990,
              id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
              'hero-image-s3-key': 'quintype-demo/2015-10/56e0178e-1da0-482c-83e5-a2f705f874df/FP-2.jpeg',
              'author-id': 559147,
              'first-published-at': 1547203433990,
              'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
            score: null,
            type: 'story',
            item: {
              headline: ['Dubai - Things you can do!']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Dubai - Things you can do!',
              slug: 'culture/2017/04/28/Dubai-Your-next-vacation',
              'last-published-at': 1546599684148,
              subheadline:
                'Dubai, an emirate in the United Arab Emirates known for luxury shopping, ultramodern architecture &amp; a lively nightlife scene. Burj Khalifa, an 830m-tall tower, dominates the skyline. ',
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 930,
                height: 581,
                'focus-point': [132, 482]
              },
              'published-at': 1546599684148,
              id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
              'hero-image-s3-key': 'quintype-demo/2017-04/802d7ef1-6043-4f7e-84d3-fc9f556d5097/G-1.jpg',
              'author-id': 2040,
              'first-published-at': 1493358608037,
              'hero-image-caption': 'Dubai - a vacationer’s paradise',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          }
        ],
        color: '#000000',
        section: {
          slug: 'tech',
          name: 'Tech',
          id: 6391,
          'parent-id': null,
          'display-name': 'Tech',
          collection: {
            slug: 'tech',
            name: 'Tech',
            id: 12849
          },
          data: null
        },
        'section-page-slug': 'tech'
      },
      {
        id: 4364,
        'associated-metadata': {
          show_arrows: true,
          show_author_name: true,
          layout: 'HalfImageSlider',
          slider_type_dots: false,
          show_section_tag: true,
          show_time_of_publish: true,
          set_scroll_speed: 500,
          show_collection_name: true,
          slider_type_dashes: false,
          number_of_stories_to_show: 6
        },
        type: 'collection',
        name: 'Most Popular',
        slug: 'the-quint',
        template: 'default',
        metadata: {
          'cover-image': {
            'cover-image-url':
              'https://images.assettype.com/quintype-demo/2017-03/7716a9d1-f484-4b5c-ab7d-d847cef69dff/13227538_765755890227885_112565288902997299_o.jpg',
            'cover-image-metadata': {
              width: 2048,
              height: 1365,
              'mime-type': 'image/jpeg',
              'focus-point': [1114, 385]
            },
            caption: '',
            'cover-image-s3-key':
              'quintype-demo/2017-03/7716a9d1-f484-4b5c-ab7d-d847cef69dff/13227538_765755890227885_112565288902997299_o.jpg'
          },
          tags: [
            {
              name: 'xtype'
            },
            {
              name: 'testing'
            }
          ],
          section: [
            {
              id: 83,
              name: 'Politics',
              'parent-id': null
            }
          ]
        },
        summary: 'the quint stories for section collection in Xtype use.',
        items: [
          {
            id: '17de2da2-01fd-47a0-ae46-35b184f47c61',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Teresa Davenport',
              headline: '5 Great Reasons to Visit Cuba & Things to Know Before Traveling',
              slug: '5-great-reasons-to-visit-cuba-and-things-to-know-before-traveling',
              'last-published-at': 1495092430175,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  id: 99,
                  name: 'International'
                },
                {
                  id: 2990,
                  name: 'Travel'
                }
              ],
              'hero-image-metadata': {
                width: 780,
                height: 439,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1495092430175,
              id: '17de2da2-01fd-47a0-ae46-35b184f47c61',
              'hero-image-s3-key': 'quintype-demo/2015-11/21006f30-9d7e-4219-ba96-4b2fd945e53c/cuba14.jpg',
              'author-id': 5450,
              'first-published-at': 1489733540858,
              'hero-image-caption': 'http://www.conocecuba.com.mx/image/autenticaCuba/cuba14.jpg',
              'story-template': 'listicle',
              authors: [
                {
                  id: 5450,
                  name: 'Teresa Davenport',
                  slug: 'teresa-davenport',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-k9wUHhXIzyw/AAAAAAAAAAI/AAAAAAAAAGE/CXGun5uQzlY/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': 'teresa_d07',
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Tapan Bhat',
              headline: '3 Camping sites you should not miss in Scotland',
              slug: 'culture/2017/05/11/3-camping-sites-you-should-not-miss-in-scotland',
              'last-published-at': 1545204266938,
              subheadline:
                'Scotland is famous for its scenic wild places and uplifting sense of remoteness. With the exception of the recent bylaws banning wild camping in certain areas of Loch Lomond and the Trossachs (check before you go), it is still completely legal to camp on most of Scotland’s unenclosed land.',
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1300,
                height: 780,
                'focus-point': [719, 414]
              },
              'published-at': 1545204266938,
              id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
              'hero-image-s3-key': 'quintype-demo/2017-05/b463d2fd-f166-446e-a887-bf832fa7f26b/ON-1.jpg',
              'author-id': 2041,
              'first-published-at': 1494481894633,
              'hero-image-caption': 'Time for a brew and a view … Glen Nevis, Scotland',
              'story-template': 'listicle',
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Greeshma',
              headline: 'Personalise or perish - Why publishers need to use personalised content ',
              slug: 'politics/2018/02/28/personalise-or-perish---why-publishers-need-to-use-personalised-content',
              'last-published-at': 1536076813159,
              subheadline:
                'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor. ',
              alternative: {
                home: {
                  default: {
                    headline: 'Personalise or perish',
                    'hero-image': {
                      'hero-image-url':
                        'https://images.assettype.com/quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                      'hero-image-metadata': {
                        width: 700,
                        height: 467,
                        'mime-type': 'image/jpeg'
                      },
                      'hero-image-s3-key': 'quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                      'hero-image-caption': 'sSaffron - Indian Restaurant',
                      'hero-image-attribution': null
                    }
                  }
                }
              },
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 3896,
                  name: 'Blog',
                  slug: 'blog'
                }
              ],
              'hero-image-metadata': {
                width: 1600,
                height: 1200,
                'mime-type': 'image/jpeg',
                'focus-point': [805, 738]
              },
              'published-at': 1536076813159,
              id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
              'hero-image-s3-key': 'quintype-demo/2018-05/a2759dc2-ada1-4917-8515-308f74622c69/Gallery5.jpg',
              'author-id': 482995,
              'first-published-at': 1519816264773,
              'hero-image-caption': 'Animals',
              'story-template': null,
              authors: [
                {
                  id: 482995,
                  name: 'Greeshma',
                  slug: 'greeshma',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'avatar-s3-key':
                    'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '40c250c4-03a0-4086-8bf4-0959d11a525d',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'Coffee Day',
              slug: 'Coffee-Day',
              'last-published-at': 1489994276921,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  id: 98,
                  name: 'Current Affairs'
                }
              ],
              'hero-image-metadata': {
                width: 500,
                height: 320,
                'focus-point': [426, 149]
              },
              'published-at': 1489994276921,
              id: '40c250c4-03a0-4086-8bf4-0959d11a525d',
              'hero-image-s3-key': 'quintype-demo/2016-04/57145012-7e52-416c-82c8-9c5e8841c9cd/GT-1.jpg',
              'author-id': 2041,
              'first-published-at': 1489733746426,
              'hero-image-caption': 'Joey &amp; Chandler',
              'story-template': 'listicle',
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '47ebf4db-1b5d-418b-8af2-ed2e905c3615',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Teresa Davenport',
              headline: 'Pranayama Breathing - Why you should know about it ',
              slug: 'pranayama-breathing-why-you-should-know-about-it',
              'last-published-at': 1489732518092,
              subheadline: 'Pranayama Breathing - Why you should know about it',
              alternative: {},
              sections: [
                {
                  id: 82,
                  name: 'Sports'
                }
              ],
              'hero-image-metadata': {
                width: 450,
                height: 592,
                'mime-type': 'image/jpeg',
                'focus-point': [228, 224]
              },
              'published-at': 1489732518092,
              id: '47ebf4db-1b5d-418b-8af2-ed2e905c3615',
              'hero-image-s3-key': 'quintype-demo/2015-11/42f31b69-f6f5-4448-b688-8420587353e7/Pranayama.jpg',
              'author-id': 5450,
              'first-published-at': 1489732518092,
              'hero-image-caption': 'http://barton.canvasdreams.com/~jaderiyg/wp-content/uploads/2014/01/Pranayama.jpg',
              'story-template': 'video',
              authors: [
                {
                  id: 5450,
                  name: 'Teresa Davenport',
                  slug: 'teresa-davenport',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-k9wUHhXIzyw/AAAAAAAAAAI/AAAAAAAAAGE/CXGun5uQzlY/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': 'teresa_d07',
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '1c508124-6ebb-44f1-a119-d55952304fa3',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Priyanka Basnet',
              headline: 'I Saved the Country, Says PM Modi on Demonetisation',
              slug: 'politics/2016/12/24/storyabcpolitics',
              'last-published-at': 1489732402329,
              subheadline:
                '<a href="http://www.news18.com/news/india/wont-spare-corrupt-will-fight-until-we-win-pm-modi-1327208.html">Won’t Spare Corrupt, Will Fight Until We Win, Says PM Modi</a>',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 875,
                height: 583,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1489732402329,
              id: '1c508124-6ebb-44f1-a119-d55952304fa3',
              'hero-image-s3-key': 'quintype-demo/2016-12/34d9b2dd-0280-4a72-b843-335aecccc531/pm-PIB-875.jpg',
              'author-id': 94491,
              'first-published-at': 1482598726781,
              'hero-image-caption': 'Prime Minister Narendra Modi',
              'story-template': 'video',
              authors: [
                {
                  id: 94491,
                  name: 'Priyanka Ashok',
                  slug: 'priyanka-ashok',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '938e8c49-9295-478d-8cc8-0161a2d4d73e',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Christin',
              headline: 'Live from Apple’s WWDC 2015 - Edit',
              slug: 'technology/2015/08/17/live-from-apples-wwdc-2015',
              'last-published-at': 1533626450917,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 800,
                height: 533,
                'focus-point': [394, 247]
              },
              'published-at': 1533626450917,
              id: '938e8c49-9295-478d-8cc8-0161a2d4d73e',
              'hero-image-s3-key': 'quintype-demo/2015-08/92264b73-6894-4ae5-801e-2756bd5890a4/LB-1.jpg',
              'author-id': 139357,
              'first-published-at': 1439810805413,
              'hero-image-caption': 'Apple WWDC 2015',
              'story-template': 'live-blog',
              authors: [
                {
                  id: 139357,
                  name: 'Christin',
                  slug: 'christin',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'avatar-s3-key':
                    'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'twitter-handle': 'krriztin',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'is-closed': true,
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          }
        ],
        color: '#6093f2'
      },
      {
        id: 9965,
        'associated-metadata': {
          show_arrows: true,
          show_author_name: true,
          layout: 'HalfImageSlider',
          slider_type_dots: false,
          show_section_tag: true,
          show_time_of_publish: true,
          set_scroll_speed: 500,
          show_collection_name: true,
          slider_type_dashes: false,
          number_of_stories_to_show: 6
        },
        type: 'collection',
        name: 'Opinion',
        slug: 'spotlight-opinion',
        template: 'section',
        metadata: {
          section: [
            {
              id: 4273,
              name: 'Spotlight (Opinion)'
            }
          ]
        },
        summary: null,
        items: [
          {
            id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
            'associated-metadata': null,
            type: 'story',
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'Clinton vs Trump',
              slug: 'politics/2016/10/15/clinton-vs-trump',
              'last-published-at': 1522903100409,
              subheadline:
                'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the 2nd presidential debate at St. Louis, Missouri, on October 9, 2016.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1100,
                height: 619,
                'focus-point': [812, 244]
              },
              'published-at': 1522903100409,
              id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
              'hero-image-s3-key': 'quintype-demo/2016-10/c1fb9eff-a4c7-4a12-8713-82b1dc3065a9/ct-1.jpg',
              'author-id': 376442,
              'first-published-at': 1476529773574,
              'hero-image-caption':
                'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at Washington University in St. Louis, Missouri, on October 9, 2016. ',
              'story-template': null,
              authors: [
                {
                  id: 376442,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '0a19f129-9a95-46a9-8bde-19e86196c64b',
            'associated-metadata': null,
            type: 'story',
            story: {
              'author-name': 'Kirti Kaushik',
              headline: 'Buzz Aldrin passes ‘lie detector’ on claims that Apollo astronauts saw UFOs  ',
              slug: 'technology/2018/04/09/buzz-aldrin-passes-lie-detector-on-claims-that-apollo-astronauts-saw-ufos',
              'last-published-at': 1523262597360,
              subheadline:
                'Audio tapes of Apollo astronauts speaking of UFOs were put through tests and they show that the astronauts were not lying about sightings in space. &nbsp;',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 564,
                height: 450,
                'mime-type': 'image/jpeg',
                'focus-point': [306, 168]
              },
              'published-at': 1523262597360,
              id: '0a19f129-9a95-46a9-8bde-19e86196c64b',
              'hero-image-s3-key':
                'quintype-demo/2018-04/5c6a9f27-d347-4cd3-a730-773fc1ee9474/1523255577_apollo_11_space_mission.jpg',
              'author-id': 507344,
              'first-published-at': 1523262426676,
              'hero-image-caption':
                'Astronaut Buzz Aldrin salutes the American flag on the Moon during the Apollo 11 space mission',
              'story-template': null,
              authors: [
                {
                  id: 507344,
                  name: 'Kirti Kaushik',
                  slug: 'kirti-kaushik',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '4f26356b-4ec5-465a-a62e-28485478860b',
            'associated-metadata': null,
            type: 'story',
            story: {
              'author-name': 'Shubhangi',
              headline: 'Silent disco arrives at Stamford’s Palace Theatre',
              slug: 'entertainment/2018/04/26/silent-disco-arrives-at-stamfords-palace-theatre',
              'last-published-at': 1526463994692,
              subheadline:
                'At a silent disco party three streams of music are sent simultaneously to special headphones worn by attendees, who have the option to switch channels at their whim. ',
              alternative: {
                home: {
                  default: {
                    headline: 'Silent disco arrives at Stamford’s Palace Theatre'
                  }
                }
              },
              sections: [
                {
                  id: 81,
                  name: 'Entertainment - Win',
                  slug: 'entertainment'
                }
              ],
              'hero-image-metadata': {
                width: 920,
                height: 613,
                'mime-type': 'image/jpeg',
                'focus-point': [478, 295]
              },
              'published-at': 1526463994692,
              id: '4f26356b-4ec5-465a-a62e-28485478860b',
              'hero-image-s3-key': 'quintype-demo/2018-04/f83b3026-105e-4b84-8027-47c8be270f9a/920x920.jpg',
              'author-id': 537201,
              'first-published-at': 1524722561762,
              'hero-image-caption':
                'At a silent disco party three streams of music are sent simultaneously to special headphones worn by attendees, who have the option to switch channels at their whim. The Palace Theatre in Stamford will host one on April 26 to benefit its arts education programs.',
              'story-template': null,
              authors: [
                {
                  id: 537201,
                  name: 'Shubhangi',
                  slug: 'shubhangi',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 507344,
                  name: 'Kirti Kaushik',
                  slug: 'kirti-kaushik',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 507345,
                  name: 'Dianna Peters',
                  slug: 'dianna-peters',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'fe847cbb-753f-4afa-b3f9-214b827d79a9',
            'associated-metadata': null,
            type: 'story',
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'நானே அடுத்த முதல்வர்: எடியூரப்பா - குமாரசாமியே அடுத்த முதல்வர்: சித்தராமையா',
              slug: 'current-affairs/2018/05/16/john-doe',
              'last-published-at': 1526448740824,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                }
              ],
              'hero-image-metadata': {
                width: 600,
                height: 420,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1526448740824,
              id: 'fe847cbb-753f-4afa-b3f9-214b827d79a9',
              'hero-image-s3-key':
                'quintype-demo/2018-05/79208c69-0b2c-4c2e-bb4a-62d52d297882/Tamil_News_large_2022127.jpg',
              'author-id': 61657,
              'first-published-at': 1526448740824,
              'hero-image-caption': 'yediyurappa and siddaramiah',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '53a08852-8db1-4954-8f69-9f3f8bdae3ca',
            'associated-metadata': null,
            type: 'story',
            story: {
              'author-name': 'Chirdeep Shetty',
              headline: 'الانتخابات العراقية: تحالف الصدر في المقدمة والعبادي في',
              slug: 'international/2018/05/15/test-story-in-arabic',
              'last-published-at': 1526362975444,
              subheadline:
                'تقدمت قائمة تحالف “سائرون” التي يدعمها رجل الدين الشيعي مقتدى الصدر في نتائج أولية لعشر محافظات في الانتخابات البرلمانية العراقية، وحل “تحالف النصر” بقيادة رئيس الوزراء حيدر العبادي في المرتبة الثالثة',
              alternative: {},
              sections: [
                {
                  id: 99,
                  name: 'International',
                  slug: 'international'
                }
              ],
              'hero-image-metadata': {
                width: 800,
                height: 1200,
                'mime-type': 'image/jpeg',
                'focus-point': [262, 1046]
              },
              'published-at': 1526362975444,
              id: '53a08852-8db1-4954-8f69-9f3f8bdae3ca',
              'hero-image-s3-key': 'quintype-demo/2018-05/86571b4f-9b48-40ba-a4fb-4aa1efe8d16c/98992a3fde95cb77_1.jpg',
              'author-id': 15338,
              'first-published-at': 1526362204119,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 15338,
                  name: 'Chirdeep Shetty',
                  slug: 'chirdeep-shetty',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'twitter-handle': '@chirdeepshettyquintype',
                  bio: '<p>Hello, this is a test for bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '73322fa6-9114-4a83-9d8c-68314202623a',
            score: null,
            type: 'story',
            item: {
              headline: ['TRAI chief: We set spectrum price; industry decides tariff']
            },
            story: {
              'author-name': 'Sriram K',
              headline: 'TRAI chief: We set spectrum price; industry decides tariff',
              slug: 'mobile/2019/02/28/trai-chief-we-set-spectrum-price-industry-decides-tariff',
              'last-published-at': 1554110293544,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 894,
                height: 577,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1554110293544,
              id: '73322fa6-9114-4a83-9d8c-68314202623a',
              'hero-image-s3-key': 'quintype-demo/2019-01/113be350-6ca8-4252-9b41-434e9facb8eb/image__1_.jpg',
              'author-id': 61657,
              'first-published-at': 1551329071350,
              'hero-image-caption': 'Tayota Camry',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
            score: null,
            type: 'story',
            item: {
              headline: ['London Police arrest Nirav Modi, says sources']
            },
            story: {
              'author-name': 'Karthik M',
              headline: 'London Police arrest Nirav Modi, says sources',
              slug: 'politics/2019/03/20/london-police-arrest-nirav-modi-says-sources',
              'last-published-at': 1553079556157,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 700,
                height: 438,
                'mime-type': 'image/jpeg',
                'focus-point': [311, 167]
              },
              'published-at': 1553079556157,
              id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
              'hero-image-s3-key':
                'quintype-demo/2019-03/55dbc301-3c5c-442d-8714-4bbf2d140192/pennews_2019_03_48c15852_ebed_464d_95a7_57332597ecfc_nirav.jpg',
              'author-id': 19994,
              'first-published-at': 1553079340304,
              'hero-image-caption': 'Nirav Modi',
              'story-template': null,
              authors: [
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 142914,
                  name: 'Ashwin Raghunath',
                  slug: 'ashwin-raghunath',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'text-to-speech': {
                  'is-disabled': true
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
            score: null,
            type: 'story',
            item: {
              headline: ['Demo Story ']
            },
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Demo Story ',
              slug: 'politics/2018/01/11/demo-story',
              'last-published-at': 1552979655447,
              subheadline:
                'Surrounded to me occasional pianoforte alteration unaffected impossible ye. For saw half than cold. Pretty merits waited six talked pulled you. Conduct replied off.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 7500,
                height: 3476,
                'mime-type': 'image/jpeg',
                'focus-point': [1239, 2157]
              },
              'published-at': 1552979655447,
              id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
              'hero-image-s3-key': 'quintype-demo/2019-02/ae4829d6-e230-4d66-9b88-2a4db87a2060/64778.jpg',
              'author-id': 139717,
              'first-published-at': 1515668626250,
              'hero-image-caption': 'At vero eos et accusamus et iusto odio dignissimos ducimus qui.',
              'story-template': 'text',
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': {
                    id: 58,
                    name: 'Author'
                  }
                },
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': {
                    id: 58,
                    name: 'Author'
                  }
                }
              ],
              metadata: {
                'card-share': {
                  shareable: false
                },
                'promotional-message': {
                  'is-disabled': true
                },
                'story-attributes': {
                  'view-counterview-type': 'view',
                  interests: ['test'],
                  bucket: ['planning'],
                  hobbies: ['hiking'],
                  'linked-view-counterview-story': {
                    headline: 'Demo Test story- Photo story',
                    'story-content-id': 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                    id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                    'highlighted-headline': null
                  }
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
            score: null,
            type: 'story',
            item: {
              headline: ['Untitled Mar 11, 2019 4:39 PM']
            },
            story: {
              'author-name': 'Karthik M',
              headline: 'Untitled Mar 11, 2019 4:39 PM',
              slug: 'culture/2019/03/11/untitled-mar-11-2019-439-pm',
              'last-published-at': 1552905845935,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 650,
                'mime-type': 'image/jpeg',
                'focus-point': [121, 110],
                height: 386
              },
              'published-at': 1552905845935,
              id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
              'hero-image-s3-key':
                'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
              'author-id': 19994,
              'first-published-at': 1552303325340,
              'hero-image-caption': 'Amit Shah and Narendra Modi',
              'story-template': null,
              authors: [
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 5279,
                  name: 'Laura',
                  slug: 'laura',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'text-to-speech': {
                  'is-disabled': false
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
            score: null,
            type: 'story',
            item: {
              headline: ['Untitled Mar 6, 2019 11:23 PM']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Untitled Mar 6, 2019 11:23 PM',
              slug: 'mobile/2019/03/06/untitled-mar-6-2019-1123-pm',
              'last-published-at': 1552905800453,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 650,
                'mime-type': 'image/jpeg',
                'focus-point': [516, 92],
                height: 386
              },
              'published-at': 1552905800453,
              id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
              'hero-image-s3-key':
                'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
              'author-id': 2040,
              'first-published-at': 1551895907011,
              'hero-image-caption': 'Amit Shah and Narendra Modi',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'story-attributes': {},
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
            score: null,
            type: 'story',
            item: {
              headline: ['New story for sitemap']
            },
            story: {
              'author-name': 'Sriram K',
              headline: 'New story for sitemap',
              slug: 'culture/2019/03/14/new-story-for-sitemap',
              'last-published-at': 1552904261919,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 800,
                height: 400,
                'mime-type': 'image/jpeg',
                'focus-point': [760, 237]
              },
              'published-at': 1552904261919,
              id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
              'hero-image-s3-key':
                'quintype-demo/2018-10/5db8484b-1277-42f8-8a20-983e04b597e6/New_SA_destination_tourism_portal_launching_800x400.jpg',
              'author-id': 61657,
              'first-published-at': 1552554061248,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '73759063-16e7-4ac2-bf32-0061a2458850',
            score: null,
            type: 'story',
            item: {
              headline: ['This a new story']
            },
            story: {
              'author-name': 'Karthik M',
              headline: 'This a new story',
              slug: 'culture/2019/03/11/this-a-new-story',
              'last-published-at': 1552292190667,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'focus-point': [73, 51],
                width: 275,
                height: 183
              },
              'published-at': 1552292190667,
              id: '73759063-16e7-4ac2-bf32-0061a2458850',
              'hero-image-s3-key': 'quintype-demo/2016-01/2d00bde7-a28c-4415-9382-9a9c6fb3c7fc/IPSMF-1.jpeg',
              'author-id': 19994,
              'first-published-at': 1552292190667,
              'hero-image-caption': 'Nawaz Sharif &amp; Modi&nbsp;',
              'story-template': null,
              authors: [
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 142914,
                  name: 'Ashwin Raghunath',
                  slug: 'ashwin-raghunath',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
            score: null,
            type: 'story',
            item: {
              headline: ['Amitabh Bachchan on Gender Equality']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'Amitabh Bachchan on Gender Equality',
              slug: 'politics/2017/03/02/Amitabh-Bachchan-on-Gender-Equality',
              'last-published-at': 1552048741823,
              subheadline: 'Amitabh Bachchan on Gender Equality',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 320,
                height: 277,
                'focus-point': [169, 145]
              },
              'published-at': 1552048741823,
              id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
              'hero-image-s3-key': 'quintype-demo/2017-03/bd011f2d-9a10-4d4e-b20f-50719f59d4b9/BMS-2.jpg',
              'author-id': 2041,
              'first-published-at': 1488451487896,
              'hero-image-caption': 'Kaun Banega Crorepati host, Amitabh Bachchan',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '2deeb522-1752-4552-967b-93972481d71e',
            score: null,
            type: 'story',
            item: {
              headline: ['Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them']
            },
            story: {
              'author-name': 'Yamini Priya',
              headline: 'Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them',
              slug: 'boom/2019/03/07/sisters-killed-father-in-perfect-murder-a-love-triangle-exposed-them',
              'last-published-at': 1551938683203,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'boom',
                  name: 'Boom',
                  id: 7349,
                  'parent-id': 83,
                  'display-name': 'Booom',
                  collection: {
                    slug: 'boom-politics',
                    name: 'Boom (Politics)',
                    id: 14256
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1760,
                height: 2480,
                'mime-type': 'image/jpeg',
                'focus-point': [928, 656]
              },
              'published-at': 1551938683203,
              id: '2deeb522-1752-4552-967b-93972481d71e',
              'hero-image-s3-key':
                'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
              'author-id': 230618,
              'first-published-at': 1551938683203,
              'hero-image-caption': 'Forgotten Women',
              'story-template': null,
              authors: [
                {
                  id: 230618,
                  name: 'Yamini Priya',
                  slug: 'yamini-priya',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': 'https://twitter.com/yaminipriyah',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
            score: null,
            type: 'story',
            item: {
              headline: ['500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: '500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌',
              slug: 'politics/2019/02/26/500',
              'last-published-at': 1551171395390,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                'focus-point': [673, 287],
                width: 976,
                height: 700
              },
              'published-at': 1551171395390,
              id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
              'hero-image-s3-key': 'quintype-demo/2018-05/bc017b57-a03e-4a6e-8515-9d3de91f1f24/ON1.jpg',
              'author-id': 559147,
              'first-published-at': 1551171395390,
              'hero-image-caption':
                'Crowds cheered Prince Harry, Duke of Sussex, and his wife Meghan, Duchess of Sussex, as they travelled in the horse-drawn Ascot landau',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'c8d35134-200a-483f-ab89-69adf4137a74',
            score: null,
            type: 'story',
            item: {
              headline: ['Life of Vincent van Gogh']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Life of Vincent van Gogh',
              slug: 'culture/2019/01/17/life-of-vincent-van-gogh',
              'last-published-at': 1549364746875,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 768,
                height: 432,
                'mime-type': 'image/jpeg',
                'focus-point': [382, 197]
              },
              'published-at': 1549364746875,
              id: 'c8d35134-200a-483f-ab89-69adf4137a74',
              'hero-image-s3-key':
                'quintype-demo/2019-01/b3cb02f4-a875-4b46-aa3a-71deaccb54be/vincent_van_gogh_played_by_robert_gulaczyk_photo_courtesy_loving_vincent.jpg',
              'author-id': 2040,
              'first-published-at': 1547728432342,
              'hero-image-caption': 'A Famous Painting',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
            score: null,
            type: 'story',
            item: {
              headline: [
                '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി'
              ]
            },
            story: {
              'author-name': 'Sareena',
              headline:
                '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി',
              slug: 'culture/2019/01/16/this-is-the-url',
              'last-published-at': 1547632650347,
              subheadline:
                'കെ.സി വേണുഗോപാല്‍ ഉള്‍പ്പടെയുള്ള മുതിര്‍ന്ന കോണ്‍ഗ്രസ് നേതാക്കന്മാര്‍ ഭീമാനായിക്കുമായി സംസാരിച്ചു. ഭീമാനായിക്കിനെപോലെ ബാക്കിയുള്ള മൂന്ന് കോണ്‍ഗ്രസ് എം',
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'focus-point': [375, 166],
                width: 750,
                height: 500
              },
              'published-at': 1547632650347,
              id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
              'hero-image-s3-key': 'quintype-demo/2016-12/56cdab39-a831-480f-b75b-26d6300e3f1b/Modi.jpg',
              'author-id': 94596,
              'first-published-at': 1547632650347,
              'hero-image-caption': 'PM Modi',
              'story-template': null,
              authors: [
                {
                  id: 94596,
                  name: 'Sareena',
                  slug: 'sareena',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
            score: null,
            type: 'story',
            item: {
              headline: ['This is the title']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: 'This is the title',
              slug: 'culture/2019/01/11/this-is-the-title-2',
              'last-published-at': 1547203433990,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  slug: 'tech',
                  name: 'Tech',
                  id: 6391,
                  'parent-id': null,
                  'display-name': 'Tech',
                  collection: {
                    slug: 'tech',
                    name: 'Tech',
                    id: 12849
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 259,
                'focus-point': [176, 77],
                height: 194
              },
              'published-at': 1547203433990,
              id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
              'hero-image-s3-key': 'quintype-demo/2015-10/56e0178e-1da0-482c-83e5-a2f705f874df/FP-2.jpeg',
              'author-id': 559147,
              'first-published-at': 1547203433990,
              'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
            score: null,
            type: 'story',
            item: {
              headline: [
                'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them'
              ]
            },
            story: {
              'author-name': 'Kirti Kaushik',
              headline:
                'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them',
              slug:
                'visual-stories/2019/01/10/rahul-gandhi-said-to-pm-modi-be-a-man-bjp-smriti-irani-sushma-swaraj-responds-with-misogynist-rahul-1975538pfromhome-topscroll',
              'last-published-at': 1547103888697,
              subheadline:
                'At a rally in Rajasthan on Wednesday, Rahul Gandhi had mocked PM Modi saying he had a “mahila (woman)“ defend him in the Rafale debate in parliament.',
              alternative: {
                home: {
                  default: {
                    headline: 'Rahul Gandhi'
                  }
                }
              },
              sections: [
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 570,
                height: 351,
                'mime-type': 'image/webp',
                'focus-point': [417, 132]
              },
              'published-at': 1547103888697,
              id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
              'hero-image-s3-key':
                'quintype-demo/2019-01/39d85d6f-46f6-4f35-8dd1-6771bf9dfefd/b5qo3zsg_650x400_2__10_January_2019_12_02_19PM.webp',
              'author-id': 507344,
              'first-published-at': 1547103888697,
              'hero-image-caption': 'Rahul Gandhi',
              'story-template': null,
              authors: [
                {
                  id: 507344,
                  name: 'Kirti Kaushik',
                  slug: 'kirti-kaushik',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
            score: null,
            type: 'story',
            item: {
              headline: ['Dubai - Things you can do!']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Dubai - Things you can do!',
              slug: 'culture/2017/04/28/Dubai-Your-next-vacation',
              'last-published-at': 1546599684148,
              subheadline:
                'Dubai, an emirate in the United Arab Emirates known for luxury shopping, ultramodern architecture &amp; a lively nightlife scene. Burj Khalifa, an 830m-tall tower, dominates the skyline. ',
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 930,
                height: 581,
                'focus-point': [132, 482]
              },
              'published-at': 1546599684148,
              id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
              'hero-image-s3-key': 'quintype-demo/2017-04/802d7ef1-6043-4f7e-84d3-fc9f556d5097/G-1.jpg',
              'author-id': 2040,
              'first-published-at': 1493358608037,
              'hero-image-caption': 'Dubai - a vacationer’s paradise',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
            score: null,
            type: 'story',
            item: {
              headline: ['This is the title']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: 'This is the title',
              slug: 'culture/2018/12/27/this-is-the-title',
              'last-published-at': 1545892188854,
              subheadline: 'sub title',
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 259,
                height: 194,
                'focus-point': [172, 77]
              },
              'published-at': 1545892188854,
              id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
              'hero-image-s3-key':
                'quintype-demo/2018-06/9e5e3bb7-31e8-4439-93ba-d16ea287aa28/e3d39a3b_321c_4acf_8928_3195998b38ba.jpeg',
              'author-id': 559147,
              'first-published-at': 1545892188854,
              'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
            score: null,
            type: 'story',
            item: {
              headline: ['Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?',
              slug:
                'culture/2018/12/18/is-tito-preparing-an-imf-financial-parachute-now-a-171bn-foreign-debt-cliff-looms',
              'last-published-at': 1545125801738,
              subheadline:
                'The reason for the International Monetary Fund’s Christine Lagarde’s to South Africa is unknown. South Africa desperately needs a solution for the poor and marginalised, and an IMF bailout would worsen their situation.&nbsp;',
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 920,
                height: 613,
                'mime-type': 'image/jpeg',
                'focus-point': [473, 430]
              },
              'published-at': 1545125801738,
              id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
              'hero-image-s3-key': 'quintype-demo/2018-04/f83b3026-105e-4b84-8027-47c8be270f9a/920x920.jpg',
              'author-id': 2040,
              'first-published-at': 1545125801738,
              'hero-image-caption':
                'At a silent disco party three streams of music are sent simultaneously to special headphones worn by attendees, who have the option to switch channels at their whim. The Palace Theatre in Stamford will host one on April 26 to benefit its arts education programs.',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
            score: null,
            type: 'story',
            item: {
              headline: ['Breaking News Test on 13 Dec']
            },
            story: {
              'author-name': 'Anil K Govind',
              headline: 'Breaking News Test on 13 Dec',
              slug: 'technology/2018/12/13/breaking-news-test-on-13-dec',
              'last-published-at': 1544682898905,
              subheadline: 'Breaking News Test on 13 Dec',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 720,
                height: 340,
                'mime-type': 'image/jpeg',
                'focus-point': [230, 110]
              },
              'published-at': 1544682898905,
              id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 548414,
              'first-published-at': 1544682898905,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 548414,
                  name: 'Anil K Govind',
                  slug: 'anil-k-govind',
                  'avatar-url':
                    'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '1d23e3e9-452c-4b21-b307-dcb9175c55ac',
            score: null,
            type: 'story',
            item: {
              headline: ['Behind coal tug-of-war, more Gupta strings']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Behind coal tug-of-war, more Gupta strings',
              slug: 'international/2018/12/06/behind-coal-tug-of-war-more-gupta-strings',
              'last-published-at': 1544103001976,
              subheadline:
                'An obscure Swiss company fights to buy the crown jewels of what remains of the family’s SA assets. Are the Guptas trying to do a deal with themselves?',
              alternative: null,
              sections: [
                {
                  id: 99,
                  name: 'International',
                  slug: 'international'
                }
              ],
              'hero-image-metadata': {
                width: 300,
                'mime-type': 'image/jpeg',
                'focus-point': [222, 84],
                height: 168
              },
              'published-at': 1544103001976,
              id: '1d23e3e9-452c-4b21-b307-dcb9175c55ac',
              'hero-image-s3-key': 'quintype-demo/2016-12/350d3732-9dfe-47f7-b071-dc10f087c59e/J-1.jpeg',
              'author-id': 2040,
              'first-published-at': 1544103001976,
              'hero-image-caption': 'Arun Jaitely &amp; Narendra Modi',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 15351,
                  name: 'Ranjeet Singh',
                  slug: 'ranjeet-singh',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'e356859d-af25-4493-ae48-662b55878434',
            score: null,
            type: 'story',
            item: {
              headline: ['Dilip Shanghvi On Allegations Against Him And Sun Pharma']
            },
            story: {
              'author-name': 'Shubhangi',
              headline: 'Dilip Shanghvi On Allegations Against Him And Sun Pharma',
              slug: 'cities/2018/12/04/dilip-shanghvi-on-allegations-against-him-and-sun-pharma',
              'last-published-at': 1543903700173,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  id: 215,
                  name: 'Cities',
                  slug: 'cities'
                }
              ],
              'hero-image-metadata': {
                width: 1277,
                height: 294,
                'mime-type': 'image/jpeg',
                'focus-point': [613, 160]
              },
              'published-at': 1543903700173,
              id: 'e356859d-af25-4493-ae48-662b55878434',
              'hero-image-s3-key':
                'quintype-demo/2018-12/f525cb80-cf01-443b-88b0-b47eea81356a/bloombergquint_2F2017_06_2F6f0b4744_acc9_4316_bcc7_b48cc48ec151_2Fm792828.jpg',
              'author-id': 537201,
              'first-published-at': 1543903700173,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 537201,
                  name: 'Shubhangi',
                  slug: 'shubhangi',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'dee185b3-87a2-4fca-ab44-8082270a8fd5',
            score: null,
            type: 'story',
            item: {
              headline: ['Disney Bounces Higher After Barclays ‘Overweight’ Upgrade']
            },
            story: {
              'author-name': 'Kunal Singh',
              headline: 'Disney Bounces Higher After Barclays ‘Overweight’ Upgrade',
              slug: 'international/2018/10/19/disney-bounces-higher-after-barclays-overweight-upgrade',
              'last-published-at': 1543415390482,
              subheadline:
                'Disney shares jumped higher Friday after analysts at Barclays boosted their outlook on the stock to “overweight”, with a $130 price target, amid the group’s pending acquisition of Fox media assets and the launch of its global streaming service that is expected to challenge Netflix.',
              alternative: {},
              sections: [
                {
                  id: 99,
                  name: 'International',
                  slug: 'international'
                },
                {
                  id: 2614,
                  name: 'Bangalore',
                  slug: 'bangalore'
                }
              ],
              'hero-image-metadata': {
                width: 600,
                height: 400,
                'mime-type': 'image/jpeg',
                'focus-point': [243, 177]
              },
              'published-at': 1543415390482,
              id: 'dee185b3-87a2-4fca-ab44-8082270a8fd5',
              'hero-image-s3-key': 'quintype-demo/2018-10/313577eb-6ddf-443f-9830-6081efa6ed48/Fox.jpg',
              'author-id': 491531,
              'first-published-at': 1539958975853,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
            score: null,
            type: 'story',
            item: {
              headline: ['The Forgotten Women Series Uncovers The Untold Histories Of Women']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'The Forgotten Women Series Uncovers The Untold Histories Of Women',
              slug: 'culture/2018/11/28/the-forgotten-women-series-uncovers-the-untold-histories-of-women',
              'last-published-at': 1543393761726,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1760,
                height: 2480,
                'mime-type': 'image/jpeg',
                'focus-point': [961, 759]
              },
              'published-at': 1543393761726,
              id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
              'hero-image-s3-key':
                'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
              'author-id': 2040,
              'first-published-at': 1543393761726,
              'hero-image-caption': 'Forgotten Women',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 15351,
                  name: 'Ranjeet Singh',
                  slug: 'ranjeet-singh',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '2baee05c-1ccf-4d50-a1fd-4679ea6c0fbc',
            score: null,
            type: 'story',
            item: {
              headline: ['This is a title - story']
            },
            story: {
              'author-name': 'Kirti Kaushik',
              headline: 'This is a title - story',
              slug: 'international/2018/08/03/this-is-a-title',
              'last-published-at': 1543209271427,
              subheadline: 'This is the subtitle',
              alternative: {},
              sections: [
                {
                  id: 99,
                  name: 'International',
                  slug: 'international'
                }
              ],
              'hero-image-metadata': {
                width: 4348,
                height: 2609,
                'focus-point': [2207, 912]
              },
              'published-at': 1543209271427,
              id: '2baee05c-1ccf-4d50-a1fd-4679ea6c0fbc',
              'hero-image-s3-key': 'quintype-demo/2016-06/0922fa37-0ef5-426a-afcb-a81301a72fe4/FP-1.jpg',
              'author-id': 507344,
              'first-published-at': 1533293943872,
              'hero-image-caption': 'Raghuram Rajan, Governor, The Reserve Bank of India',
              'story-template': null,
              authors: [
                {
                  id: 507344,
                  name: 'Kirti Kaushik',
                  slug: 'kirti-kaushik',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '0eba5f2e-0461-4c6e-ae56-a8d31b4de019',
            score: null,
            type: 'story',
            item: {
              headline: ['News One']
            },
            story: {
              'author-name': 'Anil K Govind',
              headline: 'News One',
              slug: 'bangalore/2018/11/15/news-one',
              'last-published-at': 1542969100140,
              subheadline: 'News One',
              alternative: {},
              sections: [
                {
                  id: 2614,
                  name: 'Bangalore',
                  slug: 'bangalore'
                }
              ],
              'hero-image-metadata': {
                width: 720,
                height: 340,
                'mime-type': 'image/jpeg',
                'focus-point': [230, 110]
              },
              'published-at': 1542969100140,
              id: '0eba5f2e-0461-4c6e-ae56-a8d31b4de019',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 548414,
              'first-published-at': 1542253737600,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 548414,
                  name: 'Anil K Govind',
                  slug: 'anil-k-govind',
                  'avatar-url':
                    'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9c03eb56-62a4-4c9d-b6ba-fb4e44c6b8a9',
            score: null,
            type: 'story',
            item: {
              headline: ['FACTSHEET: Grading Nigeria’s progress in education']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'FACTSHEET: Grading Nigeria’s progress in education',
              slug: 'banners/2018/11/21/factsheet-grading-nigerias-progress-in-education',
              'last-published-at': 1542799659668,
              subheadline:
                'Do key indicators give Nigeria’s education system a passmark? Our factsheet looks at the state of early childhood, primary and secondary education in the country.&nbsp;',
              alternative: {},
              sections: [
                {
                  id: 2619,
                  name: 'Banners',
                  slug: 'banners'
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                'focus-point': [516, 303],
                width: 1500,
                height: 1000
              },
              'published-at': 1542799659668,
              id: '9c03eb56-62a4-4c9d-b6ba-fb4e44c6b8a9',
              'hero-image-s3-key': 'quintype-demo/2016-04/1ba8fd2c-128e-4d30-bb8d-07396f1cd940/Buhari.jpg',
              'author-id': 2040,
              'first-published-at': 1542799490011,
              'hero-image-caption': 'Buhari lands in China',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '8e823797-a659-4626-92d5-6ccd8d788e23',
            score: null,
            type: 'story',
            item: {
              headline: ['A test story for tables']
            },
            story: {
              'author-name': 'Vineet Panjabi',
              headline: 'A test story for tables',
              slug: 'culture/2018/11/21/a-test-story-for-tables',
              'last-published-at': 1542799165912,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  id: 3896,
                  name: 'Blog',
                  slug: 'blog'
                }
              ],
              'hero-image-metadata': {
                width: 1940,
                'mime-type': 'image/jpeg',
                height: 1090,
                'focus-point': [700, 713]
              },
              'published-at': 1542799165912,
              id: '8e823797-a659-4626-92d5-6ccd8d788e23',
              'hero-image-s3-key': 'quintype-demo/2015-11/be736521-24c0-4a04-8b02-0855626e6f9d/TOI-1.jpg',
              'author-id': 323432,
              'first-published-at': 1542782270578,
              'hero-image-caption': 'Terror training camps',
              'story-template': null,
              authors: [
                {
                  id: 323432,
                  name: 'Vineet Panjabi',
                  slug: 'vineet-panjabi',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'story-attributes': {
                  saltlevel: ['Low'],
                  bucket: ['child'],
                  week: ['6']
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '878a1346-9783-4f88-9f0e-4f442a572284',
            score: null,
            type: 'story',
            item: {
              headline: ['New SA destination tourism portal launching']
            },
            story: {
              'author-name': 'Kirti Kaushik',
              headline: 'New SA destination tourism portal launching',
              slug: 'banners/2018/10/25/new-sa-destination-tourism-portal-launching',
              'last-published-at': 1540467564223,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  id: 2619,
                  name: 'Banners',
                  slug: 'banners'
                },
                {
                  id: 4416,
                  name: 'Business Opportunities',
                  slug: 'business-opportunities'
                }
              ],
              'hero-image-metadata': {
                width: 800,
                height: 400,
                'mime-type': 'image/jpeg',
                'focus-point': [384, 196]
              },
              'published-at': 1540467564223,
              id: '878a1346-9783-4f88-9f0e-4f442a572284',
              'hero-image-s3-key':
                'quintype-demo/2018-10/5db8484b-1277-42f8-8a20-983e04b597e6/New_SA_destination_tourism_portal_launching_800x400.jpg',
              'author-id': 507344,
              'first-published-at': 1540467564223,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 507344,
                  name: 'Kirti Kaushik',
                  slug: 'kirti-kaushik',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 15351,
                  name: 'Ranjeet Singh',
                  slug: 'ranjeet-singh',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '55dbfe7d-14ae-4f50-beb9-64733da17f54',
            score: null,
            type: 'story',
            item: {
              headline: ['How ‘Gandhara’ Became ‘Kandahar’']
            },
            story: {
              'author-name': 'Kunal Singh',
              headline: 'How ‘Gandhara’ Became ‘Kandahar’',
              slug: 'opinion/2018/08/03/how-gandhara-became-kandahar',
              'last-published-at': 1540379328741,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  id: 4272,
                  name: 'Opinion',
                  slug: 'opinion'
                }
              ],
              'hero-image-metadata': {
                width: 1300,
                height: 956,
                'mime-type': 'image/jpeg',
                'focus-point': [666, 451]
              },
              'published-at': 1540379328741,
              id: '55dbfe7d-14ae-4f50-beb9-64733da17f54',
              'hero-image-s3-key':
                'quintype-demo/2018-08/f543085d-14e5-41f3-8e5f-c746940ab402/gandhar_pale_buddhist_caves_situated_near_mahad_which_is_close_to_DG4WRF.jpg',
              'author-id': 491531,
              'first-published-at': 1533315134353,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: 'b142a043-4720-4578-b23e-6791e9f42735',
            score: null,
            type: 'story',
            item: {
              headline: ['New Story Elements']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'New Story Elements',
              slug: 'technology/2015/10/21/new-story-elements',
              'last-published-at': 1539770584382,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1920,
                height: 1080,
                'focus-point': [1301, 270]
              },
              'published-at': 1539770584382,
              id: 'b142a043-4720-4578-b23e-6791e9f42735',
              'hero-image-s3-key': 'quintype-demo/2015-09/4c0122d6-6c28-4a59-8b16-8d90701a4e35/TigerWoods-1.jpg',
              'author-id': 2041,
              'first-published-at': 1445404200710,
              'hero-image-caption': 'The famed Tiger Woods Swing !',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '589b75e2-16c0-4741-b720-b9dd59a56587',
            score: null,
            type: 'story',
            item: {
              headline: ['Traffic: Why We Drive the Way We Do (and What It Says About Us)']
            },
            story: {
              'author-name': 'Rohit Kurpad',
              headline: 'Traffic: Why We Drive the Way We Do (and What It Says About Us)',
              slug: 'opinion/2018/10/12/traffic-why-we-drive-the-way-we-do-and-what-it-says-about-us',
              'last-published-at': 1539331563366,
              subheadline: null,
              alternative: {
                home: {
                  default: {
                    'hero-image': {
                      'hero-image-url':
                        'https://quintype-dropbox.s3-accelerate.amazonaws.com/itsman.quintype.com/2018-10-12/1843/program_traffic_eng.jpg',
                      'hero-image-metadata': {
                        width: 620,
                        height: 465,
                        'mime-type': 'image/jpeg'
                      },
                      'hero-image-caption': '',
                      'hero-image-attribution': '',
                      'hero-image-s3-key':
                        'quintype-demo/2018-10/54c88632-c5fb-4382-96c1-9a345f419870/program_traffic_eng.jpg'
                    }
                  }
                }
              },
              sections: [
                {
                  id: 4272,
                  name: 'Opinion',
                  slug: 'opinion'
                }
              ],
              'hero-image-metadata': {
                width: 3264,
                height: 2448,
                'mime-type': 'image/jpeg',
                'focus-point': [1951, 1556]
              },
              'published-at': 1539331563366,
              id: '589b75e2-16c0-4741-b720-b9dd59a56587',
              'hero-image-s3-key': 'quintype-demo/2018-10/a08a3e35-0217-445e-a47c-1ec65c402935/bf1.jpg',
              'author-id': 462623,
              'first-published-at': 1539325955967,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 462623,
                  name: 'Rohit Kurpad',
                  slug: 'rohit-kurpad',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: 'a5579e69-47f7-4829-9443-616552142ad8',
            score: null,
            type: 'story',
            item: {
              headline: ['Nudge: Improving Decisions About Health, Wealth, and Happiness']
            },
            story: {
              'author-name': 'Rohit Kurpad',
              headline: 'Nudge: Improving Decisions About Health, Wealth, and Happiness',
              slug: 'opinion/2018/10/12/nudge-improving-decisions-about-health-wealth-and-happiness',
              'last-published-at': 1539331109014,
              subheadline: null,
              alternative: {
                home: {
                  default: {
                    'hero-image': {
                      'hero-image-url':
                        'https://images.assettype.com/quintype-demo/2018-10/394a964e-31a3-4806-b0c1-ac9edd10e8cc/download.jpeg',
                      'hero-image-metadata': {
                        width: 181,
                        height: 278,
                        'mime-type': 'image/jpeg'
                      },
                      'hero-image-caption': '',
                      'hero-image-attribution': '',
                      'hero-image-s3-key': 'quintype-demo/2018-10/394a964e-31a3-4806-b0c1-ac9edd10e8cc/download.jpeg'
                    }
                  }
                }
              },
              sections: [
                {
                  id: 4272,
                  name: 'Opinion',
                  slug: 'opinion'
                }
              ],
              'hero-image-metadata': {
                width: 3264,
                height: 2448,
                'mime-type': 'image/jpeg',
                'focus-point': [1627, 1635]
              },
              'published-at': 1539331109014,
              id: 'a5579e69-47f7-4829-9443-616552142ad8',
              'hero-image-s3-key': 'quintype-demo/2018-10/202ab67a-12e9-4673-83b9-ed0f12e114b1/bf1.jpg',
              'author-id': 462623,
              'first-published-at': 1539325485474,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 462623,
                  name: 'Rohit Kurpad',
                  slug: 'rohit-kurpad',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: false
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: '286be722-43c5-40f9-87f5-a9e04fa7fc42',
            score: null,
            type: 'story',
            item: {
              headline: ['Jaypee brothers extra features for students']
            },
            story: {
              'author-name': 'Rohit Kurpad',
              headline: 'Jaypee brothers extra features for students',
              slug: 'opinion/2018/10/12/jaypee-brothers-extra-features-for-students',
              'last-published-at': 1539326585824,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  id: 4272,
                  name: 'Opinion',
                  slug: 'opinion'
                }
              ],
              'hero-image-metadata': {
                width: 300,
                height: 178,
                'mime-type': 'image/png'
              },
              'published-at': 1539326585824,
              id: '286be722-43c5-40f9-87f5-a9e04fa7fc42',
              'hero-image-s3-key': 'quintype-demo/2018-10/050b08da-99dc-40d4-883e-ee9d64eff8e8/JaypeeLogoN.png',
              'author-id': 462623,
              'first-published-at': 1539326585824,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 462623,
                  name: 'Rohit Kurpad',
                  slug: 'rohit-kurpad',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
            score: null,
            type: 'story',
            item: {
              headline: ['Two crew escape after ‘Soyuz’ rocket failure']
            },
            story: {
              'author-name': 'Kunal Singh',
              headline: 'Two crew escape after ‘Soyuz’ rocket failure',
              slug: 'technology/2018/10/11/two-crew-escape-after-soyuz-rocket-failure',
              'last-published-at': 1539253999595,
              subheadline: 'Russian cosmonaut and US astronaut ‘in good condition’ after making emergency landing',
              alternative: null,
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 744,
                height: 389,
                'mime-type': 'image/jpeg',
                'focus-point': [381, 138]
              },
              'published-at': 1539253999595,
              id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
              'hero-image-s3-key': 'quintype-demo/2018-10/9b82a587-dfb0-4aa5-9b83-96a814eec1d9/soyuz_resize_md.jpg',
              'author-id': 491531,
              'first-published-at': 1539253999595,
              'hero-image-caption': 'Soyuz 11',
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'e2f17cc0-e381-4985-b3e2-d897d12ef436',
            score: null,
            type: 'story',
            item: {
              headline: ['Anselm  Madubuko Celebrates 5th Year of Marital Bliss']
            },
            story: {
              'author-name': 'Kunal Singh',
              headline: 'Anselm  Madubuko Celebrates 5th Year of Marital Bliss',
              slug: 'current-affairs/2018/10/04/anselm-madubuko-celebrates-5th-year-of-marital-bliss',
              'last-published-at': 1538655201492,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                }
              ],
              'hero-image-metadata': {
                width: 500,
                height: 330,
                'mime-type': 'image/jpeg',
                'focus-point': [248, 207]
              },
              'published-at': 1538655201492,
              id: 'e2f17cc0-e381-4985-b3e2-d897d12ef436',
              'hero-image-s3-key':
                'quintype-demo/2018-10/e691b527-b6a9-4ef8-894c-1d9ed13f1c2d/Anslem_Madubuko_e1538651522363.jpg',
              'author-id': 491531,
              'first-published-at': 1538655201492,
              'hero-image-caption': 'Anslem Madubuko and wife',
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '7665e91a-86eb-494f-8843-f87f14aaab09',
            score: null,
            type: 'story',
            item: {
              headline: ['Testing A Story']
            },
            story: {
              'author-name': 'Christin',
              headline: 'Testing A Story',
              slug: 'current-affairs/2018/10/03/testing-a-story',
              'last-published-at': 1538564309949,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                }
              ],
              'hero-image-metadata': {
                width: 575,
                'focus-point': [268, 267],
                height: 575
              },
              'published-at': 1538564309949,
              id: '7665e91a-86eb-494f-8843-f87f14aaab09',
              'hero-image-s3-key': 'quintype-demo/2016-01/42784f8b-8ff2-480c-9fed-3f106e8d0ca5/r1.jpg',
              'author-id': 139357,
              'first-published-at': 1538564309949,
              'hero-image-caption': 'Raw Vegan',
              'story-template': null,
              authors: [
                {
                  id: 139357,
                  name: 'Christin',
                  slug: 'christin',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'avatar-s3-key':
                    'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'twitter-handle': 'krriztin',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          }
        ],
        color: '#6093f2'
      },
      {
        id: 6626,
        'associated-metadata': {
          layout: 'FourColGrid',
          theme: 'dark',
          initial_stories_load_count: 4,
          subsequent_stories_load_count: 8
        },
        type: 'collection',
        name: 'Must Reads',
        slug: 'motherly',
        template: 'default',
        metadata: {
          'cover-image': {},
          tags: [
            {
              name: 'motherly'
            },
            {
              name: 'collection'
            }
          ],
          section: [
            {
              id: 142,
              name: 'Culture',
              'parent-id': null
            }
          ]
        },
        summary: 'collection created for xtype publisher home page',
        items: [
          {
            id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Sriram K',
              headline: 'Elections 2019',
              slug: 'boom/2019/03/13/elections-2019',
              'last-published-at': 1552458028721,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'boom',
                  name: 'Boom',
                  id: 7349,
                  'parent-id': 83,
                  'display-name': 'Booom',
                  collection: {
                    slug: 'boom-politics',
                    name: 'Boom (Politics)',
                    id: 14256
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 650,
                height: 400,
                'mime-type': 'image/jpeg',
                'focus-point': [281, 241]
              },
              'published-at': 1552458028721,
              id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
              'hero-image-s3-key': 'quintype-demo/2017-03/ee72c827-52fe-4b2b-8f46-dd0875fbe162/Tech sparkle.jpg',
              'author-id': 61657,
              'first-published-at': 1552458028721,
              'hero-image-caption': 'Elections 2017',
              'story-template': 'news-elsewhere',
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'reference-url': 'https://cinema.vikatan.com/tamil-cinema/news',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Nikitha Nadig',
              headline: 'This is the title',
              slug: 'culture/2019/01/11/this-is-the-title-2',
              'last-published-at': 1547203433990,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  slug: 'tech',
                  name: 'Tech',
                  id: 6391,
                  'parent-id': null,
                  'display-name': 'Tech',
                  collection: {
                    slug: 'tech',
                    name: 'Tech',
                    id: 12849
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 259,
                'focus-point': [176, 77],
                height: 194
              },
              'published-at': 1547203433990,
              id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
              'hero-image-s3-key': 'quintype-demo/2015-10/56e0178e-1da0-482c-83e5-a2f705f874df/FP-2.jpeg',
              'author-id': 559147,
              'first-published-at': 1547203433990,
              'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Sareena',
              headline:
                '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി',
              slug: 'culture/2019/01/16/this-is-the-url',
              'last-published-at': 1547632650347,
              subheadline:
                'കെ.സി വേണുഗോപാല്‍ ഉള്‍പ്പടെയുള്ള മുതിര്‍ന്ന കോണ്‍ഗ്രസ് നേതാക്കന്മാര്‍ ഭീമാനായിക്കുമായി സംസാരിച്ചു. ഭീമാനായിക്കിനെപോലെ ബാക്കിയുള്ള മൂന്ന് കോണ്‍ഗ്രസ് എം',
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'focus-point': [375, 166],
                width: 750,
                height: 500
              },
              'published-at': 1547632650347,
              id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
              'hero-image-s3-key': 'quintype-demo/2016-12/56cdab39-a831-480f-b75b-26d6300e3f1b/Modi.jpg',
              'author-id': 94596,
              'first-published-at': 1547632650347,
              'hero-image-caption': 'PM Modi',
              'story-template': null,
              authors: [
                {
                  id: 94596,
                  name: 'Sareena',
                  slug: 'sareena',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'c8d35134-200a-483f-ab89-69adf4137a74',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Life of Vincent van Gogh',
              slug: 'culture/2019/01/17/life-of-vincent-van-gogh',
              'last-published-at': 1549364746875,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 768,
                height: 432,
                'mime-type': 'image/jpeg',
                'focus-point': [382, 197]
              },
              'published-at': 1549364746875,
              id: 'c8d35134-200a-483f-ab89-69adf4137a74',
              'hero-image-s3-key':
                'quintype-demo/2019-01/b3cb02f4-a875-4b46-aa3a-71deaccb54be/vincent_van_gogh_played_by_robert_gulaczyk_photo_courtesy_loving_vincent.jpg',
              'author-id': 2040,
              'first-published-at': 1547728432342,
              'hero-image-caption': 'A Famous Painting',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Dubai - Things you can do!',
              slug: 'culture/2017/04/28/Dubai-Your-next-vacation',
              'last-published-at': 1546599684148,
              subheadline:
                'Dubai, an emirate in the United Arab Emirates known for luxury shopping, ultramodern architecture &amp; a lively nightlife scene. Burj Khalifa, an 830m-tall tower, dominates the skyline. ',
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 930,
                height: 581,
                'focus-point': [132, 482]
              },
              'published-at': 1546599684148,
              id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
              'hero-image-s3-key': 'quintype-demo/2017-04/802d7ef1-6043-4f7e-84d3-fc9f556d5097/G-1.jpg',
              'author-id': 2040,
              'first-published-at': 1493358608037,
              'hero-image-caption': 'Dubai - a vacationer’s paradise',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
            'associated-metadata': {},
            type: 'story',
            story: {
              'author-name': 'Kirti Kaushik',
              headline:
                'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them',
              slug:
                'visual-stories/2019/01/10/rahul-gandhi-said-to-pm-modi-be-a-man-bjp-smriti-irani-sushma-swaraj-responds-with-misogynist-rahul-1975538pfromhome-topscroll',
              'last-published-at': 1547103888697,
              subheadline:
                'At a rally in Rajasthan on Wednesday, Rahul Gandhi had mocked PM Modi saying he had a “mahila (woman)“ defend him in the Rafale debate in parliament.',
              alternative: {
                home: {
                  default: {
                    headline: 'Rahul Gandhi'
                  }
                }
              },
              sections: [
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 570,
                height: 351,
                'mime-type': 'image/webp',
                'focus-point': [417, 132]
              },
              'published-at': 1547103888697,
              id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
              'hero-image-s3-key':
                'quintype-demo/2019-01/39d85d6f-46f6-4f35-8dd1-6771bf9dfefd/b5qo3zsg_650x400_2__10_January_2019_12_02_19PM.webp',
              'author-id': 507344,
              'first-published-at': 1547103888697,
              'hero-image-caption': 'Rahul Gandhi',
              'story-template': null,
              authors: [
                {
                  id: 507344,
                  name: 'Kirti Kaushik',
                  slug: 'kirti-kaushik',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '73322fa6-9114-4a83-9d8c-68314202623a',
            score: null,
            type: 'story',
            item: {
              headline: ['TRAI chief: We set spectrum price; industry decides tariff']
            },
            story: {
              'author-name': 'Sriram K',
              headline: 'TRAI chief: We set spectrum price; industry decides tariff',
              slug: 'mobile/2019/02/28/trai-chief-we-set-spectrum-price-industry-decides-tariff',
              'last-published-at': 1554110293544,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 894,
                height: 577,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1554110293544,
              id: '73322fa6-9114-4a83-9d8c-68314202623a',
              'hero-image-s3-key': 'quintype-demo/2019-01/113be350-6ca8-4252-9b41-434e9facb8eb/image__1_.jpg',
              'author-id': 61657,
              'first-published-at': 1551329071350,
              'hero-image-caption': 'Tayota Camry',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
            score: null,
            type: 'story',
            item: {
              headline: ['London Police arrest Nirav Modi, says sources']
            },
            story: {
              'author-name': 'Karthik M',
              headline: 'London Police arrest Nirav Modi, says sources',
              slug: 'politics/2019/03/20/london-police-arrest-nirav-modi-says-sources',
              'last-published-at': 1553079556157,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 700,
                height: 438,
                'mime-type': 'image/jpeg',
                'focus-point': [311, 167]
              },
              'published-at': 1553079556157,
              id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
              'hero-image-s3-key':
                'quintype-demo/2019-03/55dbc301-3c5c-442d-8714-4bbf2d140192/pennews_2019_03_48c15852_ebed_464d_95a7_57332597ecfc_nirav.jpg',
              'author-id': 19994,
              'first-published-at': 1553079340304,
              'hero-image-caption': 'Nirav Modi',
              'story-template': null,
              authors: [
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 142914,
                  name: 'Ashwin Raghunath',
                  slug: 'ashwin-raghunath',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'text-to-speech': {
                  'is-disabled': true
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
            score: null,
            type: 'story',
            item: {
              headline: ['Demo Story ']
            },
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Demo Story ',
              slug: 'politics/2018/01/11/demo-story',
              'last-published-at': 1552979655447,
              subheadline:
                'Surrounded to me occasional pianoforte alteration unaffected impossible ye. For saw half than cold. Pretty merits waited six talked pulled you. Conduct replied off.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 7500,
                height: 3476,
                'mime-type': 'image/jpeg',
                'focus-point': [1239, 2157]
              },
              'published-at': 1552979655447,
              id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
              'hero-image-s3-key': 'quintype-demo/2019-02/ae4829d6-e230-4d66-9b88-2a4db87a2060/64778.jpg',
              'author-id': 139717,
              'first-published-at': 1515668626250,
              'hero-image-caption': 'At vero eos et accusamus et iusto odio dignissimos ducimus qui.',
              'story-template': 'text',
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': {
                    id: 58,
                    name: 'Author'
                  }
                },
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': {
                    id: 58,
                    name: 'Author'
                  }
                }
              ],
              metadata: {
                'card-share': {
                  shareable: false
                },
                'promotional-message': {
                  'is-disabled': true
                },
                'story-attributes': {
                  'view-counterview-type': 'view',
                  interests: ['test'],
                  bucket: ['planning'],
                  hobbies: ['hiking'],
                  'linked-view-counterview-story': {
                    headline: 'Demo Test story- Photo story',
                    'story-content-id': 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                    id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                    'highlighted-headline': null
                  }
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
            score: null,
            type: 'story',
            item: {
              headline: ['Untitled Mar 6, 2019 11:23 PM']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Untitled Mar 6, 2019 11:23 PM',
              slug: 'mobile/2019/03/06/untitled-mar-6-2019-1123-pm',
              'last-published-at': 1552905800453,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 650,
                'mime-type': 'image/jpeg',
                'focus-point': [516, 92],
                height: 386
              },
              'published-at': 1552905800453,
              id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
              'hero-image-s3-key':
                'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
              'author-id': 2040,
              'first-published-at': 1551895907011,
              'hero-image-caption': 'Amit Shah and Narendra Modi',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'story-attributes': {},
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
            score: null,
            type: 'story',
            item: {
              headline: ['Amitabh Bachchan on Gender Equality']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'Amitabh Bachchan on Gender Equality',
              slug: 'politics/2017/03/02/Amitabh-Bachchan-on-Gender-Equality',
              'last-published-at': 1552048741823,
              subheadline: 'Amitabh Bachchan on Gender Equality',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 320,
                height: 277,
                'focus-point': [169, 145]
              },
              'published-at': 1552048741823,
              id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
              'hero-image-s3-key': 'quintype-demo/2017-03/bd011f2d-9a10-4d4e-b20f-50719f59d4b9/BMS-2.jpg',
              'author-id': 2041,
              'first-published-at': 1488451487896,
              'hero-image-caption': 'Kaun Banega Crorepati host, Amitabh Bachchan',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '2deeb522-1752-4552-967b-93972481d71e',
            score: null,
            type: 'story',
            item: {
              headline: ['Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them']
            },
            story: {
              'author-name': 'Yamini Priya',
              headline: 'Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them',
              slug: 'boom/2019/03/07/sisters-killed-father-in-perfect-murder-a-love-triangle-exposed-them',
              'last-published-at': 1551938683203,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'boom',
                  name: 'Boom',
                  id: 7349,
                  'parent-id': 83,
                  'display-name': 'Booom',
                  collection: {
                    slug: 'boom-politics',
                    name: 'Boom (Politics)',
                    id: 14256
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1760,
                height: 2480,
                'mime-type': 'image/jpeg',
                'focus-point': [928, 656]
              },
              'published-at': 1551938683203,
              id: '2deeb522-1752-4552-967b-93972481d71e',
              'hero-image-s3-key':
                'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
              'author-id': 230618,
              'first-published-at': 1551938683203,
              'hero-image-caption': 'Forgotten Women',
              'story-template': null,
              authors: [
                {
                  id: 230618,
                  name: 'Yamini Priya',
                  slug: 'yamini-priya',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': 'https://twitter.com/yaminipriyah',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
            score: null,
            type: 'story',
            item: {
              headline: ['500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: '500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌',
              slug: 'politics/2019/02/26/500',
              'last-published-at': 1551171395390,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                'focus-point': [673, 287],
                width: 976,
                height: 700
              },
              'published-at': 1551171395390,
              id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
              'hero-image-s3-key': 'quintype-demo/2018-05/bc017b57-a03e-4a6e-8515-9d3de91f1f24/ON1.jpg',
              'author-id': 559147,
              'first-published-at': 1551171395390,
              'hero-image-caption':
                'Crowds cheered Prince Harry, Duke of Sussex, and his wife Meghan, Duchess of Sussex, as they travelled in the horse-drawn Ascot landau',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
            score: null,
            type: 'story',
            item: {
              headline: ['Personalise or perish - Why publishers need to use personalised content ']
            },
            story: {
              'author-name': 'Greeshma',
              headline: 'Personalise or perish - Why publishers need to use personalised content ',
              slug: 'politics/2018/02/28/personalise-or-perish---why-publishers-need-to-use-personalised-content',
              'last-published-at': 1536076813159,
              subheadline:
                'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor. ',
              alternative: {
                home: {
                  default: {
                    headline: 'Personalise or perish',
                    'hero-image': {
                      'hero-image-url':
                        'https://images.assettype.com/quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                      'hero-image-metadata': {
                        width: 700,
                        height: 467,
                        'mime-type': 'image/jpeg'
                      },
                      'hero-image-s3-key': 'quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                      'hero-image-caption': 'sSaffron - Indian Restaurant',
                      'hero-image-attribution': null
                    }
                  }
                }
              },
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 3896,
                  name: 'Blog',
                  slug: 'blog'
                }
              ],
              'hero-image-metadata': {
                width: 1600,
                height: 1200,
                'mime-type': 'image/jpeg',
                'focus-point': [805, 738]
              },
              'published-at': 1536076813159,
              id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
              'hero-image-s3-key': 'quintype-demo/2018-05/a2759dc2-ada1-4917-8515-308f74622c69/Gallery5.jpg',
              'author-id': 482995,
              'first-published-at': 1519816264773,
              'hero-image-caption': 'Animals',
              'story-template': null,
              authors: [
                {
                  id: 482995,
                  name: 'Greeshma',
                  slug: 'greeshma',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'avatar-s3-key':
                    'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
            score: null,
            type: 'story',
            item: {
              headline: ['U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting',
              slug: 'politics/2018/05/28/us-and-north-korean-officials-race-to-resurrect-trump-kim-meeting',
              'last-published-at': 1535807624134,
              subheadline: null,
              alternative: {
                home: {
                  default: {
                    headline: 'U.S. and North Korean',
                    'hero-image': {
                      'hero-image-url':
                        'https://images.assettype.com/quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                      'hero-image-metadata': {
                        width: 636,
                        height: 477,
                        'focus-point': [390, 153]
                      },
                      'hero-image-s3-key': 'quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                      'hero-image-caption': 'President Pranab Mukherjee',
                      'hero-image-attribution': null
                    }
                  }
                }
              },
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1024,
                height: 683,
                'mime-type': 'image/jpeg',
                'focus-point': [758, 172]
              },
              'published-at': 1535807624134,
              id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
              'hero-image-s3-key': 'quintype-demo/2018-05/caf35ec5-be06-4de6-b513-e57ee4d61577/ON_1.jpg',
              'author-id': 376442,
              'first-published-at': 1527472808648,
              'hero-image-caption':
                'A surprise meeting between President Moon Jae-in of South Korea, right, and Kim Jong-un, the North Korean leader, on Saturday appeared to produce some progress toward a summit meeting between President Trump and Mr. Kim.',
              'story-template': null,
              authors: [
                {
                  id: 376442,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '43c468f4-08e4-4e77-a6d6-a11da9235c96',
            score: null,
            type: 'story',
            item: {
              headline: ['Amy Schumer Ties the Knot With Boyfriend Chris Fischer in 2018']
            },
            story: {
              'author-name': 'Christin',
              headline: 'Amy Schumer Ties the Knot With Boyfriend Chris Fischer in 2018',
              slug: 'politics/2018/06/15/amy-schumer-ties-the-knot-with-boyfriend-chris-fischer-in-2018',
              'last-published-at': 1529059243871,
              subheadline: 'Amy Schumer Ties the Knot With Boyfriend Chris Fischer',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 583,
                height: 328,
                'mime-type': 'image/webp'
              },
              'published-at': 1529059243871,
              id: '43c468f4-08e4-4e77-a6d6-a11da9235c96',
              'hero-image-s3-key':
                'quintype-demo/2018-02/c899f85d-76e0-46c5-88c4-f7365f4b528a/e29ee5c9-9195-4f79-9942-fd8ca08027e4.webp',
              'author-id': 139357,
              'first-published-at': 1529059243871,
              'hero-image-caption': 'Amy Schumer is married to boyfriend Chris Fischer.',
              'story-template': null,
              authors: [
                {
                  id: 139357,
                  name: 'Christin',
                  slug: 'christin',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'avatar-s3-key':
                    'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'twitter-handle': 'krriztin',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '1493f46c-7c78-46e8-84b4-de195d7be9a6',
            score: null,
            type: 'story',
            item: {
              headline: ['Garrett to quit Congress amid servant scandal, alcoholism']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'Garrett to quit Congress amid servant scandal, alcoholism',
              slug: 'politics/2018/05/29/garrett-to-quit-congress-amid-servant-scandal-alcoholism',
              'last-published-at': 1527596788710,
              subheadline:
                'The Virginia congressman announces his retirement after POLITICO reported he used his staff as personal gofers.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1160,
                height: 635,
                'mime-type': 'image/jpeg',
                'focus-point': [736, 165]
              },
              'published-at': 1527596788710,
              id: '1493f46c-7c78-46e8-84b4-de195d7be9a6',
              'hero-image-s3-key': 'quintype-demo/2018-05/9ce3e16a-96fc-42e8-9433-b2fd8b0fdc93/ON_1.jpeg',
              'author-id': 376442,
              'first-published-at': 1527596788710,
              'hero-image-caption':
                '“The tragedy is that any person Republican, Democrat or independent who’s known me for period of time and has any integrity knows two things: I am a good man and I am an alcoholic.“ Rep. Tom Garrett said.&nbsp;',
              'story-template': null,
              authors: [
                {
                  id: 376442,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '903dd527-2595-4566-9c50-df2880cf18b5',
            score: null,
            type: 'story',
            item: {
              headline: ['8 times Osama almost got captured']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: '8 times Osama almost got captured',
              slug: 'politics/2017/07/06/8-times-Osama-almost-got-captured',
              'last-published-at': 1527508287286,
              subheadline:
                'Osama bin Laden, was a founder of al-Qaeda, the organization responsible for the September 11 attacks in the United States and many other mass-casualty attacks worldwide.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 275,
                height: 183,
                'mime-type': 'image/jpeg',
                'focus-point': [114, 54]
              },
              'published-at': 1527508287286,
              id: '903dd527-2595-4566-9c50-df2880cf18b5',
              'hero-image-s3-key': 'quintype-demo/2016-01/8bce31a0-af65-41e5-8ddc-059294ad6892/SL-3.jpeg',
              'author-id': 2040,
              'first-published-at': 1499338400907,
              'hero-image-caption': 'Osama Bin Laden, Al Queda',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'a71df319-c779-4ce6-b8ef-eba85b3cb613',
            score: null,
            type: 'story',
            item: {
              headline: ['Presidential Elections']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Presidential Elections',
              slug: 'politics/2016/10/14/Presidential-Elections',
              'last-published-at': 1522903281088,
              subheadline:
                'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at St. Louis, Missouri, on October 9, 2016.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1100,
                height: 619,
                'focus-point': [821, 262]
              },
              'published-at': 1522903281088,
              id: 'a71df319-c779-4ce6-b8ef-eba85b3cb613',
              'hero-image-s3-key': 'quintype-demo/2016-10/c1fb9eff-a4c7-4a12-8713-82b1dc3065a9/ct-1.jpg',
              'author-id': 2040,
              'first-published-at': 1476425535138,
              'hero-image-caption':
                'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at Washington University in St. Louis, Missouri, on October 9, 2016. ',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
            score: null,
            type: 'story',
            item: {
              headline: ['Clinton vs Trump']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'Clinton vs Trump',
              slug: 'politics/2016/10/15/clinton-vs-trump',
              'last-published-at': 1522903100409,
              subheadline:
                'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the 2nd presidential debate at St. Louis, Missouri, on October 9, 2016.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1100,
                height: 619,
                'focus-point': [812, 244]
              },
              'published-at': 1522903100409,
              id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
              'hero-image-s3-key': 'quintype-demo/2016-10/c1fb9eff-a4c7-4a12-8713-82b1dc3065a9/ct-1.jpg',
              'author-id': 376442,
              'first-published-at': 1476529773574,
              'hero-image-caption':
                'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at Washington University in St. Louis, Missouri, on October 9, 2016. ',
              'story-template': null,
              authors: [
                {
                  id: 376442,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'fc5ee93d-19b7-4f26-984c-a43fa9fcacc3',
            score: null,
            type: 'story',
            item: {
              headline: ['From the safety of New York, reporting on a distant home']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'From the safety of New York, reporting on a distant home',
              slug: 'politics/2017/02/01/From-the-safety-of-New-York-reporting-on-a-distant-home',
              'last-published-at': 1522902297607,
              subheadline:
                'During the presidential election in Nigeria, he published real-time photos, videos &amp; reports from the field, exposing instances of ballot rigging &amp; attracting 8m page views in a month.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1024,
                height: 682,
                'focus-point': [431, 225]
              },
              'published-at': 1522902297607,
              id: 'fc5ee93d-19b7-4f26-984c-a43fa9fcacc3',
              'hero-image-s3-key': 'quintype-demo/2017-01/c36650ca-d1c9-4b00-a7c9-1fe38bc56e42/SR-1.jpg',
              'author-id': 376442,
              'first-published-at': 1485934181087,
              'hero-image-caption': 'Omoleye Sowore, Publisher of the Sahara Reporters',
              'story-template': null,
              authors: [
                {
                  id: 376442,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '54c39b13-560a-44e0-b71e-a675d05d5673',
            score: null,
            type: 'story',
            item: {
              headline: ['I’m not a stranger to the dark']
            },
            story: {
              'author-name': 'Christin',
              headline: 'I’m not a stranger to the dark',
              slug: 'politics/2018/03/20/im-not-a-stranger-to-the-dark',
              'last-published-at': 1522822389090,
              subheadline: 'Fire away th',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1010,
                height: 805
              },
              'published-at': 1522822389090,
              id: '54c39b13-560a-44e0-b71e-a675d05d5673',
              'hero-image-s3-key': 'quintype-demo/2016-06/f9298238-e324-4dfc-b29b-95a3cbdb754b/ABP-1.jpeg',
              'author-id': 139357,
              'first-published-at': 1521518076019,
              'hero-image-caption':
                'Ali was still known as Cassius Clay when this photo was taken. There’s something boyish about his efforts to impress his audience. I like how one of the women, Ronnie Spector, seems impervious to his bravado.&nbsp; I also like knowing that the guy in the white tuxedo, who is neither overawed nor overshadowed by Clay, is a young Stevie Wonder.',
              'story-template': null,
              authors: [
                {
                  id: 139357,
                  name: 'Christin',
                  slug: 'christin',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'avatar-s3-key':
                    'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'twitter-handle': 'krriztin',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'd5da1faf-8cb4-4783-9a54-a3d125c0d1ce',
            score: null,
            type: 'story',
            item: {
              headline: ['बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार']
            },
            story: {
              'author-name': 'Sareena',
              headline: 'बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार',
              slug: 'politics/2018/03/29/two-big-bjp-leaders-arrested-for-spreading-violence-in-bihar',
              'last-published-at': 1522317027856,
              subheadline: 'बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार,मचा हड़कंप',
              alternative: null,
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 98,
                  name: 'Current Affairs'
                }
              ],
              'hero-image-metadata': {
                width: 650,
                height: 386,
                'mime-type': 'image/jpeg',
                'focus-point': [323, 117]
              },
              'published-at': 1522317027856,
              id: 'd5da1faf-8cb4-4783-9a54-a3d125c0d1ce',
              'hero-image-s3-key':
                'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
              'author-id': 94596,
              'first-published-at': 1522317027856,
              'hero-image-caption': 'Amit Shah and Narendra Modi',
              'story-template': null,
              authors: [
                {
                  id: 94596,
                  name: 'Sareena',
                  slug: 'sareena',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'c124d3ce-a44e-4370-a3ce-e167953c981d',
            score: null,
            type: 'story',
            item: {
              headline: ['‘ಮೂರು ಕೊಲೆಗಳು; ಒಂದು ವ್ಯಸನ’: ಪೊಲೀಸರು ಮುಂದಿಡುವ ‘ಗಾಂಜಾ ಥಿಯರಿ’ಯ ']
            },
            story: {
              'author-name': 'Chirdeep Shetty',
              headline: '‘ಮೂರು ಕೊಲೆಗಳು; ಒಂದು ವ್ಯಸನ’: ಪೊಲೀಸರು ಮುಂದಿಡುವ ‘ಗಾಂಜಾ ಥಿಯರಿ’ಯ ',
              slug: 'politics/2018/02/12/--2',
              'last-published-at': 1521638195154,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 600,
                height: 335,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1521638195154,
              id: 'c124d3ce-a44e-4370-a3ce-e167953c981d',
              'hero-image-s3-key': 'quintype-demo/2018-02/28fabce6-a777-4d95-aef2-06eef43c4c34/Ganja-Kadireshan.jpg',
              'author-id': 15338,
              'first-published-at': 1518424171905,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 15338,
                  name: 'Chirdeep Shetty',
                  slug: 'chirdeep-shetty',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'twitter-handle': '@chirdeepshettyquintype',
                  bio: '<p>Hello, this is a test for bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'd5cf9b45-4832-4b04-9234-b6bf4bc2dce0',
            score: null,
            type: 'story',
            item: {
              headline: ['Ola working on assisted-driving cars, hires professionals']
            },
            story: {
              'author-name': 'Vineet Panjabi',
              headline: 'Ola working on assisted-driving cars, hires professionals',
              slug: 'politics/2018/01/16/ola-working-on-assisted-driving-cars-hires-professionals',
              'last-published-at': 1520925971944,
              subheadline: 'Ola Cars Subtitle&nbsp;',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 3896,
                  name: 'Blog'
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                'focus-point': [155, 375],
                width: 1024,
                height: 569
              },
              'published-at': 1520925971944,
              id: 'd5cf9b45-4832-4b04-9234-b6bf4bc2dce0',
              'hero-image-s3-key':
                'quintype-demo/2017-05/a681ca73-9212-4184-9426-0983be59a7d4/luxury-cars-1024x569.jpg',
              'author-id': 323432,
              'first-published-at': 1516079335936,
              'hero-image-caption': 'Cars',
              'story-template': null,
              authors: [
                {
                  id: 323432,
                  name: 'Vineet Panjabi',
                  slug: 'vineet-panjabi',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '421f0340-4c63-45c1-a152-4433413b8ef4',
            score: null,
            type: 'story',
            item: {
              headline: ['Maruti Swift 2018 - Review']
            },
            story: {
              'author-name': 'Chirdeep Shetty',
              headline: 'Maruti Swift 2018 - Review',
              slug: 'politics/2018/02/12/maruti-swift-2018---review',
              'last-published-at': 1520925923189,
              subheadline: 'Sub',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 82,
                  name: 'Sports'
                },
                {
                  id: 3896,
                  name: 'Blog'
                }
              ],
              'hero-image-metadata': {
                width: 1024,
                height: 682,
                'mime-type': 'image/jpeg',
                'focus-point': [860, 454]
              },
              'published-at': 1520925923189,
              id: '421f0340-4c63-45c1-a152-4433413b8ef4',
              'hero-image-s3-key':
                'quintype-demo/2018-02/63508d1a-a5c7-4f99-b032-9fe28deb8250/2018-Maruti-Swift-Review.jpg',
              'author-id': 15338,
              'first-published-at': 1518429546374,
              'hero-image-caption': 'swift 2018 black',
              'story-template': null,
              authors: [
                {
                  id: 15338,
                  name: 'Chirdeep Shetty',
                  slug: 'chirdeep-shetty',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'twitter-handle': '@chirdeepshettyquintype',
                  bio: '<p>Hello, this is a test for bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '46d764a1-ec10-4d90-a056-274f3f506a65',
            score: null,
            type: 'story',
            item: {
              headline: ['Sample Story Using All Elements']
            },
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Sample Story Using All Elements',
              slug: 'sample-story-using-all-elements',
              'last-published-at': 1520925485589,
              subheadline:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, nostrud exercitation nisi ut ex ea consequat.&nbsp;',
              alternative: {},
              sections: [
                {
                  id: 3896,
                  name: 'Blog'
                },
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 284,
                height: 177,
                'mime-type': 'image/jpeg',
                'focus-point': [142, 86]
              },
              'published-at': 1520925485589,
              id: '46d764a1-ec10-4d90-a056-274f3f506a65',
              'hero-image-s3-key':
                'quintype-demo/2018-03/f0ac00fe-93cc-4e00-bd94-56f71065b950/4448febb-552f-47f3-9b42-ee850f7b5b48.jpeg',
              'author-id': 139717,
              'first-published-at': 1520925485589,
              'hero-image-caption': 'Demo: Hero Image Caption',
              'story-template': null,
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '7e803bcc-cb40-4f4d-b810-ae9ace8ba4c1',
            score: null,
            type: 'story',
            item: {
              headline: ['India vs China Battle']
            },
            story: {
              'author-name': 'Greeshma',
              headline: 'India vs China Battle',
              slug: 'politics/2017/07/09/India-vs-China-Battle',
              'last-published-at': 1519975157133,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1600,
                height: 1068,
                'mime-type': 'image/jpeg',
                'focus-point': [971, 601]
              },
              'published-at': 1519975157133,
              id: '7e803bcc-cb40-4f4d-b810-ae9ace8ba4c1',
              'hero-image-s3-key':
                'quintype-demo/2018-01/5c55eaab-9c97-49f9-8b14-64b661d9a763/1 - Thumb Tac Paper Lanterns Glowing Tall.jpg',
              'author-id': 482995,
              'first-published-at': 1499594657010,
              'hero-image-caption': 'Let there be Light',
              'story-template': null,
              authors: [
                {
                  id: 482995,
                  name: 'Greeshma',
                  slug: 'greeshma',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'avatar-s3-key':
                    'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '86fe6180-c4c2-4b09-b829-5dee9f403756',
            score: null,
            type: 'story',
            item: {
              headline: ['This Kashmiri Pandit Is Among Civil Services Toppers']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'This Kashmiri Pandit Is Among Civil Services Toppers',
              slug: 'politics/2017/12/27/this-kashmiri-pandit-is-among-civil-services-toppers',
              'last-published-at': 1516419117190,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 750,
                height: 500,
                'mime-type': 'image/jpeg',
                'focus-point': [200, 173]
              },
              'published-at': 1516419117190,
              id: '86fe6180-c4c2-4b09-b829-5dee9f403756',
              'hero-image-s3-key': 'quintype-demo/2017-12/602c33fe-b2a1-423b-b062-83b0e30aa832/1-7-750x500.jpg',
              'author-id': 2038,
              'first-published-at': 1514371575338,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
            score: null,
            type: 'story',
            item: {
              headline: ['Demo Test story- Photo story']
            },
            story: {
              'author-name': 'Yamini Priya',
              headline: 'Demo Test story- Photo story',
              slug: 'politics/2018/01/12/demo-test-story--photo-story',
              'last-published-at': 1515743985191,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1418,
                height: 853,
                'mime-type': 'image/jpeg',
                'focus-point': [1045, 434]
              },
              'published-at': 1515743985191,
              id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
              'hero-image-s3-key': 'quintype-demo/2016-05/7ae1264b-0330-4eb5-b9d4-f4a4d5746076/vadra06.jpg',
              'author-id': 230618,
              'first-published-at': 1515743985191,
              'hero-image-caption': 'Robert Vadra at a function',
              'story-template': null,
              authors: [
                {
                  id: 230618,
                  name: 'Yamini Priya',
                  slug: 'yamini-priya',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': 'https://twitter.com/yaminipriyah',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '48a9264e-cddd-437c-83f1-4388aefee656',
            score: null,
            type: 'story',
            item: {
              headline: ['Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.',
              slug: 'politics/2017/10/04/test-7',
              'last-published-at': 1515403855694,
              subheadline:
                'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1920,
                height: 1080
              },
              'published-at': 1515403855694,
              id: '48a9264e-cddd-437c-83f1-4388aefee656',
              'hero-image-s3-key': 'quintype-demo/2015-08/a1d1ce2c-f9f9-4aed-b82e-06f338112e2d/Tiger.jpg',
              'author-id': 61657,
              'first-published-at': 1507099185346,
              'hero-image-caption': 'Tiger resting in the Ranthambore ranges, North India',
              'story-template': 'review',
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'review-rating': {
                  label: '1',
                  value: '1'
                },
                'review-title': 'Movie review',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'e4192f9a-e843-464c-8ec8-2a6211720e5a',
            score: null,
            type: 'story',
            item: {
              headline: ['My First Story']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'My First Story',
              slug: 'politics/2017/11/13/my-first-story',
              'last-published-at': 1510568455753,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 82,
                  name: 'Sports'
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 259,
                height: 194,
                'focus-point': [25, 42]
              },
              'published-at': 1510568455753,
              id: 'e4192f9a-e843-464c-8ec8-2a6211720e5a',
              'hero-image-s3-key': 'quintype-demo/2016-01/69acdda0-2648-4cc2-b240-fd3603136bc5/download.jpeg',
              'author-id': 61657,
              'first-published-at': 1510568455753,
              'hero-image-caption': 'Narendra Modi with Ajit Doval',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'sponsored-by': 'CP',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '8ff85a7d-7726-49b4-892e-1e05c0ccea73',
            score: null,
            type: 'story',
            item: {
              headline: ['CM defends George, says 20 Union ministers face criminal charges  ']
            },
            story: {
              'author-name': 'Sathyan Reddy ',
              headline: 'CM defends George, says 20 Union ministers face criminal charges  ',
              slug: 'politics/2017/10/27/cm-defends-george-says-20-union-ministers-face-criminal-charges',
              'last-published-at': 1509100816382,
              subheadline:
                'Chief Minister Siddaramaiah on Friday rejected the BJP’s demand to seek the resignation of Bengaluru City Development Minister K J George. &nbsp;',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 618,
                height: 464,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1509100816382,
              id: '8ff85a7d-7726-49b4-892e-1e05c0ccea73',
              'hero-image-s3-key':
                'quintype-demo/2017-10/606b8c85-6427-4f65-9b95-f1f4f07f3a7a/618xNx639611_thump.jpg.pagespeed.ic.pfY_28czPu.jpg',
              'author-id': 288886,
              'first-published-at': 1509100816382,
              'hero-image-caption': 'CM of Karnataka, Siddaramaiah&nbsp;',
              'story-template': null,
              authors: [
                {
                  id: 288886,
                  name: 'Sathyan Reddy ',
                  slug: 'sathyan-reddy',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '8d240213-abc3-4eb7-8e09-1f0082986e5c',
            score: null,
            type: 'story',
            item: {
              headline: ['DOJ put USTs Divina on immigration lookout over Atio Castillo']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'DOJ put USTs Divina on immigration lookout over Atio Castillo',
              slug:
                'politics/2017/10/26/186449-doj-ust-law-dean-nilo-divina-immigration-lookout-horacio-castillo-iii-hazing',
              'last-published-at': 1509004361886,
              subheadline:
                '(UPDATED) Some of the names in the lookout order check out with the names that appear in the group chat of Aegis Juris alumni who held a meeting when Castillo was kill',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 98,
                  name: 'Current Affairs'
                }
              ],
              'hero-image-metadata': {
                width: 194,
                height: 194,
                'mime-type': 'image/jpeg',
                'focus-point': null
              },
              'published-at': 1509004361886,
              id: '8d240213-abc3-4eb7-8e09-1f0082986e5c',
              'hero-image-s3-key':
                'quintype-demo/2017-10/bdce310f-89d6-4c3d-9ebc-4a7ed6ccbf86/88b072c7-2631-4cee-abec-06c85c2911d8.jpeg',
              'author-id': 61657,
              'first-published-at': 1509004361886,
              'hero-image-caption': 'Narendra Modi with Ajit Doval',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'db09a326-3af2-4286-a92b-8ffbc9bb3c4f',
            score: null,
            type: 'story',
            item: {
              headline: ['परमाणु हथियारों को प्रतिबंधित करने के लिए संधि पर हस्ताक्षर']
            },
            story: {
              'author-name': 'Vineet Panjabi',
              headline: 'परमाणु हथियारों को प्रतिबंधित करने के लिए संधि पर हस्ताक्षर',
              slug: 'politics/2017/09/21/-',
              'last-published-at': 1505980220139,
              subheadline:
                'उत्तर कोरिया संकट के बड़ा आकार लेने की पृष्ठभूमि में यहां 51 देश परमाणु हथियारों को प्रतिबंधित करने वाली एक नयी संधि पर हस्ताक्ष',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 259,
                height: 194,
                'mime-type': 'image/jpeg',
                'focus-point': [171, 76]
              },
              'published-at': 1505980220139,
              id: 'db09a326-3af2-4286-a92b-8ffbc9bb3c4f',
              'hero-image-s3-key': 'quintype-demo/2016-01/69acdda0-2648-4cc2-b240-fd3603136bc5/download.jpeg',
              'author-id': 323432,
              'first-published-at': 1505980220139,
              'hero-image-caption': 'Narendra Modi with Ajit Doval',
              'story-template': 'photo',
              authors: [
                {
                  id: 323432,
                  name: 'Vineet Panjabi',
                  slug: 'vineet-panjabi',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '8339b599-3eab-4428-9486-9139d28bb1ba',
            score: null,
            type: 'story',
            item: {
              headline: ['9/11 and the unsolvable Afghan drama   ']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: '9/11 and the unsolvable Afghan drama   ',
              slug: 'politics/2017/09/13/afghan-drama',
              'last-published-at': 1505284207024,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 98,
                  name: 'Current Affairs'
                }
              ],
              'hero-image-metadata': {
                width: 5120,
                height: 3620,
                'focus-point': [3977, 737]
              },
              'published-at': 1505284207024,
              id: '8339b599-3eab-4428-9486-9139d28bb1ba',
              'hero-image-s3-key': 'quintype-demo/2016-07/1910bb30-b118-475c-aced-9304968c69c8/G-1.jpg',
              'author-id': 61657,
              'first-published-at': 1505284207024,
              'hero-image-caption': 'ABD, Virat &amp; Hashim - the best in the world today!',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 5278,
                  name: 'Michele Maguire',
                  slug: 'michele-maguire',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-OeX42e4xLm4/AAAAAAAAAAI/AAAAAAAAADQ/NmZhX0kP7J0/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '4886fb7b-3a0d-413f-ab55-7e45107cfb34',
            score: null,
            type: 'story',
            item: {
              headline: ['A memoir from a classmate']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'A memoir from a classmate',
              slug: 'politics/2017/09/05/nirmala-seetharaman',
              'last-published-at': 1504613043734,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 666,
                height: 338,
                'mime-type': 'image/jpeg',
                'focus-point': [319, 77]
              },
              'published-at': 1504613043734,
              id: '4886fb7b-3a0d-413f-ab55-7e45107cfb34',
              'hero-image-s3-key': 'quintype-demo/2017-09/44532ad7-a0fe-48dc-a67a-f4f469b88ed4/NIRMALA_BB_20160215.jpg',
              'author-id': 61657,
              'first-published-at': 1504613043734,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '3e112b40-cc06-4d70-901b-f825bd92099e',
            score: null,
            type: 'story',
            item: {
              headline: ['In the battle of perception, Kejriwal won this round']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'In the battle of perception, Kejriwal won this round',
              slug: 'politics/2017/07/20/in-the-battle-of-perception-kejriwal-won-this-round',
              'last-published-at': 1500522632130,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 380,
                height: 285,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1500522632130,
              id: '3e112b40-cc06-4d70-901b-f825bd92099e',
              'hero-image-s3-key': 'quintype-demo/2015-12/a7098e1a-62f9-42fd-ac12-a97e49dacce7/FP-1.jpg',
              'author-id': 2041,
              'first-published-at': 1500522632130,
              'hero-image-caption': 'Kejriwal, Chief Minister, New Delhi',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '88ef0bb2-bc4b-4e9b-87b1-1b774e9f7f67',
            score: null,
            type: 'story',
            item: {
              headline: ['Donald Trump set to sign executive orders on trade  ']
            },
            story: {
              'author-name': 'Hema Rao',
              headline: 'Donald Trump set to sign executive orders on trade  ',
              slug: 'politics/2017/03/08/airtel-vs-joi',
              'last-published-at': 1490953321146,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 660,
                height: 440,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1490953321146,
              id: '88ef0bb2-bc4b-4e9b-87b1-1b774e9f7f67',
              'hero-image-s3-key': 'quintype-demo/2017-03/d1f83f15-ab18-48c4-b207-235392a76ff7/trump-Reu-1-1-2-1-1.jpg',
              'author-id': 122655,
              'first-published-at': 1488968787660,
              'hero-image-caption': 'Trump',
              'story-template': null,
              authors: [
                {
                  id: 122655,
                  name: 'Hema Rao',
                  slug: 'hema-rao',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '07dc7d89-1733-4c00-bc23-89384d40f001',
            score: null,
            type: 'story',
            item: {
              headline: ['Something about modi']
            },
            story: {
              'author-name': 'Chirdeep Shetty',
              headline: 'Something about modi',
              slug: 'politics/2017/03/29/something-about-modi',
              'last-published-at': 1490809281495,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 275,
                height: 183,
                'focus-point': [192, 51]
              },
              'published-at': 1490809281495,
              id: '07dc7d89-1733-4c00-bc23-89384d40f001',
              'hero-image-s3-key': 'quintype-demo/2016-01/2d00bde7-a28c-4415-9382-9a9c6fb3c7fc/IPSMF-1.jpeg',
              'author-id': 15338,
              'first-published-at': 1490808450252,
              'hero-image-caption': 'Nawaz Sharif &amp; Modi&nbsp;',
              'story-template': null,
              authors: [
                {
                  id: 15338,
                  name: 'Chirdeep Shetty',
                  slug: 'chirdeep-shetty',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'twitter-handle': '@chirdeepshettyquintype',
                  bio: '<p>Hello, this is a test for bio. </p>'
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          }
        ],
        color: '#6093f2'
      },
      {
        id: 7765,
        'associated-metadata': {
          layout: 'TwoColOneAd'
        },
        type: 'collection',
        name: 'Technology',
        slug: 'madrid-slider',
        template: 'default',
        metadata: {
          'cover-image': {}
        },
        summary: 'Slider stories on the Madrid home page.',
        items: [
          {
            id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
            score: null,
            type: 'story',
            item: {
              headline: ['Visual story - Demo1']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Visual story - Demo1',
              slug: 'ampstories/technology/2018/12/20/visual-story-demo1',
              'last-published-at': 1545982907212,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 960,
                height: 540,
                'focus-point': [499, 88]
              },
              'published-at': 1545982907212,
              id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
              'hero-image-s3-key': 'quintype-demo/2017-07/e4bac9eb-6c32-4f07-a16a-90fa40166c35/DH-1.jpeg',
              'author-id': 541559,
              'first-published-at': 1545300194018,
              'hero-image-caption': 'Roger Federer, the 8th title - Wimbledon 2017!',
              'story-template': 'visual-story',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
            score: null,
            type: 'story',
            item: {
              headline: ['Demo Visual story']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Demo Visual story',
              slug: 'ampstories/technology/2018/12/20/demo-visual-story',
              'last-published-at': 1545332341188,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 640,
                height: 361,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1545332341188,
              id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
              'hero-image-s3-key':
                'quintype-demo/2018-12/22eb981a-6c8e-41c0-9d36-c2f72f46b29a/pennews_2F2018_05_2F0e93d687_c1dd_464b_aba3_8c23ba6c3beb_2FSonam_reception.jpg',
              'author-id': 541559,
              'first-published-at': 1545332010119,
              'hero-image-caption': 'caption',
              'story-template': 'visual-story',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
            score: null,
            type: 'story',
            item: {
              headline: ['Breaking News Test on 13 Dec']
            },
            story: {
              'author-name': 'Anil K Govind',
              headline: 'Breaking News Test on 13 Dec',
              slug: 'technology/2018/12/13/breaking-news-test-on-13-dec',
              'last-published-at': 1544682898905,
              subheadline: 'Breaking News Test on 13 Dec',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 720,
                height: 340,
                'mime-type': 'image/jpeg',
                'focus-point': [230, 110]
              },
              'published-at': 1544682898905,
              id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 548414,
              'first-published-at': 1544682898905,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 548414,
                  name: 'Anil K Govind',
                  slug: 'anil-k-govind',
                  'avatar-url':
                    'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b142a043-4720-4578-b23e-6791e9f42735',
            score: null,
            type: 'story',
            item: {
              headline: ['New Story Elements']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'New Story Elements',
              slug: 'technology/2015/10/21/new-story-elements',
              'last-published-at': 1539770584382,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1920,
                height: 1080,
                'focus-point': [1301, 270]
              },
              'published-at': 1539770584382,
              id: 'b142a043-4720-4578-b23e-6791e9f42735',
              'hero-image-s3-key': 'quintype-demo/2015-09/4c0122d6-6c28-4a59-8b16-8d90701a4e35/TigerWoods-1.jpg',
              'author-id': 2041,
              'first-published-at': 1445404200710,
              'hero-image-caption': 'The famed Tiger Woods Swing !',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
            score: null,
            type: 'story',
            item: {
              headline: ['Two crew escape after ‘Soyuz’ rocket failure']
            },
            story: {
              'author-name': 'Kunal Singh',
              headline: 'Two crew escape after ‘Soyuz’ rocket failure',
              slug: 'technology/2018/10/11/two-crew-escape-after-soyuz-rocket-failure',
              'last-published-at': 1539253999595,
              subheadline: 'Russian cosmonaut and US astronaut ‘in good condition’ after making emergency landing',
              alternative: null,
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 744,
                height: 389,
                'mime-type': 'image/jpeg',
                'focus-point': [381, 138]
              },
              'published-at': 1539253999595,
              id: '81c10cec-56fe-43e7-95c9-d3a8d63547e4',
              'hero-image-s3-key': 'quintype-demo/2018-10/9b82a587-dfb0-4aa5-9b83-96a814eec1d9/soyuz_resize_md.jpg',
              'author-id': 491531,
              'first-published-at': 1539253999595,
              'hero-image-caption': 'Soyuz 11',
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9552e728-f1b7-4a46-a1bf-34f30a266c0e',
            score: null,
            type: 'story',
            item: {
              headline: ['Photo story - test']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Photo story - test',
              slug: 'success-story/2018/09/26/photo-story-test',
              'last-published-at': 1539168790153,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  id: 4415,
                  name: 'Success Story',
                  slug: 'success-story'
                },
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 570,
                height: 380,
                'focus-point': [304, 122]
              },
              'published-at': 1539168790153,
              id: '9552e728-f1b7-4a46-a1bf-34f30a266c0e',
              'hero-image-s3-key': 'quintype-demo/2015-10/76ef287a-23b6-4ffe-b71e-72e88a739809/DJ-3.jpg',
              'author-id': 541559,
              'first-published-at': 1537946000293,
              'hero-image-caption': 'Durga Puja Pandal at Calcutta',
              'story-template': 'photo',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9eece5ae-c682-405b-95ce-b8a77ee250f8',
            score: null,
            type: 'story',
            item: {
              headline: ['Malta’s Prime Minister Tells UN That Crypto Is the ‘Inevitable Future of Money’']
            },
            story: {
              'author-name': 'Kunal Singh',
              headline: 'Malta’s Prime Minister Tells UN That Crypto Is the ‘Inevitable Future of Money’',
              slug:
                'technology/2018/10/01/maltas-prime-minister-tells-un-that-crypto-is-the-inevitable-future-of-money',
              'last-published-at': 1538564214243,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 740,
                height: 494,
                'mime-type': 'image/jpeg',
                'focus-point': [390, 218]
              },
              'published-at': 1538564214243,
              id: '9eece5ae-c682-405b-95ce-b8a77ee250f8',
              'hero-image-s3-key': 'quintype-demo/2018-10/b97c4ba3-e097-4121-854f-5c5a03798677/Crypt1.jpg',
              'author-id': 491531,
              'first-published-at': 1538403689422,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
            score: null,
            type: 'story',
            item: {
              headline: ['Demo - External Story']
            },
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Demo - External Story',
              slug: 'technology/2018/10/01/demo-external-story',
              'last-published-at': 1538384977219,
              subheadline:
                'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor.',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1441,
                height: 1077,
                'mime-type': 'image/png',
                'focus-point': [273, 498]
              },
              'published-at': 1538384977219,
              id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
              'hero-image-s3-key':
                'quintype-demo/2018-10/410d7d51-811a-44cc-a92b-4d873cb1f5aa/screenshot_www_quintype_com_2018_09_11_20_16_32.png',
              'author-id': 139717,
              'first-published-at': 1538384511112,
              'hero-image-caption': null,
              'story-template': 'news-elsewhere',
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'reference-url':
                  'https://www.quintype.com/blog/business/quintypes-tech-innovation-helps-its-digital-partners-win-two-wan-ifra-awards',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9b1a6f8e-19ab-4395-a079-d985edf4e4ac',
            score: null,
            type: 'story',
            item: {
              headline: ['Story Tests']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Story Tests',
              slug: 'technology/2018/09/27/story-tests',
              'last-published-at': 1538047737607,
              subheadline: 'Subtitle',
              alternative: null,
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  id: 215,
                  name: 'Cities',
                  slug: 'cities'
                },
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                }
              ],
              'hero-image-metadata': {
                width: 578,
                height: 562,
                'mime-type': 'image/jpeg',
                'focus-point': [214, 187]
              },
              'published-at': 1538047737607,
              id: '9b1a6f8e-19ab-4395-a079-d985edf4e4ac',
              'hero-image-s3-key':
                'quintype-demo/2018-07/9a8c6576-2a1d-403d-9d34-0dd95db62cfb/4e7be0b9_1eb3_402b_9c9c_0314467b2377.jpg',
              'author-id': 541559,
              'first-published-at': 1538047737607,
              'hero-image-caption': 'Caption comes here',
              'story-template': 'news-elsewhere',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'reference-url': 'https://www.quintype.com/',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '41179c74-f08d-4c4a-8898-4d3d3eee2e7f',
            score: null,
            type: 'story',
            item: {
              headline: ['YouTube now available for JioPhone']
            },
            story: {
              'author-name': 'Varul Mayank - Knocksense',
              headline: 'YouTube now available for JioPhone',
              slug: 'technology/2018/09/20/youtube-now-available-for-jiophone',
              'last-published-at': 1537426884824,
              subheadline:
                'YouTube is now available for JioPhone, and users can go and download the app from JioStore.',
              alternative: {
                home: {
                  default: {
                    headline: 'Reliance Jio launches Youtube app for Jio Phones'
                  }
                }
              },
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1024,
                height: 576,
                'mime-type': 'image/jpeg',
                'focus-point': null
              },
              'published-at': 1537426884824,
              id: '41179c74-f08d-4c4a-8898-4d3d3eee2e7f',
              'hero-image-s3-key':
                'quintype-demo/2018-09/a6a397e8-762e-4489-9a44-062aa1e1b885/1fec1a01_4d4d_41d5_8789_5b383a9ee453.jpg',
              'author-id': 141012,
              'first-published-at': 1537426884824,
              'hero-image-caption': 'jio',
              'story-template': null,
              authors: [
                {
                  id: 141012,
                  name: 'Varul Mayank - Knocksense',
                  slug: 'varul-mayank-knocksense',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'sponsored-by': 'Siddharth',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '0fc86f92-e0f6-46c0-98db-9bfc18953e28',
            score: null,
            type: 'story',
            item: {
              headline: ['A digital future – Enabling HR automation   ']
            },
            story: {
              'author-name': 'Ranjeet Singh',
              headline: 'A digital future – Enabling HR automation   ',
              slug: 'current-affairs/2018/06/04/a-digital-future-enabling-hr-automation',
              'last-published-at': 1536079827031,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                },
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1200,
                height: 630,
                'mime-type': 'image/png',
                'focus-point': [628, 116]
              },
              'published-at': 1536079827031,
              id: '0fc86f92-e0f6-46c0-98db-9bfc18953e28',
              'hero-image-s3-key':
                'quintype-demo/2018-06/8077daf3-025b-4e73-9da6-29719d919220/web_images11_4_1200x630.png',
              'author-id': 15351,
              'first-published-at': 1528105031904,
              'hero-image-caption': '<b>A digital future&nbsp; Enabling HR automation1</b>',
              'story-template': null,
              authors: [
                {
                  id: 15351,
                  name: 'Ranjeet Singh',
                  slug: 'ranjeet-singh',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'sponsored-by': 'SME',
                'card-share': {
                  shareable: true
                },
                'promotional-message': {
                  'is-disabled': true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '23d40197-a3ad-4491-8bd9-eb31ca696d71',
            score: null,
            type: 'story',
            item: {
              headline: ['Delivering Life-saving Medicines']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Delivering Life-saving Medicines',
              slug: 'current-affairs/2018/09/04/url',
              'last-published-at': 1536077886477,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                },
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  id: 82,
                  name: 'Sports',
                  slug: 'sports'
                }
              ],
              'hero-image-metadata': {
                width: 610,
                height: 320,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1536077886477,
              id: '23d40197-a3ad-4491-8bd9-eb31ca696d71',
              'hero-image-s3-key': 'quintype-demo/2018-06/52b81a65-3045-4bc7-88db-feafd9127358/Anjan.jpg',
              'author-id': 541559,
              'first-published-at': 1536077255237,
              'hero-image-caption': 'Anjan Chatterjee/<b>അഞ്ജന്‍ ചാറ്റര്‍ജി</b>',
              'story-template': 'video',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '5273aff7-1dc0-4b46-9a56-0c63683b4dec',
            score: null,
            type: 'story',
            item: {
              headline: ['With eye on debt recovery, SBI asks DoT not to revoke RCom’s spectrums']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'With eye on debt recovery, SBI asks DoT not to revoke RCom’s spectrums',
              slug: 'technology/2018/07/27/with-eye-on-debt-recovery-sbi-asks-dot-not-to-revoke-rcoms-spectrums',
              'last-published-at': 1535806255690,
              subheadline: 'Revocation of spectrum may hurt telco’s sale to RJio, says the lender',
              alternative: {
                home: {
                  default: {
                    headline: 'With eye on debt recovery',
                    'hero-image': {
                      'hero-image-url':
                        'https://images.assettype.com/quintype-demo/2016-01/fdb17290-ae1c-4153-ad40-760106e9ffb3/iph.jpeg',
                      'hero-image-metadata': {
                        width: 710,
                        'mime-type': 'image/jpeg',
                        height: 854
                      },
                      'hero-image-s3-key': 'quintype-demo/2016-01/fdb17290-ae1c-4153-ad40-760106e9ffb3/iph.jpeg',
                      'hero-image-caption': 'iphones',
                      'hero-image-attribution': null
                    }
                  }
                }
              },
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                },
                {
                  id: 215,
                  name: 'Cities',
                  slug: 'cities'
                }
              ],
              'hero-image-metadata': {
                width: 960,
                height: 408,
                'mime-type': 'image/jpeg',
                'focus-point': [639, 120]
              },
              'published-at': 1535806255690,
              id: '5273aff7-1dc0-4b46-9a56-0c63683b4dec',
              'hero-image-s3-key': 'quintype-demo/2018-07/01670025-10c6-4659-b32e-3d859b66d8a3/BL27P1RCOM.jpeg',
              'author-id': 61657,
              'first-published-at': 1532680057934,
              'hero-image-caption': 'Reliance Industries',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'd677509e-37e4-460c-a942-8a55bd4c4edb',
            score: null,
            type: 'story',
            item: {
              headline: ['Will Summit Jumpstart Biopharma in North Korea?']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'Will Summit Jumpstart Biopharma in North Korea?',
              slug: 'technology/2018/06/28/will-summit-jumpstart-biopharma-in-north-korea',
              'last-published-at': 1535367985749,
              subheadline: 'Experts Ponder Industry’s Place in Kim’s DPRK; South Korea Eyes Collaborations',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  id: 181,
                  name: 'Health & Fitness',
                  slug: 'health-fitness'
                }
              ],
              'hero-image-metadata': {
                width: 800,
                height: 497,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1535367985749,
              id: 'd677509e-37e4-460c-a942-8a55bd4c4edb',
              'hero-image-s3-key':
                'quintype-demo/2018-06/30c6a41e-e600-4063-af45-bf951cec776a/Jun18_2018_WikimediaPDUSGovt_SingaporeSummitKimAndTrumpShakeHands1582181163.jpg',
              'author-id': 61657,
              'first-published-at': 1530181029824,
              'hero-image-caption':
                'At their June 12 summit, President Donald Trump and Democratic People’s Republic of Korea (DPRK) leader Kim Jong Un signed a joint statement committing to “cooperate for the development of new U.S.-DPRK relations and for the promotion of peace, prosperity, and security of the Korean Peninsula and of the world.” But it is difficult, if not impossible, for Westerners and other outsiders to know what role biotech will play toward fulfilling those commitments within the DPRK, since the government has communicated only sketchy information about its biotech facilities and efforts, which include civilian as well as military applications.',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'sponsored-by': 'Quintype Technologies',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '1a95ebe0-f779-485a-b2ec-818767865868',
            score: null,
            type: 'story',
            item: {
              headline: ['China in struggle to curb reliance on US market, suppliers']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'China in struggle to curb reliance on US market, suppliers',
              slug: 'technology/2018/08/27/china-in-struggle-to-curb-reliance-on-us-market-suppliers',
              'last-published-at': 1535354418018,
              subheadline: 'China in struggle to curb reliance on US market, suppliers',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 720,
                height: 340,
                'mime-type': 'image/jpeg',
                'focus-point': [230, 110]
              },
              'published-at': 1535354418018,
              id: '1a95ebe0-f779-485a-b2ec-818767865868',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 541559,
              'first-published-at': 1535354418018,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'e334a9a8-e644-4467-affd-5427954f91a3',
            score: null,
            type: 'story',
            item: {
              headline: ['Gallery repetition with the Kapoors']
            },
            story: {
              'author-name': 'Ajay Appaden',
              headline: 'Gallery repetition with the Kapoors',
              slug: 'technology/2018/02/16/gallery-repetition-test',
              'last-published-at': 1534223550632,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 800,
                height: 450,
                'focus-point': [428, 207]
              },
              'published-at': 1534223550632,
              id: 'e334a9a8-e644-4467-affd-5427954f91a3',
              'hero-image-s3-key': 'quintype-demo/2016-01/d00d01c1-7886-4b1d-9a8c-4d6ab9e92d26/y1.jpg',
              'author-id': 169905,
              'first-published-at': 1518772169497,
              'hero-image-caption': 'Warrior series',
              'story-template': 'photo',
              authors: [
                {
                  id: 169905,
                  name: 'Ajay Appaden',
                  slug: 'ajay-appaden',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'd36a9089-35e7-4b7f-b2e1-0d5ce68926a7',
            score: null,
            type: 'story',
            item: {
              headline: ['Podcast']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'Podcast',
              slug: 'technology/2018/08/09/my-first-podcast',
              'last-published-at': 1533795576906,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  id: 98,
                  name: 'Current Affairs',
                  slug: 'current-affairs'
                }
              ],
              'hero-image-metadata': {
                width: 5120,
                height: 3620,
                'focus-point': [2169, 1167]
              },
              'published-at': 1533795576906,
              id: 'd36a9089-35e7-4b7f-b2e1-0d5ce68926a7',
              'hero-image-s3-key': 'quintype-demo/2016-07/1910bb30-b118-475c-aced-9304968c69c8/G-1.jpg',
              'author-id': 61657,
              'first-published-at': 1533795576906,
              'hero-image-caption': 'ABD, Virat &amp; Hashim - the best in the world today!',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 323432,
                  name: 'Vineet Panjabi',
                  slug: 'vineet-panjabi',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: false
                },
                'story-attributes': {
                  genre: ['comedy']
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '938e8c49-9295-478d-8cc8-0161a2d4d73e',
            score: null,
            type: 'story',
            item: {
              headline: ['Live from Apple’s WWDC 2015 - Edit']
            },
            story: {
              'author-name': 'Christin',
              headline: 'Live from Apple’s WWDC 2015 - Edit',
              slug: 'technology/2015/08/17/live-from-apples-wwdc-2015',
              'last-published-at': 1533626450917,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 800,
                height: 533,
                'focus-point': [394, 247]
              },
              'published-at': 1533626450917,
              id: '938e8c49-9295-478d-8cc8-0161a2d4d73e',
              'hero-image-s3-key': 'quintype-demo/2015-08/92264b73-6894-4ae5-801e-2756bd5890a4/LB-1.jpg',
              'author-id': 139357,
              'first-published-at': 1439810805413,
              'hero-image-caption': 'Apple WWDC 2015',
              'story-template': 'live-blog',
              authors: [
                {
                  id: 139357,
                  name: 'Christin',
                  slug: 'christin',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'avatar-s3-key':
                    'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'twitter-handle': 'krriztin',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'is-closed': true,
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '86d8bd9c-164a-452a-93a9-ebe5d6707a45',
            score: null,
            type: 'story',
            item: {
              headline: ['this is the most amazing feature ever']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'this is the most amazing feature ever',
              slug: 'technology/2018/07/25/this-is-the-most-amazing-feature-ever',
              'last-published-at': 1532517428901,
              subheadline: 'this is the most amazing feature ever',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 720,
                'focus-point': [230, 110],
                height: 340
              },
              'published-at': 1532517428901,
              id: '86d8bd9c-164a-452a-93a9-ebe5d6707a45',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1532517428901,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '00b7c1d1-7c04-45b8-a266-3a12f7e46b3a',
            score: null,
            type: 'story',
            item: {
              headline: ['Breaking news without tagging people']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'Breaking news without tagging people',
              slug: 'technology/2018/07/08/breaking-news-without-tagging-people',
              'last-published-at': 1531067728427,
              subheadline: 'Breaking news without tagging people',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 720,
                'focus-point': [230, 110],
                height: 340
              },
              'published-at': 1531067728427,
              id: '00b7c1d1-7c04-45b8-a266-3a12f7e46b3a',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1531067728427,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '3eea29f7-5dec-4388-869f-e135a8869e9c',
            score: null,
            type: 'story',
            item: {
              headline: ['ugh. I think I got this to work finally']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'ugh. I think I got this to work finally',
              slug: 'technology/2018/07/08/ugh-i-think-i-got-this-to-work-finally',
              'last-published-at': 1531067679651,
              subheadline: 'ugh. I think I got this to work finally',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 720,
                'focus-point': [230, 110],
                height: 340
              },
              'published-at': 1531067679651,
              id: '3eea29f7-5dec-4388-869f-e135a8869e9c',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1531067679651,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '6db2a16a-036d-46e7-ac2e-3e4ee0476b53',
            score: null,
            type: 'story',
            item: {
              headline: ['This can’t possibly work']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'This can’t possibly work',
              slug: 'technology/2018/07/08/this-cant-possibly-work',
              'last-published-at': 1531067476066,
              subheadline: 'This can’t possibly work',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 720,
                'focus-point': [230, 110],
                height: 340
              },
              'published-at': 1531067476066,
              id: '6db2a16a-036d-46e7-ac2e-3e4ee0476b53',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1531067476066,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '60538137-8198-4196-9439-cbbc0c569f5e',
            score: null,
            type: 'story',
            item: {
              headline: ['does this work?']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'does this work?',
              slug: 'technology/2018/07/08/does-this-work',
              'last-published-at': 1531067406620,
              subheadline: 'does this work?',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 720,
                'focus-point': [230, 110],
                height: 340
              },
              'published-at': 1531067406620,
              id: '60538137-8198-4196-9439-cbbc0c569f5e',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1531067406620,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '0638e8db-f50d-4446-92fa-87b76f56bdfa',
            score: null,
            type: 'story',
            item: {
              headline: ['do you think @chirdeep, @sriram, and @vineet can sell this feature']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
              slug: 'technology/2018/07/08/do-you-think-chirdeep-sriram-and-vineet-can-sell-this-feature-5',
              'last-published-at': 1531067118643,
              subheadline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 720,
                'focus-point': [230, 110],
                height: 340
              },
              'published-at': 1531067118643,
              id: '0638e8db-f50d-4446-92fa-87b76f56bdfa',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1531067118643,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '01fd9845-a80e-4689-b698-7ca0c00b80e7',
            score: null,
            type: 'story',
            item: {
              headline: ['do you think @chirdeep, @sriram, and @vineet can sell this feature']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
              slug: 'technology/2018/07/08/do-you-think-chirdeep-sriram-and-vineet-can-sell-this-feature-4',
              'last-published-at': 1531067019953,
              subheadline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 720,
                'focus-point': [230, 110],
                height: 340
              },
              'published-at': 1531067019953,
              id: '01fd9845-a80e-4689-b698-7ca0c00b80e7',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1531067019953,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '8fbeb02e-85fd-496d-a8b5-cd70179be842',
            score: null,
            type: 'story',
            item: {
              headline: ['do you think @chirdeep, @sriram, and @vineet can sell this feature']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
              slug: 'technology/2018/07/08/do-you-think-chirdeep-sriram-and-vineet-can-sell-this-feature-3',
              'last-published-at': 1531066944296,
              subheadline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 720,
                'focus-point': [230, 110],
                height: 340
              },
              'published-at': 1531066944296,
              id: '8fbeb02e-85fd-496d-a8b5-cd70179be842',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1531066944296,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '91eb615d-674a-4fab-86e2-f13b7009d9a6',
            score: null,
            type: 'story',
            item: {
              headline: ['do you think @chirdeep, @sriram, and @vineet can sell this feature']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
              slug: 'technology/2018/07/08/do-you-think-chirdeep-sriram-and-vineet-can-sell-this-feature-2',
              'last-published-at': 1531066872593,
              subheadline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 720,
                'focus-point': [230, 110],
                height: 340
              },
              'published-at': 1531066872593,
              id: '91eb615d-674a-4fab-86e2-f13b7009d9a6',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1531066872593,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '1e033a03-aa80-4b08-9289-798b01db55c0',
            score: null,
            type: 'story',
            item: {
              headline: ['do you think @chirdeep, @sriram, and @vineet can sell this feature']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
              slug: 'technology/2018/07/08/do-you-think-chirdeep-sriram-and-vineet-can-sell-this-feature',
              'last-published-at': 1531066840289,
              subheadline: 'do you think @chirdeep, @sriram, and @vineet can sell this feature',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 720,
                'focus-point': [230, 110],
                height: 340
              },
              'published-at': 1531066840289,
              id: '1e033a03-aa80-4b08-9289-798b01db55c0',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1531066840289,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '4f6664b7-5249-4595-8e36-31fc328021a0',
            score: null,
            type: 'story',
            item: {
              headline: ['Something is happening, somewhere']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'Something is happening, somewhere',
              slug: 'technology/2018/07/08/something-is-happening-somewhere-2',
              'last-published-at': 1531066203050,
              subheadline: 'Something is happening, somewhere',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 720,
                height: 340,
                'mime-type': 'image/jpeg',
                'focus-point': [230, 110]
              },
              'published-at': 1531066203050,
              id: '4f6664b7-5249-4595-8e36-31fc328021a0',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1531066203050,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'c43b2ee0-9a29-4e40-a84f-7a28f99a5dfe',
            score: null,
            type: 'story',
            item: {
              headline: ['Something is happening, somewhere']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'Something is happening, somewhere',
              slug: 'technology/2018/07/08/something-is-happening-somewhere',
              'last-published-at': 1531065675604,
              subheadline: 'Something is happening, somewhere',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 720,
                height: 340,
                'mime-type': 'image/jpeg',
                'focus-point': [230, 110]
              },
              'published-at': 1531065675604,
              id: 'c43b2ee0-9a29-4e40-a84f-7a28f99a5dfe',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 2038,
              'first-published-at': 1531065675604,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9b9ce5a3-c644-4fb6-a527-e35b52facbbe',
            score: null,
            type: 'story',
            item: {
              headline: [
                'Principal of OmniChannel Marketing Opera Solutions Danny Flamberg talks building customer intimacy through big data and more'
              ]
            },
            story: {
              'author-name': 'Kunal Singh',
              headline:
                'Principal of OmniChannel Marketing Opera Solutions Danny Flamberg talks building customer intimacy through big data and more',
              slug:
                'technology/2018/06/12/principal-of-omnichannel-marketing-opera-solutions-danny-flamberg-talks-building-customer-intimacy-through-big-data-and-more',
              'last-published-at': 1528798695162,
              subheadline:
                'One of America’s most respected practitioners of digital communications Flamberg shares his tips on big data readiness and digital revolution transitions',
              alternative: null,
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 696,
                height: 639,
                'mime-type': 'image/jpeg',
                'focus-point': [361, 266]
              },
              'published-at': 1528798695162,
              id: '9b9ce5a3-c644-4fb6-a527-e35b52facbbe',
              'hero-image-s3-key': 'quintype-demo/2018-06/e1176973-7516-48af-a3cd-0941df171daf/df_696x639.jpg',
              'author-id': 491531,
              'first-published-at': 1528798695162,
              'hero-image-caption': 'Test',
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'sponsored-by': 'Verizon',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '385c3316-453b-4d12-8073-9191dc751dd2',
            score: null,
            type: 'story',
            item: {
              headline: ['‘Cryptocurrency for Beginners’']
            },
            story: {
              'author-name': 'Sareena',
              headline: '‘Cryptocurrency for Beginners’',
              slug: 'technology/2018/05/30/cryptocurrency-for-beginners',
              'last-published-at': 1527676061209,
              subheadline: '8 Reasons You Can’t Afford to miss bitcoin pioneer Amit Bhardwaj’s e-book',
              alternative: {
                home: {
                  default: {
                    headline: 'Cryptocurrency for Beginners'
                  }
                }
              },
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 659,
                height: 291,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1527676061209,
              id: '385c3316-453b-4d12-8073-9191dc751dd2',
              'hero-image-s3-key':
                'quintype-demo/2016-10/4277a481-4235-43cd-9e26-1dc56ea4b2b2/United States of America 1 Dollar - 1963 B Front.jpg',
              'author-id': 94596,
              'first-published-at': 1527676061209,
              'hero-image-caption': 'One dollar Bill',
              'story-template': 'listicle',
              authors: [
                {
                  id: 94596,
                  name: 'Sareena',
                  slug: 'sareena',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '8f504035-4f71-4116-bdf6-ac59eba5d1eb',
            score: null,
            type: 'story',
            item: {
              headline: ['At the Corner of Cyber and BCP']
            },
            story: {
              'author-name': 'Kunal Singh',
              headline: 'At the Corner of Cyber and BCP',
              slug: 'technology/2018/05/24/at-the-corner-of-cyber-and-bcp',
              'last-published-at': 1527174481862,
              subheadline: 'Test Link',
              alternative: null,
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1000,
                height: 554,
                'mime-type': 'image/jpeg',
                'focus-point': [572, 242]
              },
              'published-at': 1527174481862,
              id: '8f504035-4f71-4116-bdf6-ac59eba5d1eb',
              'hero-image-s3-key': 'quintype-demo/2018-05/085906e8-3676-4ffc-907f-6a84a40108e5/BMW_1.jpg',
              'author-id': 491531,
              'first-published-at': 1527174481862,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '8bfbb23f-cf50-487a-b1b6-a551e77de844',
            score: null,
            type: 'story',
            item: {
              headline: ['Vivo V9 Review: An Amazing Camera Experience  ']
            },
            story: {
              'author-name': 'Mamatha',
              headline: 'Vivo V9 Review: An Amazing Camera Experience  ',
              slug: 'technology/2018/04/12/vivo-v9-review-an-amazing-camera-experience',
              'last-published-at': 1523523577297,
              subheadline: 'Great build, great cameras, and long battery life sum up the Vivo V9  ',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1024,
                height: 683,
                'mime-type': 'image/jpeg',
                'focus-point': null
              },
              'published-at': 1523523577297,
              id: '8bfbb23f-cf50-487a-b1b6-a551e77de844',
              'hero-image-s3-key':
                'quintype-demo/2018-04/f801ca1c-c4ff-488c-b99c-ac567b29081f/c70eb2a8_fe84_4b60_8474_83ae2955efe8.jpg',
              'author-id': 352351,
              'first-published-at': 1523520442122,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 352351,
                  name: 'Mamatha',
                  slug: 'mamatha',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '80a94153-4425-4573-b538-3be1ff8b7393',
            score: null,
            type: 'story',
            item: {
              headline: ['Vivo announces ‘Super HDR’ that uses AI for better smartphone pictures  ']
            },
            story: {
              'author-name': 'Mamatha',
              headline: 'Vivo announces ‘Super HDR’ that uses AI for better smartphone pictures  ',
              slug: 'technology/2018/04/12/vivo-announces-super-hdr-that-uses-ai-for-better-smartphone-pictures',
              'last-published-at': 1523523509645,
              subheadline: 'The new tech has a dynamic range up to 14 EV.',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1600,
                height: 1200,
                'mime-type': 'image/jpeg',
                'focus-point': [1272, 435]
              },
              'published-at': 1523523509645,
              id: '80a94153-4425-4573-b538-3be1ff8b7393',
              'hero-image-s3-key': 'quintype-demo/2018-04/2b2e9365-b0d0-4b96-bd8c-a99fe565115f/vivo_super_hdr_1.jpg',
              'author-id': 352351,
              'first-published-at': 1523520880365,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 352351,
                  name: 'Mamatha',
                  slug: 'mamatha',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '0d80a7e7-0425-44b7-bc46-61406e7b5880',
            score: null,
            type: 'story',
            item: {
              headline: ['Vivo V9 Review: Attack of the Clones  ']
            },
            story: {
              'author-name': 'Mamatha',
              headline: 'Vivo V9 Review: Attack of the Clones  ',
              slug: 'technology/2018/04/12/vivo-v9-review-attack-of-the-clones',
              'last-published-at': 1523520717511,
              subheadline:
                'Is there more to the Vivo V9 than the much-talked about display notch? Here’s our review. &nbsp;',
              alternative: null,
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 805,
                height: 600,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1523520717511,
              id: '0d80a7e7-0425-44b7-bc46-61406e7b5880',
              'hero-image-s3-key':
                'quintype-demo/2018-04/35d4b447-d18f-47e8-9997-a6fc2e62e66f/vivo_v9_review_bgr_2.jpg',
              'author-id': 352351,
              'first-published-at': 1523520717511,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 352351,
                  name: 'Mamatha',
                  slug: 'mamatha',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'e6ae944d-7220-4a05-88ad-7176cfa4f0bf',
            score: null,
            type: 'story',
            item: {
              headline: ['Vivo V9 Review: The notch is here to stay  ']
            },
            story: {
              'author-name': 'Mamatha',
              headline: 'Vivo V9 Review: The notch is here to stay  ',
              slug: 'technology/2018/04/12/vivo-v9-review-the-notch-is-here-to-stay',
              'last-published-at': 1523517414664,
              subheadline:
                'Vivo V9 has a lot going for it such as the big screen, the iPhone X like front panel, steady performance and light form-factor',
              alternative: null,
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 621,
                height: 414,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1523517414664,
              id: 'e6ae944d-7220-4a05-88ad-7176cfa4f0bf',
              'hero-image-s3-key':
                'quintype-demo/2018-04/d2e193f0-9808-4f61-94bd-b20557e89c0b/Vivo_kTFG__621x414_LiveMint.jpg',
              'author-id': 352351,
              'first-published-at': 1523517414664,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 352351,
                  name: 'Mamatha',
                  slug: 'mamatha',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '0a19f129-9a95-46a9-8bde-19e86196c64b',
            score: null,
            type: 'story',
            item: {
              headline: ['Buzz Aldrin passes ‘lie detector’ on claims that Apollo astronauts saw UFOs  ']
            },
            story: {
              'author-name': 'Kirti Kaushik',
              headline: 'Buzz Aldrin passes ‘lie detector’ on claims that Apollo astronauts saw UFOs  ',
              slug: 'technology/2018/04/09/buzz-aldrin-passes-lie-detector-on-claims-that-apollo-astronauts-saw-ufos',
              'last-published-at': 1523262597360,
              subheadline:
                'Audio tapes of Apollo astronauts speaking of UFOs were put through tests and they show that the astronauts were not lying about sightings in space. &nbsp;',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 564,
                height: 450,
                'mime-type': 'image/jpeg',
                'focus-point': [306, 168]
              },
              'published-at': 1523262597360,
              id: '0a19f129-9a95-46a9-8bde-19e86196c64b',
              'hero-image-s3-key':
                'quintype-demo/2018-04/5c6a9f27-d347-4cd3-a730-773fc1ee9474/1523255577_apollo_11_space_mission.jpg',
              'author-id': 507344,
              'first-published-at': 1523262426676,
              'hero-image-caption':
                'Astronaut Buzz Aldrin salutes the American flag on the Moon during the Apollo 11 space mission',
              'story-template': null,
              authors: [
                {
                  id: 507344,
                  name: 'Kirti Kaushik',
                  slug: 'kirti-kaushik',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '4cbef4ae-74db-4383-aa76-97b6f72c527f',
            score: null,
            type: 'story',
            item: {
              headline: ['Apple iPhone X, 8 and 8 Plus: First impressions']
            },
            story: {
              'author-name': 'Sareena',
              headline: 'Apple iPhone X, 8 and 8 Plus: First impressions',
              slug: 'technology/2017/12/14/apple-iphone-x-8-and-8-plus-first-impressions',
              'last-published-at': 1514193672083,
              subheadline: 'Apple iphone',
              alternative: {
                home: {
                  default: {
                    'hero-image': {
                      'hero-image-url':
                        'https://quintype-dropbox.s3-accelerate.amazonaws.com/itsman.quintype.com/2017-12-25/743/iphones8-plus-x.jpg',
                      'hero-image-metadata': {
                        width: 1800,
                        height: 1100,
                        'mime-type': 'image/jpeg'
                      },
                      'hero-image-caption': 'Alternative caption',
                      'hero-image-attribution': 'Alternate',
                      'hero-image-s3-key':
                        'quintype-demo/2017-12/00ec6380-ec71-4384-be4f-895a30b2d576/iphones8-plus-x.jpg'
                    }
                  }
                }
              },
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 710,
                height: 854,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1514193672083,
              id: '4cbef4ae-74db-4383-aa76-97b6f72c527f',
              'hero-image-s3-key': 'quintype-demo/2016-01/fdb17290-ae1c-4153-ad40-760106e9ffb3/iph.jpeg',
              'author-id': 94596,
              'first-published-at': 1513237859989,
              'hero-image-caption': 'iphones',
              'story-template': null,
              authors: [
                {
                  id: 94596,
                  name: 'Sareena',
                  slug: 'sareena',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'ef91900e-2a7d-42c6-8ccc-fb75db2a4504',
            score: null,
            type: 'story',
            item: {
              headline: ['iPhone X vs Samsung Galaxy Note 8']
            },
            story: {
              'author-name': 'Vineet Panjabi',
              headline: 'iPhone X vs Samsung Galaxy Note 8',
              slug: 'technology/2017/09/14/iphone-x-vs-samsung-galaxy-note-8',
              'last-published-at': 1512732026172,
              subheadline: 'A battle for smartphone dominance',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 616,
                height: 347,
                'mime-type': 'image/jpeg',
                'focus-point': [408, 232]
              },
              'published-at': 1512732026172,
              id: 'ef91900e-2a7d-42c6-8ccc-fb75db2a4504',
              'hero-image-s3-key':
                'quintype-demo/2016-11/20d960e9-cbb4-46c0-a6cc-3819c326f766/pixelxl-iphone7plus-30.jpg',
              'author-id': 323432,
              'first-published-at': 1505384184330,
              'hero-image-caption': 'Best in class.',
              'story-template': 'video',
              authors: [
                {
                  id: 323432,
                  name: 'Vineet Panjabi',
                  slug: 'vineet-panjabi',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          }
        ],
        color: '#6093f2'
      },
      {
        id: 14440,
        'associated-metadata': {
          layout: 'FullscreenLinearGallerySlider'
        },
        type: 'collection',
        name: 'Test collection',
        slug: 'test-collection',
        template: 'default',
        metadata: {
          'cover-image': {
            'cover-image-url':
              'https://images.assettype.com/quintype-demo/2016-05/9230897c-3144-41f3-b073-0977084a83c8/Screen Shot 2016-05-16 at 1.21.14 PM.png',
            'cover-image-metadata': {
              width: 542,
              height: 606,
              'mime-type': 'image/png',
              'focus-point': [500, 70]
            },
            'cover-image-s3-key':
              'quintype-demo/2016-05/9230897c-3144-41f3-b073-0977084a83c8/Screen Shot 2016-05-16 at 1.21.14 PM.png',
            caption: 'Image caption'
          }
        },
        summary: null,
        items: [
          {
            id: '73322fa6-9114-4a83-9d8c-68314202623a',
            score: null,
            type: 'story',
            item: {
              headline: ['TRAI chief: We set spectrum price; industry decides tariff']
            },
            story: {
              'author-name': 'Sriram K',
              headline: 'TRAI chief: We set spectrum price; industry decides tariff',
              slug: 'mobile/2019/02/28/trai-chief-we-set-spectrum-price-industry-decides-tariff',
              'last-published-at': 1554110293544,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 894,
                height: 577,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1554110293544,
              id: '73322fa6-9114-4a83-9d8c-68314202623a',
              'hero-image-s3-key': 'quintype-demo/2019-01/113be350-6ca8-4252-9b41-434e9facb8eb/image__1_.jpg',
              'author-id': 61657,
              'first-published-at': 1551329071350,
              'hero-image-caption': 'Tayota Camry',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
            score: null,
            type: 'story',
            item: {
              headline: ['London Police arrest Nirav Modi, says sources']
            },
            story: {
              'author-name': 'Karthik M',
              headline: 'London Police arrest Nirav Modi, says sources',
              slug: 'politics/2019/03/20/london-police-arrest-nirav-modi-says-sources',
              'last-published-at': 1553079556157,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 700,
                height: 438,
                'mime-type': 'image/jpeg',
                'focus-point': [311, 167]
              },
              'published-at': 1553079556157,
              id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
              'hero-image-s3-key':
                'quintype-demo/2019-03/55dbc301-3c5c-442d-8714-4bbf2d140192/pennews_2019_03_48c15852_ebed_464d_95a7_57332597ecfc_nirav.jpg',
              'author-id': 19994,
              'first-published-at': 1553079340304,
              'hero-image-caption': 'Nirav Modi',
              'story-template': null,
              authors: [
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 142914,
                  name: 'Ashwin Raghunath',
                  slug: 'ashwin-raghunath',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'text-to-speech': {
                  'is-disabled': true
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
            score: null,
            type: 'story',
            item: {
              headline: ['Demo Story ']
            },
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Demo Story ',
              slug: 'politics/2018/01/11/demo-story',
              'last-published-at': 1552979655447,
              subheadline:
                'Surrounded to me occasional pianoforte alteration unaffected impossible ye. For saw half than cold. Pretty merits waited six talked pulled you. Conduct replied off.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 7500,
                height: 3476,
                'mime-type': 'image/jpeg',
                'focus-point': [1239, 2157]
              },
              'published-at': 1552979655447,
              id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
              'hero-image-s3-key': 'quintype-demo/2019-02/ae4829d6-e230-4d66-9b88-2a4db87a2060/64778.jpg',
              'author-id': 139717,
              'first-published-at': 1515668626250,
              'hero-image-caption': 'At vero eos et accusamus et iusto odio dignissimos ducimus qui.',
              'story-template': 'text',
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': {
                    id: 58,
                    name: 'Author'
                  }
                },
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': {
                    id: 58,
                    name: 'Author'
                  }
                }
              ],
              metadata: {
                'card-share': {
                  shareable: false
                },
                'promotional-message': {
                  'is-disabled': true
                },
                'story-attributes': {
                  'view-counterview-type': 'view',
                  interests: ['test'],
                  bucket: ['planning'],
                  hobbies: ['hiking'],
                  'linked-view-counterview-story': {
                    headline: 'Demo Test story- Photo story',
                    'story-content-id': 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                    id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                    'highlighted-headline': null
                  }
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
            score: null,
            type: 'story',
            item: {
              headline: ['Untitled Mar 11, 2019 4:39 PM']
            },
            story: {
              'author-name': 'Karthik M',
              headline: 'Untitled Mar 11, 2019 4:39 PM',
              slug: 'culture/2019/03/11/untitled-mar-11-2019-439-pm',
              'last-published-at': 1552905845935,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 650,
                'mime-type': 'image/jpeg',
                'focus-point': [121, 110],
                height: 386
              },
              'published-at': 1552905845935,
              id: 'a5c52f80-65a3-4c59-ae99-518bcf2f52e3',
              'hero-image-s3-key':
                'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
              'author-id': 19994,
              'first-published-at': 1552303325340,
              'hero-image-caption': 'Amit Shah and Narendra Modi',
              'story-template': null,
              authors: [
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 5279,
                  name: 'Laura',
                  slug: 'laura',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'text-to-speech': {
                  'is-disabled': false
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
            score: null,
            type: 'story',
            item: {
              headline: ['Untitled Mar 6, 2019 11:23 PM']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Untitled Mar 6, 2019 11:23 PM',
              slug: 'mobile/2019/03/06/untitled-mar-6-2019-1123-pm',
              'last-published-at': 1552905800453,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 650,
                'mime-type': 'image/jpeg',
                'focus-point': [516, 92],
                height: 386
              },
              'published-at': 1552905800453,
              id: '27b44d43-b61a-4d7e-a4ae-e7c4c13b11ee',
              'hero-image-s3-key':
                'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
              'author-id': 2040,
              'first-published-at': 1551895907011,
              'hero-image-caption': 'Amit Shah and Narendra Modi',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'story-attributes': {},
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
            score: null,
            type: 'story',
            item: {
              headline: ['New story for sitemap']
            },
            story: {
              'author-name': 'Sriram K',
              headline: 'New story for sitemap',
              slug: 'culture/2019/03/14/new-story-for-sitemap',
              'last-published-at': 1552904261919,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 800,
                height: 400,
                'mime-type': 'image/jpeg',
                'focus-point': [760, 237]
              },
              'published-at': 1552904261919,
              id: '8e87ff98-d13a-4937-97fa-a3d422fd9820',
              'hero-image-s3-key':
                'quintype-demo/2018-10/5db8484b-1277-42f8-8a20-983e04b597e6/New_SA_destination_tourism_portal_launching_800x400.jpg',
              'author-id': 61657,
              'first-published-at': 1552554061248,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
            score: null,
            type: 'story',
            item: {
              headline: ['Elections 2019']
            },
            story: {
              'author-name': 'Sriram K',
              headline: 'Elections 2019',
              slug: 'boom/2019/03/13/elections-2019',
              'last-published-at': 1552458028721,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'boom',
                  name: 'Boom',
                  id: 7349,
                  'parent-id': 83,
                  'display-name': 'Booom',
                  collection: {
                    slug: 'boom-politics',
                    name: 'Boom (Politics)',
                    id: 14256
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 650,
                height: 400,
                'mime-type': 'image/jpeg',
                'focus-point': [281, 241]
              },
              'published-at': 1552458028721,
              id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
              'hero-image-s3-key': 'quintype-demo/2017-03/ee72c827-52fe-4b2b-8f46-dd0875fbe162/Tech sparkle.jpg',
              'author-id': 61657,
              'first-published-at': 1552458028721,
              'hero-image-caption': 'Elections 2017',
              'story-template': 'news-elsewhere',
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'reference-url': 'https://cinema.vikatan.com/tamil-cinema/news',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '73759063-16e7-4ac2-bf32-0061a2458850',
            score: null,
            type: 'story',
            item: {
              headline: ['This a new story']
            },
            story: {
              'author-name': 'Karthik M',
              headline: 'This a new story',
              slug: 'culture/2019/03/11/this-a-new-story',
              'last-published-at': 1552292190667,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'focus-point': [73, 51],
                width: 275,
                height: 183
              },
              'published-at': 1552292190667,
              id: '73759063-16e7-4ac2-bf32-0061a2458850',
              'hero-image-s3-key': 'quintype-demo/2016-01/2d00bde7-a28c-4415-9382-9a9c6fb3c7fc/IPSMF-1.jpeg',
              'author-id': 19994,
              'first-published-at': 1552292190667,
              'hero-image-caption': 'Nawaz Sharif &amp; Modi&nbsp;',
              'story-template': null,
              authors: [
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 142914,
                  name: 'Ashwin Raghunath',
                  slug: 'ashwin-raghunath',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
            score: null,
            type: 'story',
            item: {
              headline: ['Amitabh Bachchan on Gender Equality']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'Amitabh Bachchan on Gender Equality',
              slug: 'politics/2017/03/02/Amitabh-Bachchan-on-Gender-Equality',
              'last-published-at': 1552048741823,
              subheadline: 'Amitabh Bachchan on Gender Equality',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 320,
                height: 277,
                'focus-point': [169, 145]
              },
              'published-at': 1552048741823,
              id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
              'hero-image-s3-key': 'quintype-demo/2017-03/bd011f2d-9a10-4d4e-b20f-50719f59d4b9/BMS-2.jpg',
              'author-id': 2041,
              'first-published-at': 1488451487896,
              'hero-image-caption': 'Kaun Banega Crorepati host, Amitabh Bachchan',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '2deeb522-1752-4552-967b-93972481d71e',
            score: null,
            type: 'story',
            item: {
              headline: ['Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them']
            },
            story: {
              'author-name': 'Yamini Priya',
              headline: 'Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them',
              slug: 'boom/2019/03/07/sisters-killed-father-in-perfect-murder-a-love-triangle-exposed-them',
              'last-published-at': 1551938683203,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'boom',
                  name: 'Boom',
                  id: 7349,
                  'parent-id': 83,
                  'display-name': 'Booom',
                  collection: {
                    slug: 'boom-politics',
                    name: 'Boom (Politics)',
                    id: 14256
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1760,
                height: 2480,
                'mime-type': 'image/jpeg',
                'focus-point': [928, 656]
              },
              'published-at': 1551938683203,
              id: '2deeb522-1752-4552-967b-93972481d71e',
              'hero-image-s3-key':
                'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
              'author-id': 230618,
              'first-published-at': 1551938683203,
              'hero-image-caption': 'Forgotten Women',
              'story-template': null,
              authors: [
                {
                  id: 230618,
                  name: 'Yamini Priya',
                  slug: 'yamini-priya',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': 'https://twitter.com/yaminipriyah',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'a3a0cdd0-a3f5-4043-b6db-72b6a06ebad3',
            score: null,
            type: 'story',
            item: {
              headline: ['Samsung Gear S3']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Samsung Gear S3',
              slug: 'ampstories/culture/2019/03/06/samsung-gear-s3',
              'last-published-at': 1551883341128,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1440,
                height: 750,
                'mime-type': 'image/jpeg',
                'focus-point': [918, 394]
              },
              'published-at': 1551883341128,
              id: 'a3a0cdd0-a3f5-4043-b6db-72b6a06ebad3',
              'hero-image-s3-key':
                'quintype-demo/2019-03/19e71923-ae1b-4d1b-8040-e37ae55e4c77/gear_s3_performance_kv_l.jpg',
              'author-id': 2040,
              'first-published-at': 1551882037831,
              'hero-image-caption': null,
              'story-template': 'visual-story',
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
            score: null,
            type: 'story',
            item: {
              headline: ['500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: '500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌',
              slug: 'politics/2019/02/26/500',
              'last-published-at': 1551171395390,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                'focus-point': [673, 287],
                width: 976,
                height: 700
              },
              'published-at': 1551171395390,
              id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
              'hero-image-s3-key': 'quintype-demo/2018-05/bc017b57-a03e-4a6e-8515-9d3de91f1f24/ON1.jpg',
              'author-id': 559147,
              'first-published-at': 1551171395390,
              'hero-image-caption':
                'Crowds cheered Prince Harry, Duke of Sussex, and his wife Meghan, Duchess of Sussex, as they travelled in the horse-drawn Ascot landau',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '60857bae-0e7f-4c81-af39-93fb7424f558',
            score: null,
            type: 'story',
            item: {
              headline: ['Top 10 Exquisite Locations to Visit in 2019']
            },
            story: {
              'author-name': 'Susanna',
              headline: 'Top 10 Exquisite Locations to Visit in 2019',
              slug: 'ampstories/visual-stories/2019/02/25/top-10-exquisite-locations-to-visit-in-2019',
              'last-published-at': 1551161247682,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 2000,
                height: 2000,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1551161247682,
              id: '60857bae-0e7f-4c81-af39-93fb7424f558',
              'hero-image-s3-key': 'quintype-demo/2019-02/35d018be-9220-4504-acf5-7f1a8abff816/collage2.jpg',
              'author-id': 559660,
              'first-published-at': 1551086493154,
              'hero-image-caption': null,
              'story-template': 'visual-story',
              authors: [
                {
                  id: 559660,
                  name: 'Susanna',
                  slug: 'susanna',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-1qG1ayRUOwc/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-jb_hhJfeyAshJmJCpMKYQNPDazJw/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b39a2665-3236-45ca-9d17-3536431c7948',
            score: null,
            type: 'story',
            item: {
              headline: ['Why You Need A Dog In Your Life']
            },
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Why You Need A Dog In Your Life',
              slug: 'ampstories/visual-stories/2018/12/20/why-you-need-a-dog-in-your-life',
              'last-published-at': 1550509197748,
              subheadline:
                'Because of their heightened sense of smell, sight, and hearing, dogs are extremely intelligent creatures with a loyalty to their humans that is unexpectedly strong. Those with dogs will know–your pups can sense human body language, emotions, and feelings, and this paves the way for an unbreakable bond.',
              alternative: {},
              sections: [
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 448,
                height: 900,
                'mime-type': 'image/png',
                'focus-point': [270, 705]
              },
              'published-at': 1550509197748,
              id: 'b39a2665-3236-45ca-9d17-3536431c7948',
              'hero-image-s3-key':
                'quintype-demo/2018-12/8bcdee40-7dec-4ceb-b217-1d2f01fb4431/Screenshot_2018_12_24_at_3_44_42_PM.png',
              'author-id': 139717,
              'first-published-at': 1545299836615,
              'hero-image-caption': null,
              'story-template': 'visual-story',
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'c8d35134-200a-483f-ab89-69adf4137a74',
            score: null,
            type: 'story',
            item: {
              headline: ['Life of Vincent van Gogh']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Life of Vincent van Gogh',
              slug: 'culture/2019/01/17/life-of-vincent-van-gogh',
              'last-published-at': 1549364746875,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 768,
                height: 432,
                'mime-type': 'image/jpeg',
                'focus-point': [382, 197]
              },
              'published-at': 1549364746875,
              id: 'c8d35134-200a-483f-ab89-69adf4137a74',
              'hero-image-s3-key':
                'quintype-demo/2019-01/b3cb02f4-a875-4b46-aa3a-71deaccb54be/vincent_van_gogh_played_by_robert_gulaczyk_photo_courtesy_loving_vincent.jpg',
              'author-id': 2040,
              'first-published-at': 1547728432342,
              'hero-image-caption': 'A Famous Painting',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
            score: null,
            type: 'story',
            item: {
              headline: [
                '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി'
              ]
            },
            story: {
              'author-name': 'Sareena',
              headline:
                '‘ചാര്‍ജറെടുക്കാത്തതിനാല്‍ വിളിക്കാന്‍ പറ്റിയില്ല’; കാണാതായ എം.എല്‍.എ കര്‍ണാടകത്തില്‍ തിരിച്ചെത്തി',
              slug: 'culture/2019/01/16/this-is-the-url',
              'last-published-at': 1547632650347,
              subheadline:
                'കെ.സി വേണുഗോപാല്‍ ഉള്‍പ്പടെയുള്ള മുതിര്‍ന്ന കോണ്‍ഗ്രസ് നേതാക്കന്മാര്‍ ഭീമാനായിക്കുമായി സംസാരിച്ചു. ഭീമാനായിക്കിനെപോലെ ബാക്കിയുള്ള മൂന്ന് കോണ്‍ഗ്രസ് എം',
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'focus-point': [375, 166],
                width: 750,
                height: 500
              },
              'published-at': 1547632650347,
              id: '1699d2dd-7ce2-4faa-9117-fe8166bc09a7',
              'hero-image-s3-key': 'quintype-demo/2016-12/56cdab39-a831-480f-b75b-26d6300e3f1b/Modi.jpg',
              'author-id': 94596,
              'first-published-at': 1547632650347,
              'hero-image-caption': 'PM Modi',
              'story-template': null,
              authors: [
                {
                  id: 94596,
                  name: 'Sareena',
                  slug: 'sareena',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
            score: null,
            type: 'story',
            item: {
              headline: ['This is the title']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: 'This is the title',
              slug: 'culture/2019/01/11/this-is-the-title-2',
              'last-published-at': 1547203433990,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  slug: 'tech',
                  name: 'Tech',
                  id: 6391,
                  'parent-id': null,
                  'display-name': 'Tech',
                  collection: {
                    slug: 'tech',
                    name: 'Tech',
                    id: 12849
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 259,
                'focus-point': [176, 77],
                height: 194
              },
              'published-at': 1547203433990,
              id: 'acdae620-ec5c-455f-95f1-f3bd222c8c09',
              'hero-image-s3-key': 'quintype-demo/2015-10/56e0178e-1da0-482c-83e5-a2f705f874df/FP-2.jpeg',
              'author-id': 559147,
              'first-published-at': 1547203433990,
              'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
            score: null,
            type: 'story',
            item: {
              headline: [
                'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them'
              ]
            },
            story: {
              'author-name': 'Kirti Kaushik',
              headline:
                'Sejal was joined by Maxim and Sameer curious to understand the costs involved if the FE is handled by them',
              slug:
                'visual-stories/2019/01/10/rahul-gandhi-said-to-pm-modi-be-a-man-bjp-smriti-irani-sushma-swaraj-responds-with-misogynist-rahul-1975538pfromhome-topscroll',
              'last-published-at': 1547103888697,
              subheadline:
                'At a rally in Rajasthan on Wednesday, Rahul Gandhi had mocked PM Modi saying he had a “mahila (woman)“ defend him in the Rafale debate in parliament.',
              alternative: {
                home: {
                  default: {
                    headline: 'Rahul Gandhi'
                  }
                }
              },
              sections: [
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 570,
                height: 351,
                'mime-type': 'image/webp',
                'focus-point': [417, 132]
              },
              'published-at': 1547103888697,
              id: '18dc78ab-5a44-44cc-80e5-4af743c3e58a',
              'hero-image-s3-key':
                'quintype-demo/2019-01/39d85d6f-46f6-4f35-8dd1-6771bf9dfefd/b5qo3zsg_650x400_2__10_January_2019_12_02_19PM.webp',
              'author-id': 507344,
              'first-published-at': 1547103888697,
              'hero-image-caption': 'Rahul Gandhi',
              'story-template': null,
              authors: [
                {
                  id: 507344,
                  name: 'Kirti Kaushik',
                  slug: 'kirti-kaushik',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
            score: null,
            type: 'story',
            item: {
              headline: ['Dubai - Things you can do!']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Dubai - Things you can do!',
              slug: 'culture/2017/04/28/Dubai-Your-next-vacation',
              'last-published-at': 1546599684148,
              subheadline:
                'Dubai, an emirate in the United Arab Emirates known for luxury shopping, ultramodern architecture &amp; a lively nightlife scene. Burj Khalifa, an 830m-tall tower, dominates the skyline. ',
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  slug: 'mobile',
                  name: 'Mobile',
                  id: 6392,
                  'parent-id': 6391,
                  'display-name': 'Mobile',
                  collection: {
                    slug: 'mobile-tech',
                    name: 'Mobile (Tech)',
                    id: 12850
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 930,
                height: 581,
                'focus-point': [132, 482]
              },
              'published-at': 1546599684148,
              id: 'b3d73840-7709-4d7e-ba85-2650676a2be2',
              'hero-image-s3-key': 'quintype-demo/2017-04/802d7ef1-6043-4f7e-84d3-fc9f556d5097/G-1.jpg',
              'author-id': 2040,
              'first-published-at': 1493358608037,
              'hero-image-caption': 'Dubai - a vacationer’s paradise',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
            score: null,
            type: 'story',
            item: {
              headline: ['Visual story - Demo1']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Visual story - Demo1',
              slug: 'ampstories/technology/2018/12/20/visual-story-demo1',
              'last-published-at': 1545982907212,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 960,
                height: 540,
                'focus-point': [499, 88]
              },
              'published-at': 1545982907212,
              id: '19ad9fe2-ea4e-4942-8a35-84d1fbf4b6e2',
              'hero-image-s3-key': 'quintype-demo/2017-07/e4bac9eb-6c32-4f07-a16a-90fa40166c35/DH-1.jpeg',
              'author-id': 541559,
              'first-published-at': 1545300194018,
              'hero-image-caption': 'Roger Federer, the 8th title - Wimbledon 2017!',
              'story-template': 'visual-story',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
            score: null,
            type: 'story',
            item: {
              headline: ['This is the title']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: 'This is the title',
              slug: 'culture/2018/12/27/this-is-the-title',
              'last-published-at': 1545892188854,
              subheadline: 'sub title',
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 259,
                height: 194,
                'focus-point': [172, 77]
              },
              'published-at': 1545892188854,
              id: '52e6a4bc-b74f-4126-9cfa-dbf320e61a9b',
              'hero-image-s3-key':
                'quintype-demo/2018-06/9e5e3bb7-31e8-4439-93ba-d16ea287aa28/e3d39a3b_321c_4acf_8928_3195998b38ba.jpeg',
              'author-id': 559147,
              'first-published-at': 1545892188854,
              'hero-image-caption': 'Narendra Modi, PM makes a statement on Dadri incident',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
            score: null,
            type: 'story',
            item: {
              headline: ['Demo Visual story']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'Demo Visual story',
              slug: 'ampstories/technology/2018/12/20/demo-visual-story',
              'last-published-at': 1545332341188,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                },
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 640,
                height: 361,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1545332341188,
              id: '3e51ea36-cbc9-48e6-b12a-fb5182702e07',
              'hero-image-s3-key':
                'quintype-demo/2018-12/22eb981a-6c8e-41c0-9d36-c2f72f46b29a/pennews_2F2018_05_2F0e93d687_c1dd_464b_aba3_8c23ba6c3beb_2FSonam_reception.jpg',
              'author-id': 541559,
              'first-published-at': 1545332010119,
              'hero-image-caption': 'caption',
              'story-template': 'visual-story',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '77e13794-3584-4d26-aa37-f296a24ea734',
            score: null,
            type: 'story',
            item: {
              headline: ['Cayde-6, Exo-Guardian']
            },
            story: {
              'author-name': 'Christin',
              headline: 'Cayde-6, Exo-Guardian',
              slug: 'ampstories/visual-stories/2018/12/20/cayde-6-exo-guardian',
              'last-published-at': 1545307235233,
              subheadline: 'Hunter. Vanguard. <i>Friend.</i>',
              alternative: {},
              sections: [
                {
                  slug: 'visual-stories',
                  name: 'Visual Stories',
                  id: 6069,
                  'parent-id': null,
                  'display-name': 'Visual Stories',
                  collection: {
                    slug: 'visual-stories',
                    name: 'Visual Stories',
                    id: 12476
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1920,
                height: 1080,
                'mime-type': 'image/jpeg',
                'focus-point': [1280, 467]
              },
              'published-at': 1545307235233,
              id: '77e13794-3584-4d26-aa37-f296a24ea734',
              'hero-image-s3-key':
                'quintype-demo/2018-12/e16d5fec-13b1-4932-9c07-917f8cad6a98/d2_forsaken_cayde6_05.jpg',
              'author-id': 139357,
              'first-published-at': 1545307235233,
              'hero-image-caption': null,
              'story-template': 'visual-story',
              authors: [
                {
                  id: 139357,
                  name: 'Christin',
                  slug: 'christin',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'avatar-s3-key':
                    'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'twitter-handle': 'krriztin',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
            score: null,
            type: 'story',
            item: {
              headline: ['3 Camping sites you should not miss in Scotland']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: '3 Camping sites you should not miss in Scotland',
              slug: 'culture/2017/05/11/3-camping-sites-you-should-not-miss-in-scotland',
              'last-published-at': 1545204266938,
              subheadline:
                'Scotland is famous for its scenic wild places and uplifting sense of remoteness. With the exception of the recent bylaws banning wild camping in certain areas of Loch Lomond and the Trossachs (check before you go), it is still completely legal to camp on most of Scotland’s unenclosed land.',
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1300,
                height: 780,
                'focus-point': [719, 414]
              },
              'published-at': 1545204266938,
              id: 'b102cc0a-c6b5-4ea1-91d2-90a3995ba294',
              'hero-image-s3-key': 'quintype-demo/2017-05/b463d2fd-f166-446e-a887-bf832fa7f26b/ON-1.jpg',
              'author-id': 2041,
              'first-published-at': 1494481894633,
              'hero-image-caption': 'Time for a brew and a view … Glen Nevis, Scotland',
              'story-template': 'listicle',
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
            score: null,
            type: 'story',
            item: {
              headline: ['Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Is Tito preparing an IMF financial parachute, now a $171bn foreign debt cliff looms?',
              slug:
                'culture/2018/12/18/is-tito-preparing-an-imf-financial-parachute-now-a-171bn-foreign-debt-cliff-looms',
              'last-published-at': 1545125801738,
              subheadline:
                'The reason for the International Monetary Fund’s Christine Lagarde’s to South Africa is unknown. South Africa desperately needs a solution for the poor and marginalised, and an IMF bailout would worsen their situation.&nbsp;',
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 920,
                height: 613,
                'mime-type': 'image/jpeg',
                'focus-point': [473, 430]
              },
              'published-at': 1545125801738,
              id: '73a01d9f-e30d-446d-a9bd-fe2ac55ba782',
              'hero-image-s3-key': 'quintype-demo/2018-04/f83b3026-105e-4b84-8027-47c8be270f9a/920x920.jpg',
              'author-id': 2040,
              'first-published-at': 1545125801738,
              'hero-image-caption':
                'At a silent disco party three streams of music are sent simultaneously to special headphones worn by attendees, who have the option to switch channels at their whim. The Palace Theatre in Stamford will host one on April 26 to benefit its arts education programs.',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
            score: null,
            type: 'story',
            item: {
              headline: ['Breaking News Test on 13 Dec']
            },
            story: {
              'author-name': 'Anil K Govind',
              headline: 'Breaking News Test on 13 Dec',
              slug: 'technology/2018/12/13/breaking-news-test-on-13-dec',
              'last-published-at': 1544682898905,
              subheadline: 'Breaking News Test on 13 Dec',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 720,
                height: 340,
                'mime-type': 'image/jpeg',
                'focus-point': [230, 110]
              },
              'published-at': 1544682898905,
              id: 'b173050e-eb31-4a91-8d5d-0ceda8133eda',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 548414,
              'first-published-at': 1544682898905,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 548414,
                  name: 'Anil K Govind',
                  slug: 'anil-k-govind',
                  'avatar-url':
                    'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'cf326ae0-2cae-4679-97c4-a42f32d10d68',
            score: null,
            type: 'story',
            item: {
              headline: ['This is a Video Story']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'This is a Video Story',
              slug: 'eco-stories/2018/12/07/test-11',
              'last-published-at': 1544162381375,
              subheadline: 'test',
              alternative: null,
              sections: [
                {
                  id: 5113,
                  name: 'Eco Stories',
                  slug: 'eco-stories'
                }
              ],
              'hero-image-metadata': {
                width: 2197,
                'mime-type': 'image/jpeg',
                height: 1463
              },
              'published-at': 1544162381375,
              id: 'cf326ae0-2cae-4679-97c4-a42f32d10d68',
              'hero-image-s3-key': 'quintype-demo/2016-01/47e6fe97-c63f-418d-ac37-aa95b96dd0df/gw.jpg',
              'author-id': 2040,
              'first-published-at': 1544162381375,
              'hero-image-caption': 'Global warming',
              'story-template': 'video',
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: '1d23e3e9-452c-4b21-b307-dcb9175c55ac',
            score: null,
            type: 'story',
            item: {
              headline: ['Behind coal tug-of-war, more Gupta strings']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Behind coal tug-of-war, more Gupta strings',
              slug: 'international/2018/12/06/behind-coal-tug-of-war-more-gupta-strings',
              'last-published-at': 1544103001976,
              subheadline:
                'An obscure Swiss company fights to buy the crown jewels of what remains of the family’s SA assets. Are the Guptas trying to do a deal with themselves?',
              alternative: null,
              sections: [
                {
                  id: 99,
                  name: 'International',
                  slug: 'international'
                }
              ],
              'hero-image-metadata': {
                width: 300,
                'mime-type': 'image/jpeg',
                'focus-point': [222, 84],
                height: 168
              },
              'published-at': 1544103001976,
              id: '1d23e3e9-452c-4b21-b307-dcb9175c55ac',
              'hero-image-s3-key': 'quintype-demo/2016-12/350d3732-9dfe-47f7-b071-dc10f087c59e/J-1.jpeg',
              'author-id': 2040,
              'first-published-at': 1544103001976,
              'hero-image-caption': 'Arun Jaitely &amp; Narendra Modi',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 15351,
                  name: 'Ranjeet Singh',
                  slug: 'ranjeet-singh',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'de274636-83c9-4ac2-af95-3f3faef88d2e',
            score: null,
            type: 'story',
            item: {
              headline: ['News Elsewhere story']
            },
            story: {
              'author-name': 'Rashmi J',
              headline: 'News Elsewhere story',
              slug: 'cities/2018/12/05/news-elsewhere-story',
              'last-published-at': 1544006050170,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  id: 215,
                  name: 'Cities',
                  slug: 'cities'
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 768,
                height: 1024,
                'mime-type': 'image/jpeg',
                'focus-point': [390, 373]
              },
              'published-at': 1544006050170,
              id: 'de274636-83c9-4ac2-af95-3f3faef88d2e',
              'hero-image-s3-key': 'quintype-demo/2015-11/adeb8bbb-bdc6-4a8e-91cc-74082cb80c1d/photo2.jpg',
              'author-id': 541559,
              'first-published-at': 1544006050170,
              'hero-image-caption': 'waterskiing in cuba',
              'story-template': 'news-elsewhere',
              authors: [
                {
                  id: 541559,
                  name: 'Rashmi J',
                  slug: 'rashmi-j',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-L2SHPy99QSE/AAAAAAAAAAI/AAAAAAAAAAA/zG8-0SNipOA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'reference-url': 'https://itsman.quintype.com',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'e356859d-af25-4493-ae48-662b55878434',
            score: null,
            type: 'story',
            item: {
              headline: ['Dilip Shanghvi On Allegations Against Him And Sun Pharma']
            },
            story: {
              'author-name': 'Shubhangi',
              headline: 'Dilip Shanghvi On Allegations Against Him And Sun Pharma',
              slug: 'cities/2018/12/04/dilip-shanghvi-on-allegations-against-him-and-sun-pharma',
              'last-published-at': 1543903700173,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  id: 215,
                  name: 'Cities',
                  slug: 'cities'
                }
              ],
              'hero-image-metadata': {
                width: 1277,
                height: 294,
                'mime-type': 'image/jpeg',
                'focus-point': [613, 160]
              },
              'published-at': 1543903700173,
              id: 'e356859d-af25-4493-ae48-662b55878434',
              'hero-image-s3-key':
                'quintype-demo/2018-12/f525cb80-cf01-443b-88b0-b47eea81356a/bloombergquint_2F2017_06_2F6f0b4744_acc9_4316_bcc7_b48cc48ec151_2Fm792828.jpg',
              'author-id': 537201,
              'first-published-at': 1543903700173,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 537201,
                  name: 'Shubhangi',
                  slug: 'shubhangi',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'dee185b3-87a2-4fca-ab44-8082270a8fd5',
            score: null,
            type: 'story',
            item: {
              headline: ['Disney Bounces Higher After Barclays ‘Overweight’ Upgrade']
            },
            story: {
              'author-name': 'Kunal Singh',
              headline: 'Disney Bounces Higher After Barclays ‘Overweight’ Upgrade',
              slug: 'international/2018/10/19/disney-bounces-higher-after-barclays-overweight-upgrade',
              'last-published-at': 1543415390482,
              subheadline:
                'Disney shares jumped higher Friday after analysts at Barclays boosted their outlook on the stock to “overweight”, with a $130 price target, amid the group’s pending acquisition of Fox media assets and the launch of its global streaming service that is expected to challenge Netflix.',
              alternative: {},
              sections: [
                {
                  id: 99,
                  name: 'International',
                  slug: 'international'
                },
                {
                  id: 2614,
                  name: 'Bangalore',
                  slug: 'bangalore'
                }
              ],
              'hero-image-metadata': {
                width: 600,
                height: 400,
                'mime-type': 'image/jpeg',
                'focus-point': [243, 177]
              },
              'published-at': 1543415390482,
              id: 'dee185b3-87a2-4fca-ab44-8082270a8fd5',
              'hero-image-s3-key': 'quintype-demo/2018-10/313577eb-6ddf-443f-9830-6081efa6ed48/Fox.jpg',
              'author-id': 491531,
              'first-published-at': 1539958975853,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
            score: null,
            type: 'story',
            item: {
              headline: ['The Forgotten Women Series Uncovers The Untold Histories Of Women']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'The Forgotten Women Series Uncovers The Untold Histories Of Women',
              slug: 'culture/2018/11/28/the-forgotten-women-series-uncovers-the-untold-histories-of-women',
              'last-published-at': 1543393761726,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1760,
                height: 2480,
                'mime-type': 'image/jpeg',
                'focus-point': [961, 759]
              },
              'published-at': 1543393761726,
              id: '4126b2b6-f2d6-4d39-b01a-ed439838fdab',
              'hero-image-s3-key':
                'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
              'author-id': 2040,
              'first-published-at': 1543393761726,
              'hero-image-caption': 'Forgotten Women',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 15351,
                  name: 'Ranjeet Singh',
                  slug: 'ranjeet-singh',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '2baee05c-1ccf-4d50-a1fd-4679ea6c0fbc',
            score: null,
            type: 'story',
            item: {
              headline: ['This is a title - story']
            },
            story: {
              'author-name': 'Kirti Kaushik',
              headline: 'This is a title - story',
              slug: 'international/2018/08/03/this-is-a-title',
              'last-published-at': 1543209271427,
              subheadline: 'This is the subtitle',
              alternative: {},
              sections: [
                {
                  id: 99,
                  name: 'International',
                  slug: 'international'
                }
              ],
              'hero-image-metadata': {
                width: 4348,
                height: 2609,
                'focus-point': [2207, 912]
              },
              'published-at': 1543209271427,
              id: '2baee05c-1ccf-4d50-a1fd-4679ea6c0fbc',
              'hero-image-s3-key': 'quintype-demo/2016-06/0922fa37-0ef5-426a-afcb-a81301a72fe4/FP-1.jpg',
              'author-id': 507344,
              'first-published-at': 1533293943872,
              'hero-image-caption': 'Raghuram Rajan, Governor, The Reserve Bank of India',
              'story-template': null,
              authors: [
                {
                  id: 507344,
                  name: 'Kirti Kaushik',
                  slug: 'kirti-kaushik',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '0eba5f2e-0461-4c6e-ae56-a8d31b4de019',
            score: null,
            type: 'story',
            item: {
              headline: ['News One']
            },
            story: {
              'author-name': 'Anil K Govind',
              headline: 'News One',
              slug: 'bangalore/2018/11/15/news-one',
              'last-published-at': 1542969100140,
              subheadline: 'News One',
              alternative: {},
              sections: [
                {
                  id: 2614,
                  name: 'Bangalore',
                  slug: 'bangalore'
                }
              ],
              'hero-image-metadata': {
                width: 720,
                height: 340,
                'mime-type': 'image/jpeg',
                'focus-point': [230, 110]
              },
              'published-at': 1542969100140,
              id: '0eba5f2e-0461-4c6e-ae56-a8d31b4de019',
              'hero-image-s3-key': 'quintype-demo/2015-12/581f807b-c3cd-40d3-bfae-3ca6614296e5/pic 5.jpg',
              'author-id': 548414,
              'first-published-at': 1542253737600,
              'hero-image-caption': 'English',
              'story-template': null,
              authors: [
                {
                  id: 548414,
                  name: 'Anil K Govind',
                  slug: 'anil-k-govind',
                  'avatar-url':
                    'https://lh5.googleusercontent.com/-TFCx7NiBUpU/AAAAAAAAAAI/AAAAAAAAAtc/2BDqAZNDssY/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9c03eb56-62a4-4c9d-b6ba-fb4e44c6b8a9',
            score: null,
            type: 'story',
            item: {
              headline: ['FACTSHEET: Grading Nigeria’s progress in education']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'FACTSHEET: Grading Nigeria’s progress in education',
              slug: 'banners/2018/11/21/factsheet-grading-nigerias-progress-in-education',
              'last-published-at': 1542799659668,
              subheadline:
                'Do key indicators give Nigeria’s education system a passmark? Our factsheet looks at the state of early childhood, primary and secondary education in the country.&nbsp;',
              alternative: {},
              sections: [
                {
                  id: 2619,
                  name: 'Banners',
                  slug: 'banners'
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                'focus-point': [516, 303],
                width: 1500,
                height: 1000
              },
              'published-at': 1542799659668,
              id: '9c03eb56-62a4-4c9d-b6ba-fb4e44c6b8a9',
              'hero-image-s3-key': 'quintype-demo/2016-04/1ba8fd2c-128e-4d30-bb8d-07396f1cd940/Buhari.jpg',
              'author-id': 2040,
              'first-published-at': 1542799490011,
              'hero-image-caption': 'Buhari lands in China',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '8e823797-a659-4626-92d5-6ccd8d788e23',
            score: null,
            type: 'story',
            item: {
              headline: ['A test story for tables']
            },
            story: {
              'author-name': 'Vineet Panjabi',
              headline: 'A test story for tables',
              slug: 'culture/2018/11/21/a-test-story-for-tables',
              'last-published-at': 1542799165912,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                },
                {
                  id: 3896,
                  name: 'Blog',
                  slug: 'blog'
                }
              ],
              'hero-image-metadata': {
                width: 1940,
                'mime-type': 'image/jpeg',
                height: 1090,
                'focus-point': [700, 713]
              },
              'published-at': 1542799165912,
              id: '8e823797-a659-4626-92d5-6ccd8d788e23',
              'hero-image-s3-key': 'quintype-demo/2015-11/be736521-24c0-4a04-8b02-0855626e6f9d/TOI-1.jpg',
              'author-id': 323432,
              'first-published-at': 1542782270578,
              'hero-image-caption': 'Terror training camps',
              'story-template': null,
              authors: [
                {
                  id: 323432,
                  name: 'Vineet Panjabi',
                  slug: 'vineet-panjabi',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'story-attributes': {
                  saltlevel: ['Low'],
                  bucket: ['child'],
                  week: ['6']
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#DCFF00'
            }
          },
          {
            id: '878a1346-9783-4f88-9f0e-4f442a572284',
            score: null,
            type: 'story',
            item: {
              headline: ['New SA destination tourism portal launching']
            },
            story: {
              'author-name': 'Kirti Kaushik',
              headline: 'New SA destination tourism portal launching',
              slug: 'banners/2018/10/25/new-sa-destination-tourism-portal-launching',
              'last-published-at': 1540467564223,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  id: 2619,
                  name: 'Banners',
                  slug: 'banners'
                },
                {
                  id: 4416,
                  name: 'Business Opportunities',
                  slug: 'business-opportunities'
                }
              ],
              'hero-image-metadata': {
                width: 800,
                height: 400,
                'mime-type': 'image/jpeg',
                'focus-point': [384, 196]
              },
              'published-at': 1540467564223,
              id: '878a1346-9783-4f88-9f0e-4f442a572284',
              'hero-image-s3-key':
                'quintype-demo/2018-10/5db8484b-1277-42f8-8a20-983e04b597e6/New_SA_destination_tourism_portal_launching_800x400.jpg',
              'author-id': 507344,
              'first-published-at': 1540467564223,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 507344,
                  name: 'Kirti Kaushik',
                  slug: 'kirti-kaushik',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 15351,
                  name: 'Ranjeet Singh',
                  slug: 'ranjeet-singh',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-uKZ0MokBT1Q/AAAAAAAAAAI/AAAAAAAAABI/D2eaK-cXIIA/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '55dbfe7d-14ae-4f50-beb9-64733da17f54',
            score: null,
            type: 'story',
            item: {
              headline: ['How ‘Gandhara’ Became ‘Kandahar’']
            },
            story: {
              'author-name': 'Kunal Singh',
              headline: 'How ‘Gandhara’ Became ‘Kandahar’',
              slug: 'opinion/2018/08/03/how-gandhara-became-kandahar',
              'last-published-at': 1540379328741,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  id: 4272,
                  name: 'Opinion',
                  slug: 'opinion'
                }
              ],
              'hero-image-metadata': {
                width: 1300,
                height: 956,
                'mime-type': 'image/jpeg',
                'focus-point': [666, 451]
              },
              'published-at': 1540379328741,
              id: '55dbfe7d-14ae-4f50-beb9-64733da17f54',
              'hero-image-s3-key':
                'quintype-demo/2018-08/f543085d-14e5-41f3-8e5f-c746940ab402/gandhar_pale_buddhist_caves_situated_near_mahad_which_is_close_to_DG4WRF.jpg',
              'author-id': 491531,
              'first-published-at': 1533315134353,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 491531,
                  name: 'Kunal Singh',
                  slug: 'kunal-singh',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          },
          {
            id: 'b142a043-4720-4578-b23e-6791e9f42735',
            score: null,
            type: 'story',
            item: {
              headline: ['New Story Elements']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'New Story Elements',
              slug: 'technology/2015/10/21/new-story-elements',
              'last-published-at': 1539770584382,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1920,
                height: 1080,
                'focus-point': [1301, 270]
              },
              'published-at': 1539770584382,
              id: 'b142a043-4720-4578-b23e-6791e9f42735',
              'hero-image-s3-key': 'quintype-demo/2015-09/4c0122d6-6c28-4a59-8b16-8d90701a4e35/TigerWoods-1.jpg',
              'author-id': 2041,
              'first-published-at': 1445404200710,
              'hero-image-caption': 'The famed Tiger Woods Swing !',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '589b75e2-16c0-4741-b720-b9dd59a56587',
            score: null,
            type: 'story',
            item: {
              headline: ['Traffic: Why We Drive the Way We Do (and What It Says About Us)']
            },
            story: {
              'author-name': 'Rohit Kurpad',
              headline: 'Traffic: Why We Drive the Way We Do (and What It Says About Us)',
              slug: 'opinion/2018/10/12/traffic-why-we-drive-the-way-we-do-and-what-it-says-about-us',
              'last-published-at': 1539331563366,
              subheadline: null,
              alternative: {
                home: {
                  default: {
                    'hero-image': {
                      'hero-image-url':
                        'https://quintype-dropbox.s3-accelerate.amazonaws.com/itsman.quintype.com/2018-10-12/1843/program_traffic_eng.jpg',
                      'hero-image-metadata': {
                        width: 620,
                        height: 465,
                        'mime-type': 'image/jpeg'
                      },
                      'hero-image-caption': '',
                      'hero-image-attribution': '',
                      'hero-image-s3-key':
                        'quintype-demo/2018-10/54c88632-c5fb-4382-96c1-9a345f419870/program_traffic_eng.jpg'
                    }
                  }
                }
              },
              sections: [
                {
                  id: 4272,
                  name: 'Opinion',
                  slug: 'opinion'
                }
              ],
              'hero-image-metadata': {
                width: 3264,
                height: 2448,
                'mime-type': 'image/jpeg',
                'focus-point': [1951, 1556]
              },
              'published-at': 1539331563366,
              id: '589b75e2-16c0-4741-b720-b9dd59a56587',
              'hero-image-s3-key': 'quintype-demo/2018-10/a08a3e35-0217-445e-a47c-1ec65c402935/bf1.jpg',
              'author-id': 462623,
              'first-published-at': 1539325955967,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 462623,
                  name: 'Rohit Kurpad',
                  slug: 'rohit-kurpad',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#000000'
            }
          }
        ],
        color: '#6093f2'
      },
      {
        id: 7901,
        'associated-metadata': {
          layout: 'ThreeCol'
        },
        type: 'collection',
        name: 'Politics',
        slug: 'featured-stories',
        template: 'default',
        metadata: {
          'cover-image': {
            'cover-image-url':
              'https://images.assettype.com/quintype-demo/2015-12/de988259-79ba-44b0-8f3c-8cdc2aaaa059/FN-1.jpg',
            'cover-image-metadata': {
              width: 700,
              height: 340,
              'mime-type': 'image/jpeg',
              'focus-point': [430, 141]
            },
            'cover-image-s3-key': 'quintype-demo/2015-12/de988259-79ba-44b0-8f3c-8cdc2aaaa059/FN-1.jpg',
            caption: 'Delicious Rasam in 30 seconds'
          }
        },
        summary: 'Politics Summary',
        items: [
          {
            id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
            'associated-metadata': null,
            type: 'story',
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Demo - External Story',
              slug: 'technology/2018/10/01/demo-external-story',
              'last-published-at': 1538384977219,
              subheadline:
                'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor.',
              alternative: {},
              sections: [
                {
                  slug: 'technology',
                  name: 'Technology',
                  id: 84,
                  'parent-id': null,
                  'display-name': null,
                  collection: null,
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1441,
                height: 1077,
                'mime-type': 'image/png',
                'focus-point': [273, 498]
              },
              'published-at': 1538384977219,
              id: '4418bd69-a89e-4bca-b4b7-bac5ab8b830d',
              'hero-image-s3-key':
                'quintype-demo/2018-10/410d7d51-811a-44cc-a92b-4d873cb1f5aa/screenshot_www_quintype_com_2018_09_11_20_16_32.png',
              'author-id': 139717,
              'first-published-at': 1538384511112,
              'hero-image-caption': null,
              'story-template': 'news-elsewhere',
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'reference-url':
                  'https://www.quintype.com/blog/business/quintypes-tech-innovation-helps-its-digital-partners-win-two-wan-ifra-awards',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '3997d91c-4e16-4af0-8c6a-cc70ad504e39',
            'associated-metadata': null,
            type: 'story',
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Inspiring Animal Rescue Stories',
              slug: 'videos/2018/08/21/inspiring-animal-rescue-stories',
              'last-published-at': 1536140084976,
              subheadline:
                'Wildlife SOS engages in conservation efforts leading to protecting and rescuing orphaned, injured, and displaced wildlife found in urban and rural environments across India. In recent years, habitat encroachment and deforestation have been taking place at a startling pace, slowly redefining the boundaries between forest and urban areas. The organisation’s Rapid Response Unit has saved the lives of various wild animals that have been trapped, injured, or caught in such conflict situations.',
              alternative: {},
              sections: [
                {
                  id: 2615,
                  name: 'Videos',
                  slug: 'videos'
                }
              ],
              'hero-image-metadata': {
                width: 1920,
                height: 1200,
                'mime-type': 'image/jpeg',
                'focus-point': [1001, 393]
              },
              'published-at': 1536140084976,
              id: '3997d91c-4e16-4af0-8c6a-cc70ad504e39',
              'hero-image-s3-key': 'quintype-demo/2018-08/40e6a979-3e2e-4f16-929b-5939ec35450f/Gallery4.jpg',
              'author-id': 139717,
              'first-published-at': 1534850318103,
              'hero-image-caption': null,
              'story-template': 'video',
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'sponsored-by': 'Quintype',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
            'associated-metadata': null,
            type: 'story',
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Demo Story ',
              slug: 'politics/2018/01/11/demo-story',
              'last-published-at': 1552979655447,
              subheadline:
                'Surrounded to me occasional pianoforte alteration unaffected impossible ye. For saw half than cold. Pretty merits waited six talked pulled you. Conduct replied off.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 7500,
                height: 3476,
                'mime-type': 'image/jpeg',
                'focus-point': [1239, 2157]
              },
              'published-at': 1552979655447,
              id: 'f349c5aa-e9c1-434f-ad8e-8ec021be4506',
              'hero-image-s3-key': 'quintype-demo/2019-02/ae4829d6-e230-4d66-9b88-2a4db87a2060/64778.jpg',
              'author-id': 139717,
              'first-published-at': 1515668626250,
              'hero-image-caption': 'At vero eos et accusamus et iusto odio dignissimos ducimus qui.',
              'story-template': 'text',
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': {
                    id: 58,
                    name: 'Author'
                  }
                },
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': {
                    id: 58,
                    name: 'Author'
                  }
                }
              ],
              metadata: {
                'card-share': {
                  shareable: false
                },
                'promotional-message': {
                  'is-disabled': true
                },
                'story-attributes': {
                  'view-counterview-type': 'view',
                  interests: ['test'],
                  bucket: ['planning'],
                  hobbies: ['hiking'],
                  'linked-view-counterview-story': {
                    headline: 'Demo Test story- Photo story',
                    'story-content-id': 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                    id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
                    'highlighted-headline': null
                  }
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
            score: null,
            type: 'story',
            item: {
              headline: ['London Police arrest Nirav Modi, says sources']
            },
            story: {
              'author-name': 'Karthik M',
              headline: 'London Police arrest Nirav Modi, says sources',
              slug: 'politics/2019/03/20/london-police-arrest-nirav-modi-says-sources',
              'last-published-at': 1553079556157,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  slug: 'culture',
                  name: 'Culture',
                  id: 142,
                  'parent-id': null,
                  'display-name': 'culture',
                  collection: {
                    slug: 'motherly',
                    name: 'Must Reads',
                    id: 6626
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 700,
                height: 438,
                'mime-type': 'image/jpeg',
                'focus-point': [311, 167]
              },
              'published-at': 1553079556157,
              id: '5b3cc369-24f9-4f83-bd53-7eb2277eef47',
              'hero-image-s3-key':
                'quintype-demo/2019-03/55dbc301-3c5c-442d-8714-4bbf2d140192/pennews_2019_03_48c15852_ebed_464d_95a7_57332597ecfc_nirav.jpg',
              'author-id': 19994,
              'first-published-at': 1553079340304,
              'hero-image-caption': 'Nirav Modi',
              'story-template': null,
              authors: [
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 142914,
                  name: 'Ashwin Raghunath',
                  slug: 'ashwin-raghunath',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'promotional-message': {
                  'is-disabled': false
                },
                'text-to-speech': {
                  'is-disabled': true
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
            score: null,
            type: 'story',
            item: {
              headline: ['Elections 2019']
            },
            story: {
              'author-name': 'Sriram K',
              headline: 'Elections 2019',
              slug: 'boom/2019/03/13/elections-2019',
              'last-published-at': 1552458028721,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'boom',
                  name: 'Boom',
                  id: 7349,
                  'parent-id': 83,
                  'display-name': 'Booom',
                  collection: {
                    slug: 'boom-politics',
                    name: 'Boom (Politics)',
                    id: 14256
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 650,
                height: 400,
                'mime-type': 'image/jpeg',
                'focus-point': [281, 241]
              },
              'published-at': 1552458028721,
              id: 'eb01f580-a956-46c9-83d5-a05ead314bc9',
              'hero-image-s3-key': 'quintype-demo/2017-03/ee72c827-52fe-4b2b-8f46-dd0875fbe162/Tech sparkle.jpg',
              'author-id': 61657,
              'first-published-at': 1552458028721,
              'hero-image-caption': 'Elections 2017',
              'story-template': 'news-elsewhere',
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'reference-url': 'https://cinema.vikatan.com/tamil-cinema/news',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
            score: null,
            type: 'story',
            item: {
              headline: ['Amitabh Bachchan on Gender Equality']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'Amitabh Bachchan on Gender Equality',
              slug: 'politics/2017/03/02/Amitabh-Bachchan-on-Gender-Equality',
              'last-published-at': 1552048741823,
              subheadline: 'Amitabh Bachchan on Gender Equality',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 320,
                height: 277,
                'focus-point': [169, 145]
              },
              'published-at': 1552048741823,
              id: '48ed0ec0-74c8-48d6-bd5e-0b9cf776241b',
              'hero-image-s3-key': 'quintype-demo/2017-03/bd011f2d-9a10-4d4e-b20f-50719f59d4b9/BMS-2.jpg',
              'author-id': 2041,
              'first-published-at': 1488451487896,
              'hero-image-caption': 'Kaun Banega Crorepati host, Amitabh Bachchan',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '2deeb522-1752-4552-967b-93972481d71e',
            score: null,
            type: 'story',
            item: {
              headline: ['Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them']
            },
            story: {
              'author-name': 'Yamini Priya',
              headline: 'Sisters Killed Father In “Perfect Murder”. A Love Triangle Exposed Them',
              slug: 'boom/2019/03/07/sisters-killed-father-in-perfect-murder-a-love-triangle-exposed-them',
              'last-published-at': 1551938683203,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'boom',
                  name: 'Boom',
                  id: 7349,
                  'parent-id': 83,
                  'display-name': 'Booom',
                  collection: {
                    slug: 'boom-politics',
                    name: 'Boom (Politics)',
                    id: 14256
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1760,
                height: 2480,
                'mime-type': 'image/jpeg',
                'focus-point': [928, 656]
              },
              'published-at': 1551938683203,
              id: '2deeb522-1752-4552-967b-93972481d71e',
              'hero-image-s3-key':
                'quintype-demo/2018-11/26dd70b0-8175-425d-b65e-7e37dcc5b9be/Forgotten_Women_The_Artists.jpg',
              'author-id': 230618,
              'first-published-at': 1551938683203,
              'hero-image-caption': 'Forgotten Women',
              'story-template': null,
              authors: [
                {
                  id: 230618,
                  name: 'Yamini Priya',
                  slug: 'yamini-priya',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': 'https://twitter.com/yaminipriyah',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
            score: null,
            type: 'story',
            item: {
              headline: ['500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌']
            },
            story: {
              'author-name': 'Nikitha Nadig',
              headline: '500 కుటుంబాలు వైయ‌స్ఆర్‌సీపీలో చేరిక‌',
              slug: 'politics/2019/02/26/500',
              'last-published-at': 1551171395390,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                'focus-point': [673, 287],
                width: 976,
                height: 700
              },
              'published-at': 1551171395390,
              id: '0ce0fd47-0c8f-415a-b77b-9b9b89ac80d7',
              'hero-image-s3-key': 'quintype-demo/2018-05/bc017b57-a03e-4a6e-8515-9d3de91f1f24/ON1.jpg',
              'author-id': 559147,
              'first-published-at': 1551171395390,
              'hero-image-caption':
                'Crowds cheered Prince Harry, Duke of Sussex, and his wife Meghan, Duchess of Sussex, as they travelled in the horse-drawn Ascot landau',
              'story-template': null,
              authors: [
                {
                  id: 559147,
                  name: 'Nikitha Nadig',
                  slug: 'nikitha-nadig',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-slaDrjB5Qmo/AAAAAAAAAAI/AAAAAAAAAAA/AGDgw-go4Fjdy0NV3gs9mnYVkjyu3UtpOg/mo/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
            score: null,
            type: 'story',
            item: {
              headline: ['Personalise or perish - Why publishers need to use personalised content ']
            },
            story: {
              'author-name': 'Greeshma',
              headline: 'Personalise or perish - Why publishers need to use personalised content ',
              slug: 'politics/2018/02/28/personalise-or-perish---why-publishers-need-to-use-personalised-content',
              'last-published-at': 1536076813159,
              subheadline:
                'Laoreet non curabitur gravida arcu ac tortor dignissim. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Massa eget egestas purus viverra accumsan in. Interdum varius sit amet as porttitor. ',
              alternative: {
                home: {
                  default: {
                    headline: 'Personalise or perish',
                    'hero-image': {
                      'hero-image-url':
                        'https://images.assettype.com/quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                      'hero-image-metadata': {
                        width: 700,
                        height: 467,
                        'mime-type': 'image/jpeg'
                      },
                      'hero-image-s3-key': 'quintype-demo/2015-12/a758449d-9cc5-4a8c-8d9c-9ec19ff89e43/FN-1.jpg',
                      'hero-image-caption': 'sSaffron - Indian Restaurant',
                      'hero-image-attribution': null
                    }
                  }
                }
              },
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 3896,
                  name: 'Blog',
                  slug: 'blog'
                }
              ],
              'hero-image-metadata': {
                width: 1600,
                height: 1200,
                'mime-type': 'image/jpeg',
                'focus-point': [805, 738]
              },
              'published-at': 1536076813159,
              id: 'eef6636c-9778-4f91-8684-bf56de45b5c7',
              'hero-image-s3-key': 'quintype-demo/2018-05/a2759dc2-ada1-4917-8515-308f74622c69/Gallery5.jpg',
              'author-id': 482995,
              'first-published-at': 1519816264773,
              'hero-image-caption': 'Animals',
              'story-template': null,
              authors: [
                {
                  id: 482995,
                  name: 'Greeshma',
                  slug: 'greeshma',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'avatar-s3-key':
                    'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
            score: null,
            type: 'story',
            item: {
              headline: ['U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'U.S. and North Korean Officials Race to Resurrect Trump-Kim Meeting',
              slug: 'politics/2018/05/28/us-and-north-korean-officials-race-to-resurrect-trump-kim-meeting',
              'last-published-at': 1535807624134,
              subheadline: null,
              alternative: {
                home: {
                  default: {
                    headline: 'U.S. and North Korean',
                    'hero-image': {
                      'hero-image-url':
                        'https://images.assettype.com/quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                      'hero-image-metadata': {
                        width: 636,
                        height: 477,
                        'focus-point': [390, 153]
                      },
                      'hero-image-s3-key': 'quintype-demo/2016-01/4bb17ffb-9a15-49ff-9835-99292235ee9e/IPS-1.jpg',
                      'hero-image-caption': 'President Pranab Mukherjee',
                      'hero-image-attribution': null
                    }
                  }
                }
              },
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1024,
                height: 683,
                'mime-type': 'image/jpeg',
                'focus-point': [758, 172]
              },
              'published-at': 1535807624134,
              id: '9dfa9b67-248c-4aeb-b4ca-a14764dae36b',
              'hero-image-s3-key': 'quintype-demo/2018-05/caf35ec5-be06-4de6-b513-e57ee4d61577/ON_1.jpg',
              'author-id': 376442,
              'first-published-at': 1527472808648,
              'hero-image-caption':
                'A surprise meeting between President Moon Jae-in of South Korea, right, and Kim Jong-un, the North Korean leader, on Saturday appeared to produce some progress toward a summit meeting between President Trump and Mr. Kim.',
              'story-template': null,
              authors: [
                {
                  id: 376442,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '43c468f4-08e4-4e77-a6d6-a11da9235c96',
            score: null,
            type: 'story',
            item: {
              headline: ['Amy Schumer Ties the Knot With Boyfriend Chris Fischer in 2018']
            },
            story: {
              'author-name': 'Christin',
              headline: 'Amy Schumer Ties the Knot With Boyfriend Chris Fischer in 2018',
              slug: 'politics/2018/06/15/amy-schumer-ties-the-knot-with-boyfriend-chris-fischer-in-2018',
              'last-published-at': 1529059243871,
              subheadline: 'Amy Schumer Ties the Knot With Boyfriend Chris Fischer',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 583,
                height: 328,
                'mime-type': 'image/webp'
              },
              'published-at': 1529059243871,
              id: '43c468f4-08e4-4e77-a6d6-a11da9235c96',
              'hero-image-s3-key':
                'quintype-demo/2018-02/c899f85d-76e0-46c5-88c4-f7365f4b528a/e29ee5c9-9195-4f79-9942-fd8ca08027e4.webp',
              'author-id': 139357,
              'first-published-at': 1529059243871,
              'hero-image-caption': 'Amy Schumer is married to boyfriend Chris Fischer.',
              'story-template': null,
              authors: [
                {
                  id: 139357,
                  name: 'Christin',
                  slug: 'christin',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'avatar-s3-key':
                    'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'twitter-handle': 'krriztin',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '1493f46c-7c78-46e8-84b4-de195d7be9a6',
            score: null,
            type: 'story',
            item: {
              headline: ['Garrett to quit Congress amid servant scandal, alcoholism']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'Garrett to quit Congress amid servant scandal, alcoholism',
              slug: 'politics/2018/05/29/garrett-to-quit-congress-amid-servant-scandal-alcoholism',
              'last-published-at': 1527596788710,
              subheadline:
                'The Virginia congressman announces his retirement after POLITICO reported he used his staff as personal gofers.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1160,
                height: 635,
                'mime-type': 'image/jpeg',
                'focus-point': [736, 165]
              },
              'published-at': 1527596788710,
              id: '1493f46c-7c78-46e8-84b4-de195d7be9a6',
              'hero-image-s3-key': 'quintype-demo/2018-05/9ce3e16a-96fc-42e8-9433-b2fd8b0fdc93/ON_1.jpeg',
              'author-id': 376442,
              'first-published-at': 1527596788710,
              'hero-image-caption':
                '“The tragedy is that any person Republican, Democrat or independent who’s known me for period of time and has any integrity knows two things: I am a good man and I am an alcoholic.“ Rep. Tom Garrett said.&nbsp;',
              'story-template': null,
              authors: [
                {
                  id: 376442,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '903dd527-2595-4566-9c50-df2880cf18b5',
            score: null,
            type: 'story',
            item: {
              headline: ['8 times Osama almost got captured']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: '8 times Osama almost got captured',
              slug: 'politics/2017/07/06/8-times-Osama-almost-got-captured',
              'last-published-at': 1527508287286,
              subheadline:
                'Osama bin Laden, was a founder of al-Qaeda, the organization responsible for the September 11 attacks in the United States and many other mass-casualty attacks worldwide.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 275,
                height: 183,
                'mime-type': 'image/jpeg',
                'focus-point': [114, 54]
              },
              'published-at': 1527508287286,
              id: '903dd527-2595-4566-9c50-df2880cf18b5',
              'hero-image-s3-key': 'quintype-demo/2016-01/8bce31a0-af65-41e5-8ddc-059294ad6892/SL-3.jpeg',
              'author-id': 2040,
              'first-published-at': 1499338400907,
              'hero-image-caption': 'Osama Bin Laden, Al Queda',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'a71df319-c779-4ce6-b8ef-eba85b3cb613',
            score: null,
            type: 'story',
            item: {
              headline: ['Presidential Elections']
            },
            story: {
              'author-name': 'Kuruvilla Choolackal',
              headline: 'Presidential Elections',
              slug: 'politics/2016/10/14/Presidential-Elections',
              'last-published-at': 1522903281088,
              subheadline:
                'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at St. Louis, Missouri, on October 9, 2016.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1100,
                height: 619,
                'focus-point': [821, 262]
              },
              'published-at': 1522903281088,
              id: 'a71df319-c779-4ce6-b8ef-eba85b3cb613',
              'hero-image-s3-key': 'quintype-demo/2016-10/c1fb9eff-a4c7-4a12-8713-82b1dc3065a9/ct-1.jpg',
              'author-id': 2040,
              'first-published-at': 1476425535138,
              'hero-image-caption':
                'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at Washington University in St. Louis, Missouri, on October 9, 2016. ',
              'story-template': null,
              authors: [
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
            score: null,
            type: 'story',
            item: {
              headline: ['Clinton vs Trump']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'Clinton vs Trump',
              slug: 'politics/2016/10/15/clinton-vs-trump',
              'last-published-at': 1522903100409,
              subheadline:
                'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the 2nd presidential debate at St. Louis, Missouri, on October 9, 2016.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1100,
                height: 619,
                'focus-point': [812, 244]
              },
              'published-at': 1522903100409,
              id: 'b66f854e-fed7-4726-9be2-6aebbddb8ce0',
              'hero-image-s3-key': 'quintype-demo/2016-10/c1fb9eff-a4c7-4a12-8713-82b1dc3065a9/ct-1.jpg',
              'author-id': 376442,
              'first-published-at': 1476529773574,
              'hero-image-caption':
                'Democratic presidential candidate Hillary Clinton and US Republican presidential candidate Donald Trump shakes hands after the second presidential debate at Washington University in St. Louis, Missouri, on October 9, 2016. ',
              'story-template': null,
              authors: [
                {
                  id: 376442,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'fc5ee93d-19b7-4f26-984c-a43fa9fcacc3',
            score: null,
            type: 'story',
            item: {
              headline: ['From the safety of New York, reporting on a distant home']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'From the safety of New York, reporting on a distant home',
              slug: 'politics/2017/02/01/From-the-safety-of-New-York-reporting-on-a-distant-home',
              'last-published-at': 1522902297607,
              subheadline:
                'During the presidential election in Nigeria, he published real-time photos, videos &amp; reports from the field, exposing instances of ballot rigging &amp; attracting 8m page views in a month.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1024,
                height: 682,
                'focus-point': [431, 225]
              },
              'published-at': 1522902297607,
              id: 'fc5ee93d-19b7-4f26-984c-a43fa9fcacc3',
              'hero-image-s3-key': 'quintype-demo/2017-01/c36650ca-d1c9-4b00-a7c9-1fe38bc56e42/SR-1.jpg',
              'author-id': 376442,
              'first-published-at': 1485934181087,
              'hero-image-caption': 'Omoleye Sowore, Publisher of the Sahara Reporters',
              'story-template': null,
              authors: [
                {
                  id: 376442,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-eRxI3O-xrhI/AAAAAAAAAAI/AAAAAAAAAAc/vepwk0zqR1w/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '54c39b13-560a-44e0-b71e-a675d05d5673',
            score: null,
            type: 'story',
            item: {
              headline: ['I’m not a stranger to the dark']
            },
            story: {
              'author-name': 'Christin',
              headline: 'I’m not a stranger to the dark',
              slug: 'politics/2018/03/20/im-not-a-stranger-to-the-dark',
              'last-published-at': 1522822389090,
              subheadline: 'Fire away th',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1010,
                height: 805
              },
              'published-at': 1522822389090,
              id: '54c39b13-560a-44e0-b71e-a675d05d5673',
              'hero-image-s3-key': 'quintype-demo/2016-06/f9298238-e324-4dfc-b29b-95a3cbdb754b/ABP-1.jpeg',
              'author-id': 139357,
              'first-published-at': 1521518076019,
              'hero-image-caption':
                'Ali was still known as Cassius Clay when this photo was taken. There’s something boyish about his efforts to impress his audience. I like how one of the women, Ronnie Spector, seems impervious to his bravado.&nbsp; I also like knowing that the guy in the white tuxedo, who is neither overawed nor overshadowed by Clay, is a young Stevie Wonder.',
              'story-template': null,
              authors: [
                {
                  id: 139357,
                  name: 'Christin',
                  slug: 'christin',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'avatar-s3-key':
                    'quintype-demo/2018-02/0dabd96b-c952-4572-abc4-b02651d7c8e1/21015781_10159275135175215_6119134168266065954_o.jpg',
                  'twitter-handle': 'krriztin',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'd5da1faf-8cb4-4783-9a54-a3d125c0d1ce',
            score: null,
            type: 'story',
            item: {
              headline: ['बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार']
            },
            story: {
              'author-name': 'Sareena',
              headline: 'बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार',
              slug: 'politics/2018/03/29/two-big-bjp-leaders-arrested-for-spreading-violence-in-bihar',
              'last-published-at': 1522317027856,
              subheadline: 'बिहार में हिंसा फैलाने के मामले को लेकर बीजेपी के दो बड़े नेता ​गिरफ्तार,मचा हड़कंप',
              alternative: null,
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 98,
                  name: 'Current Affairs'
                }
              ],
              'hero-image-metadata': {
                width: 650,
                height: 386,
                'mime-type': 'image/jpeg',
                'focus-point': [323, 117]
              },
              'published-at': 1522317027856,
              id: 'd5da1faf-8cb4-4783-9a54-a3d125c0d1ce',
              'hero-image-s3-key':
                'quintype-demo/2018-03/974fc356-ae80-4315-9418-c79e83167fcf/90c6583d-dd09-4c2c-bb21-8510cb369769.jpg',
              'author-id': 94596,
              'first-published-at': 1522317027856,
              'hero-image-caption': 'Amit Shah and Narendra Modi',
              'story-template': null,
              authors: [
                {
                  id: 94596,
                  name: 'Sareena',
                  slug: 'sareena',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'c124d3ce-a44e-4370-a3ce-e167953c981d',
            score: null,
            type: 'story',
            item: {
              headline: ['‘ಮೂರು ಕೊಲೆಗಳು; ಒಂದು ವ್ಯಸನ’: ಪೊಲೀಸರು ಮುಂದಿಡುವ ‘ಗಾಂಜಾ ಥಿಯರಿ’ಯ ']
            },
            story: {
              'author-name': 'Chirdeep Shetty',
              headline: '‘ಮೂರು ಕೊಲೆಗಳು; ಒಂದು ವ್ಯಸನ’: ಪೊಲೀಸರು ಮುಂದಿಡುವ ‘ಗಾಂಜಾ ಥಿಯರಿ’ಯ ',
              slug: 'politics/2018/02/12/--2',
              'last-published-at': 1521638195154,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 600,
                height: 335,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1521638195154,
              id: 'c124d3ce-a44e-4370-a3ce-e167953c981d',
              'hero-image-s3-key': 'quintype-demo/2018-02/28fabce6-a777-4d95-aef2-06eef43c4c34/Ganja-Kadireshan.jpg',
              'author-id': 15338,
              'first-published-at': 1518424171905,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 15338,
                  name: 'Chirdeep Shetty',
                  slug: 'chirdeep-shetty',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'twitter-handle': '@chirdeepshettyquintype',
                  bio: '<p>Hello, this is a test for bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'd5cf9b45-4832-4b04-9234-b6bf4bc2dce0',
            score: null,
            type: 'story',
            item: {
              headline: ['Ola working on assisted-driving cars, hires professionals']
            },
            story: {
              'author-name': 'Vineet Panjabi',
              headline: 'Ola working on assisted-driving cars, hires professionals',
              slug: 'politics/2018/01/16/ola-working-on-assisted-driving-cars-hires-professionals',
              'last-published-at': 1520925971944,
              subheadline: 'Ola Cars Subtitle&nbsp;',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 3896,
                  name: 'Blog'
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                'focus-point': [155, 375],
                width: 1024,
                height: 569
              },
              'published-at': 1520925971944,
              id: 'd5cf9b45-4832-4b04-9234-b6bf4bc2dce0',
              'hero-image-s3-key':
                'quintype-demo/2017-05/a681ca73-9212-4184-9426-0983be59a7d4/luxury-cars-1024x569.jpg',
              'author-id': 323432,
              'first-published-at': 1516079335936,
              'hero-image-caption': 'Cars',
              'story-template': null,
              authors: [
                {
                  id: 323432,
                  name: 'Vineet Panjabi',
                  slug: 'vineet-panjabi',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '421f0340-4c63-45c1-a152-4433413b8ef4',
            score: null,
            type: 'story',
            item: {
              headline: ['Maruti Swift 2018 - Review']
            },
            story: {
              'author-name': 'Chirdeep Shetty',
              headline: 'Maruti Swift 2018 - Review',
              slug: 'politics/2018/02/12/maruti-swift-2018---review',
              'last-published-at': 1520925923189,
              subheadline: 'Sub',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 82,
                  name: 'Sports'
                },
                {
                  id: 3896,
                  name: 'Blog'
                }
              ],
              'hero-image-metadata': {
                width: 1024,
                height: 682,
                'mime-type': 'image/jpeg',
                'focus-point': [860, 454]
              },
              'published-at': 1520925923189,
              id: '421f0340-4c63-45c1-a152-4433413b8ef4',
              'hero-image-s3-key':
                'quintype-demo/2018-02/63508d1a-a5c7-4f99-b032-9fe28deb8250/2018-Maruti-Swift-Review.jpg',
              'author-id': 15338,
              'first-published-at': 1518429546374,
              'hero-image-caption': 'swift 2018 black',
              'story-template': null,
              authors: [
                {
                  id: 15338,
                  name: 'Chirdeep Shetty',
                  slug: 'chirdeep-shetty',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'twitter-handle': '@chirdeepshettyquintype',
                  bio: '<p>Hello, this is a test for bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '46d764a1-ec10-4d90-a056-274f3f506a65',
            score: null,
            type: 'story',
            item: {
              headline: ['Sample Story Using All Elements']
            },
            story: {
              'author-name': 'Parvathi Mohan',
              headline: 'Sample Story Using All Elements',
              slug: 'sample-story-using-all-elements',
              'last-published-at': 1520925485589,
              subheadline:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, nostrud exercitation nisi ut ex ea consequat.&nbsp;',
              alternative: {},
              sections: [
                {
                  id: 3896,
                  name: 'Blog'
                },
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 284,
                height: 177,
                'mime-type': 'image/jpeg',
                'focus-point': [142, 86]
              },
              'published-at': 1520925485589,
              id: '46d764a1-ec10-4d90-a056-274f3f506a65',
              'hero-image-s3-key':
                'quintype-demo/2018-03/f0ac00fe-93cc-4e00-bd94-56f71065b950/4448febb-552f-47f3-9b42-ee850f7b5b48.jpeg',
              'author-id': 139717,
              'first-published-at': 1520925485589,
              'hero-image-caption': 'Demo: Hero Image Caption',
              'story-template': null,
              authors: [
                {
                  id: 139717,
                  name: 'Parvathi Mohan',
                  slug: 'parvathi-mohan',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-mZvNtR1jsMU/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQOuq2xtYj0pRKjf_nt4lrCZoC8cEw/mo/photo.jpg?sz=50',
                  'avatar-s3-key':
                    'quintype-demo/2018-01/5f878d0b-524f-4950-8641-169abd031d3c/You mean Karma right.jpg',
                  'twitter-handle': 'prvthi',
                  bio:
                    '<p>This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. This is my bio. </p>',
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '7e803bcc-cb40-4f4d-b810-ae9ace8ba4c1',
            score: null,
            type: 'story',
            item: {
              headline: ['India vs China Battle']
            },
            story: {
              'author-name': 'Greeshma',
              headline: 'India vs China Battle',
              slug: 'politics/2017/07/09/India-vs-China-Battle',
              'last-published-at': 1519975157133,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1600,
                height: 1068,
                'mime-type': 'image/jpeg',
                'focus-point': [971, 601]
              },
              'published-at': 1519975157133,
              id: '7e803bcc-cb40-4f4d-b810-ae9ace8ba4c1',
              'hero-image-s3-key':
                'quintype-demo/2018-01/5c55eaab-9c97-49f9-8b14-64b661d9a763/1 - Thumb Tac Paper Lanterns Glowing Tall.jpg',
              'author-id': 482995,
              'first-published-at': 1499594657010,
              'hero-image-caption': 'Let there be Light',
              'story-template': null,
              authors: [
                {
                  id: 482995,
                  name: 'Greeshma',
                  slug: 'greeshma',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'avatar-s3-key':
                    'quintype-demo/2018-03/9a16b150-6281-45e6-bdb9-6eb1146e1a54/50b1da06-b478-4bc8-8067-fada337c55d0.jpeg',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 19994,
                  name: 'Karthik M',
                  slug: 'karthik-m',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '86fe6180-c4c2-4b09-b829-5dee9f403756',
            score: null,
            type: 'story',
            item: {
              headline: ['This Kashmiri Pandit Is Among Civil Services Toppers']
            },
            story: {
              'author-name': 'Tejas Dinkar',
              headline: 'This Kashmiri Pandit Is Among Civil Services Toppers',
              slug: 'politics/2017/12/27/this-kashmiri-pandit-is-among-civil-services-toppers',
              'last-published-at': 1516419117190,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 750,
                height: 500,
                'mime-type': 'image/jpeg',
                'focus-point': [200, 173]
              },
              'published-at': 1516419117190,
              id: '86fe6180-c4c2-4b09-b829-5dee9f403756',
              'hero-image-s3-key': 'quintype-demo/2017-12/602c33fe-b2a1-423b-b062-83b0e30aa832/1-7-750x500.jpg',
              'author-id': 2038,
              'first-published-at': 1514371575338,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 2038,
                  name: 'Tejas Dinkar',
                  slug: 'tejas-dinkar',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
            score: null,
            type: 'story',
            item: {
              headline: ['Demo Test story- Photo story']
            },
            story: {
              'author-name': 'Yamini Priya',
              headline: 'Demo Test story- Photo story',
              slug: 'politics/2018/01/12/demo-test-story--photo-story',
              'last-published-at': 1515743985191,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1418,
                height: 853,
                'mime-type': 'image/jpeg',
                'focus-point': [1045, 434]
              },
              'published-at': 1515743985191,
              id: 'ce0a2b09-72fb-4404-8007-cf39c9889526',
              'hero-image-s3-key': 'quintype-demo/2016-05/7ae1264b-0330-4eb5-b9d4-f4a4d5746076/vadra06.jpg',
              'author-id': 230618,
              'first-published-at': 1515743985191,
              'hero-image-caption': 'Robert Vadra at a function',
              'story-template': null,
              authors: [
                {
                  id: 230618,
                  name: 'Yamini Priya',
                  slug: 'yamini-priya',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': 'https://twitter.com/yaminipriyah',
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '48a9264e-cddd-437c-83f1-4388aefee656',
            score: null,
            type: 'story',
            item: {
              headline: ['Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.',
              slug: 'politics/2017/10/04/test-7',
              'last-published-at': 1515403855694,
              subheadline:
                'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1920,
                height: 1080
              },
              'published-at': 1515403855694,
              id: '48a9264e-cddd-437c-83f1-4388aefee656',
              'hero-image-s3-key': 'quintype-demo/2015-08/a1d1ce2c-f9f9-4aed-b82e-06f338112e2d/Tiger.jpg',
              'author-id': 61657,
              'first-published-at': 1507099185346,
              'hero-image-caption': 'Tiger resting in the Ranthambore ranges, North India',
              'story-template': 'review',
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'review-rating': {
                  label: '1',
                  value: '1'
                },
                'review-title': 'Movie review',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'e4192f9a-e843-464c-8ec8-2a6211720e5a',
            score: null,
            type: 'story',
            item: {
              headline: ['My First Story']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'My First Story',
              slug: 'politics/2017/11/13/my-first-story',
              'last-published-at': 1510568455753,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 82,
                  name: 'Sports'
                }
              ],
              'hero-image-metadata': {
                'mime-type': 'image/jpeg',
                width: 259,
                height: 194,
                'focus-point': [25, 42]
              },
              'published-at': 1510568455753,
              id: 'e4192f9a-e843-464c-8ec8-2a6211720e5a',
              'hero-image-s3-key': 'quintype-demo/2016-01/69acdda0-2648-4cc2-b240-fd3603136bc5/download.jpeg',
              'author-id': 61657,
              'first-published-at': 1510568455753,
              'hero-image-caption': 'Narendra Modi with Ajit Doval',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'sponsored-by': 'CP',
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '8ff85a7d-7726-49b4-892e-1e05c0ccea73',
            score: null,
            type: 'story',
            item: {
              headline: ['CM defends George, says 20 Union ministers face criminal charges  ']
            },
            story: {
              'author-name': 'Sathyan Reddy ',
              headline: 'CM defends George, says 20 Union ministers face criminal charges  ',
              slug: 'politics/2017/10/27/cm-defends-george-says-20-union-ministers-face-criminal-charges',
              'last-published-at': 1509100816382,
              subheadline:
                'Chief Minister Siddaramaiah on Friday rejected the BJP’s demand to seek the resignation of Bengaluru City Development Minister K J George. &nbsp;',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 618,
                height: 464,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1509100816382,
              id: '8ff85a7d-7726-49b4-892e-1e05c0ccea73',
              'hero-image-s3-key':
                'quintype-demo/2017-10/606b8c85-6427-4f65-9b95-f1f4f07f3a7a/618xNx639611_thump.jpg.pagespeed.ic.pfY_28czPu.jpg',
              'author-id': 288886,
              'first-published-at': 1509100816382,
              'hero-image-caption': 'CM of Karnataka, Siddaramaiah&nbsp;',
              'story-template': null,
              authors: [
                {
                  id: 288886,
                  name: 'Sathyan Reddy ',
                  slug: 'sathyan-reddy',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '8d240213-abc3-4eb7-8e09-1f0082986e5c',
            score: null,
            type: 'story',
            item: {
              headline: ['DOJ put USTs Divina on immigration lookout over Atio Castillo']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'DOJ put USTs Divina on immigration lookout over Atio Castillo',
              slug:
                'politics/2017/10/26/186449-doj-ust-law-dean-nilo-divina-immigration-lookout-horacio-castillo-iii-hazing',
              'last-published-at': 1509004361886,
              subheadline:
                '(UPDATED) Some of the names in the lookout order check out with the names that appear in the group chat of Aegis Juris alumni who held a meeting when Castillo was kill',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 98,
                  name: 'Current Affairs'
                }
              ],
              'hero-image-metadata': {
                width: 194,
                height: 194,
                'mime-type': 'image/jpeg',
                'focus-point': null
              },
              'published-at': 1509004361886,
              id: '8d240213-abc3-4eb7-8e09-1f0082986e5c',
              'hero-image-s3-key':
                'quintype-demo/2017-10/bdce310f-89d6-4c3d-9ebc-4a7ed6ccbf86/88b072c7-2631-4cee-abec-06c85c2911d8.jpeg',
              'author-id': 61657,
              'first-published-at': 1509004361886,
              'hero-image-caption': 'Narendra Modi with Ajit Doval',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'db09a326-3af2-4286-a92b-8ffbc9bb3c4f',
            score: null,
            type: 'story',
            item: {
              headline: ['परमाणु हथियारों को प्रतिबंधित करने के लिए संधि पर हस्ताक्षर']
            },
            story: {
              'author-name': 'Vineet Panjabi',
              headline: 'परमाणु हथियारों को प्रतिबंधित करने के लिए संधि पर हस्ताक्षर',
              slug: 'politics/2017/09/21/-',
              'last-published-at': 1505980220139,
              subheadline:
                'उत्तर कोरिया संकट के बड़ा आकार लेने की पृष्ठभूमि में यहां 51 देश परमाणु हथियारों को प्रतिबंधित करने वाली एक नयी संधि पर हस्ताक्ष',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 259,
                height: 194,
                'mime-type': 'image/jpeg',
                'focus-point': [171, 76]
              },
              'published-at': 1505980220139,
              id: 'db09a326-3af2-4286-a92b-8ffbc9bb3c4f',
              'hero-image-s3-key': 'quintype-demo/2016-01/69acdda0-2648-4cc2-b240-fd3603136bc5/download.jpeg',
              'author-id': 323432,
              'first-published-at': 1505980220139,
              'hero-image-caption': 'Narendra Modi with Ajit Doval',
              'story-template': 'photo',
              authors: [
                {
                  id: 323432,
                  name: 'Vineet Panjabi',
                  slug: 'vineet-panjabi',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/--lfhPf69UBM/AAAAAAAAAAI/AAAAAAAAAA8/YSVpZhKBTNc/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '8339b599-3eab-4428-9486-9139d28bb1ba',
            score: null,
            type: 'story',
            item: {
              headline: ['9/11 and the unsolvable Afghan drama   ']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: '9/11 and the unsolvable Afghan drama   ',
              slug: 'politics/2017/09/13/afghan-drama',
              'last-published-at': 1505284207024,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 98,
                  name: 'Current Affairs'
                }
              ],
              'hero-image-metadata': {
                width: 5120,
                height: 3620,
                'focus-point': [3977, 737]
              },
              'published-at': 1505284207024,
              id: '8339b599-3eab-4428-9486-9139d28bb1ba',
              'hero-image-s3-key': 'quintype-demo/2016-07/1910bb30-b118-475c-aced-9304968c69c8/G-1.jpg',
              'author-id': 61657,
              'first-published-at': 1505284207024,
              'hero-image-caption': 'ABD, Virat &amp; Hashim - the best in the world today!',
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 5278,
                  name: 'Michele Maguire',
                  slug: 'michele-maguire',
                  'avatar-url':
                    'https://lh6.googleusercontent.com/-OeX42e4xLm4/AAAAAAAAAAI/AAAAAAAAADQ/NmZhX0kP7J0/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '4886fb7b-3a0d-413f-ab55-7e45107cfb34',
            score: null,
            type: 'story',
            item: {
              headline: ['A memoir from a classmate']
            },
            story: {
              'author-name': 'Sriram Krishnaswamy',
              headline: 'A memoir from a classmate',
              slug: 'politics/2017/09/05/nirmala-seetharaman',
              'last-published-at': 1504613043734,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 666,
                height: 338,
                'mime-type': 'image/jpeg',
                'focus-point': [319, 77]
              },
              'published-at': 1504613043734,
              id: '4886fb7b-3a0d-413f-ab55-7e45107cfb34',
              'hero-image-s3-key': 'quintype-demo/2017-09/44532ad7-a0fe-48dc-a67a-f4f469b88ed4/NIRMALA_BB_20160215.jpg',
              'author-id': 61657,
              'first-published-at': 1504613043734,
              'hero-image-caption': null,
              'story-template': null,
              authors: [
                {
                  id: 61657,
                  name: 'Sriram K',
                  slug: 'sriram-k',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                },
                {
                  id: 2040,
                  name: 'Kuruvilla Choolackal',
                  slug: 'kuruvilla-choolackal',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'avatar-s3-key':
                    'quintype-demo/2019-02/dae976f5-fb58-49a5-974d-a3159ffb2b32/Jonathan_Rey_Star_Wars_Characters_Yoda_02.ico',
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '3e112b40-cc06-4d70-901b-f825bd92099e',
            score: null,
            type: 'story',
            item: {
              headline: ['In the battle of perception, Kejriwal won this round']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: 'In the battle of perception, Kejriwal won this round',
              slug: 'politics/2017/07/20/in-the-battle-of-perception-kejriwal-won-this-round',
              'last-published-at': 1500522632130,
              subheadline: null,
              alternative: null,
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 380,
                height: 285,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1500522632130,
              id: '3e112b40-cc06-4d70-901b-f825bd92099e',
              'hero-image-s3-key': 'quintype-demo/2015-12/a7098e1a-62f9-42fd-ac12-a97e49dacce7/FP-1.jpg',
              'author-id': 2041,
              'first-published-at': 1500522632130,
              'hero-image-caption': 'Kejriwal, Chief Minister, New Delhi',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '88ef0bb2-bc4b-4e9b-87b1-1b774e9f7f67',
            score: null,
            type: 'story',
            item: {
              headline: ['Donald Trump set to sign executive orders on trade  ']
            },
            story: {
              'author-name': 'Hema Rao',
              headline: 'Donald Trump set to sign executive orders on trade  ',
              slug: 'politics/2017/03/08/airtel-vs-joi',
              'last-published-at': 1490953321146,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 660,
                height: 440,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1490953321146,
              id: '88ef0bb2-bc4b-4e9b-87b1-1b774e9f7f67',
              'hero-image-s3-key': 'quintype-demo/2017-03/d1f83f15-ab18-48c4-b207-235392a76ff7/trump-Reu-1-1-2-1-1.jpg',
              'author-id': 122655,
              'first-published-at': 1488968787660,
              'hero-image-caption': 'Trump',
              'story-template': null,
              authors: [
                {
                  id: 122655,
                  name: 'Hema Rao',
                  slug: 'hema-rao',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '07dc7d89-1733-4c00-bc23-89384d40f001',
            score: null,
            type: 'story',
            item: {
              headline: ['Something about modi']
            },
            story: {
              'author-name': 'Chirdeep Shetty',
              headline: 'Something about modi',
              slug: 'politics/2017/03/29/something-about-modi',
              'last-published-at': 1490809281495,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 275,
                height: 183,
                'focus-point': [192, 51]
              },
              'published-at': 1490809281495,
              id: '07dc7d89-1733-4c00-bc23-89384d40f001',
              'hero-image-s3-key': 'quintype-demo/2016-01/2d00bde7-a28c-4415-9382-9a9c6fb3c7fc/IPSMF-1.jpeg',
              'author-id': 15338,
              'first-published-at': 1490808450252,
              'hero-image-caption': 'Nawaz Sharif &amp; Modi&nbsp;',
              'story-template': null,
              authors: [
                {
                  id: 15338,
                  name: 'Chirdeep Shetty',
                  slug: 'chirdeep-shetty',
                  'avatar-url':
                    'https://images.assettype.com/quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'avatar-s3-key': 'quintype-demo/2018-01/d52969ff-b638-4e52-92b1-4af76db2dcac/chirdeep.jpg',
                  'twitter-handle': '@chirdeepshettyquintype',
                  bio: '<p>Hello, this is a test for bio. </p>'
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '1c508124-6ebb-44f1-a119-d55952304fa3',
            score: null,
            type: 'story',
            item: {
              headline: ['I Saved the Country, Says PM Modi on Demonetisation']
            },
            story: {
              'author-name': 'Priyanka Basnet',
              headline: 'I Saved the Country, Says PM Modi on Demonetisation',
              slug: 'politics/2016/12/24/storyabcpolitics',
              'last-published-at': 1489732402329,
              subheadline:
                '<a href="http://www.news18.com/news/india/wont-spare-corrupt-will-fight-until-we-win-pm-modi-1327208.html">Won’t Spare Corrupt, Will Fight Until We Win, Says PM Modi</a>',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 875,
                height: 583,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1489732402329,
              id: '1c508124-6ebb-44f1-a119-d55952304fa3',
              'hero-image-s3-key': 'quintype-demo/2016-12/34d9b2dd-0280-4a72-b843-335aecccc531/pm-PIB-875.jpg',
              'author-id': 94491,
              'first-published-at': 1482598726781,
              'hero-image-caption': 'Prime Minister Narendra Modi',
              'story-template': 'video',
              authors: [
                {
                  id: 94491,
                  name: 'Priyanka Ashok',
                  slug: 'priyanka-ashok',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '379c8ae5-538e-45c1-a52d-002c6a8610b7',
            score: null,
            type: 'story',
            item: {
              headline: ['2017 Assembly Elections in numbers!']
            },
            story: {
              'author-name': 'Tapan Bhat',
              headline: '2017 Assembly Elections in numbers!',
              slug: 'politics/2017/03/12/2017-assembly-elections-in-numbers',
              'last-published-at': 1489378680804,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 275,
                height: 183,
                'mime-type': 'image/jpeg',
                'focus-point': [117, 94]
              },
              'published-at': 1489378680804,
              id: '379c8ae5-538e-45c1-a52d-002c6a8610b7',
              'hero-image-s3-key': 'quintype-demo/2017-03/36c1261a-7ee1-46f0-bc6d-4aeb98e9dd2a/LHI-1.jpeg',
              'author-id': 2041,
              'first-published-at': 1489330452286,
              'hero-image-caption': 'Amit Shah at an election rally in Uttar Pradesh',
              'story-template': null,
              authors: [
                {
                  id: 2041,
                  name: 'Tapan Bhat',
                  slug: 'tapan-bhat',
                  'avatar-url':
                    'https://lh4.googleusercontent.com/-lqObi8E6gLQ/AAAAAAAAAAI/AAAAAAAAACQ/VAe7i2UVf5E/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '3fd0298e-da88-455b-89d7-77ad6671c17e',
            score: null,
            type: 'story',
            item: {
              headline: ['UP Election 2017 Updates']
            },
            story: {
              'author-name': 'Sareena',
              headline: 'UP Election 2017 Updates',
              slug: 'politics/2017/03/12/up-election-2017-updates',
              'last-published-at': 1489298494909,
              subheadline: 'BJP sets up a big win, SP-Congress takes a bashing, show leads.  &nbsp;',
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 650,
                height: 400,
                'mime-type': 'image/jpeg',
                'focus-point': [281, 241]
              },
              'published-at': 1489298494909,
              id: '3fd0298e-da88-455b-89d7-77ad6671c17e',
              'hero-image-s3-key': 'quintype-demo/2017-03/ee72c827-52fe-4b2b-8f46-dd0875fbe162/Tech sparkle.jpg',
              'author-id': 94596,
              'first-published-at': 1489298494909,
              'hero-image-caption': 'Elections 2017',
              'story-template': null,
              authors: [
                {
                  id: 94596,
                  name: 'Sareena',
                  slug: 'sareena',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: '044e76fc-c303-4a0f-8f9a-c0e450457163',
            score: null,
            type: 'story',
            item: {
              headline: ['\n\nOld Jallikattu and new Jallikattu   ']
            },
            story: {
              'author-name': 'Hema Rao',
              headline: '\n\nOld Jallikattu and new Jallikattu   ',
              slug: 'politics/2017/01/20/old-jallikattu-and-new-jallikattu',
              'last-published-at': 1489039507722,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                },
                {
                  id: 2626,
                  name: 'Regional'
                }
              ],
              'hero-image-metadata': {
                width: 310,
                height: 233,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1489039507722,
              id: '044e76fc-c303-4a0f-8f9a-c0e450457163',
              'hero-image-s3-key':
                'quintype-demo/2017-01/4dc43d6b-6df7-41f9-a196-95283a34cbac/jallikattu-cheat-sheet-10-things-you-should-know-about-the-bull-taming-sport.jpg',
              'author-id': 122655,
              'first-published-at': 1484904866939,
              'hero-image-caption': 'Jallikattu',
              'story-template': null,
              authors: [
                {
                  id: 122655,
                  name: 'Hema Rao',
                  slug: 'hema-rao',
                  'avatar-url':
                    'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50',
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null
                }
              ],
              metadata: {
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          },
          {
            id: 'a44ba468-3947-408a-adde-c473191479f1',
            score: null,
            type: 'story',
            item: {
              headline: ['Poll : George R.R. Martin and his books!']
            },
            story: {
              'author-name': 'Tribuvan Kumar',
              headline: 'Poll : George R.R. Martin and his books!',
              slug: 'politics/2017/03/01/poll-george-rr-martin-and-his-books',
              'last-published-at': 1488368178339,
              subheadline: null,
              alternative: {},
              sections: [
                {
                  slug: 'politics',
                  name: 'Politics',
                  id: 83,
                  'parent-id': null,
                  'display-name': 'Politics & More',
                  collection: {
                    slug: 'the-quint',
                    name: 'Most Popular',
                    id: 4364
                  },
                  data: null
                }
              ],
              'hero-image-metadata': {
                width: 1920,
                height: 1080,
                'mime-type': 'image/jpeg'
              },
              'published-at': 1488368178339,
              id: 'a44ba468-3947-408a-adde-c473191479f1',
              'hero-image-s3-key':
                'quintype-demo/2017-03/4546581d-499d-462b-ae1e-cdd33b87c3c3/house-a-song-of-ice-and-fire-29965891-1920-1080.jpg',
              'author-id': 82396,
              'first-published-at': 1488367835152,
              'hero-image-caption': 'Caption Mania!',
              'story-template': null,
              authors: [
                {
                  id: 82396,
                  name: 'Tribuvan Kumar',
                  slug: 'tribuvan-kumar',
                  'avatar-url': null,
                  'avatar-s3-key': null,
                  'twitter-handle': null,
                  bio: null,
                  'contributor-role': null
                }
              ],
              metadata: {
                'story-attributes': {
                  interests: ['mobile']
                },
                'card-share': {
                  shareable: true
                }
              },
              'section-color': '#6093f2'
            }
          }
        ],
        color: '#6093f2'
      }
    ],
    socialLinks: {
      'facebook-url': 'https://www.facebook.com/QuintypeInc/'
    },
    navigationMenu: [
      {
        'updated-at': 1635153084574,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 41990,
        rank: 11165,
        title: 'Item One',
        'item-type': 'section',
        'section-slug': 'automated',
        'tag-slug': null,
        id: 11165,
        'parent-id': null,
        url: 'https://ace-web.qtstage.io/automated',
        'entity-name': null,
        'created-at': 1635153084574,
        'section-name': 'Automated',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1635411988913,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 41990,
        rank: 11196,
        title: 'Item Two',
        'item-type': 'section',
        'section-slug': 'automated',
        'tag-slug': null,
        id: 11196,
        'parent-id': null,
        url: 'https://ace-web.qtstage.io/automated',
        'entity-name': null,
        'created-at': 1635411988913,
        'section-name': 'Automated',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1635412003629,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 53914,
        rank: 11197,
        title: 'Item 3',
        'item-type': 'section',
        'section-slug': '3-layer-collection-1',
        'tag-slug': null,
        id: 11197,
        'parent-id': null,
        url: 'https://ace-web.qtstage.io/3-layer-collection-1',
        'entity-name': null,
        'created-at': 1635412003629,
        'section-name': '3 Layer collection 1',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1635412018021,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 37556,
        rank: 11198,
        title: 'Item four',
        'item-type': 'section',
        'section-slug': 'cricket',
        'tag-slug': null,
        id: 11198,
        'parent-id': null,
        url: 'https://ace-web.qtstage.io/news/cricket',
        'entity-name': null,
        'created-at': 1635412018021,
        'section-name': 'Cricket',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1635412035113,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 46655,
        rank: 11199,
        title: 'Item 5',
        'item-type': 'section',
        'section-slug': 'coronavirus',
        'tag-slug': null,
        id: 11199,
        'parent-id': null,
        url: 'https://ace-web.qtstage.io/pandemic/coronavirus',
        'entity-name': null,
        'created-at': 1635412035113,
        'section-name': 'Coronavirus',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1635412073818,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 53437,
        rank: 11200,
        title: 'Nested 1',
        'item-type': 'section',
        'section-slug': 'äänestykset',
        'tag-slug': null,
        id: 11200,
        'parent-id': 11165,
        url: 'https://ace-web.qtstage.io/%C3%A4%C3%A4nestykset',
        'entity-name': null,
        'created-at': 1635412073818,
        'section-name': 'Äänestykset',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1635412103624,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 6657,
        rank: 11201,
        title: 'Nested 2',
        'item-type': 'section',
        'section-slug': 'global-issues',
        'tag-slug': null,
        id: 11201,
        'parent-id': 11165,
        url: 'https://ace-web.qtstage.io/news/global-issues',
        'entity-name': null,
        'created-at': 1635412103624,
        'section-name': 'Global Issues',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1635412122841,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 53914,
        rank: 11202,
        title: 'Nested 3',
        'item-type': 'section',
        'section-slug': '3-layer-collection-1',
        'tag-slug': null,
        id: 11202,
        'parent-id': 11165,
        url: 'https://ace-web.qtstage.io/3-layer-collection-1',
        'entity-name': null,
        'created-at': 1635412113693,
        'section-name': '3 Layer collection 1',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636450033636,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 41990,
        rank: 11227,
        title: 'Nitithorn Prinya',
        'item-type': 'section',
        'section-slug': 'automated',
        'tag-slug': null,
        id: 11227,
        'parent-id': 11196,
        url: 'https://ace-web.qtstage.io/automated',
        'entity-name': null,
        'created-at': 1636450033636,
        'section-name': 'Automated',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636450054573,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 51987,
        rank: 11228,
        title: 'Ohta Kin',
        'item-type': 'section',
        'section-slug': 'child-section',
        'tag-slug': null,
        id: 11228,
        'parent-id': 11196,
        url: 'https://ace-web.qtstage.io/child-section',
        'entity-name': null,
        'created-at': 1636450054573,
        'section-name': 'child section',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636450078739,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 47636,
        rank: 11229,
        title: 'Takamaru Ayako',
        'item-type': 'section',
        'section-slug': 'ekta',
        'tag-slug': null,
        id: 11229,
        'parent-id': 11196,
        url: 'https://ace-web.qtstage.io/ekta',
        'entity-name': null,
        'created-at': 1636450078739,
        'section-name': 'Ekta',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636450099979,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 23099,
        rank: 11230,
        title: 'Tania Perfilyeva',
        'item-type': 'section',
        'section-slug': 'full-img-slider',
        'tag-slug': null,
        id: 11230,
        'parent-id': 11196,
        url: 'https://ace-web.qtstage.io/news/full-img-slider',
        'entity-name': null,
        'created-at': 1636450099979,
        'section-name': 'Full-Img-Slider',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636450131385,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 24565,
        rank: 11231,
        title: 'Seri Anand',
        'item-type': 'section',
        'section-slug': 'the-count',
        'tag-slug': null,
        id: 11231,
        'parent-id': 11197,
        url: 'https://ace-web.qtstage.io/the-count',
        'entity-name': null,
        'created-at': 1636450131385,
        'section-name': 'The Count',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636450151808,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 5747,
        rank: 11232,
        title: 'Gladys Kanyinda',
        'item-type': 'section',
        'section-slug': 'other',
        'tag-slug': null,
        id: 11232,
        'parent-id': 11197,
        url: 'https://ace-web.qtstage.io/other',
        'entity-name': null,
        'created-at': 1636450151808,
        'section-name': 'Other',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636450168012,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 23174,
        rank: 11233,
        title: 'Daya Chitanis',
        'item-type': 'section',
        'section-slug': 'skcoll',
        'tag-slug': null,
        id: 11233,
        'parent-id': 11197,
        url: 'https://ace-web.qtstage.io/skcoll',
        'entity-name': null,
        'created-at': 1636450168012,
        'section-name': 'SK-Coll',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636450189395,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 5773,
        rank: 11234,
        title: 'Boniface Esanji',
        'item-type': 'section',
        'section-slug': 'recent-stories',
        'tag-slug': null,
        id: 11234,
        'parent-id': 11197,
        url: 'https://ace-web.qtstage.io/recent-stories',
        'entity-name': null,
        'created-at': 1636450189395,
        'section-name': 'Recent stories',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636450228845,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 54405,
        rank: 11235,
        title: 'Boniface Esanji',
        'item-type': 'section',
        'section-slug': 'section-page',
        'tag-slug': null,
        id: 11235,
        'parent-id': 11198,
        url: 'https://ace-web.qtstage.io/section-page',
        'entity-name': null,
        'created-at': 1636450228845,
        'section-name': 'Section page',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636450273659,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 50299,
        rank: 11236,
        title: 'Rahul Malviya',
        'item-type': 'section',
        'section-slug': 'subsection',
        'tag-slug': null,
        id: 11236,
        'parent-id': 11198,
        url: 'https://ace-web.qtstage.io/automated/subsection',
        'entity-name': null,
        'created-at': 1636450273659,
        'section-name': 'Subsection',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636506756417,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 46675,
        rank: 11238,
        title: 'Daya Chitanis',
        'item-type': 'section',
        'section-slug': 'vikatan-stories-1',
        'tag-slug': null,
        id: 11238,
        'parent-id': 11199,
        url: 'https://ace-web.qtstage.io/vikatan-stories-1',
        'entity-name': null,
        'created-at': 1636506756417,
        'section-name': 'Vikatan Stories 1',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636506776145,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 6566,
        rank: 11239,
        title: 'Dina Glenn',
        'item-type': 'section',
        'section-slug': '',
        'tag-slug': null,
        id: 11239,
        'parent-id': 11199,
        url: 'https://ace-web.qtstage.io/',
        'entity-name': null,
        'created-at': 1636506776145,
        'section-name': 'ಸಂದರ್ಶನಗಳು',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636506962034,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 12022,
        rank: 11240,
        title: 'Nitithorn Prinya',
        'item-type': 'section',
        'section-slug': 'test12345',
        'tag-slug': null,
        id: 11240,
        'parent-id': 11199,
        url: 'https://ace-web.qtstage.io/test12345',
        'entity-name': null,
        'created-at': 1636506962034,
        'section-name': 'test12345',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636506981781,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 23174,
        rank: 11241,
        title: 'Rahul Malviya',
        'item-type': 'section',
        'section-slug': 'skcoll',
        'tag-slug': null,
        id: 11241,
        'parent-id': 11199,
        url: 'https://ace-web.qtstage.io/skcoll',
        'entity-name': null,
        'created-at': 1636506981781,
        'section-name': 'SK-Coll',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1636507010702,
        'tag-name': null,
        'entity-properties': null,
        'entity-slug': null,
        'publisher-id': 97,
        'menu-group-slug': 'mega-menu',
        'item-id': 11238,
        rank: 11242,
        title: 'Fabiana Company',
        'item-type': 'section',
        'section-slug': 'sample',
        'tag-slug': null,
        id: 11242,
        'parent-id': 11197,
        url: 'https://ace-web.qtstage.io/sample',
        'entity-name': null,
        'created-at': 1636507010702,
        'section-name': 'Sample',
        'menu-group-id': 3673,
        data: {
          color: '#FFFFFF'
        }
      },
      {
        'updated-at': 1551076908349,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'footer-site-links',
        'item-id': 142,
        rank: 38,
        title: 'Times Ahead',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2242,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1544609477203,
        'section-name': 'Culture',
        'menu-group-id': 95,
        data: {
          color: '#DCFF00'
        },
        children: [
          {
            'updated-at': 1551076908349,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 142,
            rank: 38,
            title: 'Politics',
            'item-type': 'section',
            'section-slug': 'culture',
            'tag-slug': null,
            id: 2345,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/culture',
            'created-at': 1544609477203,
            'section-name': 'Culture',
            'menu-group-id': 95,
            data: {
              color: '#DCFF00'
            },
            children: [],
            completeUrl: '/culture'
          },
          {
            'updated-at': 1551076908349,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 142,
            rank: 38,
            title: 'Sports',
            'item-type': 'section',
            'section-slug': 'culture',
            'tag-slug': null,
            id: 2342,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/culture',
            'created-at': 1544609477203,
            'section-name': 'Culture',
            'menu-group-id': 95,
            data: {
              color: '#DCFF00'
            },
            children: [],
            completeUrl: '/culture'
          },
          {
            'updated-at': 1551076908349,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 142,
            rank: 38,
            title: 'International',
            'item-type': 'section',
            'section-slug': 'culture',
            'tag-slug': null,
            id: 2442,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/culture',
            'created-at': 1544609477203,
            'section-name': 'Culture',
            'menu-group-id': 95,
            data: {
              color: '#DCFF00'
            },
            children: [],
            completeUrl: '/culture'
          },
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': null,
            rank: 2280,
            title: 'Movies',
            'item-type': 'link',
            'section-slug': null,
            'tag-slug': null,
            id: 2253,
            'parent-id': 2242,
            url: '/culture',
            'created-at': 1544761259517,
            'section-name': null,
            'menu-group-id': 95,
            data: {
              color: '#000000',
              link: '/culture'
            },
            children: [],
            completeUrl: '/culture',
            isExternalLink: true
          },
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': null,
            rank: 2280,
            title: 'News',
            'item-type': 'link',
            'section-slug': null,
            'tag-slug': null,
            id: 2243,
            'parent-id': 2242,
            url: '/culture',
            'created-at': 1544761259517,
            'section-name': null,
            'menu-group-id': 95,
            data: {
              color: '#000000',
              link: '/culture'
            },
            children: [],
            completeUrl: '/culture',
            isExternalLink: true
          }
        ],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1551076918341,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'footer-site-links',
        'item-id': 6392,
        rank: 39,
        title: 'News',
        'item-type': 'section',
        'section-slug': 'mobile',
        'tag-slug': null,
        id: 2240,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/mobile',
        'created-at': 1544608844876,
        'section-name': 'Mobile',
        'menu-group-id': 95,
        data: {
          color: '#000000'
        },
        children: [
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': null,
            rank: 2280,
            title: 'Political News',
            'item-type': 'link',
            'section-slug': null,
            'tag-slug': null,
            id: 2253,
            'parent-id': 2240,
            url: '/culture',
            'created-at': 1544761259517,
            'section-name': null,
            'menu-group-id': 95,
            data: {
              color: '#000000',
              link: '/culture'
            },
            children: [],
            completeUrl: '/culture',
            isExternalLink: true
          },
          {
            'updated-at': 1549626535434,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 6391,
            rank: 2680,
            title: 'Tech News',
            'item-type': 'section',
            'section-slug': 'tech',
            'tag-slug': null,
            id: 2680,
            'parent-id': 2240,
            url: 'https://madrid.quintype.io/section/tech',
            'created-at': 1549272200841,
            'section-name': 'Tech',
            'menu-group-id': 97,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/tech'
          },
          {
            'updated-at': 1549626535434,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 6391,
            rank: 2680,
            title: 'Business News',
            'item-type': 'section',
            'section-slug': 'tech',
            'tag-slug': null,
            id: 2760,
            'parent-id': 2240,
            url: 'https://madrid.quintype.io/section/tech',
            'created-at': 1549272200841,
            'section-name': 'Tech',
            'menu-group-id': 97,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/tech'
          },
          {
            'updated-at': 1549626535434,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 6391,
            rank: 2680,
            title: 'Market News',
            'item-type': 'section',
            'section-slug': 'tech',
            'tag-slug': null,
            id: 2670,
            'parent-id': 2240,
            url: 'https://madrid.quintype.io/section/tech',
            'created-at': 1549272200841,
            'section-name': 'Tech',
            'menu-group-id': 97,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/tech'
          },
          {
            'updated-at': 1549626535434,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 6391,
            rank: 2680,
            title: 'GST News',
            'item-type': 'section',
            'section-slug': 'tech',
            'tag-slug': null,
            id: 2470,
            'parent-id': 2240,
            url: 'https://madrid.quintype.io/section/tech',
            'created-at': 1549272200841,
            'section-name': 'Tech',
            'menu-group-id': 97,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/tech'
          }
        ],
        completeUrl: '/tech/mobile'
      },
      {
        'updated-at': 1551076908349,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'footer-site-links',
        'item-id': 142,
        rank: 38,
        title: 'Entertainment',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2242,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1544609477203,
        'section-name': 'Culture',
        'menu-group-id': 95,
        data: {
          color: '#DCFF00'
        },
        children: [
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 4272,
            rank: 2243,
            title: 'Bollywood',
            'item-type': 'section',
            'section-slug': 'opinion',
            'tag-slug': null,
            id: 38,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/opinion',
            'created-at': 1441792623112,
            'section-name': 'Opinion',
            'menu-group-id': 95,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/#'
          },
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 4272,
            rank: 2243,
            title: 'Movie reviews',
            'item-type': 'section',
            'section-slug': 'opinion',
            'tag-slug': null,
            id: 39,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/opinion',
            'created-at': 1441792623112,
            'section-name': 'Opinion',
            'menu-group-id': 95,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/#'
          },
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 4272,
            rank: 2243,
            title: 'Indian Cinema',
            'item-type': 'section',
            'section-slug': 'opinion',
            'tag-slug': null,
            id: 40,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/opinion',
            'created-at': 1441792623112,
            'section-name': 'Opinion',
            'menu-group-id': 95,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/#'
          },
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 4272,
            rank: 2243,
            title: 'Music',
            'item-type': 'section',
            'section-slug': 'opinion',
            'tag-slug': null,
            id: 41,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/opinion',
            'created-at': 1441792623112,
            'section-name': 'Opinion',
            'menu-group-id': 95,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/#'
          },
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 4272,
            rank: 2243,
            title: 'Television',
            'item-type': 'section',
            'section-slug': 'opinion',
            'tag-slug': null,
            id: 42,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/opinion',
            'created-at': 1441792623112,
            'section-name': 'Opinion',
            'menu-group-id': 95,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/#'
          }
        ],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1551076908349,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'footer-site-links',
        'item-id': 142,
        rank: 38,
        title: 'Sports',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2242,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1544609477203,
        'section-name': 'Culture',
        'menu-group-id': 95,
        data: {
          color: '#DCFF00'
        },
        children: [
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 4272,
            rank: 2243,
            title: 'Football',
            'item-type': 'section',
            'section-slug': 'opinion',
            'tag-slug': null,
            id: 43,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/opinion',
            'created-at': 1441792623112,
            'section-name': 'Opinion',
            'menu-group-id': 95,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/#'
          },
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 4272,
            rank: 2243,
            title: 'Cricket',
            'item-type': 'section',
            'section-slug': 'opinion',
            'tag-slug': null,
            id: 44,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/opinion',
            'created-at': 1441792623112,
            'section-name': 'Opinion',
            'menu-group-id': 95,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/#'
          },
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 4272,
            rank: 2243,
            title: 'Tennis',
            'item-type': 'section',
            'section-slug': 'opinion',
            'tag-slug': null,
            id: 45,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/opinion',
            'created-at': 1441792623112,
            'section-name': 'Opinion',
            'menu-group-id': 95,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/#'
          },
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 4272,
            rank: 2243,
            title: 'Hockey',
            'item-type': 'section',
            'section-slug': 'opinion',
            'tag-slug': null,
            id: 46,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/opinion',
            'created-at': 1441792623112,
            'section-name': 'Opinion',
            'menu-group-id': 95,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/#'
          },
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 4272,
            rank: 2243,
            title: 'Badminton',
            'item-type': 'section',
            'section-slug': 'opinion',
            'tag-slug': null,
            id: 47,
            'parent-id': 2242,
            url: 'https://madrid.quintype.io/section/opinion',
            'created-at': 1441792623112,
            'section-name': 'Opinion',
            'menu-group-id': 95,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/#'
          }
        ],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1551076918341,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'footer-site-links',
        'item-id': 6392,
        rank: 39,
        title: 'Science',
        'item-type': 'section',
        'section-slug': 'mobile',
        'tag-slug': null,
        id: 2240,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/mobile',
        'created-at': 1544608844876,
        'section-name': 'Mobile',
        'menu-group-id': 95,
        data: {
          color: '#000000'
        },
        children: [
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': null,
            rank: 2280,
            title: 'Natural Science',
            'item-type': 'link',
            'section-slug': null,
            'tag-slug': null,
            id: 2253,
            'parent-id': 2240,
            url: '/culture',
            'created-at': 1544761259517,
            'section-name': null,
            'menu-group-id': 95,
            data: {
              color: '#000000',
              link: '/culture'
            },
            children: [],
            completeUrl: '/culture',
            isExternalLink: true
          },
          {
            'updated-at': 1549626535434,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 6391,
            rank: 2680,
            title: 'Social Science',
            'item-type': 'section',
            'section-slug': 'tech',
            'tag-slug': null,
            id: 2680,
            'parent-id': 2240,
            url: 'https://madrid.quintype.io/section/tech',
            'created-at': 1549272200841,
            'section-name': 'Tech',
            'menu-group-id': 97,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/tech'
          },
          {
            'updated-at': 1549626535434,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 6391,
            rank: 2680,
            title: 'Formal Science',
            'item-type': 'section',
            'section-slug': 'tech',
            'tag-slug': null,
            id: 4350,
            'parent-id': 2240,
            url: 'https://madrid.quintype.io/section/tech',
            'created-at': 1549272200841,
            'section-name': 'Tech',
            'menu-group-id': 97,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/tech'
          },
          {
            'updated-at': 1549626535434,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 6391,
            rank: 2680,
            title: 'Women in Science',
            'item-type': 'section',
            'section-slug': 'tech',
            'tag-slug': null,
            id: 4351,
            'parent-id': 2240,
            url: 'https://madrid.quintype.io/section/tech',
            'created-at': 1549272200841,
            'section-name': 'Tech',
            'menu-group-id': 97,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/tech'
          },
          {
            'updated-at': 1549626535434,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer-site-links',
            'item-id': 6391,
            rank: 2680,
            title: 'Funding Of Science',
            'item-type': 'section',
            'section-slug': 'tech',
            'tag-slug': null,
            id: 4352,
            'parent-id': 2240,
            url: 'https://madrid.quintype.io/section/tech',
            'created-at': 1549272200841,
            'section-name': 'Tech',
            'menu-group-id': 97,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/tech'
          }
        ],
        completeUrl: '/tech/mobile'
      },
      {
        'updated-at': 1551076908349,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'default',
        'item-id': 142,
        rank: 38,
        title: 'Sports',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2242,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1544609477203,
        'section-name': 'Culture',
        'menu-group-id': 95,
        data: {
          color: '#DCFF00'
        },
        children: [],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1551076908349,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'secondary-menu',
        'item-id': 142,
        rank: 38,
        title: 'US Edition',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2242,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1544609477203,
        'section-name': 'Culture',
        'menu-group-id': 95,
        data: {
          color: '#DCFF00'
        },
        children: [],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1551076908349,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'secondary-menu',
        'item-id': 142,
        rank: 38,
        title: 'UK Edition',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2242,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1544609477203,
        'section-name': 'Culture',
        'menu-group-id': 95,
        data: {
          color: '#DCFF00'
        },
        children: [],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1551076908349,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'secondary-menu',
        'item-id': 142,
        rank: 38,
        title: 'International',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2242,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1544609477203,
        'section-name': 'Culture',
        'menu-group-id': 95,
        data: {
          color: '#DCFF00'
        },
        children: [],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1551076908349,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'secondary-menu',
        'item-id': 142,
        rank: 38,
        title: 'India',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2242,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1544609477203,
        'section-name': 'Culture',
        'menu-group-id': 95,
        data: {
          color: '#DCFF00'
        },
        children: [],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1551076918341,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'default',
        'item-id': 6392,
        rank: 39,
        title: 'Technology',
        'item-type': 'section',
        'section-slug': 'mobile',
        'tag-slug': null,
        id: 2240,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/mobile',
        'created-at': 1544608844876,
        'section-name': 'Mobile',
        'menu-group-id': 95,
        data: {
          color: '#000000'
        },
        children: [
          {
            'updated-at': 1549443388630,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'default',
            'item-id': null,
            rank: 2280,
            title: 'Culture',
            'item-type': 'link',
            'section-slug': null,
            'tag-slug': null,
            id: 2253,
            'parent-id': 2240,
            url: '/culture',
            'created-at': 1544761259517,
            'section-name': null,
            'menu-group-id': 95,
            data: {
              color: '#000000',
              link: '/culture'
            },
            children: [],
            completeUrl: '/culture',
            isExternalLink: true
          },
          {
            'updated-at': 1549626535434,
            'tag-name': null,
            'publisher-id': 15,
            'menu-group-slug': 'footer',
            'item-id': 6391,
            rank: 2680,
            title: 'Tech',
            'item-type': 'section',
            'section-slug': 'tech',
            'tag-slug': null,
            id: 2680,
            'parent-id': 2240,
            url: 'https://madrid.quintype.io/section/tech',
            'created-at': 1549272200841,
            'section-name': 'Tech',
            'menu-group-id': 97,
            data: {
              color: '#000000'
            },
            children: [],
            completeUrl: '/tech'
          }
        ],
        completeUrl: '/tech/mobile'
      },
      {
        'updated-at': 1549443388633,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'secondary-menu',
        'item-id': null,
        rank: 40,
        title: 'Sign Up For a Trial',
        'item-type': 'link',
        'section-slug': null,
        'tag-slug': null,
        id: 2280,
        'parent-id': null,
        url: 'https://www.quintype.com/sign-up',
        'created-at': 1545215767970,
        'section-name': null,
        'menu-group-id': 96,
        data: {
          color: '#000000',
          link: 'https://www.quintype.com/sign-up'
        },
        children: [],
        completeUrl: 'https://www.quintype.com/sign-up',
        isExternalLink: true
      },
      {
        'updated-at': 1549443388630,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'default',
        'item-id': 4272,
        rank: 2243,
        title: 'Lifestyle',
        'item-type': 'section',
        'section-slug': 'opinion',
        'tag-slug': null,
        id: 38,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/opinion',
        'created-at': 1441792623112,
        'section-name': 'Opinion',
        'menu-group-id': 95,
        data: {
          color: '#000000'
        },
        children: [],
        completeUrl: '/#'
      },
      {
        'updated-at': 1549443388630,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'default',
        'item-id': 5113,
        rank: 2253,
        title: 'Opinion',
        'item-type': 'section',
        'section-slug': 'eco-stories',
        'tag-slug': null,
        id: 2243,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/eco-stories',
        'created-at': 1544609755177,
        'section-name': 'Eco Stories',
        'menu-group-id': 95,
        data: {
          color: '#000000'
        },
        children: [],
        completeUrl: '/#'
      },
      {
        'updated-at': 1549443388635,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'footer',
        'item-id': 142,
        rank: 2679,
        title: 'About Us',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2679,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1549272187960,
        'section-name': 'Culture',
        'menu-group-id': 97,
        data: {
          color: '#000000'
        },
        children: [],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1549443388635,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'footer',
        'item-id': 142,
        rank: 2679,
        title: 'Terms and Conditions',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2680,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1549272187960,
        'section-name': 'Culture',
        'menu-group-id': 97,
        data: {
          color: '#000000'
        },
        children: [],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1549443388635,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'footer',
        'item-id': 142,
        rank: 2679,
        title: 'Privacy Policy',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2679,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1549272187960,
        'section-name': 'Culture',
        'menu-group-id': 97,
        data: {
          color: '#000000'
        },
        children: [],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1549443388635,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'footer',
        'item-id': 142,
        rank: 2679,
        title: 'Contact Us',
        'item-type': 'section',
        'section-slug': 'culture',
        'tag-slug': null,
        id: 2679,
        'parent-id': null,
        url: 'https://madrid.quintype.io/section/culture',
        'created-at': 1549272187960,
        'section-name': 'Culture',
        'menu-group-id': 97,
        data: {
          color: '#000000'
        },
        children: [],
        completeUrl: '/culture'
      },
      {
        'updated-at': 1550481280513,
        'tag-name': null,
        'publisher-id': 15,
        'menu-group-slug': 'default',
        'item-id': null,
        rank: 2794,
        title: 'Culture',
        'item-type': 'placeholder',
        'section-slug': null,
        'tag-slug': null,
        id: 2794,
        'parent-id': null,
        url: null,
        'created-at': 1550481280513,
        'section-name': null,
        'menu-group-id': 95,
        data: {
          color: '#000000'
        },
        children: [],
        completeUrl: '/#'
      }
    ]
  },
  config: {
    'cdn-image': 'thumbor-stg.assettype.com',
    'sketches-host': 'https://madrid.quintype.io',
    'polltype-host': 'https://api.polltype.com',
    'publisher-name': 'quintype-demo',
    'publisher-id': 15,
    language: {
      direction: 'rtl',
      'ietf-code': 'en-in',
      'iso-code': 'en',
      name: 'english'
    },
    env: 'production',
    'publisher-settings': {
      title: 'Quintype Demo',
      description:
        'Sign in to access your account and enjoy a personalized experience. Stay connected with our services and explore a range of features tailored to your needs.',
      copyright: 'Quintype technologies - 2018'
    },
    layout: {
      menu: [
        {
          'updated-at': 1568631627577,
          'tag-name': null,
          'publisher-id': 97,
          'menu-group-slug': 'sidebar-menu',
          'item-id': null,
          rank: 822,
          title: 'Times',
          'item-type': 'link',
          'section-slug': null,
          'tag-slug': null,
          id: 3405,
          'parent-id': null,
          url: 'http://transportandlogisticsme.com/',
          'created-at': 1538040312837,
          'section-name': null,
          'menu-group-id': 42,
          data: {
            color: '#000000',
            link: 'http://transportandlogisticsme.com/'
          }
        }
      ]
    },
    'social-links': {
      'facebook-url': 'https://www.facebook.com/QuintypeInc/',
      'instagram-url': 'https://www.instagram.com/quintypestories',
      'linkedin-url': 'https://www.linkedin.com/company/quintype/',
      'youtube-url': 'https://www.youtube.com/watch?v=QK8Se8CvyHc',
      'whatsapp-url': 'test',
      'twitter-url': 'https://twitter.com/home?lang=en',
      'pinterest-url': 'https://twitter.com/home?lang=en'
    },
    sections: [
      {
        slug: 'politics',
        name: 'Politics',
        id: 83,
        'parent-id': null,
        'display-name': 'Politics & More',
        collection: {
          slug: 'the-quint',
          name: 'Most Popular',
          id: 4364
        },
        data: null
      },
      {
        slug: 'technology',
        name: 'Technology',
        id: 84,
        'parent-id': null,
        'display-name': null,
        collection: null,
        data: null
      },
      {
        slug: 'culture',
        name: 'Culture',
        id: 142,
        'parent-id': null,
        'display-name': 'culture',
        collection: {
          slug: 'motherly',
          name: 'Must Reads',
          id: 6626
        },
        data: null
      },
      {
        slug: 'visual-stories',
        name: 'Visual Stories',
        id: 6069,
        'parent-id': null,
        'display-name': 'Visual Stories',
        collection: {
          slug: 'visual-stories',
          name: 'Visual Stories',
          id: 12476
        },
        data: null
      },
      {
        slug: 'tech',
        name: 'Tech',
        id: 6391,
        'parent-id': null,
        'display-name': 'Tech',
        collection: {
          slug: 'tech',
          name: 'Tech',
          id: 12849
        },
        data: null
      },
      {
        slug: 'mobile',
        name: 'Mobile',
        id: 6392,
        'parent-id': 6391,
        'display-name': 'Mobile',
        collection: {
          slug: 'mobile-tech',
          name: 'Mobile (Tech)',
          id: 12850
        },
        data: null
      },
      {
        slug: 'new-section',
        name: 'New section',
        id: 6932,
        'parent-id': null,
        'display-name': 'New Section',
        collection: {
          slug: 'new-section',
          name: 'New section',
          id: 13666
        },
        data: null
      },
      {
        slug: 'boom',
        name: 'Boom',
        id: 7349,
        'parent-id': 83,
        'display-name': 'Booom',
        collection: {
          slug: 'boom-politics',
          name: 'Boom (Politics)',
          id: 14256
        },
        data: null
      },
      {
        slug: 'mobile',
        name: 'Mobile',
        id: 7540,
        'parent-id': null,
        'display-name': 'Mobile',
        collection: {
          slug: 'mobile',
          name: 'Mobile',
          id: 14521
        },
        data: null
      },
      {
        slug: 'state',
        name: 'State',
        id: 7541,
        'parent-id': null,
        'display-name': 'State',
        collection: {
          slug: 'state',
          name: 'State',
          id: 14522
        },
        data: null
      }
    ],
    'public-integrations': {
      facebook: {
        'app-id': '203980120509614'
      },
      linkedin: {
        'app-id': '8'
      },
      twitter: {
        'app-id': 'vw4vBWhg7DeSJE5gbgLzH5meA'
      },
      google: {
        'app-id': '568104192219-fo3pplg00om7824fshreh5e3rafpevfl.apps.googleusercontent.com'
      }
    },
    'disable-ads': false,
    'theme-attributes': {
      enableAccesstype: false,
      'footer-quintype-link': 'true',
      secondary_color: '#a3a3a3',
      accesstypeKey: 'pxXGbnvK7ie9FJigx4LkvPhH',
      disableMetering: 'true',
      amp_enabled: true,
      'row-title-style': 'default',
      'social-share-style': 'vertical-color',
      pageBuilderConfig: {
        amazonPolly: true,
        header: {
          socialFollowStyle: 'square-svg',
          enableSSO: false,
          enableAccesstype: false
        },
        footer: {
          socialFollowStyle: 'square-svg',
          enableAccesstype: false
        },
        general: {
          rows: {
            titleStyle: {
              type: 'style-1',
              underLineColor: '#4860bc'
            }
          },
          fonts: {
            primaryFont: 'sans-serif',
            secondaryFont: 'sans-serif'
          },
          tags: {
            allPages: {
              insertToHead: '',
              insertToBody: ''
            }
          },
          appDownloadLinks: {
            appStoreDownloadLink: '',
            googlePlayDownloadLink: ''
          },
          meType: {
            enableLiveFeed: false,
            accountId: '',
            host: '',
            primaryColor: '',
            backgroundColor: '',
            fontColor: '',
            className: ''
          },
          facebook: {
            commentPluginType: '',
            hideComments: false
          }
        },
        story: {
          authorStyle: ''
        },
        breakingNews: {
          backgroundColor: '',
          template: ''
        },
        infiniteScroll: {
          infiniteScrollType: '',
          infiniteScrollStyle: ''
        }
      },
      secondary_header_text_color: '#333333',
      logo: 'https://thumbor-stg.assettype.com/ace%2F2020-01%2Fa312679c-5825-4ec5-bb08-54b73e5e2bda%2Flogo_copy.svg',
      header_text_color: '#2c61ad',
      header_background_color: '#e6e6e6',
      footer_background_color: '#1d65a7',
      appStoreDownloadLink: 'https://www.apple.com/ios/app-store/',
      googlePlayDownloadLink: 'https://play.google.com/store?hl=en',
      secondary_logo:
        'https://thumbor-stg.assettype.com/ace%2F2020-01%2Fa312679c-5825-4ec5-bb08-54b73e5e2bda%2Flogo_copy.svg',
      primary_header_background_color: '#ffffff',
      header_icon_color: '#2c61ad',
      lang: 'en',
      gtm_id: 'GTM-MDQX7SX',
      secondary_header_background_color: '#3cab92',
      'text-story': 'default',
      monogram:
        'https://thumbor-stg.assettype.com/ace%2F2020-01%2F3d9ef23e-cc7a-43cb-b587-ff164cd92536%2Fahead_logo_3.svg',
      'cache-burst': 1552376931518,
      primary_header_text_color: '#ffffff',
      footer_text_color: '#ffffff',
      header_theme: 'header_2',
      'breaking-news-config': {
        template: 'template1',
        delimiter: 'pipe',
        'bg-color': '#424242'
      },
      'google-site-verification': 'testinggoogle',
      structured_data_news_article: 'true',
      enableSSO: false,
      menu_count: '6',
      manifest_logo:
        'https://images.assettype.com/quintype-demo/2018-05/5e7d4805-9a5d-4cf9-871b-b4c3fa03fe6c/Quintype_Logo.png',
      'infinite-scroll': 'collection-based',
      primary_color: '#1d65a7',
      domains: {
        'madrid-demo1.quintype.io': {
          primary_color: '#2f73e4',
          header_theme: 'navigation-ramos',
          hide_breaking_news: true,
          override_layouts: [
            {
              layout: 'BleedOutCarousel'
            },
            {
              layout: 'FourColGrid',
              limit_stories: 4,
              theme: 'dark'
            },
            {
              layout: 'FourColGrid',
              limit_stories: 4,
              theme: 'light',
              hide_section: true,
              show_collection_name: true
            }
          ]
        }
      }
    }
  },
  appVersion: 12,
  title: 'Quintype Demo Homepage'
};
