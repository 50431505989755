import { FieldLabel } from '@quintype/em/components/field-label';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updateSectionTag } from '../../actions/builder-action';
import { ThemeSetter } from '../theme-setter';
import sectionTagData from './section-tag-data';
import styles from './section-tag.module.css';

export const SectionTag = () => {
  const dispatch = useDispatch();
  const [selectedStateIndex, updateSelectedIndex] = useState(-1);

  const sectionTagHandler = (name: string, index: number) => {
    updateSelectedIndex(index);
    dispatch(updateSectionTag({ template: name }));
    dispatch(updateHaveUnsavedChanges(true));
  };
  const handleSectionTagColorPicker = (key: string, payload: string) => {
    dispatch(updateSectionTag({ [key]: payload }));
    dispatch(updateHaveUnsavedChanges(true));
  };
  const { enableDarkModePreview = false } = useSelector(state => get(state, ['builder', 'config'], {}));
  const { darkColor: darkBorderColor, color: borderColor } = useSelector(state =>
    get(state, ['builder', 'config', 'general', 'rows', 'sectionTag'], '')
  );

  return (
    <>
      <FieldLabel label="Choose Section Tag Style" classname={styles.label} />
      <div className={styles.sectionTagWrapper}>
        {sectionTagData.map((item: { name: string; svg: string }, index: number) => (
          <div
            key={index}
            className={selectedStateIndex === index ? styles.selectedImgWrapper : styles.imageWrapper}
            onClick={() => sectionTagHandler(item.name, index)}
          >
            <img src={item.svg} className={styles.image} alt={item.name} />
          </div>
        ))}
      </div>
      <div>
        <ThemeSetter
          label="Choose color"
          updateTheStore={(key: string, color: string) => handleSectionTagColorPicker(key, color)}
          lightColorExtract={borderColor}
          lightUpdateStoreKey="color"
          darkColorExtract={darkBorderColor}
          darkUpdateStoreKey="darkColor"
          lightDefaultColor="#3cab92"
          darkDefaultColor="#3cab92"
          enableDarkModePreview={enableDarkModePreview}
        />
      </div>
    </>
  );
};
