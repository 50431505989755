import axios from 'axios';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import aheadLogo from '../../atoms/ahead-logo/ahead-logo.svg';
import { useQuery } from '../../hooks/useQuery';
import { BASE_API_URL, getRouteData, redirectToSSOLogin } from '../../utils/utils';
import styles from './sso-components.module.css';

export const SSOLogin = () => {
  const [routeData, setRouteData] = useState(null);
  const [requestStatus, setRequestStatus] = useState('loading');

  const onClick = () => {
    const integrationId = get(routeData, ['bridgekeeperIntegrationId']);
    const redirectUri = `${window.location.origin}/user/signup`;
    if (integrationId) {
      redirectToSSOLogin(integrationId, redirectUri, null, null);
    }
  };

  const renderSignInButton = () => {
    switch (requestStatus) {
      case 'loading':
        return <p className={styles.description}>Loading...</p>;
      case 'invalid':
      case 'error':
        return 'Invalid URL, please contact support';
      default:
        if (routeData && get(routeData, ['bridgekeeperIntegrationId'])) {
          return (
            <>
              <p className={styles.description}>Sign in to your Pagebuilder account to access the dashboard.</p>
              <button className={styles.signInButton} onClick={onClick}>
                SIGN IN
              </button>
            </>
          );
        }
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const routeDataResponse = await getRouteData('/login');
        setRouteData(routeDataResponse);
        if (!get(routeDataResponse, ['bridgekeeperIntegrationId'])) {
          setRequestStatus('invalid');
          return;
        }
        setRequestStatus('success');
      } catch (err) {
        setRequestStatus('error');
      }
    };
    if (!routeData) {
      getData();
    }
  });

  return (
    <div className={styles.ssoContainer}>
      <div className={styles.logoWrapper}>
        <img src={aheadLogo} alt="ahead-logo" className={styles.logo} />
        <span className={styles.title}>PAGEBUILDER</span>
      </div>
      <div className={styles.contentWrapper}>
        <h2 className={styles.pageHeader}>Page Builder</h2>
        {renderSignInButton()}
      </div>
    </div>
  );
};

export const UserSignup = ({ history }: any) => {
  const queryParams = useQuery();
  const code = queryParams.get('code');
  const [userStatus, setUserStatus] = useState(null);

  const onClick = async () => {
    await fetch('/logout');
    history.push('/login');
  };

  useEffect(() => {
    const getUserStatus = async () => {
      const signupReq = await axios.post(`${BASE_API_URL}/signup`, { code });
      setUserStatus(signupReq.data);
    };
    !userStatus && getUserStatus();

    if (userStatus && get(userStatus, ['status']) === 'success') {
      history.push(`/accounts/${get(userStatus, ['publisherId'])}/${get(userStatus, ['publisherName'])}`);
    }
  });
  return (
    <div className={styles.ssoContainer}>
      <div className={styles.logoWrapper}>
        <img src={aheadLogo} alt="ahead-logo" className={styles.logo} />
        <span className={styles.title}>PAGEBUILDER</span>
      </div>
      <div className={styles.contentWrapper}>
        <h2 className={styles.pageHeader}>Page Builder</h2>
        <p className={styles.description}>Signing in, {get(userStatus, ['email'])}</p>
        <p className={styles.description}>{get(userStatus, ['message'])}</p>
        {get(userStatus, ['status']) === 'unassigned' && (
          <button className={styles.signInButton} onClick={onClick}>
            TRY ANOTHER ACCOUNT
          </button>
        )}
      </div>
    </div>
  );
};
