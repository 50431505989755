import { Button } from '@quintype/em/components/button';
import React from 'react';
import { Close } from '../../atoms/close-svg';
import styles from './confirmation-popup.module.css';

interface IConfirmationPopupProps {
  onCancel: () => void;
  onDiscard: () => void;
  onSave: () => void;
}

export const ConfirmationPopup = ({ onCancel, onDiscard, onSave }: IConfirmationPopupProps) => {
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupWrapper}>
        <div className={styles.closeButton} onClick={onCancel}>
          <Close />
        </div>
        <div className={styles.heading}>Do you want to discard your changes?</div>
        <div className={styles.subheading}>
          You've made changes that haven't been saved yet. Please confirm if you'd like to discard your changes.
        </div>
        <div className={styles.btnWrapper}>
          <Button onClick={onDiscard}>Discard & Continue</Button>
          <Button type="primary" onClick={() => onSave()}>
            Save & Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
