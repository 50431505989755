import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFooterThemeAttributes } from '../../../actions/footer-action';
import { defaultDarkTheme, defaultTheme } from '../../../constant';
import { ThemeSetter } from '../../../molecules/theme-setter';
import { IFooterConfig, IPBState } from '../../../utils/interfaces';

export const ColorPickerFooter = () => {
  const { footer, enableDarkModePreview = false } = useSelector((state: IPBState) => get(state, ['builder', 'config']));
  const { textColor = '', backgroundColor = '', darkBackgroundColor = '', darkTextColor = '' }: IFooterConfig = footer;
  const dispatch = useDispatch();
  const updateFooterColors = (key: string, value: string) => dispatch(updateFooterThemeAttributes({ [key]: value }));

  return (
    <>
      <ThemeSetter
        label="Background color"
        updateTheStore={updateFooterColors}
        lightColorExtract={backgroundColor}
        lightUpdateStoreKey="backgroundColor"
        darkColorExtract={darkBackgroundColor}
        darkUpdateStoreKey="darkBackgroundColor"
        lightDefaultColor="#3cab92"
        darkDefaultColor={defaultDarkTheme}
        enableDarkModePreview={enableDarkModePreview}
      />
      <ThemeSetter
        label="Text color"
        updateTheStore={updateFooterColors}
        lightColorExtract={textColor}
        lightUpdateStoreKey="textColor"
        darkColorExtract={darkTextColor}
        darkUpdateStoreKey="darkTextColor"
        lightDefaultColor={defaultTheme}
        darkDefaultColor={defaultTheme}
        enableDarkModePreview={enableDarkModePreview}
      />
    </>
  );
};
