import { Accordion, AccordionSection } from '@quintype/em/components/accordion';
import { Button } from '@quintype/em/components/button';
import { Dialog } from '@quintype/em/components/dialog';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { updateHaveLayoutUpdates, updatePageDetails, updatePageSettings } from '../../actions/builder-action';
import { StoryPageSettings } from '../../containers/story/StoryPageSettings';
import PBInspector from '../../molecules/inspector';
import { getLandingPage } from '../../utils/getLandingPageName';
import { getConfiguredDomains } from '../../utils/utils';
import Importer from '../importer-inspector';
import { PageDetails } from '../landing-page-details';
import { LoginSettings } from '../login/settings';
import { ManageSubscriptionSettings } from '../manage-subscription-and-plan/settings';
import SelectPageLayoutType from '../page-layout';
import { PaywallSettings } from '../pay-wall-banner/settings';
import { ProfilePageSettings } from '../profile-page/settings';
import { PurchasedStorySettings } from '../purchased-story-page/settings';
import { Sidebar } from '../sidebar';
import { SubscriptionSettings } from '../subscription/settings';
import { ColorPickerPageBackgroundColor } from './color-picker-theme-panel';
import styles from './header-dropdown.module.css';
import SelectSectionTheme from './select-section-theme';

interface IProps {
  handleDropdown: () => void;
}
const HeaderDropdown = (props: IProps) => {
  const { pageType, pageId, haveUnsavedChanges = false, subPageType, config = {} } = useSelector(state =>
    get(state, ['builder'], {})
  );

  const { layouts = {} } = get(config, ['landingPage'], {});
  const sectionSelectedOptions = get(config, ['section', 'pageSetting', 'selectedOptions'], []);
  const [isInspector, isInspectorOpen] = useState(null);
  const [showDialog, updateShowDialogStatus] = useState(false);
  const [configuredDomains, setConfiguredDomains] = useState([]);

  const dispatch = useDispatch();
  const { publisherId = '', domain = '' } = useParams();
  const history = useHistory();
  const openSideBar = (event: any, index: any) => {
    const setIndex = isInspector === index ? null : index;
    event.stopPropagation();
    event.preventDefault();
    isInspectorOpen(setIndex);
  };

  useEffect(() => {
    (async () => {
      const { data } = await getConfiguredDomains(publisherId);
      if (data.domains) {
        setConfiguredDomains(data.domains);
      }
    })();
  }, [publisherId]);

  const clickHandler = async () => {
    props.handleDropdown();
    isInspectorOpen(null);
  };

  const [isAccordionActive, updateAccordionActive] = useState(false);

  const onClickHandler = () => {
    updateAccordionActive(!isAccordionActive);
  };

  const landingPageObj = getLandingPage(layouts, pageId);
  const landingPageName = get(landingPageObj, [1, 'layoutName']);
  const { selectedOptions = [], shouldAssignToAllSections } = get(landingPageObj, [1, 'pageSetting'], {});
  const getSelectedOptions = () => {
    const options = [...sectionSelectedOptions, ...selectedOptions];
    return options.filter((option, index) => options.indexOf(option) === index);
  };
  const dialogActionHandler = () => {
    dispatch(updatePageDetails({ isDeleted: true }));
    dispatch(updatePageSettings({ selectedOptions: getSelectedOptions(), shouldAssignToAllSections }, 'section'));
    updateShowDialogStatus(false);
    dispatch(updateHaveLayoutUpdates(true));
    setTimeout(() => {
      history.push(`/accounts/${publisherId}/${domain}/section`);
    }, 1000);
  };

  const getPageSetting = (pageType: string) => {
    const getPageName = () => {
      switch (pageType) {
        case 'section':
          return 'Section Page';
        case 'tag':
          return 'Tag Page';
        case 'author':
          return 'Author Page';
        case 'authors':
          return 'Authors Listing Page';
        case 'magazine-landing':
          return 'Magazine Landing Page';
        case 'issue-landing':
          return 'Magazine Issue Page';
        case 'archive':
          return 'Magazine Archive Page';
        case 'landingPage':
          return `${landingPageName}`;
        case 'story':
          return 'Story Page General';
        case 'profile-page':
          return 'Profile Page';
        case 'purchased-story-page':
          return 'Purchased Stories Page';
        case 'subscription':
          return 'Subscription Page';
        case 'manage-subscriptions':
          return 'Manage Subscriptions';
        case 'available-plans':
          return 'Manage Subscriptions';
        case 'plan-change-overview':
          return 'Manage Subscriptions';
        case 'sign-in':
        case 'sign-up':
        case 'forgot-password':
        case 'email-otp':
        case 'mobile-otp':
        case 'reset-password':
        case 'enter-password':
          return 'Sign in Page';
        default:
          return 'Home Page';
      }
    };
    return getPageName() + ' Settings';
  };

  const generalSettingsDropdown = [
    {
      name: 'General Settings',
      component: <Sidebar title="General Settings" isActive={true} onCloseHandler={clickHandler} />
    }
  ];

  const getSettings = () => {
    switch (pageType) {
      case 'landingPage':
        return (
          <>
            <AccordionSection label="Page Details" isLabelUpperCase index={0}>
              <PageDetails />
            </AccordionSection>
            <AccordionSection label="Page Background Color" isLabelUpperCase index={1}>
              <ColorPickerPageBackgroundColor />
            </AccordionSection>
            <AccordionSection label="Page Type" isLabelUpperCase index={2}>
              <SelectPageLayoutType />
            </AccordionSection>
            <AccordionSection label="Assign Template To" isLabelUpperCase index={3}>
              <SelectSectionTheme />
            </AccordionSection>
          </>
        );
      case 'section':
        return (
          <>
            <AccordionSection label="Page Background Color" isLabelUpperCase index={0}>
              <ColorPickerPageBackgroundColor />
            </AccordionSection>
            <AccordionSection label="Page Type" isLabelUpperCase index={1}>
              <SelectPageLayoutType />
            </AccordionSection>
            <AccordionSection label="Assign Template To" isLabelUpperCase index={2}>
              <SelectSectionTheme />
            </AccordionSection>
          </>
        );
      case 'subscription':
        return <SubscriptionSettings />;
      case 'story':
        return <StoryPageSettings />;
      case 'sign-in':
      case 'sign-up':
      case 'forgot-password':
      case 'email-otp':
      case 'mobile-otp':
      case 'reset-password':
      case 'enter-password':
        return <LoginSettings />;
      case 'manage-subscriptions':
        return <ManageSubscriptionSettings />;
      case 'profile-page':
        return <ProfilePageSettings />;
      case 'purchased-story-page':
        return <PurchasedStorySettings />;
      default:
        return (
          <AccordionSection label="Page Background Color" isLabelUpperCase index={0}>
            <ColorPickerPageBackgroundColor />
          </AccordionSection>
        );
    }
  };

  const getDeleteTemplate = () => {
    return (
      pageType === 'landingPage' && (
        <div className={styles.deleteWrapper}>
          <p className={styles.deleteLabel}>Delete Template</p>
          <p>This page template will be deleted. Assigned pages will reset to default template</p>
          <Button type="primary" variant="danger" onClick={() => updateShowDialogStatus(true)}>
            Delete {landingPageName}
          </Button>
          <Dialog
            isOpen={showDialog}
            onClose={() => {
              updateShowDialogStatus(false);
              console.log('close action');
            }}
            onAction={dialogActionHandler}
            actionButtonLabel="Delete"
          >
            <h3>{landingPageName}</h3>
            <p>
              Are you sure you want to delete landing page template `{landingPageName}`. All the assigned pages will be
              redirected to default template
            </p>
          </Dialog>
        </div>
      )
    );
  };

  const customSettingsDropdown = [
    {
      name: 'General Settings',
      component: <Sidebar title="General Settings" isActive={true} onCloseHandler={clickHandler} />
    },
    {
      name: getPageSetting(pageType),
      component: (
        <PBInspector
          title={getPageSetting(pageType)}
          isActive={true}
          onActionButtonClick={clickHandler}
          onClose={clickHandler}
          isActionButtonDisabled={!haveUnsavedChanges}
        >
          <Accordion hasSingleActivePanel onToggle={onClickHandler}>
            {getSettings()}
          </Accordion>
          {getDeleteTemplate()}
        </PBInspector>
      )
    }
  ];

  const meterSettingsDropdown = [
    ...generalSettingsDropdown,
    {
      name: 'Metered wall Settings',
      component: <PaywallSettings title="Metered wall Settings" isActive={true} onCloseHandler={clickHandler} />
    }
  ];

  if (configuredDomains.length > 1) {
    customSettingsDropdown.push({
      name: 'Importer',
      component: (
        <Importer
          title="Importer"
          isActive={true}
          onCloseHandler={clickHandler}
          domain={domain}
          publisherId={publisherId}
        />
      )
    });
  }

  const hidePageSettings = ['search', 'available-plans', 'plan-change-overview'];
  const mapCustomSettingsDropdown = () => {
    if (hidePageSettings.includes(pageType)) {
      return generalSettingsDropdown;
    }
    if (pageType === 'meteredPaywall') {
      return meterSettingsDropdown;
    }
    return customSettingsDropdown;
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>Settings</p>
      <ul className={styles.items}>
        {mapCustomSettingsDropdown().map((option: any, index: any) => {
          return (
            <li key={index}>
              <div
                className={styles.list}
                onClick={event => openSideBar(event, index)}
                data-test-id={camelCase(option.name)}
              >
                {option.name}
              </div>
              <span className={`${index === isInspector ? styles.openPanel : styles.panel}`}>{option.component}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { HeaderDropdown };
