import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { Checkbox } from '@quintype/em/components/checkbox';
import { TextField } from '@quintype/em/components/text-field';
import { FieldLabel } from '@quintype/em/components/field-label';
import Select from '@quintype/em/components/select';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import { updateHaveUnsavedChanges, ampSetting } from '../../actions/builder-action';
import { ampLocalization } from './localization-elements-data';
import { Separator } from '../../atoms/separator';
import { ToggleButton } from '../toggle-button';
import { AD_PROVIDERS } from '../../constant';
import { IAmpConfig } from '../../utils/interfaces';
import ViewEditor from '../../atoms/inspector-accordion-button/button';
import styles from './amp-setting.module.css';

interface IStore {
  builder: {
    config: {
      general: {
        amp: {
          'amp-story-pages': boolean;
          'align-visual-story-logo': string;
          enableAmpCustomLabels: boolean;
          localizedElements: object;
          ads: {
            visualStories: {
              enabled: boolean;
              provider: string;
              adsense: {
                clientId: string;
                slotId: string;
              };
              admanager: {
                adunitPath: string;
              };
              mgid: {
                widgetId: string;
              };
            };
          };
        };
      };
    };
  };
}

interface ICustomLabels {
  type: string;
  label: string;
  placeholder: string;
  value: string;
}

const AmpSettings = ({ toggleConfirmationPopup }: any) => {
  const {
    'amp-story-pages': enableAmpStoryPages = true,
    enableAutoAdvance = false,
    'align-visual-story-logo': visualStoryLogoAlignment = 'center',
    enableAmpCustomLabels,
    localizedElements,
    ads = {}
  } = useSelector((state: IStore | {}) => get(state, ['builder', 'config', 'general', 'amp'], {}));
  const dispatch = useDispatch();
  const [visualStoryAdsEnabled, setEnableVisualStoryAds] = useState(get(ads, ['visualStories', 'enabled'], false));
  const [visualStoryAdProvider, setVisualStoryAdProvider] = useState(get(ads, ['visualStories', 'provider']));

  const adProviderOnchange = (e: React.MouseEvent | React.TouchEvent | React.ChangeEvent) => {
    const provider = (e.target as HTMLInputElement).value;
    const payload = merge({}, { ads }, { ads: { visualStories: { provider } } });
    updateSettings(payload);
    setVisualStoryAdProvider(provider);
  };

  const updateSettings = (payload: IAmpConfig) => {
    dispatch(ampSetting(payload));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const logoAlignmentOptions = (align: string) => {
    switch (align) {
      case 'left':
        return [{ label: 'Left', value: 'left' }];
      case 'center':
        return [{ label: 'Center', value: 'center' }];
      default:
        return [
          { label: 'Left', value: 'left' },
          { label: 'Center', value: 'center' }
        ];
    }
  };

  const getAmpCustomLabels = () => {
    if (!enableAmpCustomLabels) {
      return null;
    }

    return ampLocalization.map((item: ICustomLabels, index) => {
      const { type = '', label = '', placeholder = '', value = '' } = item;
      if (type === 'textField') {
        return (
          <TextField
            value={get(localizedElements, [value], '')}
            label={label}
            key={`${index}`}
            placeholder={placeholder}
            onChange={(updateText: string) => updateSettings({ localizedElements: { [value]: updateText } })}
          />
        );
      }
    });
  };

  return (
    <>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          label="Story Pages In AMP Format"
          id="amp"
          checked={enableAmpStoryPages}
          onChange={(keyValue: boolean) => updateSettings({ 'amp-story-pages': keyValue })}
        />
      </div>

      <div className={styles.checkboxWrapper}>
        <Checkbox
          label="Auto Advance Story Cards"
          id="amp-enableAutoAdvance"
          checked={enableAutoAdvance}
          onChange={(keyValue: boolean) => updateSettings({ enableAutoAdvance: keyValue })}
        />
      </div>

      <Select
        label="Web Story Logo Alignment"
        defaultValue={logoAlignmentOptions(visualStoryLogoAlignment)}
        options={logoAlignmentOptions('')}
        onChange={(key: { value: string }) => updateSettings({ 'align-visual-story-logo': key.value })}
      />

      <div className={styles.checkboxWrapper}>
        <Checkbox
          label="Enable Custom Labels"
          id="enableAmpCustomLabels"
          checked={enableAmpCustomLabels}
          onChange={(keyValue: boolean) => updateSettings({ enableAmpCustomLabels: keyValue })}
        />
      </div>
      {getAmpCustomLabels()}
      <Separator />
      <div>
        <FieldLabel classname={styles['block-field-label']} label="Ads for Web Stories" />
        <ToggleButton
          label="Show Ads"
          toggleName="showVisualStoryAds"
          id="showVisualStoryAds"
          updateToggleState={visualStoryAdsEnabled}
          updateToggleButton={(name: string, value: boolean) => {
            const payload = merge({}, { ads }, { ads: { visualStories: { enabled: false } } });
            if (value) {
              payload.ads.visualStories.enabled = true;
              setEnableVisualStoryAds(true);
            } else {
              setEnableVisualStoryAds(false);
            }
            updateSettings(payload);
          }}
        />
        {visualStoryAdsEnabled && (
          <div className={styles['visualstory-ad-radio-btns']}>
            <Radio
              name="visualstory-adProvider"
              label="Select Ad Provider"
              selected={visualStoryAdProvider}
              onChange={adProviderOnchange}
            >
              <RadioOption
                value={AD_PROVIDERS.GOOGLE_ADSENSE}
                checked={visualStoryAdProvider === AD_PROVIDERS.GOOGLE_ADSENSE}
              >
                Google AdSense
              </RadioOption>
              <RadioOption
                value={AD_PROVIDERS.GOOGLE_AD_MANAGER}
                checked={visualStoryAdProvider === AD_PROVIDERS.GOOGLE_AD_MANAGER}
              >
                Google Ad Manager
              </RadioOption>
              <RadioOption value={AD_PROVIDERS.MGID} checked={visualStoryAdProvider === AD_PROVIDERS.MGID}>
                MGID
              </RadioOption>
            </Radio>
            {visualStoryAdProvider === AD_PROVIDERS.GOOGLE_ADSENSE && (
              <div className={styles['adprovider-inputs']}>
                <TextField
                  classname={styles['adsense-client-id']}
                  value={get(ads, ['visualStories', 'adsense', 'clientId'], '')}
                  label="Client ID"
                  placeholder="ca-pub-0000000000000000"
                  onChange={(updateText: string) =>
                    updateSettings(
                      merge({}, { ads }, { ads: { visualStories: { adsense: { clientId: updateText } } } })
                    )
                  }
                />
                <TextField
                  classname={styles['adsense-slot-id']}
                  value={get(ads, ['visualStories', 'adsense', 'slotId'], '')}
                  label="Slot ID"
                  placeholder="00000000"
                  onChange={(updateText: string) =>
                    updateSettings(merge({}, { ads }, { ads: { visualStories: { adsense: { slotId: updateText } } } }))
                  }
                />
              </div>
            )}
            {visualStoryAdProvider === AD_PROVIDERS.GOOGLE_AD_MANAGER && (
              <div className={`${styles['admanager-wrapper']} ${styles['adprovider-inputs']}`}>
                <TextField
                  value={get(ads, ['visualStories', 'admanager', 'adunitPath'], '')}
                  label="Ad Unit Path"
                  placeholder="/30497360/a4a/amp_story_dfp_example"
                  onChange={(updateText: string) =>
                    updateSettings(
                      merge({}, { ads }, { ads: { visualStories: { admanager: { adunitPath: updateText } } } })
                    )
                  }
                />
              </div>
            )}
            {visualStoryAdProvider === AD_PROVIDERS.MGID && (
              <div className={styles['adprovider-inputs']}>
                <TextField
                  value={get(ads, ['visualStories', 'mgid', 'widgetId'], '')}
                  label="Widget ID"
                  placeholder="123456"
                  onChange={(updateText: string) =>
                    updateSettings(merge({}, { ads }, { ads: { visualStories: { mgid: { widgetId: updateText } } } }))
                  }
                />
              </div>
            )}
          </div>
        )}
        {/* leaving this commented out on purpose. It will be enabled when we implement AMP validator */}
        {/* <div className={styles['insert-amp-custom-code']}>
          <ViewEditor
            toggleConfirmationPopup={toggleConfirmationPopup}
            navigationPath="amp-custom-code"
            text="Insert Custom Code"
          />
        </div> */}
      </div>
    </>
  );
};

export default AmpSettings;
