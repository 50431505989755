import { Accordion, AccordionSection } from '@quintype/em/components/accordion';
import get from 'lodash/get';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateGeneralConfig } from '../../actions/general-settings-action';
import BreakingNewsVisibilitySettings from '../../ahead/ahead-breaking-news/breaking-news-visibility-settings';
import { Palette } from '../color-palette';
import { ToggleButton } from '../toggle-button';
import { ThemeSetter } from '../theme-setter';
import FallbackImage from '../fallback-image';
import { LanguageFonts } from '../fonts';
import { Localization } from '../localization';
import { UploadLogo } from '../upload-logo';
import styles from './visual.module.css';

interface IPBState {
  isAccordionActive: false;
}
const Visual = () => {
  const onClickHandler = useSelector((state: IPBState) => {
    return (prevState: { isAccordionActive: any }) => ({ isAccordionActive: !prevState.isAccordionActive });
  });
  const { enableDarkModePreview = false } = useSelector(state => get(state, ['builder', 'config'], {}));
  const { primaryColor, darkPrimaryColor, enableRoundedCorners = false } = useSelector((state: IPBState) =>
    get(state, ['builder', 'config', 'general'])
  );

  const dispatch = useDispatch();
  const updatePrimaryColor = (key: string, value: string) => {
    dispatch(updateGeneralConfig({ [key]: value }));
  };

  return (
    <div className={styles['visual-content-wrapper']}>
      <Accordion hasSingleActivePanel onToggle={() => onClickHandler}>
        <AccordionSection label="Language and Fonts" isLabelUpperCase index={0}>
          <LanguageFonts onClickHandler={onClickHandler} />
        </AccordionSection>
        <AccordionSection label="Palette" isLabelUpperCase index={1}>
          <Palette description="Add upto 8 brand colours." />

          <ThemeSetter
            label="Choose a primary color for website"
            updateTheStore={updatePrimaryColor}
            lightColorExtract={primaryColor}
            lightUpdateStoreKey="primaryColor"
            darkColorExtract={darkPrimaryColor}
            darkUpdateStoreKey="darkPrimaryColor"
            lightDefaultColor="#3cab92"
            darkDefaultColor="#3cab92"
            enableDarkModePreview={enableDarkModePreview}
          />
        </AccordionSection>
        <AccordionSection label="Logo" isLabelUpperCase index={2}>
          <UploadLogo />
        </AccordionSection>
        <AccordionSection label="Image settings" isLabelUpperCase index={2}>
          <ToggleButton
            label="Rounded Image Corners"
            toggleName="enableRoundedCorners"
            id="imageRoundedCorners"
            updateToggleState={enableRoundedCorners}
            updateToggleButton={(name: string, value: boolean) => {
              dispatch(updateGeneralConfig({ [name]: value }));
            }}
          />
        </AccordionSection>
        <AccordionSection label="Fallback image" isLabelUpperCase index={4}>
          <FallbackImage />
        </AccordionSection>
        <AccordionSection label="Custom Labels" isLabelUpperCase index={5}>
          <Localization />
        </AccordionSection>
        <AccordionSection label="Breaking News" isLabelUpperCase index={6}>
          <BreakingNewsVisibilitySettings />
        </AccordionSection>
      </Accordion>
    </div>
  );
};

export { Visual };
